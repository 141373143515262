import {
  CHANGE_CONFERENCE_LOCATION,
  DB_TIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  IN_PERSON,
  IN_PERSON_OR_REMOTE,
  REMOTE,
  REMOVE_CONFERENCE_SELECTION,
  TWELVE_HOUR_FORMAT,
  TWENTY_FOUR_HOUR_FORMAT,
  UPDATE_TEACHER_SLOTS,
  ERROR_MESSAGE_FAILED,
} from './constants';

function addReservation(reservations, conference) {
  const dateTime = new Date(conference.start_date);
  const dateEpoch = dateTime.getTime();
  const startTime = conference.start_time;
  const endTime = conference.end_time;
  const epochStart = getEpochFromTimeString(startTime, dateTime);
  const epochEnd = getEpochFromTimeString(endTime, dateTime);

  const newReservationTime = {
    epochStart,
    epochEnd,
    conferences: [conference],
    startTime,
    endTime,
  };

  const reservationDate = reservations.find((res) => res.dateEpoch === dateEpoch);
  if (!reservationDate) {
    const newReservationDate = {
      dateEpoch,
      startDate: conference.start_date,
      times: [newReservationTime],
    };
    reservations.push(newReservationDate);
    return;
  }

  const reservationTime = reservationDate.times.find((resTime) => {
    return resTime.epochStart === epochStart && resTime.epochEnd === epochEnd;
  });
  if (!reservationTime) {
    reservationDate.times.push(newReservationTime);
    return;
  }

  reservationTime.conferences.push(conference);
}

export function getSchedule(students) {
  const reservations = [];

  students.forEach((student) => {
    if (!student.teachers) {
      return;
    }
    student.teachers.forEach((teacher) => {
      if (!teacher.conferences) {
        return;
      }
      teacher.conferences.forEach((conference) => {
        if (conference.selected) {
          // preferred name can be first name but only use preferred name if it is included in display name format
          const useStudentPreferredName =
            student.preferred_name &&
            student.display_name &&
            student.display_name.includes(student.preferred_name);
          conference.studentName = useStudentPreferredName
            ? student.preferred_name
            : student.first_name;

          conference.teacherName = getDisplayName(teacher);
          conference.studentId = student.person_fk;
          conference.teacherId = teacher.person_fk;
          addReservation(reservations, conference);
        }
      });
    });
  });
  return sortReservations(reservations);
}

function sortReservations(reservations) {
  reservations.forEach((reservationDate) => {
    reservationDate.times = reservationDate.times.sort((a, b) => {
      return a.epochStart - b.epochStart || a.epochEnd - b.epochEnd;
    });
  });
  return reservations.sort((a, b) => a.dateEpoch - b.dateEpoch);
}

export function getOverlappingTimes(times, resTime) {
  return times.filter((time) => {
    if (time.epochStart === resTime.epochStart && time.epochEnd === resTime.epochEnd) {
      // don't count exact time matches
      return false;
    }
    return time.epochEnd > resTime.epochStart && time.epochStart < resTime.epochEnd;
  });
}

export function getTimeConflicts(schedule) {
  const conflicts = {};
  schedule.forEach((reservationDate) => {
    reservationDate.times.forEach((reservationTime) => {
      const { conferences, startTime, endTime } = reservationTime;
      const conflictKey = `${reservationDate.startDate}-${startTime}-${endTime}`;

      const overlapTimes = getOverlappingTimes(reservationDate.times, reservationTime, true);
      const hasOverlaps = overlapTimes.length > 0;
      const isCurrentConflict = hasOverlaps || conferences.length > 1;

      const conferenceConflicts = conferences.map((c) => c);
      if (hasOverlaps) {
        // add overlap conferences to conflicts list
        overlapTimes.forEach((overlapTime) => {
          overlapTime.conferences.forEach((conference) => {
            conferenceConflicts.push(conference);
          });
        });
      }

      conflicts[conflictKey] = {
        isCurrentConflict,
        hasOverlaps,
        conferenceConflicts,
      };
    });
  });
  return conflicts;
}

function getTeacherObject(students, data) {
  const { studentKey, teacherKey } = data;

  let teacherFound = null;
  students.forEach((student) => {
    if (student.person_fk === studentKey && student.teachers) {
      student.teachers.forEach((teacher) => {
        if (teacher.person_fk === teacherKey) {
          teacherFound = teacher;
        }
      });
    }
  });
  return teacherFound;
}

export function updateTeacherSlots(students, data) {
  const { newConfKey, prevConfKey, newConferences } = data;

  const teacher = getTeacherObject(students, data);
  if (newConferences) {
    // update conferences to new values from fetch
    teacher.conferences = newConferences;
  }
  if (newConfKey === null) {
    // no selection update is needed
    return students;
  }

  teacher.conferences.forEach((conference) => {
    if (conference.event_fk === newConfKey) {
      conference.selected = true;
    }
    // Values 'showSuccess' and 'showRemoval' are set here to tell
    // the ScheduleItem component which slide transition to use.
    conference.showSuccess = conference.event_fk === newConfKey;
    conference.showRemoval = prevConfKey && conference.event_fk === prevConfKey;
  });

  return students;
}

export function removeConferenceSelection(students, data) {
  const teacher = getTeacherObject(students, data);

  if (teacher && teacher.conferences) {
    teacher.conferences.forEach((conference) => {
      conference.showRemoval = data.prevConfKey === conference.event_fk;
    });
  }
  return students;
}

export function updateConferenceLocation(students, data) {
  data.studentKey = data.student_id;
  data.teacherKey = data.teacher_id;
  const teacher = getTeacherObject(students, data);

  if (teacher && teacher.conferences) {
    teacher.conferences.forEach((conference) => {
      if (conference.event_fk === data.event_fk) {
        conference.attendance_option_choice = data.location;
      }
    });
  }
  return students;
}

export async function makeRequest(url, method, authToken, body = null) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store',
    'X-CSRF-Token': authToken,
  };

  const params = {
    method,
    headers,
  };
  if (body) {
    params.body = JSON.stringify(body);
  }
  try {
    const response = await fetch(url, params);
    return await response.json();
  } catch {
    return ERROR_MESSAGE_FAILED;
  }
}

export function getNumOfSelections(student) {
  let numSelections = 0;
  if (student.teachers) {
    student.teachers.forEach((teacher) => {
      if (teacher.conferences) {
        for (let x = 0; x < teacher.conferences.length; ++x) {
          if (teacher.conferences[x].selected) {
            numSelections += 1;
            return; // skip to next teacher
          }
        }
      }
    });
  }
  return numSelections;
}

export function getEpochFromTimeString(timeString, dateTime) {
  const [hours, mins, secs] = timeString.split(':');
  dateTime.setHours(hours);
  dateTime.setMinutes(mins);
  dateTime.setSeconds(secs);
  return dateTime.getTime();
}

export function isTimeAlreadySelected(schedule, confKey) {
  const found = schedule.find((reservationDate) => {
    return reservationDate.times.find((reservationTime) => {
      return reservationTime.conferences.find((conference) => {
        return conference.event_fk === confKey;
      });
    });
  });
  return Boolean(found);
}

export function getTimeConflict(timeConflicts, startDate, startTime, endTime) {
  const conflictKey = `${startDate}-${startTime}-${endTime}`;
  if (
    timeConflicts &&
    conflictKey in timeConflicts &&
    timeConflicts[conflictKey].isCurrentConflict
  ) {
    return timeConflicts[conflictKey];
  }
  return null;
}

export function getPotentialTimeConflicts(schedule, conference, timeConflicts) {
  const confKey = `${conference.start_date}-${conference.start_time}-${conference.end_time}`;
  if (confKey in timeConflicts) {
    return timeConflicts[confKey].conferenceConflicts;
  }
  const dateTime = new Date(conference.start_date);
  const epochStart = getEpochFromTimeString(conference.start_time, dateTime);
  const epochEnd = getEpochFromTimeString(conference.end_time, dateTime);
  const conflicts = [];

  // need to find array of potential overlapping times
  schedule.forEach((resDate) => {
    if (resDate.startDate === conference.start_date) {
      resDate.times.forEach((resTime) => {
        if (epochEnd > resTime.epochStart && epochStart < resTime.epochEnd) {
          resTime.conferences.forEach((conf) => {
            conflicts.push(conf);
          });
        }
      });
    }
  });
  return conflicts;
}

export function getMomentTimeFormat(timeFormat) {
  return timeFormat === '24' ? TWENTY_FOUR_HOUR_FORMAT : TWELVE_HOUR_FORMAT;
}

export function getTimeString(timeFormat, startTime, endTime) {
  const momentTimeFormat = getMomentTimeFormat(timeFormat);
  const start = moment(startTime, DB_TIME_FORMAT).format(momentTimeFormat);
  const end = moment(endTime, DB_TIME_FORMAT).format(momentTimeFormat);
  return `${start} - ${end}`;
}

export function getDateFormat(dateFormat) {
  return dateFormat || DEFAULT_DATE_FORMAT;
}

export function areConferencesAvailable(student) {
  return (
    student.teachers &&
    student.teachers.some((teacher) => {
      return teacher.conferences && teacher.conferences.length;
    })
  );
}

export function getDisplayName(person) {
  return person.display_name || `${person.first_name} ${person.last_name}`;
}

export const isInPersonSelected = (conference) => conference.attendance_option_choice === IN_PERSON;
export const isRemoteSelected = (conference) => conference.attendance_option_choice === REMOTE;
export const isInPersonOnly = (conference) => conference.attendance_option === IN_PERSON;
export const isRemoteOnly = (conference) => conference.attendance_option === REMOTE;
export const isInPersonOrRemote = (conference) =>
  conference.attendance_option === IN_PERSON_OR_REMOTE;

export function isAttendanceOptionAvailable(students) {
  // attendance icons need to show if at least one conference
  // has a remote option AND at least one conference has an
  // in-person option
  let hasRemoteOnly = false;
  let hasInPersonOnly = false;

  return students.some((student) => {
    return (
      student.teachers &&
      student.teachers.some((teacher) => {
        return (
          teacher.conferences &&
          teacher.conferences.some((conference) => {
            if (isInPersonOrRemote(conference)) {
              // has at least one conference with the option between the two choices
              return true;
            }

            hasRemoteOnly = hasRemoteOnly || isRemoteOnly(conference);
            hasInPersonOnly = hasInPersonOnly || isInPersonOnly(conference);

            if (hasRemoteOnly && hasInPersonOnly) {
              return true;
            }
            return false;
          })
        );
      })
    );
  });
}

export function reducer(state, action) {
  let hasAttendanceOption;
  let students;
  switch (action.type) {
    case REMOVE_CONFERENCE_SELECTION:
      students = removeConferenceSelection(state.students, action.data);
      hasAttendanceOption = isAttendanceOptionAvailable(students);
      return {
        students,
        hasAttendanceOption,
      };
    case UPDATE_TEACHER_SLOTS:
      students = updateTeacherSlots(state.students, action.data);
      hasAttendanceOption = isAttendanceOptionAvailable(students);
      return {
        students,
        hasAttendanceOption,
      };
    case CHANGE_CONFERENCE_LOCATION:
      students = updateConferenceLocation(state.students, action.data);
      return {
        students,
        hasAttendanceOption: state.hasAttendanceOption,
      };
    default:
      return state;
  }
}
