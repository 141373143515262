import React from 'react';
import classNames from 'classnames';
import LmsSidebarTabs from './LmsSidebarTabs';
import useNavigationStore from './useNavigationStore';

const SidebarToggleButton = () => {
  const { isExpanded, setIsExpanded } = useNavigationStore();

  const containerClasses = classNames(
    'flex items-center justify-center border border-gray-500 hover:border-gray-600 hover:bg-gray-300 transition-colors rounded-full bg-white absolute w-6 h-6 top-[13px] -right-3 cursor-pointer z-50 vx-tooltipped vx-tooltipped--s'
  );

  const arrowClasses = classNames('nc-icon-glyph font-black text-xs', {
    'arrows-1_bold-left': isExpanded,
    'arrows-1_bold-right': !isExpanded,
  });

  return (
    <div
      className={containerClasses}
      aria-label={isExpanded ? 'Collapse sidebar' : 'Expand sidebar'}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <i className={arrowClasses}></i>
    </div>
  );
};

const LmsDesktopSidebar = () => {
  const className = useNavigationStore((state) => state.className);
  const { isExpanded, setIsModalOpen } = useNavigationStore();

  const sidebarClasses = classNames(
    ' md:w-fit md:mt-0 border-r border-gray-200 bg-gray-100 h-full md:rounded-l-2xl relative',
    {
      'md:max-w-64 lg:w-64': isExpanded,
    }
  );

  return (
    <div className="max-md:hidden h-full">
      <div className={sidebarClasses}>
        <SidebarToggleButton />
        <div className="w-full items-center">
          <div
            className={
              'float-left p-3 w-fit flex md:m-3 mb-0 bg-neutral-3 md:bg-gray-100 items-center text-neutral-6 md:text-gray-900 group font-medium rounded-md cursor-pointer'
            }
            onClick={() => setIsModalOpen(true)}
          >
            <div
              className="vx-tooltipped vx-tooltipped--s self-start"
              aria-label={'Change class'}
            >
              <i
                className={
                  'nc-icon-outline education_chalkboard text-neutral-6 md:text-gray-600 text-xl md:group-hover:text-black'
                }
              ></i>
            </div>
            {isExpanded && (
              <div className="ml-3 text-left leading-3 md:leading-5">
                <div className="font-semibold text-neutral-6 md:text-black">{className}</div>
                <div className="text-xs pointer-events-auto text-neutral-6 md:text-gray-700 md:group-hover:text-black">
                  change class
                </div>
              </div>
            )}
          </div>
        </div>
        <LmsSidebarTabs isExpanded={isExpanded} />
      </div>
    </div>
  );
};

export default LmsDesktopSidebar;
