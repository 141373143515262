import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from './DatePicker';
import { HTTP_METHOD, useFetch } from '../../../hooks/useFetch';
import SettingsDropdownButton from './SettingsDropdownButton';
import MarkAttendanceButton from './MarkAttendanceButton';
import AttendanceContext from './AttendanceContext';
import {
  ATTENDANCE_CATEGORY_PRESENT,
  ATTENDANCE_CATEGORY_TARDY,
  ATTENDANCE_STATUS_NOT_AVAILABLE,
  ATTENDANCE_STATUS_NOT_TAKEN,
  ATTENDANCE_STATUS_TAKEN,
  isAttendanceTaken,
} from './utils';
import PeriodSelect from './PeriodSelect';
import { useTranslation } from 'react-i18next';

const AttendanceTotals = () => {
  const { students, attendance } = React.useContext(AttendanceContext);
  if (!students || !attendance || !Array.isArray(students) || typeof attendance !== 'object') {
    return null;
  }

  const totalStudents = students.length;
  const totals = _.countBy(students, (entry) => {
    const studentId = entry.id;
    const category = attendance[studentId].category;
    return category === null ||
      category === ATTENDANCE_CATEGORY_PRESENT ||
      category === ATTENDANCE_CATEGORY_TARDY
      ? 'expected'
      : 'notExpected';
  });

  return (
    <div
      className="attendance-total"
      data-testid="attendance-total"
    >
      {`Total: ${totalStudents} (Expected: ${totals.expected || 0}, Not Expected: ${
        totals.notExpected || 0
      })`}
    </div>
  );
};

const AttendanceHeader = ({
  attendanceTakenStatus,
  internalClassId,
  date,
  hasAttendance,
  handleDateChange,
  isMaster,
  settings,
  setSettings,
  today,
  markAttendanceAsTaken,
  scheduledDays,
  switchPeriod,
  periods,
  isLoadingPeriod,
}) => {
  const isComplete = isAttendanceTaken(attendanceTakenStatus);
  const hasAttendanceStatusRecord = attendanceTakenStatus !== ATTENDANCE_STATUS_NOT_AVAILABLE;
  const { loading, sendFetchRequest: sendUpdateAttendanceStatusRequest } = useFetch();
  const { t } = useTranslation('words');
  let title = t('Attendance');
  if (hasAttendance) {
    if (isMaster) {
      title = t('Daily Attendance');
    } else {
      title = t('Class Attendance');
    }
  }

  const toggleComplete = async () => {
    const PORTALS_REQUEST_URL_PARAMS = {
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
    };

    const url = Routes.update_class_attendance_status_path({
      ...PORTALS_REQUEST_URL_PARAMS,
    });

    const body = {
      internal_class_id: internalClassId,
      attendance_status: ATTENDANCE_STATUS_TAKEN,
      attendance_date: date,
      period_id: periods.selected.value,
    };

    await sendUpdateAttendanceStatusRequest({
      url,
      method: HTTP_METHOD.put,
      body,
    });
    markAttendanceAsTaken();
  };

  return (
    <div className="ae-container attendance-header">
      <div className="ae-grid">
        <div className="ae-grid__item item-sm-6 item-xs-12">
          <div className="attendance-heading">
            <h2 className="line-height-normal">
              {title}
              {hasAttendance && hasAttendanceStatusRecord ? (
                <span
                  className={`attendance-status-badge vx-badge ${
                    isComplete ? 'vx-badge--green active' : ''
                  }`}
                >
                  {isComplete ? 'Taken' : 'Not Taken'}
                </span>
              ) : null}
            </h2>
            <div className="attendance-dropdown-container">
              <DatePicker
                selectYears={10}
                onChange={handleDateChange}
                date={date}
                today={today}
                selectedDateIsTaken={isComplete}
                scheduledDays={scheduledDays}
              />
              {hasAttendance && (
                <PeriodSelect
                  scheduledDays={scheduledDays}
                  switchPeriod={switchPeriod}
                  periods={periods}
                  date={date}
                  isLoadingPeriod={isLoadingPeriod}
                />
              )}
            </div>
            <AttendanceTotals />
          </div>
        </div>
        {hasAttendance ? (
          <div className="ae-grid__item item-sm-6 item-xs-12 attendance-buttons max-md:flex max-md:flex-col">
            {!isComplete && hasAttendanceStatusRecord ? (
              <MarkAttendanceButton
                onClick={toggleComplete}
                loading={loading}
              />
            ) : null}
            <SettingsDropdownButton
              settings={settings}
              setSettings={setSettings}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

AttendanceHeader.propTypes = {
  attendanceTakenStatus: PropTypes.oneOf([
    ATTENDANCE_STATUS_NOT_AVAILABLE,
    ATTENDANCE_STATUS_NOT_TAKEN,
    ATTENDANCE_STATUS_TAKEN,
  ]).isRequired,
  internalClassId: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  hasAttendance: PropTypes.bool.isRequired,
  isMaster: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  statuses: PropTypes.object.isRequired,
};

export default AttendanceHeader;
