import React from 'react';

import AuthTokenContext from 'components/shared/AuthTokenContext';

const defaultConfig = {
  requestMethod: 'POST',
  fieldValidations: {},
  buildSubmitData: (values) => values,
  submitSuccessMessageText: 'Success!',
  submitFailureMessageText: 'Error!',
  validateFailureMessageText: 'There is an issue with one or more of the fields.',
};

export default function useForm(props) {
  const { url, initialFormValues } = props;

  const config = { ...defaultConfig, ...props.config };

  const authToken = props.authToken || React.useContext(AuthTokenContext);

  const [formState, setFormState] = React.useState('LOADED');
  const [formMessage, setFormMessage] = React.useState({});

  const [formValues, setFormValues] = React.useState(initialFormValues);
  const setFormValue = (name, value) => setFormValues((values) => ({ ...values, [name]: value }));
  const resetFormValues = () => setFormValues(initialFormValues);
  const hasFormValues = () => Object.values(formValues).filter((value) => value).length > 0;

  /***************************************************************************************************
   ** ERRORS
   ***************************************************************************************************/

  const [formErrors, setFormErrors] = React.useState({});
  const setFormError = (name, error) => setFormErrors((errors) => ({ ...errors, [name]: error }));
  const clearFormError = (name) => delete formErrors[name];

  const [isFormValid, setIsFormValid] = React.useState(true);

  React.useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [formErrors]);

  /***************************************************************************************************
   ** VALIDATIONS
   ***************************************************************************************************/

  // SOURCE: https://emailregex.com/
  const EMAIL_FORMAT_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // SOURCE: https://urlregex.com/
  const URL_FORMAT_REGEX =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

  const FIELD_VALIDATION_TYPES = [
    {
      type: 'isRequired',
      validateFn: (value) => value !== undefined && value !== null && String(value).trim() !== '',
      errorText: 'This field is required',
    },
    {
      type: 'hasEmailFormat',
      validateFn: (value) => !value || EMAIL_FORMAT_REGEX.test(value),
      errorText: 'This field has an invalid email',
    },
    {
      type: 'hasUrlFormat',
      validateFn: (value) => !value || URL_FORMAT_REGEX.test(value),
      errorText: 'This field has an invalid url',
    },
  ];

  const validationFieldsByType = (type) => {
    const fieldValidationsArray = Object.entries(config.fieldValidations);
    const fields = fieldValidationsArray.filter(([fieldName, fieldValidations]) =>
      fieldValidations.includes(type)
    );
    const fieldNames = fields.map(([fieldName, fieldValidations]) => fieldName);

    return fieldNames;
  };

  const validateForm = () => {
    let isFormValid = true;
    const errors = {};

    for (const { type, validateFn, errorText } of FIELD_VALIDATION_TYPES) {
      const fields = validationFieldsByType(type);

      fields.forEach((name) => {
        const fieldValue = formValues[name];
        const isFieldValid = validateFn(fieldValue);

        if (!errors[name] && !isFieldValid) {
          isFormValid = false;
          errors[name] = errorText;
        }
      });
    }

    setFormErrors(errors);

    return isFormValid;
  };

  /***************************************************************************************************
   ** REQUEST
   ***************************************************************************************************/

  const REQUEST_HEADERS = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store',
    'X-CSRF-Token': authToken,
  };

  const sendRequest = async (data, method) => {
    const response = await fetch(url, {
      method: method,
      headers: REQUEST_HEADERS,
      body: JSON.stringify(data),
    });

    const responseBody = await response.json();

    if (response.ok) {
      return responseBody;
    } else {
      throw new Error(responseBody.status);
    }
  };

  /***************************************************************************************************
   ** HANDLERS
   ***************************************************************************************************/

  const handleFormFieldChange = (event) => {
    const { name, value } = event.target;
    setFormValue(name, value);
    clearFormError(name);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setFormState('SUBMITTING');

    const isFormValid = validateForm();

    if (!isFormValid) {
      setFormState('LOADED');
      setFormMessage({
        type: 'error',
        text: config.validateFailureMessageText,
      });
    } else {
      const submitData = config.buildSubmitData(formValues);

      sendRequest(submitData, config.requestMethod)
        .then((data) => {
          setFormState('SUBMITTED');
          setFormMessage({
            type: 'success',
            text: config.submitSuccessMessageText,
          });
        })
        .catch((error) => {
          setFormState('LOADED');
          setFormMessage({
            type: 'error',
            text: config.submitFailureMessageText,
          });
        });
    }
  };

  const handleFormReset = () => {
    setFormState('LOADED');
    setFormMessage({});
    resetFormValues();
  };

  /***************************************************************************************************
   ** RETURN
   ***************************************************************************************************/

  return {
    formState,
    formMessage,
    formValues,
    formErrors,
    handleFormFieldChange,
    handleFormSubmit,
    handleFormReset,
  };
}
