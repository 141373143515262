import React from 'react';
import classNames from 'classnames';

export default function MessageBanner(props) {
  const { type, text } = props;

  if (!text) return null;

  const messageBannerClassNames = classNames({
    'vx-message-banner': true,
    'vx-message-banner--text-align-center': true,
    'vx-message-banner--success': type === 'success',
    'vx-message-banner--danger': type === 'error',
  });

  return (
    <div
      className={messageBannerClassNames}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}
