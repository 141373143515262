import React from 'react';

const FieldLabel = ({ label, tooltipInfo }: { label: string; tooltipInfo?: string }) => {
  return (
    <div className="mt-2.5 text-gray-500 uppercase">
      {label}
      {tooltipInfo && (
        <span
          className="vx-tooltipped vx-tooltipped--s adjusted"
          aria-label={tooltipInfo}
        >
          <i className="ml-1 nc-icon-glyph ui-2_alert-circle-i" />
        </span>
      )}
    </div>
  );
};

export default FieldLabel;
