import React from 'react';
import { useCourseRequestState } from '../../../../../stores/useCourseRequestsStore';
import { ZgOption } from '../../types';

const SubjectsList = ({
  handleSelectSubject,
}: {
  handleSelectSubject: (subject: ZgOption) => void;
}) => {
  const { subjects } = useCourseRequestState();

  return (
    <div className="flex flex-wrap max-h-[280px] overflow-auto">
      {subjects.map((subject) => {
        return (
          <div
            className="max-h-12 overflow-auto basis-1/3 pr-1 pb-1.5 mb-1 underline max-sm:basis-1/2 max-sm:mb-1.5"
            key={subject.id}
          >
            <span
              className="cursor-pointer"
              onClick={() => handleSelectSubject(subject)}
            >
              {subject.description}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default SubjectsList;
