import { useMutation } from '@tanstack/react-query';
import useClassSettingStore from './stores/useClassSettingStore';
import { PORTALS_REQUEST_URL_PARAMS, makeRequest } from './helpers';
import { SqlErrorMessage } from './types';

// Send the request to the controller
async function updateConfiguration(
  db_source: string,
  configuration_target: string,
  new_value: boolean | string
) {
  const classState = useClassSettingStore.getState().classState;
  const url = Routes.update_class_settings_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    internal_class_id: classState.class_id,
  });

  const body = {
    db_source,
    configuration_target,
    new_value,
  };

  return await makeRequest(url, 'PUT', classState.authToken, body);
}

// Initializes useMutation
export function useUpdateConfiguration(
  onMutate: () => void,
  onError: (error: SqlErrorMessage) => void
) {
  return useMutation({
    mutationFn: async (params: {
      db_source: string;
      configuration_target: string;
      new_value: boolean | string;
    }) => {
      return await updateConfiguration(
        params.db_source,
        params.configuration_target,
        params.new_value
      );
    },
    // Optimistic update
    onMutate: onMutate,
    // Revert Optimistic update on error
    onError: onError, // TODO: add a notification of error?
  });
}
