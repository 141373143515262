import React from 'react';
import classNames from 'classnames';

const defaultProps = {
  isEnabled: true,
};

// prettier-ignore
function DropdownMenuItem(props) {
  const {
    label,
    icon,
    onClick,
    isEnabled,
  } = { ...defaultProps, ...props };

  const isDisabled = !isEnabled;

  const handleClick = React.useCallback((event) => {
    event.preventDefault();
    if (isDisabled) return;

    onClick(event);
  }, [onClick, isDisabled]);

  /**************************************************************************************************/

  const iconClassNames = classNames([
    'dropdown-menu-item-icon',
    'nc-icon-glyph',
    icon,
  ]);

  return (
    <div
      className="dropdown-menu-item"
      onClick={handleClick}
      disabled={isDisabled}
    >
      {icon && (
        <i className={iconClassNames} />
      )}
      {label}
    </div>
  );
}

export default React.memo(DropdownMenuItem);
