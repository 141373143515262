import React from 'react';

type LoadingPlaceholderProps = {
  outerCss: string;
  innerCss: string;
};

const LoadingPlaceholder = ({ outerCss, innerCss }: LoadingPlaceholderProps) => {
  return (
    <div
      className={`loading-placeholder relative overflow-hidden h-[26px] mb-1 rounded-sm bg-neutral-7 text-center ${outerCss}`}
    >
      <div
        className={`bg-gradient-to-r from-neutral-4 animate-loading absolute top-0 left-0 w-full ${innerCss}`}
      ></div>
    </div>
  );
};

export default LoadingPlaceholder;
