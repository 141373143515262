import React from 'react';
import { useConfigurations } from '../../../../stores/useGradebookStore';
import HeaderCell from './HeaderCell';

const Header = ({ label }: { label: string }) => {
  return (
    <HeaderCell classOverrides="bg-blue-6 border border-blue-5 leading-4 text-center flex flex-col pb-3 w-24 bg-neutral-6">
      <div
        className="mt-auto break-words"
        data-testid="total-grade-header"
      >
        {label}
      </div>
    </HeaderCell>
  );
};

const TotalGradeHeaders = () => {
  const {
    display_calculated_grade_column: displayCalculated,
    display_predicted_grade_column: displayPredicted,
    calculated_grade_label: calculatedLabel,
    predicted_grade_label: predictedLabel,
  } = useConfigurations();

  return (
    <>
      {displayCalculated && <Header label={calculatedLabel} />}
      {displayPredicted && <Header label={predictedLabel} />}
    </>
  );
};

export default TotalGradeHeaders;
