import { HTTP_METHOD } from '../../../hooks/useFetch';
import { CourseRequestFormData, CourseRequestUpdateData } from './types';

export const PORTALS_REQUEST_URL_PARAMS = {
  client: Portals.config.client,
  portal_name: Portals.config.portal_name,
};

export const updateRequestsComplete = async (
  requestsComplete,
  setRequestsComplete,
  personPk,
  courseRequestsCompleteRequest
): Promise<any> => {
  const route = Routes.toggle_course_requests_finalized_path;

  const url = route(personPk, {
    ...PORTALS_REQUEST_URL_PARAMS,
  });

  setRequestsComplete(!requestsComplete);
  await courseRequestsCompleteRequest({
    url,
    method: HTTP_METHOD.put,
    body: {
      course_requests_finalized: !requestsComplete,
    },
  });
};

export const getStudentData = async (student, sendStudentDataRequest) => {
  const requestParams = {
    ...PORTALS_REQUEST_URL_PARAMS,
    person_pk: student.person_pk || 0,
  };
  const url = Routes.student_course_request_path(requestParams);

  await sendStudentDataRequest({ url, method: HTTP_METHOD.get });
};

export const getCourseRequestData = async (personPk, sendCourseRequestDataRequest) => {
  const requestParams = {
    ...PORTALS_REQUEST_URL_PARAMS,
    person_pk: personPk || 0,
  };
  const url = Routes.course_request_courses_path(requestParams);

  await sendCourseRequestDataRequest({ url, method: HTTP_METHOD.get });
};

export const handleCreateRequest = async (
  personFk: number,
  data: CourseRequestFormData,
  requestGroup,
  version,
  createCourseRequest: (params: {
    url: string;
    method: string;
    body: CourseRequestFormData;
  }) => Promise<any>
): Promise<{ created_id: number }> => {
  const route =
    version === 2 ? Routes.course_request_create_path : Routes.course_request_create_v1_path;
  const url = route({
    ...PORTALS_REQUEST_URL_PARAMS,
  });

  data.request_group = requestGroup;
  const body = {
    person_fk: personFk,
    ...data,
  };
  return await createCourseRequest({ url, method: HTTP_METHOD.post, body });
};

export const handleUpdateRequest = async (
  requestId: number,
  field: string,
  value: string | number | boolean,
  updateCourseRequest: (params: {
    url: string;
    method: string;
    body: CourseRequestUpdateData;
  }) => Promise<any>
): Promise<{ updated_id: number }> => {
  const route = Routes.course_request_update_path;
  const url = route(requestId, {
    ...PORTALS_REQUEST_URL_PARAMS,
  });
  const body = {
    id: requestId,
    field,
    value,
  };
  return await updateCourseRequest({ url, method: HTTP_METHOD.put, body });
};

export const handleDeleteRequest = async (
  courseRequestPk: number,
  deleteCourseRequest: (params: { url: string; method: string }) => Promise<any>
): Promise<{ deleted_id: number }> => {
  const route = Routes.course_request_destroy_path;
  const url = route(courseRequestPk, {
    ...PORTALS_REQUEST_URL_PARAMS,
  });
  return await deleteCourseRequest({ url, method: HTTP_METHOD.delete });
};
