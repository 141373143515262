import React from 'react';

type DropdownOptionWithShortcutProps = {
  description: string;
  shortcut: string;
};
const DropdownOptionWithShortcut = ({ description, shortcut }: DropdownOptionWithShortcutProps) => {
  return (
    <div className="flex justify-between w-full h-6 items-center">
      <div className="w-32">{description}</div>
      <div
        className="bg-neutral-6 text-sm w-6 h-6 rounded-sm border border-neutral-4 text-neutral-3 text-center"
        title="keyboard shortcut"
      >
        {shortcut}
      </div>
    </div>
  );
};

export default DropdownOptionWithShortcut;
