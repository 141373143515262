import React from 'react';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { useClassGrades, useGridData } from '../queries';
import MobileGridPage from './MobileGridPage';
import AssignmentCell from './AssignmentCell';
import StudentRow from './StudentRow';
import TotalGradeTable from './TotalGradeTable';
import EmptyPage from '../../../shared/EmptyPage';
import { useLocation } from 'react-router-dom';
import { LocationState } from '../types';
import PageBanner from '../../../shared/PageBanner';
import AssignmentAverages from './AssignmentAverages';

const TableHeader = () => {
  const { data } = useGridData();

  return (
    <tr className="sticky top-0 z-50">
      {data.assignments.map((assignment, idx) => (
        <AssignmentCell
          assignment={assignment}
          key={idx}
        />
      ))}
    </tr>
  );
};

const TableBody = () => {
  const { data } = useGridData();
  const { assignments, students } = data;

  return (
    <>
      {students.map((student) => {
        return (
          <StudentRow
            key={student.person_pk}
            assignments={assignments}
            student={student}
          />
        );
      })}
      <AssignmentAverages
        students={students}
        assignments={assignments}
      />
    </>
  );
};

const GradebookGrid = () => {
  const { data: gridData } = useGridData();
  const { isLoading: isLoadingGrades } = useClassGrades();
  const location = useLocation<LocationState>();
  const bannerMessage = location.state?.bannerMessage;
  window.history.replaceState({}, document.title);
  const hasAssignments = gridData?.assignments.length;
  const hasStudents = gridData?.students.length;

  if (!gridData || isLoadingGrades) {
    return <LoadingSpinner />;
  }

  if (!hasStudents) {
    return <EmptyPage message="No students assigned to this class." />;
  }

  return (
    <>
      {bannerMessage && <PageBanner message={bannerMessage} />}
      <div className=" max-md:hidden flex relative overflow-auto min-h-[750px] h-[80vh] pb-5 pr-5">
        <TotalGradeTable />
        <table className={`h-fit z-10 ${!hasAssignments ? 'sticky top-0' : ''}`}>
          <thead>{Boolean(hasAssignments) && <TableHeader />}</thead>
          <tbody>
            <TableBody />
            {!hasAssignments && (
              <EmptyPage
                message="No assignments matching the current filters exist."
                subMessage="Click 'Add Assignment' button above to create one."
              />
            )}
          </tbody>
        </table>
      </div>
      <MobileGridPage />
    </>
  );
};

export default GradebookGrid;
