import React from 'react';
import { useField } from 'formik';
import { ElementMetadata } from '../types';

const VxErrorMessage = ({ element, metadata }: ElementMetadata) => {
  const [_, meta] = useField(metadata.dataKeypath);
  const hasError = meta.touched && meta.error;

  if (!hasError) {
    return null;
  }

  return (
    <div
      className="vx-validation-message vx-validation-message--display"
      data-testid="vx-error-message"
    >
      {meta.error}
    </div>
  );
};

export default VxErrorMessage;
