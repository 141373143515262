import React from 'react';
import { useClassState, useConfigurations } from '../../../../stores/useGradebookStore';
import { ArrayStatsType, Assignment, Student } from '../types';
import TodayCell from './TodayCell';
import Cell from './Cell';
import { useGridData } from '../queries';
import { ArrayStats } from '../../../shared/utils';
import { getCellDateColor, hasGrade } from '../helpers';
import BorderRow from './BorderRow';

type AssignmentAveragesRowProps = {
  assignments: Assignment[];
  students: Student[];
  category: string;
};
const AssignmentAveragesRow = ({ assignments, students, category }: AssignmentAveragesRowProps) => {
  const { data } = useGridData();
  const { today } = useClassState();

  const getAverageValue = (assignment: Assignment) => {
    const grades = data
      ? students
          .map((student) => {
            const gradeRecord =
              data.assignment_grades_by_student[
                `${student.person_pk}-${assignment.class_assignment_pk}`
              ];
            return hasGrade(gradeRecord) ? parseFloat(gradeRecord?.score) : null;
          })
          .filter((g) => g !== null)
      : [];

    return (new ArrayStats(grades) as ArrayStatsType)[category];
  };

  return (
    <tr className="text-center">
      {assignments.map((assignment, idx) => {
        const average = getAverageValue(assignment);
        const display = average ? average.toFixed(2) : '-';
        const cellColor = getCellDateColor(today, assignment.due_date);

        return (
          <TodayCell
            key={`assignments-${category}-${idx}`}
            assignment={assignment}
          >
            <Cell classOverrides={`${cellColor} font-normal`}>
              <div>{display}</div>
            </Cell>
          </TodayCell>
        );
      })}
    </tr>
  );
};

type AssignmentAveragesProps = {
  assignments: Assignment[];
  students: Student[];
};
const AssignmentAverages = ({ assignments, students }: AssignmentAveragesProps) => {
  const {
    display_class_mean_row: displayAssignmentsMeanRow,
    display_class_median_row: displayAssignmentsMedianRow,
    display_class_standard_deviation_row: displayAssignmentsStandardDeviationRow,
    display_calculated_grade_column: displayCalculated,
    display_predicted_grade_column: displayPredicted,
  } = useConfigurations();
  const { data } = useGridData();
  const hasAtLeastOneAverageRow =
    displayAssignmentsMeanRow ||
    displayAssignmentsMedianRow ||
    displayAssignmentsStandardDeviationRow;
  const hasNoTotalGradeColumns = !displayCalculated && !displayPredicted;

  return (
    <>
      {hasAtLeastOneAverageRow && (
        <BorderRow
          numberOfBorderCells={data.assignments.length + 1}
          trimLast={true}
          trimFirst={hasNoTotalGradeColumns}
        />
      )}
      {displayAssignmentsMeanRow && (
        <AssignmentAveragesRow
          assignments={assignments}
          category="mean"
          students={students}
        />
      )}

      {displayAssignmentsMedianRow && (
        <AssignmentAveragesRow
          assignments={assignments}
          category="median"
          students={students}
        />
      )}

      {displayAssignmentsStandardDeviationRow && (
        <AssignmentAveragesRow
          assignments={assignments}
          category="stdev"
          students={students}
        />
      )}
    </>
  );
};

export default AssignmentAverages;
