import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type NavTabProps = {
  linkTo?: string;
  isSelected: boolean;
  isDisabled?: boolean;
  onClick?: () => any;
};
const NavTab: FC<NavTabProps> = ({
  children,
  linkTo,
  isSelected,
  isDisabled,
  onClick = () => {},
}) => {
  if (isDisabled) {
    return (
      <div>
        <div
          className="mr-8 h-full opacity-40 text-black px-1 items-center"
          data-testid="navTab-content"
        >
          {children}
        </div>
      </div>
    );
  }

  if (isSelected) {
    return (
      <div
        className="h-[100%] border-b-[3px] px-[5px] mb-[-3px] border-sky-400 text-sky-400 flex items-center"
        data-testid="navTab-content"
      >
        {children}
      </div>
    );
  }

  const content = (
    <div
      className="h-[100%] opacity-60 text-black px-[5px] flex items-center cursor-pointer"
      onClick={onClick}
      data-testid="navTab-content"
    >
      {children}
    </div>
  );
  if (!linkTo) {
    return content;
  }

  return <Link to={linkTo}>{content}</Link>;
};

export default NavTab;
