import create from 'zustand';
import produce from 'immer';
import { ClassSettingsState, ClassState, Actions } from '../types';

const useClassSettingsStore = create<ClassSettingsState>((set) => ({
  classState: {
    authToken: null,
    class_id: null,
  },

  classPermissionModal: {
    isOpen: false,
    personName: '',
    personPermissions: {
      class_permission_pk: 0,
      role: 0,
      view_grades: false,
      update_grades: false,
      view_all_student_grades: false,
      view_progress_report: false,
      view_report_card: false,
      view_attendance: false,
      view_behavior: false,
      teacher_portal_visibility: false,
      first_name: '',
      nick_name: '',
      last_name: '',
    },
  },

  actions: {
    initialize: (data) => {
      set(
        produce((state) => {
          state.classState = {
            authToken: data.authToken,
            class_id: data.class_id,
          };
        })
      );
    },
    setPermissionModal: (modalIsOpen, personName, personPermissions) => {
      set(
        produce((state) => {
          state.classPermissionModal = {
            isOpen: modalIsOpen,
            personName: personName,
            personPermissions: personPermissions,
          };
        })
      );
    },
  },
}));

export const useClassState = (): ClassState => useClassSettingsStore((state) => state.classState);
export const useClassSettingsActions = (): Actions =>
  useClassSettingsStore((state) => state.actions);

export default useClassSettingsStore;
