import React from 'react';
import { FIELD_HEIGHT } from '../constants';
import classNames from 'classnames';
import { InputChildProps } from '../types';

type NumericGradeProps = InputChildProps & {
  value: string;
  isNotifying?: boolean;
  isFocused?: boolean;
  onBlur?: () => void;
  alignRight?: boolean;
  classOverrides?: string;
  children?: any;
};
const NumericGradeInput = ({
  value,
  onChange,
  isDisabled,
  isNotifying,
  errorMessage,
  onBlur,
  alignRight,
  classOverrides,
  children,
}: NumericGradeProps) => {
  const classes = classNames(`w-12 h-[${FIELD_HEIGHT}] rounded-sm outline-none`, {
    'ring-offset ring-2 ring-green-4 rounded': isNotifying,
    'shadow-none': !isNotifying,
    'text-right pr-1': alignRight,
    [classOverrides]: classOverrides,
  });

  return (
    <>
      <input
        className={classes}
        value={value}
        disabled={isDisabled}
        data-testid="numeric-grade-input"
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
      />{' '}
      {children}
      {errorMessage && <div className="text-red-4 text-sm">{errorMessage}</div>}
    </>
  );
};

export default NumericGradeInput;
