import React from 'react';

type CheckBoxProps = {
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};
const CheckBox = ({ checked, onChange }: CheckBoxProps) => {
  return (
    <div className="w-full">
      <input
        type="checkbox"
        data-testid="checkbox"
        checked={checked}
        onChange={onChange}
        className="h-6 w-6 rounded border-gray-300 text-blue-4 shadow-none"
      />
    </div>
  );
};

export default CheckBox;
