import React, { ReactElement, useState } from 'react';
import StudentAdminHeader from './StudentAdminHeader';
import LoadingPlaceholder from './LoadingPlaceholder';
import { useStudentState } from '../../../../stores/useCourseRequestsStore';
import { ClassList, Student, StudentInfo } from '../types';

type StudentHeaderProps = {
  students: Student[];
  advisees: Student[];
  adviseesOnly: boolean;
  classes: ClassList[];
};

const renderName = (
  studentData,
  loading
): ReactElement<{ studentData: StudentInfo; loading: boolean }> => {
  if (loading) {
    return (
      <LoadingPlaceholder
        outerCss={'w-1/2'}
        innerCss={'h-full'}
      />
    );
  }
  return (
    <>
      {`${studentData.full_name} `}
      <span className="inline-block text-sm font-normal">
        &ndash; Applying For {studentData.grade_applying_for}
      </span>
    </>
  );
};

const StudentHeader = ({ students, advisees, adviseesOnly, classes }: StudentHeaderProps) => {
  const { isFacultyPortal, studentData, studentIsSelected } = useStudentState();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="w-full flex flex-wrap min-h-16 pt-1 max-lg:justify-start max-sm:justify-between">
      {isFacultyPortal && (
        <StudentAdminHeader
          students={students}
          advisees={advisees}
          adviseesOnly={adviseesOnly}
          setLoading={setLoading}
          classes={classes}
        />
      )}

      {(studentIsSelected || loading) && (
        <div className="grow w-[22rem] max-sm:w-full max-sm:order-3 mt-2">
          <h1 className="text-black text-xl mb-1 max-sm:text-lg">
            {renderName(studentData, loading)}
          </h1>
        </div>
      )}
    </div>
  );
};

export default StudentHeader;
