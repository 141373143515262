import React from 'react';
import useNavigationStore from './useNavigationStore';

type MobileBannerProps = {
  title?: string;
  subTitle?: string;
  goBack?: () => void;
};
const LmsMobileBanner = ({ title = null, subTitle = null, goBack = null }: MobileBannerProps) => {
  const setNavBar = useNavigationStore((state) => state.setNavBar);

  React.useEffect(() => {
    setNavBar({
      mobileTitle: title,
      mobileSubTitle: subTitle,
      goBack,
    });
  }, []);

  return null;
};

export default LmsMobileBanner;
