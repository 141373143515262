import React, { useEffect, useRef, useState } from 'react';
import { useAddAssignmentFeedback } from '../../mutations';
import { useClassState } from '../../../../../stores/useGradebookStore';
import classNames from 'classnames';
import FeedbackFileUploader from '../../../../shared/FileUploader';
import { ANIMATION_DURATION } from '../../constants';

type FeedbackInputProps = {
  gradeId: number;
  onSave: () => void;
  fileHandlerExpanded: boolean;
  toggleFileHandler: () => void;
  sidebarIsOpen: boolean;
};
const FeedbackInput = ({
  gradeId,
  onSave,
  fileHandlerExpanded,
  toggleFileHandler,
  sidebarIsOpen,
}: FeedbackInputProps) => {
  const { teacherPhotoUrl } = useClassState();
  const addAssignmentFeedback = useAddAssignmentFeedback();
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const { uploaderOptions } = useClassState();
  const [inputValue, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const buttonIsDisabled = (inputValue.trim() === '' && !uploadedFiles.length) || isLoading;
  const inputRef = useRef(null);

  useEffect(() => {
    setUploadedFiles([]);
    return () => {
      setUploadedFiles([]);
    };
  }, [fileHandlerExpanded]);

  useEffect(() => {
    // Focus on the input when the sidebar is opened, wait for the animation to finish
    if (sidebarIsOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, ANIMATION_DURATION);
    }
  }, [sidebarIsOpen]);

  const handleSave = async () => {
    if (buttonIsDisabled) {
      return;
    }

    const data = {
      message: inputValue.trim(),
      class_assignment_person_pk: gradeId,
      files: uploadedFiles,
    };

    setIsLoading(true);
    await addAssignmentFeedback.mutateAsync(data);
    onSave();
    setValue('');
    setIsLoading(false);
  };

  const buttonClasses = classNames(
    'h-7 inline-flex items-center rounded-md border border-transparent px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-offset-2',
    {
      'bg-purple-5': buttonIsDisabled,
      'bg-purple-3': !buttonIsDisabled,
    }
  );

  const textAreaClasses = classNames(
    'block w-full resize-none border-0 py-3 focus:ring-0 sm:text-sm focus:h-20',
    {
      'h-20': inputValue.length > 0,
    }
  );

  return (
    <>
      <div className="flex max-md:w-full bg-white justify-start p-4 md:m-2.5 md:mt-6">
        <img
          className="vx-avatar float-left object-cover mr-2 left-0 h-9 w-9"
          src={teacherPhotoUrl}
        />
        <div className="flex items-start space-x-4 w-full">
          <div className="min-w-0 flex-1 w-full">
            <div className="relative">
              <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm  focus-within:ring-1 ">
                <label
                  htmlFor="comment"
                  className="sr-only"
                ></label>
                <textarea
                  rows={1}
                  value={inputValue}
                  onChange={(e) => setValue(e.target.value)}
                  name="comment"
                  data-testid="feedback-input"
                  id="comment"
                  className={textAreaClasses}
                  placeholder="Give Feedback..."
                  ref={inputRef}
                ></textarea>

                {fileHandlerExpanded && (
                  <div
                    className="border-t p-2"
                    aria-hidden="true"
                  >
                    <div className="h-[200px]">
                      <FeedbackFileUploader
                        setUploadedFiles={setUploadedFiles}
                        uploaderOptions={uploaderOptions}
                      />
                    </div>
                  </div>
                )}
                <div
                  className="border-t"
                  aria-hidden="true"
                >
                  <div className="py-px">
                    <div className="h-9"></div>
                  </div>
                </div>
              </div>

              <div className="absolute inset-x-0 bottom-0 flex justify-between h-10 pl-3 pr-2">
                <div className="flex items-center space-x-5">
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                      onClick={toggleFileHandler}
                      aria-label="Attach Files"
                    >
                      <i className="nc-icon-glyph ui-1_attach-87 text-xl" />
                    </button>
                  </div>
                </div>
                <div className="flex items-center flex-shrink-0">
                  <button
                    onClick={handleSave}
                    type="submit"
                    disabled={buttonIsDisabled}
                    className={buttonClasses}
                  >
                    Post
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackInput;
