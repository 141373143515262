import React, { useState } from 'react';
import RichTextArea from '../../common/RichTextArea';
import { useUpdateGradeRecord } from '../../mutations';
import { getErrorMessage } from '../../helpers';
import { useStudentGrade } from '../../queries';

type PrivateNoteProps = {
  studentId: number;
};
const PrivateNote = ({ studentId }: PrivateNoteProps) => {
  const updateNotes = useUpdateGradeRecord(studentId, 'notes');
  const gradeRecord = useStudentGrade(studentId);
  const notes = gradeRecord?.notes;
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSave = async (value) => {
    try {
      await updateNotes.mutateAsync(value);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    }
  };

  return (
    <div className="pt-5 px-4">
      <div className="text-lg font-semibold">Private Note for Teacher</div>
      <div className="text-sm font-light mb-2">Private notes can only be seen by the teacher</div>
      <RichTextArea
        onChange={handleSave}
        value={notes}
        saveOnChange={false}
      />
      {errorMessage && <div className="text-red-3">{errorMessage}</div>}
    </div>
  );
};

export default PrivateNote;
