import {
  Assignment,
  EmailNotifications,
  GradebookConfigurations,
  WeightConfiguration,
  EditOptions,
  AccessStatuses,
} from './types';

export const USER_NOT_AUTH_ERROR = 'User not authorized';
export const USER_EDIT_ERROR = 'You do not have permission';
export const ASSIGNMENT_NOT_FOUND_ERROR = 'Assignment not found';

export const WEIGHTING_PATH = '/weighting';
export const NEW_ASSIGNMENT_PATH = '/assignment/new';
export const ASSIGNMENT_TAB = 'assignment';
export const STUDENTS_TAB = 'students';

export const PARSE_DATE_FORMAT = 'YYYY-MM-DD';
export const SHORT_DATE_FORMAT = 'MMM DD';
export const LONG_DATE_FORMAT = 'MMMM DD, YYYY';
export const TIME_FORMAT = 'HH:mm';

export const NOTIFY_DURATION = 3000;
export const ANIMATION_DURATION = 100;
export const STALE_TIME_DURATION = 30 * 60 * 1000;

export const SORT_BY_LOCAL_STORAGE_KEY = 'gradebook.sortAssignmentsByNewest';

export const PUBLISHED = 1;
export const UNPUBLISHED = 0;

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

export const BORDER_COLOR_SUCCESS = 'border-green-4';
export const BORDER_COLOR_DEFAULT = 'border-neutral-4';

export const ALL_YEAR_GRADING_PERIOD = 50;

export const FIELD_HEIGHT = '35px';

export const MOBILE_BREAKPOINT = 768;

export const MAX_DESCRIPTION = 256;

// from zg_Assignment_Completion_Status
export enum CompletionStatus {
  PENDING = 0,
  NOT_TURNED_IN = 1,
  INCOMPLETE = 2,
  COMPLETE = 3,
  NOT_REQUIRED_TO_COMPLETE = 4,
  COMPLETE_NO_CREDIT = 5,
  TURNED_IN_NOT_GRADED = 6,
  LATE = 7,
}

export enum DropboxStatus {
  CLOSED = 0,
  EXPECTING = 1,
  RECIEVED = 2,
}

export const STATUS_THAT_REMOVES_GRADES = new Set<CompletionStatus>([
  CompletionStatus.PENDING,
  CompletionStatus.TURNED_IN_NOT_GRADED,
]);

export const STATUS_THAT_COUNTS_ZERO_GRADES = new Set<CompletionStatus>([
  CompletionStatus.COMPLETE,
  CompletionStatus.NOT_TURNED_IN,
  CompletionStatus.INCOMPLETE,
  CompletionStatus.COMPLETE_NO_CREDIT,
  CompletionStatus.LATE,
]);

export const ERROR_TIMEOUT = 5000;

type Description = 'Received' | 'Not Turned In' | 'Expecting' | 'Late';

export const DEFAULT_GRADING_CONFIGURATIONS: WeightConfiguration = {
  weight: '0',
  drop_lowest: false,
};

export enum GradingMethodDescription {
  POINTS = 1,
  LETTER = 2,
}
export const LETTER = 'Letter';
export const POINTS = 'Points';

export const DESCRIPTION_COLOR_MAP: Record<Description, string> = {
  Received: 'bg-green-4',
  'Not Turned In': 'bg-red-4',
  Late: 'bg-yellow-4',
  Expecting: 'bg-orange-4',
};

export const DEFAULT_FIELD_VALUES: Partial<Assignment> = {
  description: '',
  maximum_score: 100,
  weight: 100,
  assignment_type: 1,
  grading_method: GradingMethodDescription.POINTS,
  grade_conversion_scale: null,
  notes: '',
  teacher_notes: '',
  assignment_date: '',
  due_date: '',
  display_status: true,
  display_on_report_card: false,
  include_in_calc_grade: true,
  applies_to_all_sections: true,
  extra_credit: false,
};

export const ASSIGNMENT_FIELDS_THAT_EFFECT_CLASS_GRADES = ['maximum_score', 'weight', 'not_graded'];

export const DEFAULT_CONFIGURATIONS: GradebookConfigurations = {
  calculated_grade_label: 'Calculated Grade',
  display_calculated_grade_column: false,
  display_predicted_grade_column: false,
  predicted_grade_format: 'numeric',
  predicted_grade_grading_period: 'active',
  enabled: false,
  gradebook_label: 'Gradebook',
  hide_gradebook_photos: false,
  predicted_grade_label: 'Predicted Grade',
  enable_keyboard_shortcuts: false,
  show_display_on_report_card_flag: false,
  default_assignment_max_score_value: 100,
  default_assignment_weight_value: 100,
  assignment_max_score_teacher_access_status: AccessStatuses.ENABLED,
  assignment_weight_teacher_access_status: AccessStatuses.ENABLED,
  display_class_mean_row: false,
  display_class_median_row: false,
  display_class_standard_deviation_row: false,
  display_date_withdrawn: false,
  display_late_date_enrolled: false,
};

export const DEFAULT_NOTIFICATIONS: EmailNotifications = {
  student: false,
  parents: false,
  advisor: false,
  homeroom: false,
};

export const BULK_EDIT_DEFAULTS: EditOptions = {
  raw_score: { value: '', checked: false },
  dropbox_status: { value: 0, checked: false },
  completion_status: { value: 0, checked: false },
  publish_status: { value: false, checked: false },
};

export const COMPLETION_STATUS = 'completion_status';

export const REMOVE_GRADES_CONFIRMATION =
  'Changing to this completion status will remove the grade, are you sure you want to continue?';

export const NOTIFICATIONS_SENT = 'Notifications Sent!';
export const HAS_FEEDBACK = 'Has Feedback';
export const HAS_PRIVATE_NOTE = 'Has Private Note';
export const GRADING_METHOD = 'Grading Method';
export const MAX_SCORE = 'Max Score';
export const EXTRA_CREDIT = 'Extra Credit?';
export const INCLUDE_IN_CALC_GRADE = 'Include in Calc. Grade?';
export const DISPLAY_ON_PORTALS = 'Display on Portals?';
export const DISPLAY_ON_REPORT_CARD = 'Display on Report Card?';
export const DESCRIPTION = 'Description';
export const ASSIGNMENT_DETAILS = 'Assignment Details';
export const TEACHER_NOTES = 'Teacher notes (internal)';
export const ASSIGNMENT_TYPE = 'Assignment Type';
export const GRADING_PERIOD = 'Grading Period';
export const WEIGHT = 'Weight';
export const DUE_DATE = 'Due Date';
export const DATE_ASSIGNED = 'Date Assigned';
export const APPLIES_TO_ALL_SECTIONS = 'Applies to all sections?';
export const GRADE_CONVERSION_SCALE = 'Grade Conversion Scale';
