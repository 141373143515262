import React from 'react';

type CourseSearchBarProps = {
  setSearchInput: (searchInput: string) => void;
};

const CourseSearchBar = ({ setSearchInput }: CourseSearchBarProps) => {
  return (
    <div className="flex-none w-56 mt-2 mr-2">
      <div className="flex h-[38px]">
        <label className="bg-gray-100 border border-[#ccc] pt-2 pb-1 px-1.5 rounded-l align-middle">
          Search
        </label>
        <input
          type="text"
          onChange={(e) => setSearchInput(e.target.value)}
          className="w-44 border border-[#ccc] rounded-r px-1 text-sm focus:ring-[#2684FF]"
        ></input>
      </div>
    </div>
  );
};

export default CourseSearchBar;
