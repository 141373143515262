import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

type ModalProps = {
  isOpen: boolean;
  isSubmitDisabled?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  label?: string;
  overrideClasses?: string;
  canClose?: boolean;
  showButtons?: boolean;
  fixedPosition?: boolean;
  onSave?: () => void;
};
export const Modal: FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  onSave = () => {},
  isSubmitDisabled = false,
  label = 'Confirm',
  overrideClasses = '',
  canClose = true,
  showButtons = true,
  fixedPosition = false,
  children,
}) => {
  const submitButtonClasses = classNames(
    'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white sm:col-start-2 focus:outline-0 bg-indigo-600',
    {
      [overrideClasses]: true,
      'bg-opacity-50': isSubmitDisabled,
      'hover:bg-indigo-500': !isSubmitDisabled,
    }
  );

  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}
    >
      <Dialog
        as="div"
        className={`${fixedPosition ? 'fixed' : 'relative'} z-[10000]`}
        onClose={setIsOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          id="shared-modal"
        >
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`${
                  fixedPosition ? 'fixed  top-1/4' : 'relative'
                } transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all md:my-8 md:p-6 min-w-[95%] md:min-w-[500px]`}
              >
                {children}
                {showButtons && (
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className={submitButtonClasses}
                      disabled={isSubmitDisabled}
                      onClick={onSave}
                      data-testid="modal-save"
                    >
                      {label}
                    </button>
                    {canClose && (
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
