import React from 'react';

import Field from './Field';

const defaultConfig = {
  defaultValue: '',
  disabled: false,
  required: false,
  placeholder: undefined,
  maxLength: 1000,
  rows: 2,
};

export default function TextAreaField(props) {
  const { name, type, value, error, onChange } = props;

  const config = { ...defaultConfig, ...props.config };

  const isDisabled = props.disabled || config.disabled;

  const textareaRef = React.useRef();
  const handleLabelClick = () => textareaRef.current.focus();

  return (
    <Field
      className="vx-text-area"
      onLabelClick={handleLabelClick}
      {...props}
    >
      <textarea
        className="vx-text-area__input"
        type="text"
        name={name}
        value={value || config.defaultValue}
        onChange={onChange}
        disabled={isDisabled}
        required={config.required}
        placeholder={config.placeholder}
        maxLength={config.maxLength}
        rows={config.rows}
        ref={textareaRef}
      />
    </Field>
  );
}
