import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ConversionLevelOption, InputChildProps } from '../types';
import ReactSelect from 'react-select';
import { useUserState } from '../../../../stores/useAppStore';
import { getScaleLevel } from '../helpers';
import { useAssignment, useGridData } from '../queries';

type LetterGradeProps = InputChildProps & {
  onChange: (option: ConversionLevelOption) => void;
  isNotifying?: boolean;
  isPending?: boolean;
  value?: string;
  studentId?: number;
};
const LetterGradeInput = ({
  onChange,
  isDisabled,
  isPending,
  value,
  errorMessage,
  isNotifying,
  studentId,
}: LetterGradeProps) => {
  const assignment = useAssignment();
  const { focusedStudentRow, setFocusedStudentRow, isFocusingInput } = useUserState();
  const options: ConversionLevelOption[] = [];
  (assignment?.zg_grade_conversion_scale_levels || []).forEach((level) => {
    options.push({ value: level.id, label: level.letter, ...level });
  });
  const selectRef = useRef(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [hasInteractedWithDropdown, setHasInteractedWithDropdown] = useState(false);
  const { data } = useGridData();
  const rowIndex = data.students.findIndex((student) => student.person_pk === studentId);

  useEffect(() => {
    if (focusedStudentRow === rowIndex && isFocusingInput) {
      selectRef.current?.focus();
    }
  }, [focusedStudentRow]);

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      setHasInteractedWithDropdown(true);
    }
    if (!hasInteractedWithDropdown && event.key === 'Enter') {
      // No navigation has happened, so we don't want to save a new value
      event.preventDefault();
      setMenuIsOpen(false);
    }
  };

  const scaleLevel = useMemo(
    () => getScaleLevel(value, assignment?.zg_grade_conversion_scale_levels),
    [value]
  );

  let selectedOption = null;
  if (scaleLevel && !isPending && value !== '') {
    selectedOption = options.find((option) => option.value === scaleLevel.id);
  }

  const handleSave = (option: ConversionLevelOption) => {
    if (option.letter === selectedOption?.letter) return;
    onChange(option);
  };
  const borderRing = isNotifying ? 'ring-offset ring-2 ring-green-4 rounded' : 'shadow-none';

  const handleMenuOpen = () => {
    setFocusedStudentRow(rowIndex);
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setHasInteractedWithDropdown(false);
    setMenuIsOpen(false);
  };

  return (
    <div
      className={`w-16 ${borderRing} ${menuIsOpen && 'menu-is-open'}`}
      data-testid={`letter-grade-dropdown${studentId ? `-${studentId}` : ''}`}
    >
      <ReactSelect
        value={selectedOption}
        options={options}
        styles={{
          valueContainer: (styles) => ({ ...styles, paddingLeft: '4px' }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          control: (styles) => ({
            ...styles,
            borderColor: '#a6a6a6',
            boxShadow: 0,
            '&:hover': {
              border: `1px solid #a6a6a6`,
            },
          }),
          menu: (styles) => ({ ...styles, opacity: 1 }),
        }}
        placeholder=""
        onChange={handleSave}
        maxMenuHeight={242}
        minMenuHeight={242}
        menuPlacement="auto"
        isSearchable={false}
        openMenuOnFocus={isFocusingInput}
        isDisabled={isDisabled}
        ref={selectRef}
        onKeyDown={handleKeyDown}
        menuIsOpen={menuIsOpen}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
      />
      {errorMessage && <div className="text-red-3 w-40">{errorMessage}</div>}
    </div>
  );
};

export default LetterGradeInput;
