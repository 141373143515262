import React from 'react';
import ReactSelect from 'react-select';
import ConferenceDropdownOption from './ConferenceDropdownOption';
import {
  getEpochFromTimeString,
  getPotentialTimeConflicts,
  getTimeConflict,
  isTimeAlreadySelected,
} from './helpers';
import classNames from 'classnames';
import { NONE_SELECTED, NO_TIMES_AVAILABLE } from './constants';
import { ClearIndicator, MenuList, Control } from './SelectComponents';

const getStyles = (isLocked, hasAttendanceOption) => {
  const borderColor = isLocked ? 'transparent' : '#a6a6a6';
  return {
    control: (provided) => ({
      ...provided,
      background: 'transparent',
      borderColor: borderColor,
      height: '45px',
      boxShadow: 0,
      '&:hover': {
        border: `1px solid ${borderColor}`,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '100%',
      padding: '0 6px',
      pointerEvents: 'visible',
    }),
    menu: (provided) => ({
      ...provided,
      right: hasAttendanceOption ? '0%' : provided.right,
      width: hasAttendanceOption ? '105%' : provided.width,
    }),
    singleValue: (provided, { isDisabled }) => ({
      ...provided,
      width: '100%',
      color: isDisabled && !isLocked ? '#555555' : '#3b3b3b',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '100%',
      padding: '8px 0',
      display: isLocked ? 'none' : '-webkit-flex',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '8px 0',
      '&:hover': {
        color: `#c63736`,
      },
    }),
  };
};

const ConferenceDropdown = ({
  conferenceTimes,
  handleSelectionChange,
  schedule,
  canSelect,
  timeConflicts,
  studentId,
  teacherId,
  dateFormat,
  timeFormat,
  allowConflicts,
  isLocked,
  isMobile,
  hasAttendanceOption,
  loading,
  isNotifying,
  errorMessage,
  hasMultipleSelections,
}) => {
  const messageWithoutDropdown = (message) => {
    return (
      <>
        <p className="vx-subtitle conferences-no-times">
          <em>{message}</em>
        </p>
        {errorMessage && <div className="conferences-dropdown-error">{errorMessage}</div>}
      </>
    );
  };

  if (!conferenceTimes || !conferenceTimes.length) {
    return messageWithoutDropdown(NO_TIMES_AVAILABLE);
  }

  const conferenceTimesSorted = conferenceTimes.sort((a, b) => {
    const dateTimeA = new Date(a.start_date);
    const dateTimeB = new Date(b.start_date);
    const epochStartA = getEpochFromTimeString(a.start_time, dateTimeA);
    const epochStartB = getEpochFromTimeString(b.start_time, dateTimeB);
    const epochEndA = getEpochFromTimeString(a.end_time, dateTimeA);
    const epochEndB = getEpochFromTimeString(b.end_time, dateTimeB);
    return epochStartA - epochStartB || epochEndA - epochEndB;
  });

  if (hasMultipleSelections) {
    return messageWithoutDropdown('Multiple Times Selected');
  }

  const selectedIdx = conferenceTimesSorted.findIndex((conference) => conference.selected);
  const areAllDisabled = !canSelect && selectedIdx === -1;

  const getConflicts = (conference) => {
    if (conference.selected) {
      const conflict = getTimeConflict(
        timeConflicts,
        conference.start_date,
        conference.start_time,
        conference.end_time
      );
      if (conflict) {
        return conflict.conferenceConflicts;
      }
      return [];
    }
    return getPotentialTimeConflicts(schedule, conference, timeConflicts);
  };

  const getConflictInfo = (conference, schedule, allowConflicts = true) => {
    const conflicts = getConflicts(conference);
    const hasConflict = Boolean(conflicts.length);
    const isDisabled =
      conference.disabled ||
      (hasConflict && !allowConflicts) ||
      areAllDisabled ||
      (isTimeAlreadySelected(schedule, conference.event_fk) && !conference.selected);
    return { isDisabled, conflicts };
  };

  const getConflictDisplayLabel = (conflicts) => {
    const conflictList = conflicts
      .map((conflict) => {
        if (teacherId === conflict.teacherId && studentId === conflict.studentId) {
          return null;
        }
        return `${conflict.teacherName} (${conflict.studentName}), `;
      })
      .filter((c) => c);

    if (conflictList.length === 0) {
      return [];
    }

    const lastIndex = conflictList.length - 1;
    if (conflictList.length > 1) {
      // if more than one element, add 'and' before last conflict
      conflictList[lastIndex] = 'and ' + conflictList[lastIndex];
    }

    conflictList[lastIndex] = conflictList[lastIndex].slice(0, -2) + '.';
    return conflictList.join('');
  };

  const getConfiguredOption = (conference, isCurrentlyDisplaying = false) => {
    const { isDisabled, conflicts } = getConflictInfo(conference, schedule, allowConflicts);
    const iconIds = {
      conflict: `conflict-icon-${conference.event_fk}`,
      inPerson: `inPerson-icon-${conference.event_fk}`,
      remote: `remote-icon-${conference.event_fk}`,
      parent: `dropdown-${teacherId}-${studentId}`,
    };
    if (isCurrentlyDisplaying) {
      Object.keys(iconIds).forEach((i) => (iconIds[i] += '-control'));
    }
    return {
      value: conference.event_fk,
      isDisabled: isDisabled,
      label: (
        <ConferenceDropdownOption
          conference={conference}
          isNotifying={isNotifying}
          isCurrentlyDisplaying={isCurrentlyDisplaying}
          loading={loading}
          isDisabled={isDisabled}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          hasConflict={conflicts && Boolean(conflicts.length)}
          iconIds={iconIds}
          hasAttendanceOption={hasAttendanceOption}
          conflicts={conflicts}
          teacherId={teacherId}
          studentId={studentId}
        />
      ),
    };
  };

  const options = conferenceTimesSorted.map((conference) => {
    return getConfiguredOption(conference);
  });

  const getSelectedOption = () => {
    if (selectedIdx === -1) {
      return null;
    }
    const conference = conferenceTimesSorted[selectedIdx];
    return getConfiguredOption(conference, true);
  };

  const handleChange = (selection) => {
    const previousValue = selectedIdx !== -1 ? options[selectedIdx].value : null;
    const newValue = selection ? selection.value : null;
    handleSelectionChange(newValue, previousValue);
  };

  const classes = classNames({
    success: isNotifying,
  });

  const noSelectionMessage = areAllDisabled ? <em>{NO_TIMES_AVAILABLE}</em> : NONE_SELECTED;
  const defaultOption = (
    <ConferenceDropdownOption
      isDefault={true}
      noSelectionMessage={noSelectionMessage}
      isNotifying={isNotifying}
    />
  );
  const defaultValue = [
    {
      value: 0,
      label: defaultOption,
    },
  ];

  let selectedConferenceConflicts;
  if (isMobile && selectedIdx !== -1) {
    const selectedConference = conferenceTimesSorted[selectedIdx];
    const { conflicts } = getConflictInfo(selectedConference, schedule);
    if (conflicts && conflicts.length) {
      selectedConferenceConflicts = 'Conflicts with ' + getConflictDisplayLabel(conflicts);
    }
  }

  return (
    <div
      className="conferences-teacher-dropdown"
      data-testid="conferences-dropdown"
    >
      <ReactSelect
        defaultValue={defaultValue}
        placeholder={defaultOption}
        value={getSelectedOption()}
        options={options}
        onChange={handleChange}
        styles={getStyles(isLocked, hasAttendanceOption)}
        isSearchable={false}
        isClearable={selectedIdx !== -1 && !loading && !isNotifying}
        maxMenuHeight={200}
        isDisabled={loading || isLocked}
        isOptionDisabled={(option) => option.isDisabled}
        className={classes}
        classNamePrefix={'conference-dropdown-slot'}
        isLoading={loading}
        menuPlacement="auto"
        parentElementId={`dropdown-${teacherId}-${studentId}`}
        components={{ ClearIndicator, MenuList, Control }}
      />
      {errorMessage && (
        <div
          className="conferences-dropdown-error"
          data-testid="conferences-dropdown-error"
        >
          {errorMessage}
        </div>
      )}
      {isMobile && selectedConferenceConflicts && (
        <div className="teacher-row-conflicts">{selectedConferenceConflicts}</div>
      )}
    </div>
  );
};

export default ConferenceDropdown;
