import React, { useState } from 'react';
import classNames from 'classnames';
import SubjectsList from './SubjectsList';
import SectionHeader from './SectionHeader';
import { Course, CourseRequestFormData, CurrentRequestData, ZgOption } from '../../types';
import CoursesBySubjectList from './CoursesBySubjectList';
import CourseRequestForm from './CourseRequestForm';
import { useCourseRequestState } from '../../../../../stores/useCourseRequestsStore';
import { getDefaultPriority } from '../../helpers';

const initDefaultPriority = (
  requestGroup: number,
  defaultFormValues: CourseRequestFormData,
  currentRequests: CurrentRequestData[]
) => {
  if (!requestGroup) return defaultFormValues;
  return {
    ...defaultFormValues,
    priority: getDefaultPriority(requestGroup, currentRequests),
  };
};

type ModalContentProps = {
  handleSave: (formValues: CourseRequestFormData) => void;
  errorSubmitting: string;
  isLoading: boolean;
  selectedRequestGroup?: number;
};
const ModalContent = ({
  handleSave,
  errorSubmitting,
  isLoading,
  selectedRequestGroup,
}: ModalContentProps) => {
  const [currentStep, setStep] = useState(1);
  const [selectedSubject, setSelectedSubject] = useState<ZgOption>(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const { default_form_values: defaultFormValues, current_course_requests: currentRequests } =
    useCourseRequestState();
  const [formValues, setFormValues] = useState<CourseRequestFormData>(
    initDefaultPriority(selectedRequestGroup, defaultFormValues, currentRequests)
  );
  const completedSteps = selectedCourse ? 3 : selectedSubject ? 2 : 1;

  const handleSubmit = async () => {
    await handleSave({ ...formValues, course: selectedCourse.id });
  };

  const handleUserSwitchToStep = (newStep: number) => {
    if (completedSteps >= newStep) {
      setStep(newStep);
    }
  };

  const handleSelectSubject = (subject: ZgOption) => {
    setSelectedSubject(subject);
    setStep(2);
  };

  const handleSelectCourse = (course: Course) => {
    setSelectedCourse(course);
    setStep(3);
  };

  const bgColorClasses = (stepNumber: number) => {
    return currentStep >= stepNumber ? 'bg-blue-3' : 'bg-blue-4';
  };

  const stepBoxClasses = (stepNumber: number) => {
    const canSelectStep = stepNumber !== currentStep && completedSteps >= stepNumber;
    const stepIsSelected = stepNumber === currentStep;
    const bgColor = bgColorClasses(stepNumber);

    return classNames(
      'w-40 h-10 border-y leading-[38px] border-white text-sm mr-1 relative text-neutral-6 text-center leading-10',
      {
        [bgColor]: true,
        'rounded-l-sm': stepNumber === 1,
        'rounded-r-sm': stepNumber === 3,
        'bg-blue-3': currentStep >= stepNumber,
        'bg-blue-4': currentStep < stepNumber,
        'cursor-pointer': canSelectStep,
        'font-semibold': stepIsSelected,
      }
    );
  };

  const arrowClasses = (stepNumber: number) => {
    const bgColor = bgColorClasses(stepNumber);
    return `${bgColor} absolute top-1/2 h-7 w-7 right-[-28px] transform -translate-y-1/2 -translate-x-1/2 rotate-45 z-20 border-r border-t border-white`;
  };

  return (
    <div
      className="w-[600px] min-h max-sm:w-[340px]"
      id="add-course-modal"
    >
      <div className="flex justify-start">
        <div
          className={stepBoxClasses(1)}
          onClick={() => handleUserSwitchToStep(1)}
        >
          <span className="max-sm:hidden">Select</span> Subject
          <div className={arrowClasses(1)}></div>
        </div>
        <div
          className={stepBoxClasses(2)}
          onClick={() => handleUserSwitchToStep(2)}
        >
          <span className="max-sm:hidden">Select</span> Course
          <div className={arrowClasses(2)}></div>
        </div>
        <div
          className={stepBoxClasses(3)}
          onClick={() => handleUserSwitchToStep(3)}
        >
          <span className="max-sm:hidden">Create</span> Request
          <div className={arrowClasses(3)}></div>
        </div>
      </div>

      {currentStep === 1 && (
        <>
          <SectionHeader
            subHeaderText="You'll select a course next."
            selectedRequestGroup={selectedRequestGroup}
          >
            Select a subject
          </SectionHeader>
          <SubjectsList handleSelectSubject={handleSelectSubject} />
        </>
      )}
      {currentStep === 2 && (
        <>
          <SectionHeader
            subHeaderText="Select a course. You can then enter a request for that course."
            selectedRequestGroup={selectedRequestGroup}
          >
            {selectedSubject.description}
          </SectionHeader>
          <CoursesBySubjectList
            handleSelectCourse={handleSelectCourse}
            selectedSubject={selectedSubject}
          />
        </>
      )}
      {currentStep === 3 && (
        <>
          <SectionHeader selectedRequestGroup={selectedRequestGroup}>
            Request{' '}
            <b>
              <i>{selectedCourse.description}</i>
            </b>
          </SectionHeader>

          <CourseRequestForm
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={handleSubmit}
            errorSubmitting={errorSubmitting}
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  );
};

export default ModalContent;
