import React, { useState } from 'react';
import { Properties, SqlErrorMessage } from '../types';
import classNames from 'classnames';
import { useUpdateConfiguration } from '../mutations';
import LockIcon from '../common/LockIcon';
import { Field, useField, useFormikContext } from 'formik';
import { NOTIFY_DURATION_MS, ROW_HOVER_COLOR, KEYPATH_SECTION_TYPE } from '../constants';
import useNavigationStore from '../../navigation/useNavigationStore';

type TextSettingTypes = {
  category: string;
  keypath: string;
  settingProperties: Properties;
};

const TextSetting = ({ category, keypath, settingProperties }: TextSettingTypes) => {
  // State Logic
  const [field, meta, helpers] = useField(keypath);
  const [value, setValue] = useState(field.value);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const isEditable = settingProperties.teacher_access_strategy === 'editable';
  const { setSubmitting } = useFormikContext();
  const [isSavedNotification, setIsSavedNotification] = useState(false);
  const { updateTabDescription } = useNavigationStore();

  const handleKeyPress = (event) => {
    if (['Enter', 'NumpadEnter'].includes(event.key)) {
      event.target.blur();
    }
  };

  const onError = (error: SqlErrorMessage) => {
    helpers.setValue(value);
    setServerError(error.message);
  };

  // Style Logic
  const rowClasses = classNames(
    `relative px-5 py-1 text-sm font-medium hover:${ROW_HOVER_COLOR} sm:flex sm:items-center sm:flex-wrap`,
    {
      'text-gray-900': isEditable,
      'text-gray-500': !isEditable,
    }
  );

  const textBoxClasses = classNames(
    'my-2 w-11/12 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 sm:flex-1 transition ease-out duration-700',
    {
      'cursor-not-allowed': !isEditable,
      'cursor-wait': loading,
      'ring-2 ring-red-300 focus:ring-red-500': meta.error || serverError,
      'ring-4 ring-green-300': isSavedNotification,
    }
  );

  const errorClasses = classNames(
    'block w-11/12 text-right text-red-500 text-xs sm:w-full sm:mr-8',
    {
      hidden: !meta.error && !serverError,
    }
  );

  const textBoxDisabled = isEditable && !loading ? false : true;

  // Submission Logic
  // instantiate useMutation
  const saveConfiguration = useUpdateConfiguration(
    () => {}, // No optimistic update, the text is already in an optimistic state
    onError // Reset if something goes wrong
  );

  //Make the update request
  const saveConfigurationRequest = async () => {
    await saveConfiguration.mutateAsync({
      db_source: settingProperties.db_source,
      configuration_target: settingProperties.target,
      new_value: field.value,
    });
  };

  const handleBlur = async () => {
    if (loading) return;

    // Trim and check for changes
    helpers.setValue(field.value.toString().trim());
    if (field.value === value) return;

    // Check for errors
    if (meta.error) return;

    // Initiate the Database update
    try {
      setLoading(true);
      setServerError(null);
      await saveConfigurationRequest();
    } finally {
      setIsSavedNotification(true);
      setTimeout(() => {
        setIsSavedNotification(false);
      }, NOTIFY_DURATION_MS);
      setLoading(false);
      // If blank, set the text to the template value (placeholder)
      if (field.value === '' && settingProperties.placeholder_value !== '') {
        helpers.setValue(settingProperties.placeholder_value.toString());
      }
      // Update the stored 'current' value
    }
    setValue(field.value);
    if (KEYPATH_SECTION_TYPE[keypath])
      updateTabDescription(
        KEYPATH_SECTION_TYPE[keypath],
        field.value || settingProperties.placeholder_value.toString()
      );
    setSubmitting(false);
  };

  return (
    <div className={rowClasses}>
      <label
        htmlFor={keypath}
        className="inline-block w-full leading-6 select-none sm:flex-1"
      >
        {settingProperties.description}
      </label>
      <Field
        type="text"
        name={keypath}
        id={keypath}
        className={textBoxClasses}
        disabled={textBoxDisabled}
        placeholder={settingProperties.placeholder_value.toString()}
        onBlur={handleBlur} // Process update
        onKeyDown={handleKeyPress} // Blur on enter
        onKeyUp={(e) => {
          // Set as submitting if there are any unsaved changes
          // Used in FormikNavigationBlocker to prevent navigation
          setSubmitting(value !== field.value);
        }}
      />
      <div className="inline-block w-1/12 sm:flex-none sm:w-8">
        <LockIcon isDisplayed={!isEditable} />
      </div>
      <div className={errorClasses}>{meta.error || serverError}</div>
    </div>
  );
};

export default TextSetting;
