import React, { FC } from 'react';
import classnames from 'classnames';

import CheckboxField from '../fields/CheckboxField';
import DateOfBirthField from '../fields/DateOfBirthField';
import DatePickerField from '../fields/DatePickerField';
import EmailField from '../fields/EmailField';
import HelpText from '../fields/HelpText';
import NumberField from '../fields/NumberField';
import PlainTextField from '../fields/PlainTextField';
import RadioField from '../fields/RadioField';
import SelectField from '../fields/SelectField';
import TextareaField from '../fields/TextareaField';
import TextboxField from '../fields/TextboxField';
import ToggleField from '../fields/ToggleField';
import WebFormScrollToError from '../WebFormScrollToError';
import { useWebForm } from '../WebFormContext';
import { getFieldKeypath, getFieldMetadata } from '../utils/element-helpers';
import { sortBy } from '../utils/form-helpers';
import { useField } from 'formik';
import { ElementProps, ElementMetadata } from '../types';

export const COMPONENT_TO_FIELD_MAP = {
  checkboxes_columns: CheckboxField,
  create_account: RadioField,
  data_consent_policy_checkbox: CheckboxField,
  date_of_birth: DateOfBirthField,
  date_picker: DatePickerField,
  email: EmailField,
  help_text: HelpText,
  number: NumberField,
  plain_text: PlainTextField,
  radio: RadioField,
  relationship: SelectField,
  section_display: RadioField,
  select: SelectField,
  textarea: TextareaField,
  textbox: TextboxField,
  toggle: ToggleField,
};

export const WebFormField: FC<ElementMetadata> = ({ element, metadata }) => {
  const TheField = COMPONENT_TO_FIELD_MAP[element.component] || NotYetImplementedField;

  return (
    <TheField
      element={element}
      metadata={metadata}
    />
  );
};

const NotYetImplementedField = ({ element }: { element: ElementProps }) => (
  <div
    className="ae-grid"
    data-testid="not-yet-implemented-field"
  >
    <div className="ae-grid__item item-sm-12">
      <h2 className="vx-heading-2">
        {element.component} - NOT YET IMPLEMENTED [{element.description}]
      </h2>
    </div>
  </div>
);

const MultiFieldElement: FC<{ element: ElementProps }> = ({ element }) => {
  const { form, submission } = useWebForm();

  const groupedElementChildren = element.children
    .sort(sortBy('sort_key'))
    .reduce((acc, obj, index) => {
      const metadata = getFieldMetadata({
        element: obj,
        formMode: form.mode,
        operationType: submission.operation_type,
        submission,
      });
      const meta = useField(metadata.dataKeypath)[1];
      const hasError = meta.touched && meta.error;

      const object = { element: obj, metadata: metadata, hasError: hasError };

      if (index === 0 || obj['force_line_break_before']) {
        acc.push([object]);
        // push a new group (with the object) to the stack
      } else {
        acc[acc.length - 1].push(object);
        // add the element in with the previous group
      }

      return acc;
    }, []);

  return (
    <div data-testid="multi-field-element">
      {groupedElementChildren.map((grouping) => (
        <div
          key={getFieldKeypath(grouping[0].element)}
          className="ae-grid"
          data-testid="ae-grid-div"
        >
          {grouping.map(({ element, metadata, hasError }) => (
            <div
              key={getFieldKeypath(element)}
              className={classnames(
                `ae-grid__item child-element-container item-md-${element.field_size || '12'}`,
                {
                  highlighted: metadata.isFieldHiddenDefault,
                  hidden: !metadata.isFieldVisible,
                  [WebFormScrollToError.className]: hasError,
                }
              )}
            >
              <WebFormField
                element={element}
                metadata={metadata}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default MultiFieldElement;
