import React from 'react';
import classNames from 'classnames';

export default function Field(props) {
  const { className, onLabelClick, ...fieldProps } = props;

  const { name, type, value, error, onChange, config } = fieldProps;

  const isDisplayed = config.displayed;
  const isRequired = config.required;
  const isBlank = !value || value == '0';
  const hasError = !!error;

  if (!isDisplayed) return null;

  const fieldClassNames = classNames({
    'vx-form-field': true,
    'vx-form-field--blank': isBlank,
    error: hasError,
  });

  const fieldLabelClassNames = classNames({
    'vx-form-label': true,
    'vx-form-label--required': isRequired,
  });

  const fieldControlClassNames = classNames({
    'vx-form-control': true,
    [className]: true,
  });

  const fieldLabel = config.label && (
    <label
      className={fieldLabelClassNames}
      htmlFor={name}
      onClick={onLabelClick}
    >
      {config.label}
    </label>
  );

  const fieldNotes = config.notes && <div className="vx-form-field-notes">{config.notes}</div>;

  const fieldError = error && <span className="vx-validation-message">{error}</span>;

  return (
    <div className={fieldClassNames}>
      {fieldLabel}
      {fieldNotes}
      <div className={fieldControlClassNames}>{props.children}</div>
      {fieldError}
    </div>
  );
}
