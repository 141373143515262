import React from 'react';
import moment from 'moment';

// TODO: This component is identical to the one in app/javascript/components/system/web-forms/DatePickerMonth.js
// Should be refactored to a shared component in the common folder.

class DatePickerMonth extends React.Component {
  constructor(props) {
    super(props);

    const years = [];

    let selectYears = props.selectYears;

    // matches pickadate's default https://amsul.ca/pickadate.js/date/#selectors
    if (selectYears !== undefined && selectYears !== false) {
      if (selectYears === true) {
        selectYears = 10;
      }

      selectYears = Math.floor(selectYears / 2);
    }

    for (
      let i = this.props.date.year() - selectYears;
      i <= this.props.date.year() + selectYears;
      i++
    ) {
      years.push(
        <option
          value={i}
          key={`year-${i}`}
        >
          {i}
        </option>
      );
    }

    this.state = {
      years: years,
    };
  }

  render() {
    return (
      <>
        <select
          data-cb="datepicker-year"
          className="datepicker-select"
          value={this.props.date.year()}
          onChange={(e) => this.props.onYearSelect(this.props.date, e.target.value)}
        >
          {this.state.years}
        </select>
        <select
          data-cb="datepicker-month"
          className="datepicker-select"
          value={this.props.date.month()}
          onChange={(e) => this.props.onMonthSelect(this.props.date, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option
              key={value}
              value={value}
            >
              {label}
            </option>
          ))}
        </select>
      </>
    );
  }
}

export default DatePickerMonth;
