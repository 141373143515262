import React from 'react';
import Field from '../Field';

export default function DataField(props) {
  const { name, value, config } = props;

  const BLANK_PLACEHOLDER = '--';
  const PARSE_DATE_FORMAT = 'YYYY-MM-DD';
  const DISPLAY_DATE_FORMAT = 'ddd MMM DD, YYYY';

  const formatDateValue = (value) => {
    return value ? moment(value, PARSE_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : BLANK_PLACEHOLDER;
  };

  const formatOptionValue = (value) => {
    let optionValue = config.options.find((option) => option.value == value);
    return optionValue ? optionValue.description : BLANK_PLACEHOLDER;
  };

  const formatNumberValue = (value) => {
    return value ? Number(value).toString().slice(0, 10) : BLANK_PLACEHOLDER;
  };

  const formatValue = (value) => {
    switch (config.type) {
      case 'date':
        return formatDateValue(value);

      case 'radio-group':
      case 'select':
        return formatOptionValue(value);

      case 'number':
        return formatNumberValue(value);

      case 'text':
      case 'textarea':
      default:
        return value || BLANK_PLACEHOLDER;
    }
  };

  const displayValue = formatValue(value);

  return (
    <Field
      className="vx-data-field"
      {...props}
    >
      <div className="vx-data-field__div">{displayValue}</div>
    </Field>
  );
}
