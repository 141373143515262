import React from 'react';

import { DateField, SelectField } from 'components/shared/form_elements';
import PurchaseRequestItemReceiving from './items/PurchaseRequestItemReceiving';
import { formatMonetaryValue } from 'components/shared/utils';
import DatePicker from '../web-forms/DatePicker';
import moment from 'moment';

// prettier-ignore
function PurchaseRequestReceivingSection(props) {
  const {
    data,
    config,
    items,
    subtotalAmount,
    formValues,
    formErrors,
    formChanges,
    handleFormFieldChange,
    setFormValue,
    loadItems,
  } = props;

  const handleReceivedStatusChange = React.useCallback((event) => {
    const { value } = event.target;
    if (value === 0) {
      setFormValue('received_date', null);
    }
    handleFormFieldChange(event);
  });

  /**************************************************************************************************/

  return (
    <>
      <div className="purchase-request-detail-section">
        <div className="purchase-request-detail-section-header">
          <div className="purchase-request-detail-section-header-title">
            Receiving Purchase Order
          </div>
        </div>
        <div className="ae-grid">
          <div className="ae-grid__item item-sm-4">
            <SelectField
                name="received_status"
                config={config.received_status}
                value={formValues.received_status}
                error={formErrors.received_status}
                onChange={handleReceivedStatusChange}
                hasChange={formChanges.received_status}
                required={true}
              />
          </div>
          <div className="ae-grid__item item-sm-4">
            {parseInt(formValues.received_status) === 0 &&
              <DateField
                  config={{...config.received_date, required: false}}
                  value={null}
                  disabled={true}
                  displayDateFormat={'MM-DD-YYYY'}
                  />
            }
            {parseInt(formValues.received_status) === 1 &&
              <DateField
                  name="received_date"
                  config={config.received_date}
                  value={formValues.received_date}
                  error={formErrors.received_date}
                  onChange={handleFormFieldChange}
                  hasChange={formChanges.received_date}
                  displayDateFormat={'MM-DD-YYYY'}
                  />
            }
          </div>
        </div>
      </div>

      <div className="purchase-request-detail-section">
        <div className="purchase-request-detail-section-header">
          <div className="purchase-request-detail-section-header-title">
            Receiving Items
          </div>
        </div>

        <div id="table-items-container" className="ae-grid">
          <div className="ae-grid__item item-sm-12">
            <div className="purchase-requests__table_wrapper">
              <table className="purchase-requests__table" style={{ overflowX: 'scroll' }}>
                <thead>
                <tr>
                  <th className="text-align-left description-th">
                    Description
                  </th>
                  <th className="text-align-center model-th">
                    Model Number
                  </th>
                  <th className="text-align-right unit-cost-th">
                    Unit Cost
                  </th>
                  <th className="text-align-center quantity-th">
                    Quantity
                  </th>
                  <th className="text-align-right total-th">
                    Total
                  </th>
                  <th className="text-align-center received-status-th">
                    Received Status
                  </th>
                  <th className="text-align-center received-quantity-th">
                    Received Quantity
                  </th>
                  <th className="text-align-center received-date-th">
                    Received Date
                  </th>
                  <th className="text-align-left actions-th">
                    Action
                  </th>
                </tr>
                </thead>
                <tbody>
                  {items?.map((item, index) => (
                    <PurchaseRequestItemReceiving
                      key={item.id}
                      data={item}
                      config={config}
                      loadItems={loadItems}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className='gray-cell' colSpan={4}>Subtotal</td>
                    <td className="text-align-right">
                      {subtotalAmount && formatMonetaryValue(subtotalAmount, {
                        locale: data.currency_locale,
                        code: data.currency_code,
                      })}
                    </td>
                    <td className='gray-cell' colSpan={4}></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(PurchaseRequestReceivingSection);
