export const PORTALS_REQUEST_URL_PARAMS = {
  client: Portals.config.client,
  portal_name: Portals.config.portal_name,
};
type Error = {
  name: string;
  message: string;
  stack?: string;
  code?: number | string;
};

export async function makeRequest(
  url: string,
  method: string,
  authToken: string,
  body = null
): Promise<any> {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store',
    'X-CSRF-Token': authToken,
  };

  const params = {
    method,
    headers,
    body: undefined,
  };
  if (body) {
    params.body = JSON.stringify(body);
  }
  const response = await fetch(url, params);
  const responseBody = await response.json();
  if (response.ok) {
    return responseBody;
  }

  if (responseBody.message) {
    // server is throwing a custom error that needs to be displayed
    throw new Error(responseBody.message) as Error;
  } else {
    throw response;
  }
}
