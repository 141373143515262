import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useScreenSizes } from '../../stores/useAppStore';

const SidebarContainer = ({
  isActive,
  closeSidebar,
  renderSidebar,
  onClose,
  children,
  navBarClass = '.vx-tab-bar',
  isScrollable = true,
  useMobileWrapper = true,
  useCloseBackground = true,
  overrideStyles = '',
}) => {
  const tabBar = document.querySelector(navBarClass);
  const tabBarBottom = tabBar && isActive ? tabBar.getBoundingClientRect().bottom : 0;
  const [sidebarTop, setSidebarTop] = React.useState(0);
  const [overflowHidden, setOverflowHidden] = React.useState(false);
  const sidebarRef = React.useRef();
  const { isSm: isMobile } = useScreenSizes();

  useEffect(() => {
    const handleOutsideClick = (e) => {
      // This handles closing the sidebar if the sidebar is open, and the user clicked outside of it.
      // Pillar class 'vx-sidebar__close-background' can handle this for us, but using it prevents
      // background scrolling. So if we want to be able to scroll the background when the sidebar
      // is open, we handle the background closing here. Also need to check if we are clicking on the modal,
      // in which case we don't want to close.
      if (
        isActive &&
        !document.getElementById('delete-modal') &&
        document.getElementById('sidebar-container').contains(e.target) === false
      ) {
        closeSidebar();
      }
    };

    if (!useCloseBackground) {
      document.addEventListener('click', handleOutsideClick);
    }
    return () => {
      if (!useCloseBackground) {
        document.removeEventListener('click', handleOutsideClick);
      }
    };
  }, [isActive]);

  const handleScroll = () => {
    if (!isScrollable) {
      return;
    }
    // if we have scrolled past the page's tab bar
    if (window.scrollY > tabBarBottom && isActive) {
      // then the sidebar should stick to the top of the screen
      setSidebarTop(Math.abs(tabBar.getBoundingClientRect().bottom));
    } else {
      setSidebarTop(0); // otherwise the sidebar should be below the tab bar
    }
  };

  const handleTransition = (transitionEvent) => {
    // we only care about the "visibility" transition, ignore other transitions
    if (isActive || transitionEvent.propertyName !== 'visibility') {
      return;
    }

    setOverflowHidden(transitionEvent.type === 'transitionrun');
  };

  React.useEffect(() => {
    sidebarRef.current.addEventListener('transitionend', handleTransition, true);
    sidebarRef.current.addEventListener('transitionrun', handleTransition, true);
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
      sidebarRef.current.removeEventListener('transitionend', handleTransition, true);
      sidebarRef.current.removeEventListener('transitionrun', handleTransition, true);
    };
  }, []);

  React.useEffect(() => {
    if (!isActive && !overflowHidden) {
      onClose();
    }
  }, [overflowHidden]);

  const overflowHiddenClass = overflowHidden ? 'vx-sidebar-wrapper--overflow-hidden' : '';
  const mobileClass = isMobile && useMobileWrapper ? 'vx-sidebar-wrapper--mobile' : '';
  const sidebarWrapperActive = isActive ? 'vx-sidebar-wrapper--active' : '';
  const scrollStyle = isScrollable
    ? {
        // dynamic inline style to keep sidebar at top of screen
        top: sidebarTop,
      }
    : {};

  return (
    <div
      className={`vx-sidebar-wrapper ${sidebarWrapperActive} ${overflowHiddenClass} ${mobileClass}`}
      data-testid="sidebar-container-div"
    >
      {isActive && (
        <div
          className={useCloseBackground ? 'vx-sidebar__close-background' : ''}
          onClick={closeSidebar}
        />
      )}
      {children}
      <div
        ref={sidebarRef}
        className={`vx-sidebar attendance-sidebar z-[10000] max-md:fixed max-md:top-11 max-md:w-full ${overrideStyles}`}
        id="sidebar-container"
        style={scrollStyle}
      >
        {renderSidebar({ closeSidebar })}
      </div>
    </div>
  );
};

SidebarContainer.propTypes = {
  isActive: PropTypes.bool.isRequired,
  renderSidebar: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  overrideStyles: PropTypes.string,
  onClose: PropTypes.func,
};

SidebarContainer.defaultProps = {
  onClose: () => {},
};

export default SidebarContainer;
