import React, { useEffect, useState } from 'react';
import { AddCourseRequestModalData, CourseRequestFormData } from '../../types';
import Modal from '../../../../shared/Modal';
import ModalContent from './ModalContent';
import { useFetch } from '../../../../../hooks/useFetch';
import { handleCreateRequest } from '../../resources';
import { CLOSE_MODAL_ANIMATION_DURATION } from '../../constants';
import { useStudentState } from '../../../../../stores/useCourseRequestsStore';
import { DEFAULT_ERROR_MESSAGE, NOTIFY_DURATION } from '../../../gradebook/constants';

type CreateCourseRequestModalProps = {
  data: AddCourseRequestModalData;
  setIsOpen: (value: boolean) => void;
  setScrollToRequest: (value: number) => void;
};

const CreateCourseRequestModal = ({
  data,
  setIsOpen,
  setScrollToRequest,
}: CreateCourseRequestModalProps) => {
  const [errorSubmitting, setErrorSubmitting] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { student } = useStudentState();
  const { isOpen, requestGroup } = data;

  const {
    data: createResponse,
    sendFetchRequest: createCourseRequest,
    error: createErrorResponse,
  } = useFetch();

  const handleSaveForm = async (formValues: CourseRequestFormData) => {
    setErrorSubmitting(null);
    setIsLoading(true);
    try {
      await handleCreateRequest(
        student.person_pk,
        formValues,
        requestGroup,
        2,
        createCourseRequest
      );
    } catch {
      setIsLoading(false);
      // if no error, loading will wait for modal to close
    }
  };

  useEffect(() => {
    if (createResponse?.data?.course_request_pk) {
      setIsOpen(false);
      setScrollToRequest(createResponse.data.course_request_pk);
    }
  }, [createResponse]);

  useEffect(() => {
    if (createErrorResponse) {
      setErrorSubmitting(createErrorResponse?.message || DEFAULT_ERROR_MESSAGE);
      setTimeout(() => {
        setErrorSubmitting(null);
      }, NOTIFY_DURATION);
    }
  }, [createErrorResponse]);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setErrorSubmitting(null);
        setIsLoading(false);
      }, CLOSE_MODAL_ANIMATION_DURATION);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showButtons={false}
      fixedPosition={true}
      label="Delete"
    >
      <ModalContent
        handleSave={handleSaveForm}
        errorSubmitting={errorSubmitting}
        isLoading={isLoading}
        selectedRequestGroup={data.requestGroup}
      />
    </Modal>
  );
};

export default CreateCourseRequestModal;
