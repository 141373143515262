import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import NavTab from './NavTab';
import GradingPeriodsDropdown from '../grid/GradingPeriodsDropdown';
import { ASSIGNMENT_TAB, NEW_ASSIGNMENT_PATH, STUDENTS_TAB, WEIGHTING_PATH } from '../constants';

const SecondaryHeader = () => {
  const { pathname } = useLocation();
  const isNewAssignmentPage = pathname === NEW_ASSIGNMENT_PATH;
  const isWeightingPage = pathname === WEIGHTING_PATH;
  const [assignmentId, selectedTab] = pathname.substring(1).split('/');

  return (
    <div className="flex justify-start max-md:sticky max-md:top-12 max-md:bg-white max-md:z-50 nav-top items-center w-full border-b border-gray-200 h-12 text-md">
      <div className="max-md:hidden ml-5">
        <Link to={`/`}>
          <button className="bg-neutral-3 text-white rounded-md h-8 w-36 flex justify-center">
            <i className="nc-icon-glyph arrows-3_circle-left text-lg mr-2 leading-8" />
            <span className="leading-8">Back to Scores</span>
          </button>
        </Link>
      </div>

      <div
        className={`h-full flex justify-evenly items-center ${isWeightingPage ? 'w-80' : 'w-60'}`}
      >
        {isWeightingPage ? (
          <>
            <NavTab
              linkTo={`/${assignmentId}/${ASSIGNMENT_TAB}`}
              isSelected={isWeightingPage}
            >
              Weights
            </NavTab>
            <GradingPeriodsDropdown />
          </>
        ) : (
          <>
            <NavTab
              linkTo={`/${assignmentId}/${ASSIGNMENT_TAB}`}
              isSelected={selectedTab === ASSIGNMENT_TAB || isNewAssignmentPage}
            >
              Assignment Info
            </NavTab>
            <NavTab
              linkTo={`/${assignmentId}/${STUDENTS_TAB}`}
              isSelected={selectedTab === STUDENTS_TAB}
              isDisabled={isNewAssignmentPage}
            >
              Students
            </NavTab>
          </>
        )}
      </div>
    </div>
  );
};

export default SecondaryHeader;
