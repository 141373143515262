import create from 'zustand';
import produce from 'immer';
import { SectionType, SubTabType, TabType } from './types';

type NavigationState = {
  // name of the class, e.g. "MSSpanish 1"
  className: string;

  // the callback to go back to the previous page (if any)
  mobileTitle: string;
  mobileSubTitle: string;
  goBack: () => void;

  // the section tabs
  tabs: TabType[];
  subTabs: SubTabType[];

  // when true, the sidebar is expanded
  isExpanded: boolean;

  // what section are we in? e.g. gradebook, attendance, etc.
  currentSection: SectionType;
  sectionName: string;
  sectionIcon: string;

  // is the class selection modal open?
  isModalOpen: boolean;

  // is the sidebar open?
  isSidebarOpen: boolean;
};

type NavigationActions = {
  initialize: (state: Partial<NavigationState>) => void;
  setIsExpanded: (isExpanded: boolean) => void;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setClassName: (className: string) => void;
  setNavBar: (data: Partial<NavigationState>) => void;
  setSidebarOpen: (isSidebarOpen: boolean) => void;
  updateTabDescription: (sectionType: SectionType, newDescription: string) => void;
};

type NavigationStore = NavigationState & NavigationActions;

const useNavigationStore = create<NavigationStore>((set) => ({
  className: '',
  mobileBackButtonUrl: '',
  mobileTitle: '',
  mobileSubTitle: '',
  tabs: [],
  subTabs: [],
  isExpanded: JSON.parse(localStorage.getItem('sidebarExpandedLMS') || 'true'),
  currentSection: 'attendance',
  sectionName: '',
  sectionIcon: '',
  isModalOpen: false,
  isSidebarOpen: false,
  goBack: null,
  initialize: (data) => {
    set(
      produce<NavigationState>((state) => {
        state.currentSection = data.currentSection;
        const activeTab = data.tabs.find((tab) => tab.is_active);
        state.sectionName = activeTab?.description || '';
        state.sectionIcon = activeTab?.nc_icon_name || '';
        state.className = data.className;
        state.tabs = data.tabs;
        state.subTabs = data.subTabs;

        state.mobileTitle = state.sectionName;
        state.mobileSubTitle = data.className;
      })
    );
  },
  setIsExpanded: (isExpanded) => {
    localStorage.setItem('sidebarExpandedLMS', JSON.stringify(isExpanded));
    set({ isExpanded });
  },
  setIsModalOpen: (isModalOpen) => set({ isModalOpen }),
  setClassName: (className) => set({ className }),
  setNavBar: (data) =>
    set(
      produce<NavigationState>((state) => {
        state.mobileTitle = data.mobileTitle || state.sectionName;
        state.mobileSubTitle = data.mobileSubTitle || state.className;
        state.goBack = data.goBack || null;
      })
    ),
  setSidebarOpen: (isSidebarOpen) => set({ isSidebarOpen }),
  updateTabDescription: (sectionType, newDescription) =>
    set(
      produce<NavigationState>((state) => {
        const tab = state.tabs.find((tab) => tab.sectionType === sectionType);
        if (tab) tab.description = newDescription;
      })
    ),
}));

export default useNavigationStore;
