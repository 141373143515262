import React, { useState } from 'react';
import PersonDisplay from '../../common/PersonDisplay';
import { useClassState } from '../../../../../stores/useGradebookStore';
import { File, AssignmentFeedbackMessage } from '../../types';
import moment from 'moment';
import { useDeleteAssignmentFeedback, useDeleteAssignmentFile } from '../../mutations';
import FileList from '../../common/FileList';
import Modal from '../../../../shared/Modal';
import { LONG_DATE_FORMAT, TIME_FORMAT } from '../../constants';

type MessageHeaderProps = {
  message: AssignmentFeedbackMessage;
  studentPhotoUrl: string;
  hasFiles?: boolean;
  handleDelete?: () => void;
  showMessageDelete: boolean;
};
const MessageHeader = ({
  message,
  studentPhotoUrl,
  hasFiles,
  handleDelete,
  showMessageDelete,
}: MessageHeaderProps) => {
  const { teacherPhotoUrl } = useClassState();
  const isStudent = message.record_type === 'student';
  const photoUrl = isStudent ? studentPhotoUrl : teacherPhotoUrl;
  const dateObject = moment.utc(message.submission_date);
  const { today } = useClassState();
  const isCurrentDate = dateObject.isSame(today, 'day');
  const dateText = dateObject.format(LONG_DATE_FORMAT);
  const timeText = dateObject.format(TIME_FORMAT);
  const hasOnlyFiles = hasFiles && !message.notes;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const title = message.submission_user;
  let preText;
  if (hasOnlyFiles) {
    preText = 'Added files on ';
  } else {
    preText = isCurrentDate ? 'Commented ' : 'Commented on ';
  }

  const subtitle = (
    <span className="text-neutral-3">
      {' '}
      {preText} {dateText} at {timeText}
    </span>
  );

  return (
    <>
      <div className="m-2.5 flex justify-start">
        <PersonDisplay
          avatarUrl={photoUrl}
          title={title}
          subtitle={subtitle}
        />
        {showMessageDelete && !isStudent && (
          <i
            className="md:invisible group-hover:visible hover:cursor-pointer nc-icon-glyph ui-1_bold-remove text-xl leading-9 ml-4 text-red-3"
            onClick={() => setIsModalOpen(true)}
            data-testid="delete-message-icon"
          />
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onSave={() => {
          setIsModalOpen(false);
          handleDelete();
        }}
        label="Delete"
        overrideClasses="bg-red-3 hover:bg-red-4"
        setIsOpen={setIsModalOpen}
      >
        <div
          className="text-red-3 text-lg"
          id="delete-modal"
        >
          Are you sure you want to delete this message?
        </div>
      </Modal>
    </>
  );
};

type MessageProps = {
  studentPhotoUrl: string;
  message: AssignmentFeedbackMessage;
  files: File[];
  onDelete: () => void;
};
const Message = ({ studentPhotoUrl, message, files, onDelete }: MessageProps) => {
  const hasFiles = Boolean(files.length);
  const deleteMessage = useDeleteAssignmentFeedback();
  const deleteFile = useDeleteAssignmentFile();
  const hasNoTextAndOnlyOneFile = !message.notes && files.length === 1;

  const handleDeleteMessage = async () => {
    await deleteMessage.mutateAsync({ feedback_id: message.feedback_id });
    onDelete();
  };

  const handleDeleteFile = async (filePk: string) => {
    if (hasNoTextAndOnlyOneFile) {
      handleDeleteMessage();
    }

    await deleteFile.mutateAsync({ file_pk: filePk });
    onDelete();
  };

  return (
    <div
      className="group"
      data-testid="feedback-message"
    >
      <MessageHeader
        message={message}
        studentPhotoUrl={studentPhotoUrl}
        hasFiles={hasFiles}
        showMessageDelete={!hasNoTextAndOnlyOneFile}
        handleDelete={handleDeleteMessage}
      />
      {message.notes && (
        <div className="mx-7 mt-2 pl-4 pb-4 pt-1 border-l-2 border-neutral-5 whitespace-pre-wrap">
          {message.notes}
        </div>
      )}
      {hasFiles && (
        <FileList
          onDelete={handleDeleteFile}
          files={files}
          classOverrides="mx-7 mb-2 p-1 pl-4 pb-4 border-l-2 border-neutral-5"
        />
      )}
    </div>
  );
};

export default Message;
