import React from 'react';
import useNavigationStore from './useNavigationStore';

const LmsMobileNavbar = () => {
  const { sectionIcon, mobileTitle, mobileSubTitle, goBack, setSidebarOpen } = useNavigationStore();

  return (
    <div className="h-12 md:hidden fixed justify-between top-0 z-40 w-full bg-gray-600 text-white flex">
      {/* if back button url is present, show it */}
      <div className="flex">
        {goBack ? (
          <i
            className="p-2 nc-icon-glyph arrows-1_tail-left text-2xl cursor-pointer"
            onClick={goBack}
          />
        ) : (
          <i className={`p-2 nc-icon-outline ${sectionIcon} text-2xl`} />
        )}
      </div>

      <div className="flex-1 flex-col py-2 overflow-hidden">
        <div className="text-xs leading-3 truncate">{mobileSubTitle}</div>
        <div className="text-base leading-5 truncate">{mobileTitle}</div>
      </div>

      <div className="flex">
        <i
          className="p-2 nc-icon-glyph ui-2_menu-34 text-2xl cursor-pointer"
          onClick={() => setSidebarOpen(true)}
        />
      </div>
    </div>
  );
};

export default LmsMobileNavbar;
