import React, { FC } from 'react';
import classnames from 'classnames';
import WebFormElement from '../elements/WebFormElement';
import { useWebForm } from '../WebFormContext';
import { getIn, useFormikContext } from 'formik';
import { getFieldKeypath, isParentElement } from '../utils/element-helpers';
import { FORM_MODE_ADMIN_EDIT, FORM_MODE_VIEW_SUBMISSION, sortBy } from '../utils/form-helpers';
import { SectionType } from '../types';

export const SECTION_DISPLAY_DISABLED = 0;
export const SECTION_DISPLAY_ENABLED = 1;
export const SECTION_DISPLAY_HIDDEN = 2;

const WebFormSection: FC<{ section: SectionType }> = ({ section }) => {
  const isSectionHidden = section.display === SECTION_DISPLAY_HIDDEN;
  const [isDisplayHidden, setIsDisplayHidden] = React.useState(isSectionHidden);
  const { form, sectionDisplayMap } = useWebForm();
  const { values } = useFormikContext();
  const sectionDisplayMapElement = sectionDisplayMap[section.data_keypath];
  const selectedKeypath = getIn(values, sectionDisplayMapElement);

  React.useEffect(() => {
    const isNotViewSubmission = form.mode !== FORM_MODE_VIEW_SUBMISSION;
    const isNotAdminEdit = form.mode !== FORM_MODE_ADMIN_EDIT;
    setIsDisplayHidden(
      isSectionHidden &&
        selectedKeypath !== section.data_keypath &&
        isNotViewSubmission &&
        isNotAdminEdit
    );
  }, [selectedKeypath]);

  return (
    <div
      data-testid={section.data_keypath}
      className={classnames('section-container', {
        hidden: isDisplayHidden,
      })}
    >
      <div className={'vx-field-group'}>
        {section.elements
          .filter(isParentElement)
          .sort(sortBy('sort_key'))
          .map((element) => (
            <WebFormElement
              key={getFieldKeypath(element)}
              element={element}
            />
          ))}
      </div>
    </div>
  );
};

export default WebFormSection;
