import React from 'react';
import { Properties } from '../types';
import { Switch } from '@headlessui/react';

const BooleanLabel = ({
  settingProperties,
  overrideClasses = '',
}: {
  settingProperties: Properties;
  overrideClasses?: string;
}) => {
  return (
    <label className={`flex-1 leading-6 select-none ${overrideClasses}`}>
      {settingProperties.description}
    </label>
  );
};

export default BooleanLabel;
