import React, { FC, ReactNode, useEffect, useState } from 'react';
import FieldLabel from '../assignment-detail/FieldLabel';
import { Field, useField } from 'formik';

type NewAssignmentFormElementProps = {
  label: string;
  attribute: string;
  component: ReactNode;
  tooltipInfo?: string;
  extraProps?: Record<any, any>;
};
const NewAssignmentFormElement: FC<NewAssignmentFormElementProps> = ({
  label,
  attribute,
  component,
  children,
  tooltipInfo,
  extraProps = {},
}) => {
  const [_field, meta, helpers] = useField(attribute);
  const { value } = meta;
  const { setValue, setTouched } = helpers;
  const [unsavedChanges, setUnsavedChanges] = useState(null);

  useEffect(() => {
    window.onbeforeunload = () => {
      if (unsavedChanges) {
        return true;
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [unsavedChanges]);

  const handleChange = async (
    newValue,
    _, // shared parameter with FormElement component
    isChangeToRedactorField = false
  ) => {
    setUnsavedChanges(newValue); // Unsaved changes that we should save before exiting page.

    if (isChangeToRedactorField) {
      // This is a special case to handle redactor textarea changes specifically.
      // Our current version of Redactor doesn't work correctly with Formik.
      // Saving to Formik when onChange triggers, causes problems. So we only save onBlur.

      // The only thing we needed to do was record the unsaved change, so we exit here
      return;
    }

    setTouched(true);
    const errors = await setValue(newValue); // save to formik

    if (errors?.[attribute]) {
      setUnsavedChanges(null);
    }
  };

  const isFormikError = meta.touched && meta.error;

  return (
    <div className="relative">
      <FieldLabel
        label={label}
        tooltipInfo={tooltipInfo}
      />
      <Field
        value={value}
        as={component}
        onChange={handleChange}
        {...extraProps}
      />
      {children}
      {isFormikError ? <div className="text-red-3 mt-0.5">{meta.error}</div> : null}
    </div>
  );
};

export default NewAssignmentFormElement;
