import React, { useEffect, useState } from 'react';
import { Properties } from '../types';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { useUpdateConfiguration } from '../mutations';
import LockIcon from './LockIcon';

const BooleanToggle = ({
  settingProperties,
  setServerError,
  serverError,
  isEditable,
}: {
  settingProperties: Properties;
  setServerError: (serverError: string | null) => void;
  serverError: string | null;
  isEditable: boolean;
}) => {
  // State Logic
  const [switchState, setSwitchState] = useState(!!settingProperties.value);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!!serverError) toggleSwitchState();
    setServerError(serverError);
  }, [serverError]);

  const toggleSwitchState = () => {
    setSwitchState(!switchState);
  };

  // Style Logic
  const switchBackgroundClasses =
    // Colorful when editable, gray when not
    classNames(
      'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2',
      {
        'bg-teal-500 cursor-pointer': isEditable && switchState,
        'bg-red-500 cursor-pointer': isEditable && !switchState,
        'bg-teal-200 cursor-not-allowed select-none': !isEditable && switchState,
        'bg-red-200 cursor-not-allowed select-none': !isEditable && !switchState,
        'cursor-wait': loading,
        'ring-2 ring-red-300 focus:ring-red-500': serverError,
      }
    );

  const switchToggleClasses =
    // Toggle animation for the switch
    classNames(
      'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
      {
        'translate-x-5': switchState,
        'translate-x-0': !switchState,
      }
    );

  // instantiate useMutation configured above
  const saveConfiguration = useUpdateConfiguration(toggleSwitchState, toggleSwitchState);

  const saveConfigurationRequest = async () => {
    await saveConfiguration.mutateAsync({
      db_source: settingProperties.db_source,
      configuration_target: settingProperties.target,
      new_value: !switchState,
    });
  };

  // Kick off the mutation on change
  const handleChange = async () => {
    if (loading || serverError) return;
    try {
      setLoading(true);
      await saveConfigurationRequest();
    } catch (e) {
      setServerError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex-none flex items-center my-1">
      <Switch
        checked={switchState}
        onChange={handleChange}
        className={switchBackgroundClasses}
        disabled={!isEditable}
      >
        <span
          aria-hidden="true"
          className={switchToggleClasses}
        />
      </Switch>
    </div>
  );
};

export default BooleanToggle;
