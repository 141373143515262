import produce from 'immer';
import create from 'zustand';
import { getNextFocusedRow } from '../components/shared/utils.js';

type AppState = {
  screenSizes: {
    isSm: boolean;
    isMd: boolean;
  };
  userState: {
    focusedStudentRow: number;
    shortcutTrigger: {
      type: string;
      id: number;
    };
    isFocusingInput: boolean;
    moveFocusedStudentRow: (numOfListItems: number, direction: 1 | -1) => void;
    setFocusedStudentRow: (newRowIndex: number, isFocusingInput?: boolean) => void;
    setShortcutTrigger: (newShortcutTrigger: { type: string; id: number }) => void;
  };
  setScreenSizes: () => void;
};
const useAppStore = create<AppState>((set) => ({
  screenSizes: {
    isSm: window.innerWidth <= 640,
    isMd: window.innerWidth <= 768,
  },

  setScreenSizes: () =>
    set(
      produce((state) => {
        state.screenSizes = {
          isSm: window.innerWidth <= 640,
          isMd: window.innerWidth <= 768,
        };
      })
    ),

  userState: {
    // index -1 is right before the first student - but starts with no student focused
    focusedStudentRow: -1,
    shortcutTrigger: null,

    // This allows for focusing on input when moving up/down, or clicking on input
    // but not when clicking on a dropdown in the same row,
    // in which case we want to focus on the row but not the input
    isFocusingInput: true,

    setShortcutTrigger: (newShortcutTrigger) =>
      set(
        produce((state) => {
          state.userState.shortcutTrigger = newShortcutTrigger;
        })
      ),

    moveFocusedStudentRow: (numOfListItems, direction) =>
      set(
        produce((state) => {
          state.userState.focusedStudentRow = getNextFocusedRow(
            state.userState.focusedStudentRow,
            numOfListItems,
            direction
          );
          state.userState.isFocusingInput = true; // always focus on input when moving up/down
        })
      ),

    setFocusedStudentRow: (newRowIndex, isFocusingInput = true) =>
      set(
        produce((state) => {
          state.userState.focusedStudentRow = newRowIndex;
          state.userState.isFocusingInput = isFocusingInput;
        })
      ),
  },
}));

const handleResize = () => {
  useAppStore.getState().setScreenSizes();
};
window.addEventListener('resize', handleResize);

export const useScreenSizes = () => useAppStore((state) => state.screenSizes);
export const useUserState = () => useAppStore((state) => state.userState);

export default useAppStore;
