/***************************************************************************************************
 ** Helpers
 ***************************************************************************************************/

export const isObjectEmpty = (obj) => {
  return Object.values(obj).filter((value) => value).length === 0;
};

export const compactify = (data) => {
  if (!_.isObject(data)) return data;

  return Object.entries(data).reduce((obj, [name, value]) => {
    if (value) obj[name] = compactify(value);
    return obj;
  }, {});
};

export const redirectTo = (url) => {
  window.location.href = url;
};

export const redirectToRoute = (pathName, params = {}) => {
  const jsRouteFn = Routes[pathName];
  const jsRouteParams = {
    client: Portals.config.client,
    portal_name: Portals.config.portal_name,
    ...params,
    _options: true,
  };

  window.location.href = jsRouteFn(jsRouteParams);
};

/**************************************************************************************************/

export const buildUrl = (url, params = {}) => {
  if (!url) return null;

  const baseUrl = url.split('?')[0].split('#')[0];
  const queryString =
    Object.entries(params)
      .map(([key, value]) => [key, value].join('='))
      .join('&') || null;

  return queryString ? [baseUrl, queryString].join('?') : baseUrl;
};

export const getCurrentUrl = () => {
  const currentFullUrl = window.location.href;
  const currentUrl = currentFullUrl.split('?')[0].split('#')[0];
  return currentUrl;
};

export const updateCurrentUrlParams = (params = {}, title = document.title) => {
  const currentUrl = getCurrentUrl();
  const currentParams = compactify(params);
  const url = buildUrl(currentUrl, currentParams);

  window.history.replaceState(title, title, url);
};

export const clearCurrentUrlParams = () => updateCurrentUrlParams({});

/**************************************************************************************************/

export const formatMonetaryValue = (value, options = {}) => {
  options.locale ||= 'en';
  options.code ||= 'USD';

  const formatter = new Intl.NumberFormat(options.locale, {
    style: 'currency',
    currency: options.code,
  });

  return formatter.format(value);
};

export const getNextFocusedRow = (currentRow, numOfStudents, direction = 1) => {
  let nextRow = currentRow + direction;
  if (direction === 1 && nextRow >= numOfStudents) {
    // reset to right before the first student
    nextRow = -1;
  }
  if (direction === -1 && nextRow < -1) {
    // move to last student in list
    nextRow = numOfStudents - 1;
  }
  return nextRow;
};

export const formatShortcut = (shortcut) => {
  let shortcutFormatted = shortcut;

  // special cases
  if (shortcut === '#') {
    shortcutFormatted = 'shift+3';
  }
  if (shortcut === '*') {
    shortcutFormatted = 'shift+8';
  }
  return shortcutFormatted;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// ArrayStats function is copied from Portals2 underscore-ext.js

// Returns an object that contains the count, sum,
// minimum, median, maximum, mean, variance, and
// standard deviation of the series of numbers stored
// in the specified array.  This function changes the
// specified array by sorting its contents.
export function ArrayStats(data) {
  // Compares two objects using
  // built-in JavaScript operators.
  const ascend = function (a, b) {
    if (a < b) return -1;
    else if (a > b) return 1;
    return 0;
  };

  this.count = data.length;

  /* Sort the data so that all seemingly
   * insignificant values such as 0.000000003 will
   * be at the beginning of the array and their
   * contribution to the mean and variance of the
   * data will not be lost because of the precision
   * of the CPU. */
  data.sort(ascend);

  /* Since the data is now sorted, the minimum value
   * is at the beginning of the array, the median
   * value is in the middle of the array, and the
   * maximum value is at the end of the array. */
  this.min = data[0];
  const middle = Math.floor(data.length / 2);
  if (data.length % 2 != 0) {
    this.median = data[middle];
  } else {
    this.median = (data[middle - 1] + data[middle]) / 2;
  }
  this.max = data[data.length - 1];

  /* Compute the mean and variance using a
   * numerically stable algorithm. */
  let sqsum = 0;
  this.mean = data[0];
  for (let i = 1; i < data.length; ++i) {
    const x = data[i];
    const delta = x - this.mean;
    const sweep = i + 1.0;
    this.mean += delta / sweep;
    sqsum += delta * delta * (i / sweep);
  }
  this.sum = this.mean * this.count;
  this.variance = sqsum / this.count;
  this.stdev = Math.sqrt(this.variance);
}
