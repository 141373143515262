import React from 'react';

import Field from './Field';

const defaultConfig = {
  disabled: false,
  required: false,
  options: [],
};

export default function RadioGroupField(props) {
  const { name, type, value, error, onChange } = props;

  const config = { ...defaultConfig, ...props.config };

  const isDisabled = props.disabled || config.disabled;

  const FieldValueOptions = config.options.map((option) => (
    <div
      className="ae-grid__item item-sm-3"
      key={option.value}
    >
      <label className="vx-radio-group__item">
        <input
          className="vx-radio-input"
          type="radio"
          name={name}
          value={option.value}
          onChange={onChange}
          disabled={isDisabled}
          required={config.required}
          checked={option.value === value}
        />
        <span className="vx-radio-group__label">{option.description}</span>
      </label>
    </div>
  ));

  return (
    <Field
      className="vx-radio-group"
      {...props}
    >
      <div
        className="ae-grid"
        aria-labelledby={name}
      >
        {FieldValueOptions}
      </div>
    </Field>
  );
}
