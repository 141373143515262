import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AssignmentDetail from '../assignment-detail/AssignmentDetail';
import { useGridData } from '../queries';
import AssignmentStudents from '../students/AssignmentStudents';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { useAssignment } from '../queries';

const AssignmentPage = () => {
  const { data } = useGridData();
  const assignment = useAssignment();

  if (!assignment) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Routes>
        <Route
          path={`students`}
          element={
            <AssignmentStudents
              data={data}
              assignment={assignment}
            />
          }
        />
        <Route
          path={`assignment`}
          element={<AssignmentDetail assignment={assignment} />}
        />
      </Routes>
    </>
  );
};

export default AssignmentPage;
