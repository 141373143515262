import React from 'react';
import { useFormikContext } from 'formik';

const WebFormScrollToError = () => {
  const { isSubmitting } = useFormikContext();

  React.useEffect(() => {
    const fieldWithError = document.querySelector(`.${WebFormScrollToError.className}`);
    fieldWithError?.scrollIntoView(true);
  }, [isSubmitting]);

  return null;
};

WebFormScrollToError.className = 'element-error';

export default WebFormScrollToError;
