import React from 'react';

const SuccessNotification = ({ isShowing }) => {
  if (!isShowing) {
    return null;
  }
  return (
    <div
      className="success-notification"
      data-testid="success-notification"
    >
      <i className="nc-icon-glyph ui-1_check-circle-08"></i>
    </div>
  );
};

export default SuccessNotification;
