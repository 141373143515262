import React from 'react';
import ReactSelect from 'react-select';

import { formatMonetaryValue } from 'components/shared/utils';
import useAsyncRequest from 'components/shared/hooks/useAsyncRequest';
import AuthTokenContext from 'components/shared/AuthTokenContext';
import { TextField, NumberField, Button } from 'components/shared/form_elements';

const FIELD_CONFIG = {
  unit_cost: {
    min: 0,
    step: 0.01,
  },
  quantity: {
    min: 0,
    step: 1.0,
  },
};

const DEFAULT_NEW_PURCHASE_REQUEST_ITEM_VALUES = {
  description: '',
  model_number: '',
  quantity: 1,
  unit_cost: 0,
};

// prettier-ignore
function NewPurchaseRequestItem(props) {
  const {
    data,
    setShowNewItemForm,
    reloadItems,
    glAccounts,
    taxTypes,
    defaultTaxType,
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const glAccountOptions = glAccounts.map((glAccount) => ({
    value: glAccount.gl_account_pk,
    label: `${glAccount.gl_number} - ${glAccount.gl_account_name}`,
  }));

  const taxTypeOptions = taxTypes.map((taxType) => ({
    value: taxType.id,
    label: taxType.description,
  }));

  /***************************************************************************************************
  ** Form Values
  ***************************************************************************************************/

  const defaultFormValues = defaultTaxType ? {...DEFAULT_NEW_PURCHASE_REQUEST_ITEM_VALUES, tax_type: {value: defaultTaxType.id, label: defaultTaxType.description}} : DEFAULT_NEW_PURCHASE_REQUEST_ITEM_VALUES;
  const [formValues, setFormValues] = React.useState(defaultFormValues);

  const setFormValue = React.useCallback((name, value) => {
    setFormValues((values) => ({ ...values, [name]: value }));
  }, []);

  const handleFormFieldChange = React.useCallback((event) => {
    setFormValue(event.target.name, event.target.value);
  }, [setFormValue]);

  const handleGlAccountChange = (selectedOption) => {
    setFormValue('gl_account', selectedOption);
  }

  const handleTaxTypeChange = (selectedOption) => {
    setFormValue('tax_type', selectedOption);
  }

  /***************************************************************************************************
  ** Create
  ***************************************************************************************************/

  const createPurchaseRequestItemRequest = useAsyncRequest({
    pathName: 'create_purchase_request_item_path',
    params: {
      purchase_request_id: data.id,
    },
    method: 'POST',
    authorizationToken: authToken,
  });

  const handleClickCreateButton = React.useCallback((event) => {
    event.preventDefault();

    const submitData = {
      purchase_request_item: {
        ...formValues,
        gl_account_fk: formValues?.gl_account?.value,
        model_number: formValues?.model_number?.trim(),
        description: formValues?.description?.trim(),
        tax_type: formValues?.tax_type?.value,
      },
    };

    createPurchaseRequestItemRequest(submitData).then((_data) => {
      setShowNewItemForm(false);
      reloadItems();
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }, [
    formValues,
    setShowNewItemForm,
    reloadItems,
  ]);

  /**************************************************************************************************/

  const handleClickCloseButton = React.useCallback((event) => {
    event.preventDefault();

    setShowNewItemForm(false);
  }, [setShowNewItemForm]);

  /**************************************************************************************************/
  return (
    <tr>
      <td>
        <TextField
          name="description"
          value={formValues.description}
          onChange={handleFormFieldChange}
        />
      </td>
      <td>
        <TextField
          name="model_number"
          value={formValues.model_number}
          onChange={handleFormFieldChange}
        />
      </td>
      <td >
        <ReactSelect
            name="gl_account"
            options={glAccountOptions}
            value={formValues.gl_account}
            onChange={handleGlAccountChange}
            menuPosition={'fixed'}
          />
      </td>

      <td>
        <NumberField
          name="unit_cost"
          value={formValues.unit_cost}
          onChange={handleFormFieldChange}
          config={FIELD_CONFIG.unit_cost}
        />
      </td>
      <td>
        <NumberField
          name="quantity"
          value={formValues.quantity}
          onChange={handleFormFieldChange}
          config={FIELD_CONFIG.quantity}
        />
      </td>
      {taxTypes?.length > 1 &&
        <>
          <td>
            <TextField
              name="pre_tax_amount"
              value={formatMonetaryValue(0, {
                locale: data.currency_locale,
                code: data.currency_code,
              })}
              disabled={true}
            />
          </td>
          <td >
            <ReactSelect
              name="tax_type"
              options={taxTypeOptions}
              value={formValues.tax_type}
              onChange={handleTaxTypeChange}
              menuPosition={'fixed'}
            />
          </td>
          <td>
            <TextField
              name="tax_amount"
              value={formatMonetaryValue(0, {
                locale: data.currency_locale,
                code: data.currency_code,
              })}
              disabled={true}
            />
          </td>
        </>
      }
      <td>
        <TextField
          name="total_cost"
          value={formatMonetaryValue(0, {
            locale: data.currency_locale,
            code: data.currency_code,
          })}
          disabled={true}
        />
      </td>
      <td className="text-align-left">
        <Button
          className="action-button vx-button vx-button--success"
          onClick={handleClickCreateButton}
          value="Create"
        />
        <Button
          className="action-button vx-button vx-button--neutral"
          onClick={handleClickCloseButton}
          value="Cancel"
        />
      </td>
    </tr>
  );
}

export default React.memo(NewPurchaseRequestItem);
