import React from 'react';
import { CalendarDay } from 'react-dates';
import { DATE_FORMAT } from './utils';

const DatePickerCalendarDay = ({ date, today, hasScheduledDays, datesProps }) => {
  // outer container of calendar day square
  const { day, modifiers } = datesProps;
  const dayString = day && day.format(DATE_FORMAT);
  const isSelected = dayString === date.format(DATE_FORMAT);
  const isToday = today === dayString;

  let newModifiersObj;
  if (isToday && !isSelected && hasScheduledDays) {
    if (modifiers && modifiers instanceof Set) {
      modifiers.add('today');
    } else {
      newModifiersObj = new Set(['today']);
    }
  } else if (modifiers && modifiers instanceof Set && modifiers.has('today')) {
    // don't let react-dates decide what 'today' is
    modifiers.delete('today');
  }

  return (
    <CalendarDay
      {...datesProps}
      modifiers={newModifiersObj || modifiers}
    />
  );
};
export default DatePickerCalendarDay;
