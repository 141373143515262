import React from 'react';
import { Link } from 'react-router-dom';
import { getCellDateColor, getDisplayDate } from '../helpers';
import { useGridData } from '../queries';
import { Assignment } from '../types';
import { useClassState } from '../../../../stores/useGradebookStore';
import classNames from 'classnames';

const EmptyAssignmentMessage = () => {
  return (
    <div className="mt-20">
      <div className="vx-empty-state-read-only">
        <div className="vx-empty-state__icon">
          <i className="nc-icon-outline x2 files_archive"></i>
        </div>
        <h5>No assignments matching the current filters exist.</h5>
        <p>
          Click the <i className="nc-icon-glyph ui-1_bold-add" /> button below to create one
        </p>
      </div>
    </div>
  );
};

const AssignmentCard = ({ assignment }: { assignment: Assignment }) => {
  const { today } = useClassState();
  const bgColor = getCellDateColor(today, assignment.due_date);

  return (
    <Link to={`${assignment.class_assignment_pk}/assignment`}>
      <li
        className={`${bgColor} overflow-hidden px-4 py-4 shadow-[0px_2px_8px_0px_rgba(0,0,0,0.2)] md:rounded-md sm:px-6 mx-2 mt-3 text-black`}
        data-testid="mobile-assignment-button"
      >
        <div className="bg-red-4 text-white uppercase w-fit px-1 text-xs rounded-0.5">
          {assignment.type_description}
        </div>
        <div className="mt-1 overflow-hidden text-ellipsis leading-4 font-semibold break-words">
          {assignment.description}
        </div>
        <div className="mt-auto font-light">
          {getDisplayDate(assignment.due_date || assignment.assignment_date)}
        </div>
      </li>
    </Link>
  );
};

const MobileGridPage = () => {
  const { data } = useGridData();
  const hasAssignments = data.assignments.length;

  return (
    <div className="md:hidden bg-white w-full">
      <ul
        role="list"
        className="space-y-3 pb-20"
      >
        {data.assignments.map((assignment, idx) => (
          <AssignmentCard
            assignment={assignment}
            key={idx}
          />
        ))}
        {!hasAssignments && <EmptyAssignmentMessage />}
      </ul>
      <Link to="assignment/new">
        <i
          className="z-50 fixed bottom-4 right-4 nc-icon-glyph ui-1_circle-bold-add text-[40px] text-green-4 bg-white rounded-full"
          data-testid="mobile-add-assignment-button"
        />
      </Link>
    </div>
  );
};

export default MobileGridPage;
