import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { InputChildProps } from '../types';

type ToggleProps = InputChildProps & {
  value: boolean;
  onChange: (
    value: string | number | boolean,
    shouldSaveToTheDatabase?: boolean,
    justNotifyOfUnsavedChanges?: boolean
  ) => void;
};
const Toggle = ({ value, onChange, isDisabled }: ToggleProps) => {
  const [isToggled, setIsToggled] = useState<boolean>(value);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isToggled !== value) {
      setIsToggled(value);
    }
  }, [value]);

  const handleToggle = async () => {
    if (loading) return;

    setIsToggled(!isToggled);

    setLoading(true);
    await onChange(!isToggled);
    setLoading(false);
  };

  const sliderClasses = classNames('vx-switch__slider shadow-none outline-none', {
    'bg-blue-4': isToggled,
    'bg-neutral-4': !isToggled,
  });

  return (
    <>
      <label className="vx-form-control vx-switch">
        <input
          className="vx-switch__input"
          type="checkbox"
          checked={Boolean(isToggled)}
          onChange={handleToggle}
          disabled={isDisabled}
          data-testid="toggle-sensitive-data-button"
        />
        <div className={sliderClasses}>
          <span className="vx-switch__text-on"></span>
        </div>
      </label>
    </>
  );
};

export default Toggle;
