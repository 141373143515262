import React from 'react';
import classNames from 'classnames';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { SORT_BY_LOCAL_STORAGE_KEY } from '../constants';
import { useGradebookActions } from '../../../../stores/useGradebookStore';
import { useScreenSizes } from '../../../../stores/useAppStore';

const AssignmentDatesSorter = ({}) => {
  const [sortByNewest, setValue] = useLocalStorage(SORT_BY_LOCAL_STORAGE_KEY, 'true');
  const { toggleSortAssignmentsByNewest } = useGradebookActions();
  const { isMd } = useScreenSizes();

  const enabled = sortByNewest === 'true';
  const tooltipMessage = enabled ? 'Newest to Oldest' : 'Oldest to Newest';

  const handleToggle = () => {
    const newValue = enabled ? 'false' : 'true';
    toggleSortAssignmentsByNewest();
    setValue(newValue);
  };

  const iconClasses = classNames('nc-icon-glyph ui-2_chart-bar-52 rotate-90 max-sm:text-xl', {
    'scale-x-[-1]': !enabled,
  });

  const containerClasses = classNames(
    'flex m-2.5 mr-1 h-8 cursor-pointer items-center text-[#555555] max-sm:text-center vx-tooltipped',
    {
      'vx-tooltipped--se': isMd,
      'vx-tooltipped--s': !isMd,
    }
  );

  return (
    <div
      className={containerClasses}
      aria-label={tooltipMessage}
      data-testid="dates-switcher"
      onClick={handleToggle}
    >
      <div className="mr-1 w-fit max-sm:invisible max-sm:w-0 ">Date Order</div>
      <i
        className={iconClasses}
        data-testid="switcher-icon"
      />
    </div>
  );
};

export default AssignmentDatesSorter;
