import React from 'react';
import ReactSelect from 'react-select';

import { DateField, NumberField, Button } from 'components/shared/form_elements';
import { formatMonetaryValue } from 'components/shared/utils';
import useAsyncRequest from 'components/shared/hooks/useAsyncRequest';
import AuthTokenContext from 'components/shared/AuthTokenContext';
import { SingleDatePicker } from 'react-dates';

// prettier-ignore
function EditPurchaseRequestReceivedItem(props) {
  const {
    config,
    data,
    setShowEditItem,
    reloadItems,
  } = props;

  const authToken = React.useContext(AuthTokenContext);

  /***************************************************************************************************
  ** Form Values
  ***************************************************************************************************/

  const receivedStatusOptions = config.received_status.options.map((receivedStatus) => ({
    value: receivedStatus.id,
    label: receivedStatus.description,
  }));

  const receivedStatusSelectedOption = receivedStatusOptions.find(option => option.value === data.received_status);
  const receivedStatusFieldValue = {
    value: receivedStatusSelectedOption.value,
    label: receivedStatusSelectedOption.label,
  }

  const [initialFormValues, setInitialFormValues] = React.useState({...data, received_status: receivedStatusFieldValue});
  const [formValues, setFormValues] = React.useState(initialFormValues);
  const [formChanges, setFormChanges] = React.useState({});

  const setFormValue = React.useCallback((name, value) => {
    setFormValues((values) => ({ ...values, [name]: value }));
    setFormChanges((changes) => ({ ...changes, [name]: (value !== initialFormValues[name] && parseFloat(value) !== parseFloat(initialFormValues[name])) }));
  }, [initialFormValues]);

  const handleFormFieldChange = React.useCallback((event) => {
    setFormValue(event.target.name, event.target.value);
  }, [setFormValue]);



  /***************************************************************************************************
  ** Update
  ***************************************************************************************************/

  const updatePurchaseRequestItemRequest = useAsyncRequest({
    pathName: 'update_purchase_request_item_path',
    params: {
      purchase_request_id: data.purchase_request_id,
      purchase_request_item_id: data.id,
    },
    method: 'PATCH',
    authorizationToken: authToken,
  });

  const handleClickSaveButton = React.useCallback((event) => {
    event.preventDefault();
    const submitData = {
      purchase_request_item: {
        ...formValues,
        received_status: formValues?.received_status?.value,
      },
    };
    updatePurchaseRequestItemRequest(submitData).then((_data) => {
      setInitialFormValues(formValues);
      setFormChanges({});
      setShowEditItem(false);
      reloadItems();
    }).catch((_error) => {
    });
  }, [
    formValues,
    setShowEditItem,
    reloadItems,
  ]);

  /***************************************************************************************************
  ** Cancel
  ***************************************************************************************************/

  const handleClickCancelButton = React.useCallback((event) => {
    event.preventDefault();
    setShowEditItem(false);
  }, [setShowEditItem]);

  /**************************************************************************************************/

  const handleReceivedStatusChange = (selectedOption) => {
    setFormValue('received_status', selectedOption);
  }

  return (
    <tr>
      <td className="text-align-left">{data.description}</td>
      <td className="text-align-left">{data.model_number}</td>
      <td className="text-align-right">
        {formatMonetaryValue(data.unit_cost, {
          locale: data.currency_locale,
          code: data.currency_code,
        })}
      </td>
      <td className="text-align-center">{data.quantity}</td>
      <td className="text-align-right">
        {formatMonetaryValue(data.total_amount, {
          locale: data.currency_locale,
          code: data.currency_code,
        })}
      </td>
      <td className="text-align-center">
        <ReactSelect
          name="received_status"
          value={formValues.received_status}
          options={receivedStatusOptions}
          onChange={handleReceivedStatusChange}
          menuPosition={'fixed'}
        />
      </td>
      <td className="text-align-center">
        <NumberField
          name="received_quantity"
          value={formValues.received_quantity}
          onChange={handleFormFieldChange}
          hasChange={formChanges.received_quantity}
        />
      </td>
      <td className="text-align-center">
        <DateField
          name="received_date"
          value={formValues.received_date}
          onChange={handleFormFieldChange}
          hasChange={formChanges.received_date}
          isPopup={true}
          displayDateFormat={'MM-DD-YYYY'}
        />
      </td>
      <td className="text-align-left">
        <Button
          className="action-button vx-button vx-button--primary"
          onClick={handleClickSaveButton}
          value="Save"
        />
        <Button
          className="action-button vx-button vx-button--neutral"
          onClick={handleClickCancelButton}
          value="Cancel"
        />
      </td>
    </tr>
  );
}

export default React.memo(EditPurchaseRequestReceivedItem);
