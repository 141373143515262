import React, { useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { HTTP_METHOD, useFetch } from '../../../hooks/useFetch';
import ErrorMessage from './ErrorMessage';
import AttendanceContext from './AttendanceContext';
import { NoteType, StudentType } from './types';
import AttendanceLogisticsRequests from './AttendanceLogisticsRequests';
import { useUserState } from '../../../stores/useAppStore';

const ExpandTextButton = ({ isExpanded, isTruncated, onClick }) => {
  if (!isTruncated) {
    return null;
  }

  return (
    <small
      className="vx-subtitle expand-text-button"
      onClick={onClick}
      data-testid="expandText-button"
    >
      {isExpanded ? 'Show Less' : 'Show More'}
    </small>
  );
};

interface AttendanceNotesProps {
  note: string;
  defaultNotes: string;
  student: StudentType;
  readOnly: boolean;
  isAttendanceLocked: boolean;
  emptyText: string;
  notesType: NoteType;
  showLogisticsRequests: boolean;
  rowIndex: number;
}

const AttendanceNotes = ({
  defaultNotes,
  isAttendanceLocked = false,
  student,
  readOnly,
  emptyText = '',
  notesType,
  showLogisticsRequests = false,
  rowIndex,
}: AttendanceNotesProps) => {
  const {
    attendance: attendanceObject,
    setAttendance,
    selectedPeriodId,
  } = React.useContext(AttendanceContext);
  const attendance = attendanceObject[student.id];
  const [isEditable, setIsEditable] = React.useState(false);
  const [notes, setNotes] = React.useState(defaultNotes || '');
  const [lastSavedNotes, setLastSavedNotes] = React.useState(defaultNotes || '');
  const { loading, error, sendFetchRequest: sendUpdateAttendanceRequest } = useFetch();
  const [isTruncated, setIsTruncated] = React.useState(true);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const showMoreRef = React.useRef(null);
  const { setFocusedStudentRow } = useUserState();

  useEffect(() => {
    if (showMoreRef.current) {
      setIsTruncated(showMoreRef.current.offsetHeight < showMoreRef.current.scrollHeight);
    }
  }, [showMoreRef, lastSavedNotes, isEditable]);

  useEffect(() => {
    const notes = attendance[notesType] && attendance[notesType].trim();
    setNotes(notes || '');
  }, [attendance, selectedPeriodId]);

  const showMore = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  const save = async (e) => {
    // This is necessary so that the click event
    // doesn't propagate to the Student component
    // which has a click handler to open the sidebar
    e.stopPropagation();

    const PORTALS_REQUEST_URL_PARAMS = {
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
    };

    let url;

    if (notesType === 'master_attendance_notes') {
      url = await Routes.update_master_attendance_path({
        ...PORTALS_REQUEST_URL_PARAMS,
      });
    } else {
      url = await Routes.update_class_attendance_path({
        ...PORTALS_REQUEST_URL_PARAMS,
      });
    }

    const body = {
      person_pk: student.id,
      attendance_date: attendance.attendance_date,
      internal_class_id: attendance.class,
      status: null,
      notes: notes.trim(),
      period_id: selectedPeriodId,
    };
    await sendUpdateAttendanceRequest({ url, method: HTTP_METHOD.put, body });
    setIsExpanded(false);
    setIsEditable(false);
    setLastSavedNotes(notes);
    setNotes((prev) => prev.trim());
    setAttendance((prev) => {
      const current = { ...prev };
      current[selectedPeriodId][student.id][notesType] = notes.trim();
      return current;
    });
  };

  const edit = (e) => {
    e.stopPropagation();
    setIsEditable(true);
  };

  const cancel = (e) => {
    e.stopPropagation();
    setIsExpanded(false);
    setIsEditable(false);
    setNotes(lastSavedNotes);
  };

  const onTextAreaChange = (e) => {
    setNotes(e.target.value);
  };

  const handleOpenNotes = (e) => {
    setFocusedStudentRow(rowIndex, false);
    edit(e);
  };

  return (
    <div className="vx-data-field">
      <div className="vx-form-control">
        <div
          ref={showMoreRef}
          className={classNames('attendance-notes truncated-text', {
            expanded: isExpanded,
            hidden: isEditable,
          })}
        >
          {_.isEmpty(notes) && !attendance.slrs.length ? emptyText : notes}
        </div>
        <ExpandTextButton
          onClick={showMore}
          isExpanded={isExpanded}
          isTruncated={isTruncated}
        />
        {showLogisticsRequests && <AttendanceLogisticsRequests attendance={attendance} />}
        {!readOnly && (
          <>
            {isEditable ? (
              <>
                <textarea
                  className="vx-text-area__input attendance-text-area__input"
                  maxLength={500}
                  value={notes}
                  disabled={isAttendanceLocked}
                  onChange={onTextAreaChange}
                  onClick={(e) => e.stopPropagation()}
                  data-testid="attendanceNotes-textArea"
                  autoFocus
                />
                <button
                  className={classNames('vx-button vx-button--small vx-button--success', {
                    'vx-button--loading': loading,
                  })}
                  data-testid="attendanceNotes-button"
                  onKeyDown={(e) => (e.key === 'Enter' ? save(e) : null)}
                  onClick={save}
                >
                  Save
                </button>
                <button
                  className="vx-button vx-button--small vx-button--cancel"
                  data-testid="attendanceNotes-cancel"
                  onClick={cancel}
                  onBlur={cancel}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                className="vx-button vx-button--small"
                onClick={handleOpenNotes}
                onFocus={handleOpenNotes}
                data-testid="editNotes-button"
                disabled={isAttendanceLocked}
              >
                {notes.length ? 'Edit' : 'Add Notes'}
              </button>
            )}
          </>
        )}
      </div>
      {error && <ErrorMessage />}
    </div>
  );
};

export default AttendanceNotes;
