import create from 'zustand';

const useConferencesStore = create((set) => ({
  tooltipInfo: null,
  setTooltipInfo: (newValue) =>
    set((state) => ({
      tooltipInfo: newValue,
    })),
}));

export default useConferencesStore;
