import React from 'react';

import Field from './Field';

const defaultConfig = {
  disabled: false,
  required: false,
  placeholer: undefined,
  min: false,
  max: true,
};

export default function DateField(props) {
  const { name, type, value, error, onChange, isPopup, displayDateFormat } = props;

  const config = { ...defaultConfig, ...props.config };

  const PARSABLE_DATE_FORMATS = ['x', 'YYYY-MM-DD', 'ddd MMM DD, YYYY'];
  const STORAGE_DATE_FORMAT = 'YYYY-MM-DD';
  const DISPLAY_DATE_FORMAT = displayDateFormat ?? 'ddd MMM DD, YYYY';

  const isDisabled = props.disabled || config.disabled;
  const isRequired = config.required;

  const inputRef = React.useRef();
  const handleLabelClick = () => inputRef.current.focus();

  React.useEffect(() => {
    $(inputRef.current).pickadate({
      min: config.min,
      max: config.max,
      clear: !isRequired ? 'Clear' : false,
      onRender: handleRender,
      onSet: handleSet,
      onClose: handleClose,
    });
  }, []);

  React.useLayoutEffect(() => {
    if (isPopup && inputRef.current) {
      inputRef.current.addEventListener('click', () => {
        const margin = 6; // spacing between the picker and the input
        const elementBounds = inputRef.current.getBoundingClientRect();
        const holder = inputRef.current.parentElement.querySelector('.picker .picker__holder');
        const holderBounds = holder.getBoundingClientRect();
        holder.style.left = elementBounds.left + 'px';
        const pageHeight = document.querySelector('.portal-background').clientHeight;
        if (elementBounds.bottom + holderBounds.height + margin > pageHeight) {
          holder.style.top = elementBounds.top - holderBounds.height - margin + 'px';
        }
      });
    }
  }, []);

  const formatDate = (date, dateFormat) => {
    return date ? moment(date, PARSABLE_DATE_FORMATS).format(dateFormat) : '';
  };

  const handleClose = () => {
    const holder = inputRef.current.parentElement.querySelector('.picker .picker__holder');
    holder.removeAttribute('style');
  };

  const handleRender = () => {
    inputRef.current.value =
      value === null ? null : formatDate(moment(value).utc(), DISPLAY_DATE_FORMAT);
  };

  const handleSet = (context) => {
    const newDate = typeof context.select === 'object' ? context.select.pick : context.select;
    setValue(formatDate(newDate, STORAGE_DATE_FORMAT));
    inputRef.current.value = formatDate(newDate, DISPLAY_DATE_FORMAT);
  };

  const setValue = (newValue) => {
    const event = { target: { name: name, value: newValue === '' ? null : newValue } };
    onChange(event);
  };

  return (
    <Field
      className="vx-date-field"
      onLabelClick={handleLabelClick}
      {...props}
    >
      <input
        className={`vx-date-field__input ${isPopup ? 'date-field-popup' : ''}`}
        type="text"
        name={name}
        data-value={value}
        onChange={onChange}
        disabled={isDisabled}
        required={config.required}
        placeholder={config.placeholder}
        ref={inputRef}
      />
      <i className="nc-icon-outline ui-1_calendar-grid-61 vx-date-field__icon" />
    </Field>
  );
}
