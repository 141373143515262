import React, { useEffect } from 'react';
import NavTab from '../../common/NavTab';
import { Student, StudentSidebarTab } from '../../types';
import Feedback from './Feedback';
import PrivateNote from './PrivateNote';
import Notifications from './Notifications';
import StudentRow from '../StudentRow';
import { useScreenSizes } from '../../../../../stores/useAppStore';

type SidebarProps = {
  student: Student;
  selectedTab: StudentSidebarTab;
  changeTab: (a: StudentSidebarTab) => void;
  closeSidebar: () => void;
  sidebarIsOpen: boolean;
};
const Sidebar = ({
  student,
  selectedTab,
  changeTab,
  closeSidebar,
  sidebarIsOpen,
}: SidebarProps) => {
  const { isMd: isMobile } = useScreenSizes();

  useEffect(() => {
    if (!isMobile && selectedTab === 'grades') {
      changeTab('feedback');
    }
  }, [isMobile]);

  if (!student) {
    return null;
  }

  return (
    <div className="flex flex-col max-md:h-[calc(100%-22px)] md:h-[calc(100%-44px)]">
      <div className=" vx-sidebar__title bg-neutral-3 text-neutral-6 items-center text-lg">
        {`${student.first_name} ${student.last_name}`}
        <i
          className="nc-icon-glyph items-center ui-1_bold-remove float-right cursor-pointer"
          data-testid="close-sidebar-button"
          onClick={closeSidebar}
        />
      </div>
      <div className="flex justify-evenly items-center w-full border-b border-gray-200 min-h-[45px] h-[45px]">
        <div className="md:hidden h-full mb-[-3px]">
          <NavTab
            isSelected={selectedTab === 'grades'}
            onClick={() => changeTab('grades')}
          >
            Grades
          </NavTab>
        </div>
        <NavTab
          isSelected={selectedTab === 'feedback'}
          onClick={() => changeTab('feedback')}
        >
          Feedback
        </NavTab>
        <NavTab
          isSelected={selectedTab === 'note'}
          onClick={() => changeTab('note')}
        >
          Private Note
        </NavTab>
        <NavTab
          isSelected={selectedTab === 'notifications'}
          onClick={() => changeTab('notifications')}
        >
          Notifications
        </NavTab>
      </div>
      {selectedTab === 'grades' && (
        <div className="md:hidden">
          <StudentRow
            student={student}
            isInSidebar={true}
          />
        </div>
      )}
      {selectedTab === 'feedback' && (
        <Feedback
          student={student}
          sidebarIsOpen={sidebarIsOpen}
        />
      )}
      {selectedTab === 'note' && <PrivateNote studentId={student.person_pk} />}
      {selectedTab === 'notifications' && (
        <Notifications
          studentId={student.person_pk}
          sidebarIsOpen={sidebarIsOpen}
        />
      )}
    </div>
  );
};

export default React.memo(Sidebar); // Memo is needed to avoid rendering on every scroll
