import React from 'react';
import PersonDisplay from '../common/PersonDisplay';
import TotalGradeCells from './TotalGradeCells';
import { Student } from '../types';
import Cell from './Cell';

type TotalGradeStudentRowProps = {
  student: Student;
};
const TotalGradeStudentRow = ({ student }: TotalGradeStudentRowProps) => {
  return (
    <tr>
      <Cell classOverrides="bg-white">
        <PersonDisplay
          avatarUrl={student.photo_url}
          title={student.first_name}
          subtitle={student.last_name}
          dateWithdrawn={student.date_withdrawn}
          lateDateEnrolled={student.late_date_enrolled}
        />
      </Cell>
      <TotalGradeCells student={student} />
    </tr>
  );
};

export default TotalGradeStudentRow;
