import React from 'react';
import PropTypes from 'prop-types';
import AttendanceContext from './AttendanceContext';
import { useFetch } from '../../../hooks/useFetch';
import ErrorMessage from './ErrorMessage';

const AttendanceTime = ({ attendance, field, isDisabled, className }) => {
  const {
    attendance: attendanceObject,
    setAttendance,
    internalClassId,
    selectedPeriodId,
  } = React.useContext(AttendanceContext);
  const [value, setValue] = React.useState(_.isEmpty(attendance[field]) ? '' : attendance[field]);
  const { data, isLoading, error, sendFetchRequest } = useFetch();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = async () => {
    const PORTALS_REQUEST_URL_PARAMS = {
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
    };

    const url = await Routes.update_master_attendance_path({
      ...PORTALS_REQUEST_URL_PARAMS,
    });

    const body = {
      internal_class_id: internalClassId,
      person_pk: attendance.student_id,
      attendance_date: attendance.attendance_date,
    };

    // timestamp format
    body[field] = moment(value, 'HH:mm')
      .year(1900)
      .month(0)
      .date(1)
      .toISOString(true)
      .split('.')[0];

    await sendFetchRequest({ url, method: 'PUT', body });
  };

  const onClick = (e) => {
    // This is called on a wrapper div so that the click event
    // from the Time doesn't propagate to the Student component
    // which has a click handler to open the sidebar
    e.stopPropagation();
  };

  React.useEffect(() => {
    setValue(attendanceObject[attendance.student_id][field] || '');
  }, [attendanceObject && attendanceObject[attendance.student_id][field]]);

  React.useEffect(() => {
    if (data) {
      setAttendance((prev) => {
        const current = { ...prev };
        current[selectedPeriodId][attendance.student_id] = data.attendance;
        return current;
      });
    }
  }, [data]);

  return (
    <div
      onClick={onClick}
      className={className}
    >
      <label className="vx-form-label">{_.startCase(field)}</label>
      <div className="vx-form-control vx-time-field">
        <input
          data-testid="attendanceTime-input"
          disabled={isLoading || isDisabled}
          className="vx-time-field__input"
          type="time"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
      {error && <ErrorMessage />}
    </div>
  );
};

AttendanceTime.propTypes = {
  attendance: PropTypes.shape({
    student_id: PropTypes.number.isRequired,
    attendance_date: PropTypes.string.isRequired,
  }).isRequired,
  field: PropTypes.string.isRequired,
};

export default AttendanceTime;
