import React from 'react';

export default function PersonContactSection(props) {
  const { email, emailConfig, phoneMobile, phoneMobileConfig, phoneHome, phoneHomeConfig } = props;

  const BLANK_PLACEHOLDER = '--';

  const EmailLink = email && (
    <a
      href={`mailto:${email}`}
      title={email}
      target="_blank"
      rel="noopener noreferrer"
    >
      {email}
    </a>
  );

  const PhoneMobileLink = phoneMobile && (
    <a
      href={`tel:${phoneMobile}`}
      title={phoneMobile}
    >
      {phoneMobile}
    </a>
  );

  const PhoneHomeLink = phoneHome && (
    <a
      href={`tel:${phoneHome}`}
      title={phoneHome}
    >
      {phoneHome}
    </a>
  );

  return (
    <div className="person-contact-section">
      {emailConfig.displayed && (
        <div className="ae-grid person-contact-section__field">
          <div className="ae-grid__item ae-grid__item--no-padding item-xs-3">
            <div className="vx-form-label">{emailConfig.label}</div>
          </div>
          <div className="ae-grid__item ae-grid__item--no-padding item-xs-9 overflow-ellipsis">
            <div className="person-contact-section__field-value">
              {EmailLink || BLANK_PLACEHOLDER}
            </div>
          </div>
        </div>
      )}
      {phoneMobileConfig.displayed && (
        <div className="ae-grid person-contact-section__field">
          <div className="ae-grid__item ae-grid__item--no-padding item-xs-3">
            <div className="vx-form-label">{phoneMobileConfig.label}</div>
          </div>
          <div className="ae-grid__item ae-grid__item--no-padding item-xs-9 overflow-ellipsis">
            <div className="person-contact-section__field-value">
              {PhoneMobileLink || BLANK_PLACEHOLDER}
            </div>
          </div>
        </div>
      )}
      {phoneHomeConfig.displayed && (
        <div className="ae-grid person-contact-section__field">
          <div className="ae-grid__item ae-grid__item--no-padding item-xs-3">
            <div className="vx-form-label">{phoneHomeConfig.label}</div>
          </div>
          <div className="ae-grid__item ae-grid__item--no-padding item-xs-9 overflow-ellipsis">
            <div className="person-contact-section__field-value">
              {PhoneHomeLink || BLANK_PLACEHOLDER}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
