import React, { memo } from 'react';
import classnames from 'classnames';
import { ElementProps } from '../types';

type HelpTextType = {
  element: Partial<ElementProps>;
  hasError?: boolean | string;
};
const HelpText = ({ element, hasError = false }: HelpTextType) => {
  const helpText = (element.help_text || '').trim();
  const hasHelpText = element.help_text && helpText !== '';

  // Note: "<p></p>" also means there's no help text
  // That's because the server sends back as HTML formatted which an
  // empty string will just be an empty <p> tag.
  const emptyHelpText = helpText === '<p></p>';
  if (!hasHelpText || emptyHelpText) {
    return null;
  }

  return (
    <div
      data-testid="help-text"
      className={classnames('vx-form-label-instructions', {
        'vx-form-label-instructions--error': hasError,
      })}
      // Note to future developer:
      //
      // The HTML should already be sanitized (i.e. tags such as "<script>"
      // are removed) on the server side before it reaches the client.
      // That's why we can use the "dangerouslySetInnerHTML" attribute here.
      //
      // If you have any doubts, refer to the controller that renders web forms
      // and confirm that it uses Rails' `simple_format` method for sanitization.
      dangerouslySetInnerHTML={{
        __html: element.help_text,
      }}
    />
  );
};

export default memo(HelpText);
