import React from 'react';
import { useCourseRequestState } from '../../../../stores/useCourseRequestsStore';
import { CREDITS_HELP_TEXT } from '../constants';

const TotalCredits = () => {
  const { current_course_requests: requests } = useCourseRequestState();
  const totalCredits = requests.reduce((sum, credit) => sum + credit.credits, 0);

  if (requests.length === 0) {
    return null;
  }

  return (
    <div
      className="text-lg rounded-sm font-light h-10 leading-10 cursor-help vx-tooltipped vx-tooltipped--w"
      aria-label={CREDITS_HELP_TEXT}
    >
      {`Total Credits: ${totalCredits.toFixed(2)}`}
    </div>
  );
};

export default TotalCredits;
