import React from 'react';

const Accordion = ({ items, renderHeader, renderBody }) => {
  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div
          className="accordion__item"
          key={index}
        >
          <div className="accordion__header">{renderHeader(item)}</div>
          <div className="accordion__body">{renderBody(item)}</div>
        </div>
      ))}
    </div>
  );
};

export const AccordionItem = ({ title, isExpanded: defaultExpanded = false, children }) => {
  const [isExpanded, toggleExpanded] = React.useState(defaultExpanded);
  return (
    <div className="accordion__item">
      <div
        className="accordion__header"
        onClick={() => toggleExpanded((prev) => !prev)}
        data-testid="accordion-expand-button"
      >
        <span className="accordion__header-title">{title}</span>
        <i
          className={`nc-icon-glyph ${
            isExpanded ? 'arrows-1_minimal-up' : 'arrows-1_minimal-down'
          }`}
        ></i>
      </div>
      {isExpanded ? children : null}
    </div>
  );
};

export default Accordion;
