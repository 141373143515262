import React from 'react';
import classnames from 'classnames';
import { useWebForm } from '../WebFormContext';
import { isFieldRequired } from '../utils/element-helpers';
import { ElementProps } from '../types';

type VxLabelProps = {
  element: ElementProps;
  htmlFor: string;
  hasError?: boolean | string;
};

const VxLabel = ({ element, htmlFor, hasError = false }: VxLabelProps) => {
  const { form, submission } = useWebForm();
  return (
    <label
      data-testid="vx-label"
      className={classnames('vx-form-label', {
        'vx-form-label--required': isFieldRequired(
          element,
          form.mode,
          submission.operation_type,
          submission
        ),
        'vx-form-label--error': hasError,
      })}
      htmlFor={htmlFor}
    >
      {element.description}
    </label>
  );
};

export default VxLabel;
