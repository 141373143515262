import create from 'zustand';

const usePhotoGalleryStore = create((set) => ({
  photoGalleries: [],
  allPhotos: [],
  setPhotoGalleries: (galleries) => set({ photoGalleries: galleries }),
  setAllPhotos: (photos) => set({ allPhotos: photos }),

  actions: {
    addGallery: (newGallery) =>
      set((state) => ({
        photoGalleries: [...state.photoGalleries, newGallery],
      })),
    updateGallery: (newGallery) =>
      set((state) => ({
        photoGalleries: state.photoGalleries.map((gallery) =>
          gallery.gallery_pk === newGallery.id ? { ...gallery, name: newGallery.name } : gallery
        ),
      })),
    removeGallery: (galleryId) =>
      set((state) => ({
        photoGalleries: state.photoGalleries.filter((gallery) => gallery.gallery_pk !== galleryId),
      })),
    addPhotos: (newPhotos) =>
      set((state) => {
        const newPhotosFilePKs = new Set(newPhotos.map((photo) => photo.file_pk));
        const updatedPhotos = state.allPhotos.filter(
          (photo) => !newPhotosFilePKs.has(photo.file_pk)
        );
        return {
          allPhotos: [...updatedPhotos, ...newPhotos],
        };
      }),
    updatePhoto: (newPhoto) =>
      set((state) => ({
        allPhotos: state.allPhotos.map((photo) =>
          photo.file_pk === newPhoto.id ? { ...photo, description: newPhoto.description } : photo
        ),
      })),
    removePhoto: (photoId) =>
      set((state) => ({
        allPhotos: state.allPhotos.filter((photo) => photo.file_pk !== photoId),
      })),
  },
}));

export const usePhotosByGallery = (galleryId) =>
  usePhotoGalleryStore((state) =>
    state.allPhotos
      .filter((photo) => photo.gallery_fk === galleryId)
      .sort((a, b) => new Date(b.input_date) - new Date(a.input_date))
  );

export default usePhotoGalleryStore;
