import React from 'react';
import Field from '../Field';

export default function SelectField(props) {
  const { name, type, value, error, onChange, config } = props;

  const selectRef = React.useRef();

  const handleLabelClick = () => {
    selectRef.current.focus();
  };

  const fieldValueOptions = config.options.map((option) => (
    <option
      key={option.value}
      value={option.value}
      disabled={option.disabled}
    >
      {option.description}
    </option>
  ));

  return (
    <Field
      className="vx-select"
      onLabelClick={handleLabelClick}
      {...props}
    >
      <select
        className="vx-select__input"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={config.placeholder}
        required={config.required}
        disabled={config.disabled}
        ref={selectRef}
      >
        {fieldValueOptions}
      </select>
    </Field>
  );
}
