import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import AttendanceTime from './AttendanceTime';
import { HTTP_METHOD, useFetch } from '../../../hooks/useFetch';
import AttendanceContext from './AttendanceContext';
import ErrorMessage from './ErrorMessage';
import { useScreenSizes, useUserState } from '../../../stores/useAppStore';
import { ATTENDANCE_STATUS, ATTENDANCE_STATUS_IMPERFECT_CLASS_ATTENDANCE_ID } from './utils';
import DropdownOptionWithShortcut from '../../shared/dropdown/DropdownOptionWithShortcut';

const ATTENDANCE_CATEGORY_PRESENT = 0;
const ATTENDANCE_CATEGORY_ABSENCE = 1;
const ATTENDANCE_CATEGORY_TARDY = 2;
const ATTENDANCE_CATEGORY_EARLY_DISMISSAL = 3;

const ATTENDANCE_CATEGORY_COLOR_TABLE = {
  [ATTENDANCE_CATEGORY_PRESENT]: '#43b664',
  [ATTENDANCE_CATEGORY_ABSENCE]: '#d87474',
  [ATTENDANCE_CATEGORY_TARDY]: '#ff9933',
  [ATTENDANCE_CATEGORY_EARLY_DISMISSAL]: '#38afff',
};

const DEFAULT_DOT_COLOR = ATTENDANCE_CATEGORY_COLOR_TABLE[ATTENDANCE_CATEGORY_PRESENT];

const dot = (category) => {
  const color = ATTENDANCE_CATEGORY_COLOR_TABLE[category] || DEFAULT_DOT_COLOR;
  return {
    alignItems: 'center',
    display: 'flex',
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      minWidth: 10,
    },
  };
};

const AttendanceStatusSelect = ({
  value: defaultValue,
  isMaster,
  student,
  isAttendanceLocked,
  options,
  rowIndex,
  isMenuOpen,
  setIsMenuOpen,
  enableKeyboardShortcuts,
}) => {
  const {
    attendance: attendanceObj,
    setAttendance,
    selectedPeriodId,
    students,
  } = React.useContext(AttendanceContext);
  const attendance = attendanceObj[student.id];
  const [value, setValue] = React.useState(defaultValue);
  const {
    focusedStudentRow,
    setFocusedStudentRow,
    shortcutTrigger,
    setShortcutTrigger,
    moveFocusedStudentRow,
    isFocusingInput,
  } = useUserState();
  const selectRef = React.useRef();
  const { loading, error, data, sendFetchRequest: sendUpdateAttendanceRequest } = useFetch();
  const attendanceCategory = value.category;
  const [hasInteractedWithDropdown, setHasInteractedWithDropdown] = React.useState(false);
  const hasKeyboardShortcut = useMemo(() => {
    return (
      enableKeyboardShortcuts &&
      Object.values(options).some((status) => Boolean(status.keyboardShortcut))
    );
  }, [enableKeyboardShortcuts, options]);
  const { isMd: isMobile } = useScreenSizes();

  const attendanceStatusOptions = Object.values(options)
    // show only teacher-allowable statuses
    .filter((status) => status.allowTeacherEntry)
    // hide "Imperfect Class Attendance" (only for Class Attendance)
    .filter(
      (status) => isMaster || status.value !== ATTENDANCE_STATUS_IMPERFECT_CLASS_ATTENDANCE_ID
    )
    // sort by sortKey
    .sort((statusA, statusB) => statusA.sortKey - statusB.sortKey)
    .map((status) => {
      if (
        !enableKeyboardShortcuts ||
        !Boolean(status.keyboardShortcut) ||
        isMobile ||
        value.value === status.value
      ) {
        return status;
      }
      return {
        ...status,
        label: (
          <DropdownOptionWithShortcut
            description={status.label}
            shortcut={status.keyboardShortcut}
          />
        ),
      };
    });

  React.useEffect(() => {
    if (data) {
      const { attendance } = data;
      const updatedAttendance = Array.isArray(attendance) ? attendance[0] : attendance;
      const studentId = updatedAttendance.student_id;
      setAttendance((prev) => ({
        ...prev,
        [selectedPeriodId]: {
          ...prev[selectedPeriodId],
          [studentId]: {
            ...prev[selectedPeriodId][studentId],
            status: updatedAttendance.status,
            category: updatedAttendance.category,
          },
        },
      }));
    }
  }, [data]);

  React.useEffect(() => {
    if (focusedStudentRow === rowIndex && shortcutTrigger?.type === ATTENDANCE_STATUS) {
      const selection = options[shortcutTrigger.id];
      if (selection && !isAttendanceLocked) {
        onChange(selection);
        moveFocusedStudentRow(students.length, 1);
      }
      setShortcutTrigger(null);
    }
  }, [shortcutTrigger]);

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      setHasInteractedWithDropdown(true);
    }
    if (!hasInteractedWithDropdown && event.key === 'Enter') {
      // No navigation has happened, so we don't want to save a new value
      event.preventDefault();
      setIsMenuOpen(false);
    }
  };

  React.useEffect(() => {
    if (focusedStudentRow === rowIndex && selectRef.current && isFocusingInput) {
      selectRef.current.focus();
    } else {
      selectRef.current.blur();
    }
  }, [focusedStudentRow]);

  React.useEffect(() => {
    const attendanceStatus = attendanceObj[student.id].status;
    setValue(options[attendanceStatus]);
  }, [attendanceObj[student.id]]);

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setHasInteractedWithDropdown(false);
    setIsMenuOpen(false);
  };

  const onChange = async (selection) => {
    setIsMenuOpen(false);
    if (selection.value === value.value) {
      return;
    }
    const previousValue = value;
    setValue(selection);

    const PORTALS_REQUEST_URL_PARAMS = {
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
    };

    let url;

    if (isMaster) {
      url = Routes.update_master_attendance_path({
        ...PORTALS_REQUEST_URL_PARAMS,
      });
    } else {
      url = Routes.update_class_attendance_path({
        ...PORTALS_REQUEST_URL_PARAMS,
      });
    }

    const body = {
      person_pk: student.id,
      attendance_date: attendance.attendance_date,
      internal_class_id: attendance.class,
      status: selection.value,
      period_id: selectedPeriodId,
      notes: null,
    };
    try {
      await sendUpdateAttendanceRequest({ url, method: HTTP_METHOD.put, body });
    } catch (error) {
      setValue(previousValue);
    }
  };

  const styles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.category) }),
    option: (styles, { data }) => ({ ...styles, ...dot(data.category) }),
    input: (styles) => ({
      ...styles,
      paddingTop: '0',
      marginTop: '0',
      'input:focus': {
        boxShadow: 'none',
        paddingTop: '0',
      },
    }),
    menu: (styles) => ({
      ...styles,
      width: hasKeyboardShortcut && !isMobile ? '200px' : styles.width,
    }),
  };

  const onClick = (e) => {
    // This is called on a wrapper div so that the click event
    // from the Select doesn't propagate to the Student component
    // which has a click handler to open the sidebar
    if (focusedStudentRow !== rowIndex) {
      setFocusedStudentRow(rowIndex);
    }
    e.stopPropagation();
  };
  return (
    <div
      onClick={onClick}
      data-testid="attendance-status-dropdown"
    >
      <ReactSelect
        defaultValue={defaultValue}
        value={value}
        options={attendanceStatusOptions}
        styles={styles}
        onChange={onChange}
        isLoading={loading}
        ref={selectRef}
        openMenuOnFocus={isFocusingInput}
        menuIsOpen={isMenuOpen}
        isDisabled={isAttendanceLocked}
        maxMenuHeight={242}
        minMenuHeight={242}
        menuPlacement="auto"
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        onKeyDown={handleKeyDown}
      />
      {isMaster &&
        [ATTENDANCE_CATEGORY_TARDY, ATTENDANCE_CATEGORY_EARLY_DISMISSAL].includes(
          attendanceCategory
        ) && (
          <AttendanceTime
            attendance={attendance}
            isDisabled={isAttendanceLocked}
            field={
              attendanceCategory === ATTENDANCE_CATEGORY_TARDY
                ? 'late_arrival_time'
                : 'early_dismissal_time'
            }
          />
        )}
      {isAttendanceLocked && (
        <small className="vx-subtitle attendance-locked-message">
          <i className="nc-icon-outline ui-1_lock"></i>
          Attendance is locked.
        </small>
      )}
      {error && <ErrorMessage />}
    </div>
  );
};

AttendanceStatusSelect.propTypes = {
  options: PropTypes.objectOf(
    PropTypes.shape({
      category: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      allowTeacherEntry: PropTypes.bool.isRequired,
      sortKey: PropTypes.number.isRequired,
    })
  ),
};

export default AttendanceStatusSelect;
