import React, { useEffect, useState } from 'react';
import { useCourseRequestState, useStudentState } from '../../../../stores/useCourseRequestsStore';
import RequestCardSelect from '../V2/RequestCardSelect';
import { getDefaultGradingPeriod } from '../helpers';
import CurrentScheduleTable from './CurrentScheduleTable';
import { FAKE_LOADING_DURATION } from '../constants';
import LoadingSpinner from '../../../shared/LoadingSpinner';

type CurrentScheduleProps = {
  isLoadingList: boolean;
};
const CurrentSchedule = ({ isLoadingList }: CurrentScheduleProps) => {
  const { grading_periods: gradingPeriods } = useCourseRequestState();
  const { activeGradingPeriod } = useStudentState();
  const [selectedGradingPeriod, setSelectedGradingPeriod] = useState<number>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {
    student,
    studentData: { schedule_url: scheduleUrl },
  } = useStudentState();

  useEffect(() => {
    if (gradingPeriods.length > 0) {
      setSelectedGradingPeriod(getDefaultGradingPeriod(gradingPeriods, activeGradingPeriod));
    }
  }, [gradingPeriods]);

  useEffect(() => {
    // The data may look identical after changing grading periods.
    // Show the loading spinner for a brief moment so the user knows the data has changed.
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, FAKE_LOADING_DURATION);
  }, [selectedGradingPeriod, student]);

  if (isLoadingList) {
    return <LoadingSpinner />;
  }

  return (
    <div className="m-2.5 relative overflow-visible">
      <div className="w-full my-2 h-10 items-center justify-between flex relative">
        <RequestCardSelect
          field="grading_period"
          options={gradingPeriods}
          selectedValue={selectedGradingPeriod}
          onChange={(_, selectedValue) => setSelectedGradingPeriod(selectedValue)}
        />
        <a
          href={scheduleUrl}
          className="leading-8 text-md"
          target="_blank"
          rel="noreferrer"
        >
          <button className=" text-white rounded-md h-8 px-2.5 flex bg-blue-3">
            <i className="nc-icon-glyph ui-1_calendar-grid-58 text-lg mr-2.5 leading-8"></i>
            <div className="leading-8 text-md text-white">View Schedule</div>
          </button>
        </a>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <CurrentScheduleTable selectedGradingPeriod={selectedGradingPeriod} />
      )}
    </div>
  );
};

export default CurrentSchedule;
