import React from 'react';
import classNames from 'classnames';
import { AccessStatuses, Assignment } from '../types';
import {
  GradingMethodDescription,
  GRADING_METHOD,
  MAX_SCORE,
  EXTRA_CREDIT,
  INCLUDE_IN_CALC_GRADE,
  DISPLAY_ON_PORTALS,
  LETTER,
  POINTS,
} from '../constants';
import { useConfigurations } from '../../../../stores/useGradebookStore';

type AssignmentPreviewProps = {
  assignment: Assignment;
};
const AssignmentPreview = ({ assignment }: AssignmentPreviewProps) => {
  const { assignment_max_score_teacher_access_status: maxScoreAccess } = useConfigurations();
  const popupClasses = classNames(
    'invisible hover:hidden group-hover:visible group-hover:delay-700 absolute left-[-4px] top-[132px] flex flex-col text-left shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)] w-40 h-fit z-[60] border border-neutral-5 text-black rounded-[5px] bg-white m-1 p-2'
  );
  const showMaxScore =
    assignment.grading_method === GradingMethodDescription.POINTS &&
    maxScoreAccess !== AccessStatuses.DISABLED;

  return (
    <div className={popupClasses}>
      <div className="flex flex-col justify-center">
        <div className="text-xs text-neutral-4 uppercase">{GRADING_METHOD}</div>
        <div className="text-xs">
          {assignment.grading_method === GradingMethodDescription.LETTER ? LETTER : POINTS}
        </div>
      </div>
      {showMaxScore && (
        <div className="flex flex-col justify-center">
          <div className="text-xs text-neutral-4 uppercase">{MAX_SCORE}</div>
          <div className="text-xs">{assignment.maximum_score}</div>
        </div>
      )}
      <div className="flex flex-col justify-center mt-0.5">
        <div className="text-xs text-neutral-4 uppercase">{INCLUDE_IN_CALC_GRADE}</div>
        <div className="text-xs">{assignment.include_in_calc_grade ? 'Yes' : 'No'}</div>
      </div>
      <div className="flex flex-col justify-center mt-0.5">
        <div className="text-xs text-neutral-4 uppercase">{EXTRA_CREDIT}</div>
        <div className="text-xs">{assignment.extra_credit ? 'Yes' : 'No'}</div>
      </div>
      <div className="flex flex-col justify-center mt-0.5">
        <div className="text-xs text-neutral-4 uppercase">{DISPLAY_ON_PORTALS}</div>
        <div className="text-xs">{assignment.display_status ? 'Yes' : 'No'}</div>
      </div>
    </div>
  );
};

export default AssignmentPreview;
