import React, { useEffect, useState } from 'react';
import { useBatchUpdateGradeRecord } from '../mutations';
import Toggle from '../common/Toggle';
import { BULK_EDIT_DEFAULTS, STATUS_THAT_REMOVES_GRADES } from '../constants';
import StatusDropdown from './StatusDropdown';
import { useGridData } from '../queries';
import classNames from 'classnames';
import Modal from '../../../shared/Modal';
import { EditOptions } from '../types';
import * as Yup from 'yup';
import BulkEditOption from './BulkEditOption';
import LetterGradeInput from './LetterGradeInput';
import { useScreenSizes, useUserState } from '../../../../stores/useAppStore';
import NumericGradeInput from './NumericGradeInput';
import { getErrorMessage } from '../helpers';
import { useAssignment } from '../queries';

const schema = Yup.object().shape({
  grade: Yup.number(),
});

const MODAL_CLOSE_TIME = 2000;

const BulkEdit = ({}) => {
  const { data } = useGridData();
  const useBatchUpdate = useBatchUpdateGradeRecord();
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingStatusWithoutGrade, setIsUpdatingStatusWithoutGrade] = useState(false);
  const [editOptions, setEditOptions] = useState<EditOptions>(BULK_EDIT_DEFAULTS);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalError, setModalError] = useState(null);
  const isSaveDisabled = Object.values(editOptions).every((option) => !option.checked);
  const assignment = useAssignment();
  const hasLetterGrading = assignment?.grading_method === 2;
  const { isMd: isMobile } = useScreenSizes();
  const { setFocusedStudentRow } = useUserState();
  const openAndMobile = popupIsOpen && isMobile;
  const isModalSubmitDisabled = isUpdating || modalError;

  useEffect(() => {
    if (!isModalOpen) {
      setTimeout(() => {
        setModalError(null);
      }, MODAL_CLOSE_TIME);
    }
  }, [isModalOpen]);

  const handleBulkEdit = async () => {
    setIsUpdating(true);
    // remove focused student row to allow all students to be updated
    setFocusedStudentRow(-1);
    const batchUpdates = Object.entries(editOptions)
      .filter(([_, option]) => option.checked)
      .map(([field, option]) => {
        return {
          field,
          value: option.value,
        };
      });

    try {
      await useBatchUpdate.mutateAsync(batchUpdates);
      setIsModalOpen(false);
    } catch (error) {
      setModalError(error);
    } finally {
      setTimeout(() => {
        setIsUpdating(false);
      }, MODAL_CLOSE_TIME);
      setPopupIsOpen(false);
      setIsUpdatingStatusWithoutGrade(false);
      setEditOptions(BULK_EDIT_DEFAULTS);
    }
  };

  const handleChange = (value: string | boolean, field: string) => {
    setEditOptions({ ...editOptions, [field]: { ...editOptions[field], value } });
  };

  const handleToggle = (checkedValue: boolean, field: string): any => {
    setEditOptions({ ...editOptions, [field]: { ...editOptions[field], checked: checkedValue } });
  };

  const handleButtonClick = () => {
    if (editOptions.raw_score.checked) {
      const valid = schema.isValidSync({ grade: editOptions.raw_score.value });
      if (!valid) {
        setErrorMessage(hasLetterGrading ? 'Must select a letter grade' : 'Must be a number');
        return;
      }
    }
    setErrorMessage(null);

    if (
      editOptions.completion_status.checked &&
      STATUS_THAT_REMOVES_GRADES.has(editOptions.completion_status.value)
    ) {
      setIsUpdatingStatusWithoutGrade(true);
    }
    setIsModalOpen(true);
  };

  const saveButtonClasses = classNames(
    'bg-green-4 text-white rounded-md my-2 w-full h-10 text-center',
    {
      'opacity-50 cursor-default': isSaveDisabled,
    }
  );

  const editButtonClasses = classNames(
    'text-neutral-1 bg-white border border-neutral-1 rounded-md h-8 font-bold flex justify-center items-center',
    {
      'w-28': openAndMobile,
      'w-20': !openAndMobile,
    }
  );

  return (
    <>
      <div className="relative group h-10 w-32 flex justify-start md:justify-end max-md:ml-3">
        <button
          className={editButtonClasses}
          onClick={() => setPopupIsOpen(!popupIsOpen)}
        >
          <span className="leading-8">Bulk Edit</span>
          {openAndMobile && <i className="ml-2 nc-icon-glyph ui-1_bold-remove text-red-3" />}
        </button>
        {(popupIsOpen || !isMobile) && (
          <div className="md:hidden group-hover:block absolute shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)] max-md:left-[-4px] right-[-4px] top-9 w-[240px] z-10 border border-neutral-5 rounded-[5px] bg-white m-1 py-2 px-4">
            <BulkEditOption
              label="Grade"
              field="raw_score"
              editOptions={editOptions}
              handleToggle={handleToggle}
              Component={hasLetterGrading ? LetterGradeInput : NumericGradeInput}
              onChange={(v, f) => handleChange(hasLetterGrading ? v.stored_value : v, f)}
              errorMessage={errorMessage}
            />

            <BulkEditOption
              label="Completion Status"
              field="completion_status"
              editOptions={editOptions}
              statuses={data.assignment_statuses}
              handleToggle={handleToggle}
              Component={StatusDropdown}
              onChange={handleChange}
            />

            <BulkEditOption
              label="Dropbox Status"
              field="dropbox_status"
              editOptions={editOptions}
              statuses={data.dropbox_statuses}
              handleToggle={handleToggle}
              Component={StatusDropdown}
              onChange={handleChange}
            />

            <BulkEditOption
              label="Published?"
              field="publish_status"
              editOptions={editOptions}
              handleToggle={handleToggle}
              Component={Toggle}
              onChange={handleChange}
            />

            <button
              className={saveButtonClasses}
              data-testid="bulk-edit-update"
              disabled={isSaveDisabled}
              onClick={handleButtonClick}
            >
              Update
            </button>
          </div>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onSave={handleBulkEdit}
        setIsOpen={setIsModalOpen}
        label={isUpdating ? 'Updating...' : 'Confirm Update'}
        overrideClasses={`bg-green-3 ${!isModalSubmitDisabled && 'hover:bg-green-4'}`}
        isSubmitDisabled={isModalSubmitDisabled}
        canClose={!isUpdating}
      >
        {!modalError && (
          <>
            <div className="text-lg">
              Are you sure you want to make these updates for all students?
            </div>
            {isUpdatingStatusWithoutGrade && (
              <div className="text-lg text-red-3">
                {'Note: Updating to this completion status will delete all assignment grades.'}
              </div>
            )}
          </>
        )}
        {modalError && (
          <div className="text-red-3 text-lg">
            {getErrorMessage(modalError) || 'Something went wrong with at least one of the updates'}
          </div>
        )}
      </Modal>
    </>
  );
};
export default BulkEdit;
