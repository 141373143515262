import React from 'react';
import { useAddAssignment } from '../mutations';
import NewAssignmentForm from './NewAssignmentForm';
import { DEFAULT_FIELD_VALUES, GradingMethodDescription } from '../constants';
import { useClassState, useConfigurations } from '../../../../stores/useGradebookStore';
import LmsMobileBanner from '../../navigation/LmsMobileBanner';
import { goBackToGrid } from '../Router';
import { useGradeConversionScales, useGradingMethods } from '../queries';

const filterGradingMethods = (gradingMethods, hasClassScale) => {
  return (
    gradingMethods
      // filter on active grading methods if class scale is present
      .filter((r) => !hasClassScale || r.active)
      // filter out letter grades if class scale is not present
      .filter((r) => hasClassScale || r.id !== GradingMethodDescription.LETTER)
  );
};

const NewAssignmentPage = () => {
  const addAssignment = useAddAssignment();
  const { today } = useClassState();
  const { data: gradingMethodsData } = useGradingMethods();
  const { data: conversionScalesData } = useGradeConversionScales();
  const {
    default_assignment_max_score_value: defaultMaxScore,
    default_assignment_weight_value: defaultWeight,
  } = useConfigurations();
  const gradingMethodsFiltered = filterGradingMethods(
    gradingMethodsData || [],
    Boolean(conversionScalesData?.class_scale?.id)
  );

  const initialValues = { ...DEFAULT_FIELD_VALUES };

  initialValues.assignment_date = today;
  initialValues.due_date = today;
  initialValues.maximum_score = defaultMaxScore;
  initialValues.weight = defaultWeight;

  initialValues.grading_method = gradingMethodsFiltered?.length
    ? gradingMethodsFiltered[0].id
    : GradingMethodDescription.POINTS;

  initialValues.grade_conversion_scale = conversionScalesData?.class_scale?.id;

  const handleSave = async (fieldValues) => {
    const data = { form_values: { ...fieldValues } };
    await addAssignment.mutateAsync(data);
  };

  return (
    <>
      <LmsMobileBanner
        title="New Assignment"
        goBack={goBackToGrid}
      />
      <NewAssignmentForm
        saveForm={handleSave}
        initialValues={initialValues}
        gradingMethods={gradingMethodsFiltered}
        conversionScales={conversionScalesData?.grade_conversion_scales || []}
      />
    </>
  );
};

export default NewAssignmentPage;
