import React from 'react';
import classNames from 'classnames';

import { Button } from 'components/shared/form_elements';
import AuthTokenContext from 'components/shared/AuthTokenContext';

import IntegrationCard from './integration_card/IntegrationCard';

export default function IntegrationCardList(props) {
  const { config, data, pageState, canLoadMoreRecords, handleRecordsLoadMore, setRecords } = props;

  const records = data.records;
  const hasRecords = records.length > 0;

  const isPageLoading = pageState === 'LOADING';
  const isPageLoadingMore = pageState === 'LOADING_MORE';
  const isPageSearching = pageState === 'SEARCHING';

  const showLoader = isPageLoading || isPageSearching;
  const showLoadMoreButton = canLoadMoreRecords;
  const loadMoreButtonText = isPageLoadingMore ? 'Loading...' : 'Load More';

  const Loader = (
    <div className="vx-form-loader">
      <div className="vx-form-loader__spinner vx-loader-spinner"></div>
      <div className="vx-form-loader__message">{'Loading...'}</div>
    </div>
  );

  const IntegrationCards =
    hasRecords &&
    records.map((record) => (
      <IntegrationCard
        key={record.id}
        data={record}
        config={config}
      />
    ));

  if (showLoader) {
    return Loader;
  } else if (hasRecords) {
    return (
      <>
        <div className="integration-card-list">
          {IntegrationCards}
          {showLoadMoreButton && (
            <Button
              className="vx-button vx-button--primary load-more-button"
              disabled={isPageLoadingMore}
              onClick={handleRecordsLoadMore}
              value={loadMoreButtonText}
            />
          )}
        </div>
      </>
    );
  } else {
    return (
      <div className="vx-empty-state-read-only">
        <h2>No schools found.</h2>
        <h6>No schools found matching those search filters.</h6>
      </div>
    );
  }
}
