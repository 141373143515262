import React, { useEffect, useState } from 'react';
import { UNPUBLISHED, PUBLISHED, DEFAULT_ERROR_MESSAGE, ERROR_TIMEOUT } from '../constants';
import { useUpdateGradeRecord } from '../mutations';
import Toggle from '../common/Toggle';
import { useUserState } from '../../../../stores/useAppStore';
import { useStudentGrade } from '../queries';
import { Grade } from '../types';
import { getErrorMessage } from '../helpers';

type PublishGradesToggleProps = {
  studentId: number;
  rowIndex?: number;
};
const PublishGradesToggle = ({ studentId, rowIndex = -1 }: PublishGradesToggleProps) => {
  const gradeRecord = useStudentGrade(studentId);
  const updateGrade = useUpdateGradeRecord(studentId, 'publish_status');
  const getIsPublished = (record: Grade) => record?.publish_status === PUBLISHED;
  const [isPublished, setIsPublished] = useState(getIsPublished(gradeRecord));
  const [errorMessage, setErrorMessage] = useState(null);
  const { setFocusedStudentRow } = useUserState();

  useEffect(() => {
    const newPublished = getIsPublished(gradeRecord);
    if (isPublished !== newPublished) {
      setIsPublished(newPublished);
    }
  }, [gradeRecord]);

  const handlePublish = async (newValue: boolean) => {
    setFocusedStudentRow(rowIndex, false);
    setIsPublished(newValue);
    try {
      const response = await updateGrade.mutateAsync(newValue ? PUBLISHED : UNPUBLISHED);
      const valueInResponse = getIsPublished(response.data.updated_object);
      if (valueInResponse !== newValue) {
        throw new Error();
      }
      setErrorMessage(null);
    } catch (error) {
      setIsPublished(!newValue);
      setErrorMessage(getErrorMessage(error));
      setTimeout(() => {
        setErrorMessage(null);
      }, ERROR_TIMEOUT);
    }
  };

  return (
    <div className="w-fit">
      <Toggle
        value={isPublished}
        onChange={handlePublish}
      />
      {errorMessage && <div className="text-red-3 pt">{DEFAULT_ERROR_MESSAGE}</div>}
    </div>
  );
};

export default PublishGradesToggle;
