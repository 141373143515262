import React from 'react';
import classnames from 'classnames';
import VxLabel from './VxLabel';
import HelpText from './HelpText';
import PlainTextField from './PlainTextField';
import { FastField, useField, useFormikContext } from 'formik';
import VxErrorMessage from './VxErrorMessage';
import { ElementMetadata } from '../types';
import { getFieldConfiguration } from '../utils/element-helpers';

const CheckboxField = ({ element, metadata }: ElementMetadata) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(metadata.dataKeypath);
  const hasError = meta.touched && meta.error;
  const isDataConsentPolicy = element.element_system_name === 'data_consent_policy_checkbox';
  if (isDataConsentPolicy) {
    element.element_value_list_items = [
      {
        description: getFieldConfiguration(element, 'checkbox_text'),
        value: getFieldConfiguration(element, 'data_consent_policy_id'),
      },
    ];
  }

  const renderPlainText = () => {
    const jsonData = field.value;
    const datum = typeof jsonData === 'string' && jsonData !== '' ? JSON.parse(jsonData) : jsonData;
    return (
      <PlainTextField>
        {element.element_value_list_items
          .filter((listItem) => datum.includes(listItem.value))
          .map((listItem) => listItem.description)
          .join(', ')}
      </PlainTextField>
    );
  };

  return (
    <>
      <VxLabel
        element={element}
        htmlFor={metadata.dataKeypath}
        hasError={hasError}
      />
      <HelpText
        element={element}
        hasError={hasError}
      />
      {isDataConsentPolicy ? (
        <div
          className={classnames('vx-form-label-instructions', {
            'vx-form-label-instructions--error': hasError,
          })}
        >
          {'I have read and accept the '}
          <a
            href={Routes.data_consent_policy_path(
              Portals.config.client,
              getFieldConfiguration(element, 'data_consent_policy_id')
            )}
          >
            data consent policy
          </a>
        </div>
      ) : null}
      <div className="vx-form-control">
        {metadata.isPlainText ? (
          renderPlainText()
        ) : (
          <>
            <div className="vx-checkbox-group ae-grid">
              {element.element_value_list_items.map(({ description, value }) => (
                <div
                  key={value}
                  className="ae-grid__item item-md-4 item-sm-6"
                >
                  <label className="vx-checkbox-group__item">
                    <FastField
                      type="checkbox"
                      className="vx-checkbox-input"
                      name={metadata.dataKeypath}
                      value={value}
                      disabled={isSubmitting}
                    />
                    <span
                      className="vx-checkbox-group__label"
                      data-option-value={value}
                    >
                      {description}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <VxErrorMessage
        element={element}
        metadata={metadata}
      />
    </>
  );
};

export default CheckboxField;
