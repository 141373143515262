import React from 'react';
import classNames from 'classnames';

import { DataField, Button } from 'components/shared/form_elements';
import Collapsible from 'components/shared/Collapsible';
import AuthTokenContext from 'components/shared/AuthTokenContext';

export default function IntegrationCard(props) {
  const { data, config } = props;

  const schoolAddress = _.compact([
    data.address_1,
    data.address_2,
    data.address_3,
    data.city,
    data.state,
    data.zip,
    data.country,
  ]).join(', ');

  const [credentials, setCredentials] = React.useState({
    client_id: '••••••••••••••••••••••••••••••••',
    client_secret: '••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••',
    redirect_uri: '--',
    scopes: '--',
  });

  /***************************************************************************************************
   ** REQUEST: GET school-integrations/:partner_id/credentials
   ***************************************************************************************************/

  const authToken = React.useContext(AuthTokenContext);

  const REQUEST_HEADERS = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store',
    'X-CSRF-Token': authToken,
  };

  const PORTALS_REQUEST_URL_PARAMS = {
    client: Portals.config.client,
    portal_name: Portals.config.portal_name,
    partner_id: data.partner_id,
  };

  const sendGetIntegrationCredentialsRequest = async (params) => {
    const url = Routes.partner_school_integration_credentials_path({
      ...PORTALS_REQUEST_URL_PARAMS,
      ...params,
    });

    const response = await fetch(url, {
      method: 'GET',
      headers: REQUEST_HEADERS,
    });

    const responseBody = await response.json();

    if (response.ok) {
      return responseBody;
    } else {
      throw new Error(responseBody.status);
    }
  };

  /***************************************************************************************************
   ** Integration Credentials
   ***************************************************************************************************/

  const REQUEST_DELAY = 500;

  const getIntegrationCredentials = async () => {
    const params = {
      database_name: data.database_name,
      partner_uuid: data.partner_uuid,
    };

    const integrationCredentials = await sendGetIntegrationCredentialsRequest(params)
      .then((response) => {
        return response.credentials;
      })
      .catch((error) => {
        console.log('Error: ', error);
        return [];
      });

    setTimeout(() => {
      setCredentials(integrationCredentials);
    }, REQUEST_DELAY);

    return integrationCredentials;
  };

  /***************************************************************************************************
   ** Render
   ***************************************************************************************************/

  return (
    <div className="integration-card">
      <div className="ae-grid">
        <div className="ae-grid__item item-lg-1">
          <img
            className="integration-card__logo"
            src={data.logo_url}
          />
        </div>
        <div className="ae-grid__item item-lg-7">
          <div className="integration-card__description">{data.description}</div>

          <div className="integration-card__address">{schoolAddress}</div>
        </div>

        <div className="ae-grid__item item-lg-2">
          <DataField
            value={'Active'}
            config={{ dataType: 'text', label: 'Status' }}
          />
        </div>
        <div className="ae-grid__item item-lg-2">
          <DataField
            value={data.input_date}
            config={{ dataType: 'date', label: 'Added On' }}
          />
        </div>
      </div>

      <Collapsible
        isCollapsedByDefault={true}
        toggleText={['View Credentials', 'Hide Credentials']}
        toggleIcon={[]}
        toggleLocation={'top'}
        toggleClassName={'integration-card__credentials_toggle'}
        onReveal={getIntegrationCredentials}
      >
        <hr className="integration-card__credentials_divider" />
        <div className="ae-grid">
          <div className="ae-grid__item item-lg-12">
            <div className="integration-card__credentials_title">Credentials</div>
          </div>
          <div className="ae-grid__item item-lg-12">
            <DataField
              value={credentials.client_id}
              config={{ label: 'Client ID', copyable: true }}
            />
          </div>
          <div className="ae-grid__item item-lg-12">
            <DataField
              value={credentials.client_secret}
              config={{
                label: 'Client Secret',
                dataType: 'masked',
                copyable: true,
              }}
            />
          </div>
          <div className="ae-grid__item item-lg-12">
            <DataField
              value={credentials.route}
              config={{ label: 'School Route', copyable: true }}
            />
          </div>
          <div className="ae-grid__item item-lg-12">
            <DataField
              value={credentials.redirect_uri}
              config={{ label: 'Redirect URI', copyable: true }}
            />
          </div>
          <div className="ae-grid__item item-lg-12">
            <DataField
              value={credentials.scopes}
              config={{ label: 'Scopes', dataType: 'tags', copyable: true }}
            />
          </div>
        </div>
      </Collapsible>
    </div>
  );
}
