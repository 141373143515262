import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AssignmentTypesDropdown from './AssignmentTypesDropdown';
import GradingPeriodsDropdown from './GradingPeriodsDropdown';
import AssignmentDatesSorter from './AssignmentDatesSorter';

const GradebookHeader = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  }, [isButtonDisabled]);

  const linkClasses = classNames({
    'pointer-events-none': isButtonDisabled,
  });

  return (
    <div className="header z-30 md:sticky left-0 h-12 max-md:h-12">
      <div className=" flex justify-between items-center w-full md:border-b border-gray-200 h-12">
        <div className="ml-5 max-md:hidden flex">
          <Link
            to={`/assignment/new`}
            className={linkClasses}
          >
            <button className="bg-green-4 text-white rounded-md h-8 px-2.5 flex">
              <i className="nc-icon-glyph ui-1_circle-add text-lg mr-2.5 leading-8"></i>
              <div className="leading-8 text-md">Add Assignment</div>
            </button>
          </Link>
          <Link
            to={`/weighting`}
            className={linkClasses}
          >
            <button className="bg-blue-4 text-white rounded-md ml-4 h-8 px-2.5 flex">
              <i className="nc-icon-glyph business_scale text-lg mr-2.5 leading-8"></i>
              <div className="leading-8 text-md">Weighting</div>
            </button>
          </Link>
        </div>
        <div className="mr-4 flex">
          <AssignmentDatesSorter />
          <div className="m-2.5 mr-1">
            <GradingPeriodsDropdown />
          </div>
          <AssignmentTypesDropdown />
        </div>
      </div>
    </div>
  );
};

export default GradebookHeader;
