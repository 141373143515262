import React from 'react';
import classNames from 'classnames';

const defaultProps = {
  className: 'vx-button vx-button--neutral',
  value: undefined,
  displayed: true,
  disabled: false,
  isLoading: false,
  loadingText: undefined,
  onClick: (event) => event.preventDefault(),
};

// prettier-ignore
function Button(props) {
  const {
    className,
    value,
    displayed,
    disabled,
    isLoading,
    loadingText,
    onClick,
  } = { ...defaultProps, ...props };

  const buttonClassNames = classNames(className, {
    'vx-button--loading': isLoading,
  });

  if (!displayed) return null;

  return (
    <button
      className={buttonClassNames}
      disabled={disabled}
      onClick={onClick}
    >
      {(isLoading && loadingText) || value || props.children}
    </button>
  );
}

export default React.memo(Button);
