import React from 'react';
import TagsList from '../../../shared/TagsList';

export default function PersonIdentitySection(props) {
  const { personPhotoUrl, personFullName, personTags } = props;

  const displayPersonTags = !!personTags;
  const displayPersonPhoto = !!personPhotoUrl;

  const personPhotoAltText = `${personFullName} Photo`;

  const PersonTags = displayPersonTags && <TagsList value={personTags} />;

  return (
    <div className="person-identity-section">
      {displayPersonPhoto && (
        <img
          className="person-identity-section__person-photo"
          src={personPhotoUrl}
          alt={personPhotoAltText}
        />
      )}
      <div className="person-identity-section__person-fields">
        <div className="person-identity-section__person-name">{personFullName}</div>
        {displayPersonTags && (
          <div className="person-identity-section__person-tags">{PersonTags}</div>
        )}
      </div>
    </div>
  );
}
