import React, { useState } from 'react';
import UploadModal from './UploadModal';
import { Link } from 'react-router-dom';

function GalleryViewHeader({ internalClassId, gallery, uploaderOptions }) {
  const [imageUpload, setImageUpload] = useState({ id: null, show: false });

  const getHomePageLink = () => {
    const url = Routes.class_admin_photos_index_path({
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
      internal_class_id: internalClassId,
    });
    return url;
  };

  return (
    <div>
      <div className="pl-5 pt-2 pb-2">
        <button
          className="bg-green-4 text-white rounded-md h-8 py-1 px-2.5 flex inline-flex items-center"
          onClick={() => setImageUpload({ id: gallery.gallery_pk, show: true })}
        >
          <i className="nc-icon-glyph ui-1_circle-bold-add text-sm leading-6 bg-green mr-2" />
          <span> Add Photos</span>
        </button>
        <UploadModal
          imageUpload={imageUpload}
          setImageUpload={setImageUpload}
          galleryId={gallery.gallery_pk}
          internalClassId={internalClassId}
          uploaderOptions={uploaderOptions}
        />
      </div>
      <hr className="border-gray-300 border-t" />
      <div className="py-2 px-2">
        <h3>
          <Link to={getHomePageLink()}> All Galleries </Link>
          {'>>'} {gallery.name}
        </h3>
      </div>
      <br />
    </div>
  );
}

export default GalleryViewHeader;
