import React from 'react';
import { AttendanceType, StudentType } from './types';

export type AttendanceContextType = {
  attendance: Record<string, AttendanceType>;
  setAttendance: React.Dispatch<React.SetStateAction<AttendanceType>>;
  internalClassId: string;
  selectedPeriodId: string;
  students: StudentType[];
};

const AttendanceContext = React.createContext<AttendanceContextType>(null);

export default AttendanceContext;
