import React from 'react';
import Submit from './Submit.js';
import SubmissionList from './SubmissionList.js';
import ForkliftUploader from 'forklift-ui';

const FileUploader = ({ uploaderOptions, onComplete, onFileRemoved }) => {
  return (
    <div className="ae-grid__item item-sm-12">
      <ForkliftUploader
        {...{
          ...uploaderOptions,
          onComplete,
          onFileRemoved,
        }}
      />
    </div>
  );
};

const AssignmentDetailDescription = ({ assignment_details }) => {
  if (assignment_details) {
    return (
      <div className="submission-section">
        <h2>Details</h2>
        <p className="vx-data-field_content">{assignment_details}</p>
      </div>
    );
  }
  return null;
};

const ErrorBanner = ({ errorMessage }) => {
  if (errorMessage) {
    return (
      <div className="vx-message-banner vx-message-banner--danger ae-grid__item item-sm-12 submit-error">
        {errorMessage}
      </div>
    );
  }
  return null;
};

class AssignmentDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submissions: [],
      submissionsLoading: true,
      files: [],
      newSubmissionText: '',
      displayFileUpload: false,
      newFilePks: [],
      submissionButtonDisabled: false,
      errorMessage: null,
    };
  }

  onComplete = ({ successful, failed }) => {
    const successfulFilePks = successful.map((file) => file.fileInfo.file_pk);
    this.setState((prevState) => {
      return { newFilePks: [...prevState.newFilePks, ...successfulFilePks] };
    });
  };

  onFileRemoved = (removedFile, reason) => {
    const { fileInfo } = removedFile;

    if (reason === 'removed-by-user' && fileInfo) {
      this.setState((prevState) => {
        const updatedFilesPk = prevState.newFilePks.filter((filePk) => {
          return filePk !== fileInfo.file_pk;
        });
        return { newFilePks: updatedFilesPk };
      });
    }
  };

  componentDidMount() {
    this.fetchSubmissionData();
  }

  // when user toggles between assignments, clear out input
  componentDidUpdate(prevProps) {
    if (
      this.props.assignment.class_assignment_person_pk !=
      prevProps.assignment.class_assignment_person_pk
    ) {
      this.fetchSubmissionData();

      // TODO: windowHeightExpanded and displayFileUpload should be refactored to one state variable
      // displayFileUpload should be promoted to parent class state, and both can depend on it.
      if (this.props.windowHeightExpanded) {
        this.props.toggleAttachFilePicker();
      }

      this.setState({
        newSubmissionText: '',
        displayFileUpload: false,
        newFilePks: [],
        errorMessage: null,
      });
    }
  }

  fetchSubmissionData = () => {
    this.setState({ submissionsLoading: true });

    $.ajax({
      method: 'GET',
      url: Routes.assignment_submission_list_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
      }),
      data: {
        class_assignment_person_fk: this.props.assignment.class_assignment_person_pk,
      },
    }).then((data) => {
      this.setState({
        submissions: data.submissions || [],
        files: data.files || [],
        submissionsLoading: false,
      });
    });
  };

  submissionsExist = () => {
    return this.state.submissions.length > 0;
  };

  handleNewSubmissionChange = (event) => {
    this.setState({ newSubmissionText: event.target.value });
  };

  handleNewSubmissionSubmit = () => {
    if (this.state.newSubmissionText.trim() || this.state.newFilePks.length) {
      this.setState({ submissionButtonDisabled: true }); // prevent duplicate submissions if clicking fast

      $.ajax({
        method: 'POST',
        url: Routes.assignment_submission_submit_path({
          client: Portals.config.client,
          portal_name: Portals.config.portal_name,
        }),
        data: {
          class_assignment_person_fk: this.props.assignment.class_assignment_person_pk,
          submission_person_fk: this.props.assignment.submission_person_fk,
          notes: this.state.newSubmissionText.trim(),
          file_pks: this.state.newFilePks,
        },
      })
        .then((data) => {
          this.setState({
            submissions: data.submissions,
            files: data.files,
            newSubmissionText: '',
            newFilePks: [],
            displayFileUpload: false,
            submissionButtonDisabled: false,
          });

          if (this.props.windowHeightExpanded) {
            this.props.toggleAttachFilePicker();
          }
        })
        .fail((data) => {
          if (data.status === 403 && data.responseJSON.error === 'Submissions closed') {
            this.setState({
              errorMessage: 'Submissions are closed for this assignment.',
              newSubmissionText: '',
              newFilePks: [],
              displayFileUpload: false,
              submissionButtonDisabled: false,
            });
          }
        });
    }
  };

  handleAttachFilesButtonClick = () => {
    this.props.toggleAttachFilePicker();
    this.setState((prevState) => ({
      displayFileUpload: !prevState.displayFileUpload,
    }));
  };

  updateErrorMessage = (message) => {
    this.setState({ errorMessage: message });
  };

  render() {
    return (
      <div className="ae-grid__item--collapse item-sm-8">
        <div
          className={`submission-details ${
            this.props.windowHeightExpanded ? 'file-attach-displayed' : ''
          }`}
        >
          <div className="submission-data">
            <AssignmentDetailDescription
              assignment_details={this.props.assignment.assignment_details}
            />
            <div className="submission-section">
              <h2>Submissions</h2>
              {this.state.submissionsLoading ? (
                <div className="vx-loader-spinner"></div>
              ) : (
                <SubmissionList
                  submissions={this.state.submissions}
                  files={this.state.files}
                />
              )}
            </div>
          </div>
          <div className="submission-workflow">
            <h5>Add Submission</h5>
            <div className="ae-grid">
              <div className="vx-form-control vx-text-area ae-grid__item item-sm-12">
                <textarea
                  className="vx-text-area__input"
                  name="new-submission-text"
                  value={this.state.newSubmissionText}
                  onChange={this.handleNewSubmissionChange}
                />
              </div>
              {this.state.displayFileUpload && (
                <FileUploader
                  uploaderOptions={this.props.uploaderOptions}
                  onComplete={this.onComplete}
                  onFileRemoved={this.onFileRemoved}
                />
              )}
              <div className="vx-form-control vx-text-area ae-grid__item item-sm-12">
                <button
                  className="vx-button attach-button"
                  onClick={this.handleAttachFilesButtonClick}
                  disabled={this.state.newFilePks.length}
                >
                  <i className="nc-icon-glyph ui-1_attach-86"></i>
                  Attach Files
                </button>
                <button
                  className="vx-button vx-button--primary"
                  onClick={this.handleNewSubmissionSubmit}
                  disabled={this.state.submissionButtonDisabled}
                >
                  Submit
                </button>
              </div>
            </div>
            <Submit
              classAssignmentPersonPk={this.props.assignment.class_assignment_person_pk}
              submissionsExist={this.submissionsExist()}
              assignmentSubmissionStatusToggle={this.props.toggleStatus}
              updateAssignmentStatus={this.props.updateAssignmentStatus}
              updateAssignmentStatusToggle={this.props.updateAssignmentStatusToggle}
              updateErrorMessage={this.updateErrorMessage}
            />
            <ErrorBanner errorMessage={this.state.errorMessage} />
          </div>
        </div>
      </div>
    );
  }
}

export default AssignmentDetails;
