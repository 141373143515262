import React from 'react';
import { AssignmentFormFieldProps } from '../types';
import classNames from 'classnames';

const InputField = ({
  onChange,
  value,
  initialValue,
  isSuccessNotifying,
  readOnly,
}: AssignmentFormFieldProps & { initialValue: string | number; readOnly?: boolean }) => {
  const handleChange = (newValue) => {
    if (newValue === '') {
      // set the formik value, but don't try to parseInt the value
      onChange(newValue, false);
      return;
    }

    const newValueParsed: string | number =
      typeof value === 'number' ? parseInt(newValue) : newValue;
    onChange(newValueParsed, false);
  };

  const handleBlur = (newValue) => {
    const newValueParsed: string | number =
      typeof value === 'number' ? parseInt(newValue) : newValue.trim();

    if (newValueParsed !== initialValue) {
      onChange(newValueParsed);
    }
  };

  const handleKeyPress = (event) => {
    if (['Enter', 'NumpadEnter'].includes(event.key)) {
      event.target.blur();
    }
  };

  const borderRing = isSuccessNotifying ? 'ring-offset ring-2 ring-green-4' : 'shadow-none';
  const classes = classNames('h-[35px] rounded w-full', {
    [`border-neutral-4 ${borderRing}`]: !readOnly,
    'bg-[#f2f2f2] border-0': readOnly,
  });

  return (
    <input
      className={classes}
      onBlur={(e) => handleBlur(e.target.value)}
      onChange={(e) => handleChange(e.target.value)}
      type={typeof value === 'string' ? 'text' : 'number'}
      value={value}
      onKeyDown={handleKeyPress}
      data-testid="assignment-input-field"
      disabled={readOnly}
    />
  );
};
export default InputField;
