require('./calendar-event');

window.Sys.Calendar = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
  },

  oninit: function () {
    trace.log();

    this.set('loading', false);

    this.event_objects = [];
    this.rendered_events = [];
    this.collectionBeginDate = null;
    this.collectionEndDate = null;

    this.calendarOptions = {
      now: moment(Portals.config.today),

      contentHeight: '100%',

      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'basicDay,basicWeek,month',
      },

      buttonText: {
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List',
      },

      views: {
        month: {
          titleFormat: 'MMMM YYYY',
          columnFormat: 'dddd',
        },
        week: {
          titleFormat: 'MMMM D YYYY',
          columnFormat: 'dddd - MMM DD',
        },
        day: {
          titleFormat: 'dddd, MMMM DD, YYYY',
          columnFormat: 'dddd - MMM DD',
        },
      },

      events: _.bind(function (start, end, timezone, callback) {
        trace.log();
        // eslint-disable-next-line no-invalid-this
        callback(this.rendered_events);
      }, this),

      viewRender: _.bind(this.onViewRender, this),
      eventOrder: ['record_type', 'title'],
      eventRender: this.onEventRender,
    };
  },

  onrender: function () {
    trace.log();
    this.calendar = $(this.find('.fullcalendar-element'))
      .fullCalendar(this.calendarOptions)
      .data('fullCalendar');
  },

  onteardown: function () {
    trace.log();

    if (this.calendar && this.calendar.destroy) {
      this.calendar.destroy();
    }
  },

  onViewRender: function (view) {
    trace.log();

    if (this.get('loading')) {
      return false;
    }

    let shouldLoadData = false;

    if (view.start < this.collectionBeginDate || _.isNull(this.collectionBeginDate)) {
      this.collectionBeginDate = view.start;
      shouldLoadData = true;
    }

    if (view.end > this.collectionEndDate || _.isNull(this.collectionEndDate)) {
      this.collectionEndDate = view.end;
      shouldLoadData = true;
    }

    if (shouldLoadData) {
      this.getEventsFromServer(view.start, view.end);
    }
  },

  getEventsFromServer: function (beginDate, endDate) {
    trace.log();

    this.set('loading', true);

    const calEl = $(this.find('.fullcalendar-element'));
    const beginDateStr = moment(beginDate).format('MM/DD/YYYY');
    const endDateStr = moment(endDate).format('MM/DD/YYYY');

    calEl.fullCalendar('option', 'contentHeight', '100%');

    $.ajax({
      type: 'GET',
      url: Routes.calendar_events_path({
        client: Portals.config.client,
        portal_name: this.get('portal'),
        calendar_name: this.get('calendar_name'),
      }),
      context: this,
      data: {
        begin_date: beginDateStr,
        end_date: endDateStr,
      },
      success: this.loadEvents,
      complete: function () {
        this.set('loading', false);
        calEl.fullCalendar('option', 'contentHeight', 'auto');
      },
    });
  },

  loadEvents: function (events) {
    trace.log();

    events = _.reject(events, _.bind(this._eventExists, this));
    this.event_objects = _.concat(this.event_objects, events);

    const renderedEvents = _.map(events, Sys.CalendarEventRenderer.render);
    this.rendered_events = _.concat(this.rendered_events, renderedEvents);

    this.calendar.refetchEvents();
  },

  _eventExists: function (newEvent) {
    return _.find(this.event_objects, function (event) {
      return (
        newEvent.record_type === event.record_type &&
        newEvent.record_identifier === event.record_identifier &&
        newEvent.start_date === event.start_date &&
        newEvent.description === event.description
      );
    });
  },

  onEventRender: function (event, element) {
    $(element).attr('title', event.tooltip);
    if (event.url) {
      let dataDetailLink;
      switch (event.record_type) {
        case 3:
          dataDetailLink = 'assignment';
          break;
        case 7:
          dataDetailLink = 'exam_session';
          break;
        default:
          dataDetailLink = 'event';
          break;
      }

      $(element).attr('data-detail-link', dataDetailLink);
      $(element).attr('data-record-pk', event.event_pk);
    }
  },
});
