import React, { useEffect, useState } from 'react';
import RequestCardBody from './RequestCardBody';
import { useCourseRequestState, useStudentState } from '../../../../stores/useCourseRequestsStore';
import DeleteCourseRequestModal from './DeleteCourseRequestModal';
import CreateCourseRequestModal from './CreateCourseRequest/CreateCourseRequestModal';
import TotalCredits from './TotalCredits';
import { AddCourseRequestModalData } from '../types';
import { CLOSE_MODAL_ANIMATION_DURATION } from '../constants';
import { groupBy } from 'lodash';
import LoadingSpinner from '../../../shared/LoadingSpinner';

const CourseRequestsList = ({ refetchList, isLoadingList }) => {
  const { current_course_requests: currentRequests } = useCourseRequestState();
  const [modalRemovingCard, setModalRemovingCard] = useState<number>(null);
  const [addCourseModalData, setAddCourseModalData] = useState<AddCourseRequestModalData>({
    isOpen: false,
  });
  const [scrollToRequest, setScrollToRequest] = useState<number>(null);
  const { readOnly } = useStudentState();
  const currentRequestsByGroup = groupBy(currentRequests, 'request_group');

  // isLoadingList starts as false for a brief moment, but we want to show the spinner initially until the list is fetched
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (scrollToRequest && currentRequests.length > 0 && !isLoadingList) {
      const element = document.getElementById(`request-${scrollToRequest}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setScrollToRequest(null);
      }
    }
  }, [currentRequests, scrollToRequest, isLoadingList]);

  useEffect(() => {
    if (scrollToRequest) {
      refetchList();
    }
  }, [scrollToRequest]);

  useEffect(() => {
    setHasFetched(true);
  }, [currentRequests]);

  useEffect(() => {
    if (!addCourseModalData.isOpen) {
      setTimeout(() => {
        setAddCourseModalData({ isOpen: false }); // resets request group
      }, CLOSE_MODAL_ANIMATION_DURATION);
    }
  }, [addCourseModalData.isOpen]);

  if (!hasFetched || isLoadingList) {
    return <LoadingSpinner />;
  }

  if (currentRequests.length === 0 && readOnly) {
    // the 'add request' button is hidden when read-only, so we need to show a message
    return (
      <div className="text-center w-full h-[60vh]">
        <div className="text-lg text-neutral-3">
          Course request portal status is set to Read-Only, and there are no requests yet for this
          student.
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="m-2.5 relative">
        <div className="w-full my-2 h-10 items-center justify-between flex relative">
          {!readOnly ? (
            <button
              className="text-white rounded-md h-8 px-2.5 flex bg-blue-3"
              onClick={() => setAddCourseModalData({ isOpen: true })}
            >
              <i className="nc-icon-glyph ui-1_circle-add text-lg mr-2.5 leading-8"></i>
              <div className="leading-8 text-md">Add Course Request</div>
            </button>
          ) : (
            <div></div>
          )}
          <TotalCredits />
        </div>
        {Object.entries(currentRequestsByGroup).map(([requestGroup, coursesInGroup], idx) => {
          return (
            <div key={requestGroup}>
              <div className="text-xl border-b border-[#bbb] font-light pl-0.5">
                {`Course Request ${idx + 1}`}
              </div>
              {coursesInGroup.map((request) => (
                <div
                  key={request.id}
                  id={`request-${request.id}`}
                >
                  <RequestCardBody
                    requestProps={request}
                    handleRemoveCard={setModalRemovingCard}
                  />
                </div>
              ))}
              {!readOnly && (
                <div
                  onClick={() =>
                    setAddCourseModalData({ isOpen: true, requestGroup: parseInt(requestGroup) })
                  }
                  className="border border-dashed cursor-pointer border-[#ddd] m-5 h-[50px] bg-clip-content text-center text-neutral-3 hover:bg-neutral-6 leading-7 font-light hover:text-neutral-2 p-2.5 max-sm:my-3 max-sm:mx-0"
                >
                  Add Alternate Course
                </div>
              )}
            </div>
          );
        })}
      </div>

      <CreateCourseRequestModal
        data={addCourseModalData}
        setIsOpen={(isOpen) =>
          setAddCourseModalData({ isOpen, requestGroup: addCourseModalData.requestGroup })
        }
        setScrollToRequest={setScrollToRequest}
      />
      <DeleteCourseRequestModal
        modalRemovingCard={modalRemovingCard}
        setModalRemovingCard={setModalRemovingCard}
        refetchList={refetchList}
      />
    </>
  );
};

export default CourseRequestsList;
