/* eslint-disable camelcase */
import { SubmissionInfo } from '../types';
import { SubmissionData } from '../WebFormContext';

const convertToObject = ({
  data,
  filter,
}: {
  data: defaultDataProps;
  filter: any;
}): defaultObjectDataProps => {
  return (data || []).filter(filter).reduce(
    (memo, { field_name, value }) => ({
      ...memo,
      [field_name]: value,
    }),
    {}
  );
};

const defaultData = [];
type defaultDataProps = SubmissionData[] & typeof defaultData;

const defaultObjectData = {};
type defaultObjectDataProps = SubmissionInfo & typeof defaultObjectData;

export const getSubmissionInfo = (data: defaultDataProps) => {
  const filter = ({ data_type }) => data_type === 'submission_metadata';
  return convertToObject({ data, filter });
};

export const getSubmissionValues = (data: defaultDataProps) => {
  const filter = ({ data_type }) => data_type !== 'submission_metadata';
  return Object.entries(convertToObject({ data, filter })).reduce((memo, [key, value]) => {
    const convertedKey = key.replace(/\.(\d*)\./g, '#$1__');

    if (value.startsWith('[')) {
      memo[convertedKey] = JSON.parse(value);
    } else if (convertedKey.includes('toggle')) {
      memo[convertedKey] = value === '1';
    } else {
      memo[convertedKey] = value;
    }

    return memo;
  }, {});
};
