import React from 'react';
import LmsMobileBanner from '../../navigation/LmsMobileBanner';
import { useConfigurations } from '../../../../stores/useGradebookStore';
import GradebookGrid from './GradebookGrid';
import GradebookHeader from './GradebookHeader';
import { LocationState } from '../types';
import { useLocation } from 'react-router-dom';
import EmptyPage from '../../../shared/EmptyPage';
import { useTranslation } from 'react-i18next';

const GradebookPage = () => {
  const { t } = useTranslation('words');
  const { enabled, gradebook_label: pageLabel } = useConfigurations();
  const location = useLocation<LocationState>();
  const notAuthorized = location.state?.notAuthorized;

  if (!enabled) {
    return (
      <EmptyPage
        message={`${t('Gradebook')} is disabled for this class`}
        subMessage="Contact your school administrator"
        icon="ui-1_lock"
      />
    );
  }

  if (notAuthorized) {
    window.history.replaceState({}, document.title);
    return (
      <EmptyPage
        message="You do not have permission to view"
        subMessage="Contact your school administrator"
        icon="ui-1_lock"
      />
    );
  }

  return (
    <div className="md:overflow-auto h-full max-md:w-full">
      <LmsMobileBanner title={pageLabel} />
      <GradebookHeader />
      <GradebookGrid />
    </div>
  );
};

export default GradebookPage;
