import React, { useState } from 'react';
import Modal from '../../../shared/Modal';
import { getFileTypeIcon } from '../helpers';
import { File } from '../types';

type FileListProps = {
  files: File[];
  classOverrides?: string;
  onDelete?: (filePk: string) => any;
  bgColor?: string;
  textColor?: string;
};
const FileList = ({
  files,
  classOverrides,
  onDelete,
  bgColor = 'bg-blue-6',
  textColor = 'text-blue-3',
}: FileListProps) => {
  const [confirmDeleteForFile, setConfirmDeleteForFile] = useState(null);

  const handleDelete = () => {
    onDelete(confirmDeleteForFile);
    setConfirmDeleteForFile(null);
  };

  return (
    <>
      <div className={`flex flex-wrap gap-2 ${classOverrides}`}>
        {files.map((file) => {
          return (
            <div
              className={`flex min-h-8 items-center p-2 rounded-md ${bgColor} group`}
              key={file.file_pk}
            >
              <a
                className={`cursor-pointer text-sm ${textColor} flex`}
                key={file.file_pk}
                href={file.url}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className={`nc-icon-glyph ${textColor} ${getFileTypeIcon(
                    file.description
                  )} mr-2 h-4 text-lg leading-4`}
                ></i>
                {file.description}
              </a>
              {Boolean(onDelete) && (
                <div
                  className="ml-2 h-3 w-4 flex items-center hover:cursor-pointer"
                  onClick={() => setConfirmDeleteForFile(file.file_pk)}
                  data-testid="delete-file-button"
                >
                  <i className="nc-icon-glyph ui-1_bold-remove text-red-3"></i>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Modal
        isOpen={Boolean(confirmDeleteForFile)}
        onSave={handleDelete}
        overrideClasses="bg-red-3 hover:bg-red-4"
        label="Delete"
        setIsOpen={setConfirmDeleteForFile}
      >
        <div
          className="text-red-3 text-lg"
          id="delete-modal"
        >
          Are you sure you want to delete this file?
        </div>
      </Modal>
    </>
  );
};

export default FileList;
