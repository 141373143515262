import React, { useState } from 'react';
import { useCourseRequestState } from '../../../../stores/useCourseRequestsStore';
import CourseSearchBar from './CourseSearchBar';
import ReactSelect from 'react-select';
import CoursesList from './CoursesList';
import { ZgOption } from '../types';

type AllCoursesProps = {
  setScrollToRequest: (value: number) => void;
};

const AllCourses = ({ setScrollToRequest }: AllCoursesProps) => {
  const { subjects, classifications } = useCourseRequestState();
  const [selectedSubject, setSelectedSubject] = useState<ZgOption[]>(null);
  const [selectedClassification, setSelectedClassification] = useState<ZgOption[]>(null);
  const [searchInput, setSearchInput] = useState<string>(null);

  const subjectList = subjects.map((subject) => ({
    ...subject,
    label: subject.description,
  }));
  const classificationList = classifications.map((classification) => ({
    ...classification,
    label: classification.description,
  }));

  const subjectFilters = selectedSubject?.map((subject) => subject.id);
  const classificationFilters = selectedClassification?.map(
    (classification) => classification.description
  );

  const selectStyles = {
    input: (styles) => ({
      ...styles,
      'input:focus': {
        boxShadow: 'none',
      },
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: '1px',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      '&:hover': {
        backgroundColor: '#2684FF',
        color: 'white',
      },
    }),
  };

  return (
    <>
      <div className="text-black mt-2">
        <h1 className="border-b border-black text-xl font-normal">All Courses</h1>
        <div className="flex flex-wrap mb-2">
          <CourseSearchBar setSearchInput={setSearchInput} />
          <ReactSelect
            options={subjectList}
            placeholder="Subject"
            className="flex-auto w-56 mt-2 mr-2"
            styles={selectStyles}
            isMulti
            value={selectedSubject}
            getOptionValue={(option) => option.id}
            filterOption={(option, searchInput) =>
              option.label.toLowerCase().includes(searchInput?.toLowerCase())
            }
            onChange={(selection) => setSelectedSubject(selection)}
          />
          <ReactSelect
            options={classificationList}
            placeholder="Classification"
            className="flex-auto w-56 mt-2 mr-2"
            styles={selectStyles}
            isMulti
            value={selectedClassification}
            getOptionValue={(option) => option.id}
            filterOption={(option, searchInput) =>
              option.label.toLowerCase().includes(searchInput?.toLowerCase())
            }
            onChange={(selection) => setSelectedClassification(selection)}
          />
        </div>
      </div>

      <div className="min-h-[600px] h-[600px] pt-1">
        <CoursesList
          searchInput={searchInput}
          subjectFilters={subjectFilters}
          classificationFilters={classificationFilters}
          setScrollToRequest={setScrollToRequest}
        />
      </div>
    </>
  );
};

export default AllCourses;
