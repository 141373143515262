import React from 'react';

type CheckboxProps = {
  field: string;
  checkedValue: boolean;
  onChange: (field: string, selection: string) => void;
  disabled: boolean;
  styles?: string;
};

const Checkbox = ({ field, checkedValue, onChange, disabled, styles }: CheckboxProps) => {
  return (
    <input
      type="checkbox"
      name={field}
      checked={checkedValue}
      onChange={(e) => onChange(field, e.target.value)}
      disabled={disabled}
      className={`rounded-sm my-1 mr-1 ring-transparent ${styles}`}
    ></input>
  );
};

export default Checkbox;
