import React from 'react';
import classNames from 'classnames';

import TabsContext from './TabsContext';

function TabPanel(props) {
  const { name } = props;

  const { isActiveTab } = React.useContext(TabsContext);

  const tabPanelClassNames = classNames({
    'tab-panel': true,
    'tab-panel--active': isActiveTab(name),
  });

  return <div className={tabPanelClassNames}>{props.children}</div>;
}

export default React.memo(TabPanel);
