import React from 'react';
import { Properties } from '../types';
import classNames from 'classnames';
import BooleanToggle from '../common/BooleanToggle';
import BooleanLabel from '../common/BooleanLabel';
import LockIcon from '../common/LockIcon';
import { noop } from 'lodash';
import { ROW_HOVER_COLOR } from '../constants';

const FIELD_DESCRIPTIONS: Record<string, () => string> = {
  view_grades: () => 'View Grades',
  update_grades: () => 'Update Grades',
  view_all_student_grades: () => 'View All Student Grades',
  view_progress_report: () => 'View Progress Report',
  view_report_card: () => `View ${i18next.t('Report Card')}`,
  view_attendance: () => 'View Attendance',
  view_behavior: () => `View ${i18next.t('Behavior')}`,
  teacher_portal_visibility: () => 'Teacher Portal Visibility',
};

const BooleanPermission = ({ field_name, value }: { field_name: string; value: boolean }) => {
  const fieldDescription = FIELD_DESCRIPTIONS[field_name];

  // If the field name is not in the list of descriptions, then it is not a boolean permission
  if (!fieldDescription) {
    return null;
  }

  const settingProperties: Properties = {
    db_source: 'class_permission',
    target: 'unused',
    class_id: 0,
    description: fieldDescription(),
    value: value,
    placeholder_value: '',
    teacher_access_strategy: 'read_only',
    sort_key: 0,
    input_type: 'checkbox',
    dropdown_options: {},
  };

  const rowClasses = classNames(
    `flex items-center justify-between p-2 hover:${ROW_HOVER_COLOR} sm:p-5`,
    {
      'text-gray-900': settingProperties.teacher_access_strategy === 'editable',
      'text-gray-500': settingProperties.teacher_access_strategy !== 'editable',
    }
  );

  return (
    <div className={rowClasses}>
      <BooleanLabel
        settingProperties={settingProperties}
        overrideClasses="text-left"
      />
      <BooleanToggle
        settingProperties={settingProperties}
        setServerError={noop}
        serverError={null}
        isEditable={false}
      />
      <LockIcon
        isDisplayed={true} // always true here, no way to update permissions from the portal
      />
    </div>
  );
};

export default BooleanPermission;
