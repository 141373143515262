import { AttendanceType, StudentLogisticRequestType } from './types';
import moment from 'moment/moment';
import React from 'react';
import { useScreenSizes } from '../../../stores/useAppStore';

const AttendanceLogisticsRequests = ({ attendance }: { attendance: AttendanceType }) => {
  // return nothing if there are no SLRs
  if (attendance.slrs.length === 0) {
    return null;
  }

  return (
    <ul className="attendance-slr-list">
      {attendance.slrs.map((slr) => (
        <AttendanceLogisticsRequest
          key={slr.student_logistics_request_pk}
          slr={slr}
        />
      ))}
    </ul>
  );
};

const convertToLocaleTime = (time: string) => moment(time).format('LT');

export const AttendanceLogisticsRequest = ({ slr }: { slr: StudentLogisticRequestType }) => {
  const { isSm: isMobile } = useScreenSizes();
  const ariaLabel = `${slr.reason}: ${slr.request_notes || '(No notes)'}`;
  const results = [`${slr.description}: ${slr.attendance_type}`];

  // add the time if it exists
  // e.g. "(8:00 AM to 9:00 AM)"  if both attendance_time and attendance_return_time exist
  // e.g. "(8:00 AM)"             if only attendance_time exists
  if (slr.attendance_time && slr.attendance_return_time) {
    const startTime = convertToLocaleTime(slr.attendance_time);
    const endTime = convertToLocaleTime(slr.attendance_return_time);
    results.push(`(${startTime} to ${endTime})`);
  } else if (slr.attendance_time) {
    const startTime = convertToLocaleTime(slr.attendance_time);
    results.push(`(${startTime})`);
  }

  return (
    <li>
      <span
        // only add the tooltip if it's not mobile
        {...(!isMobile && {
          className: 'vx-tooltipped vx-tooltipped--s',
          'aria-label': ariaLabel,
        })}
      >
        <i className="nc-icon-glyph travel_info" />
        {results.join(' ')}
      </span>
      {isMobile && (
        <>
          <br />
          <em>{ariaLabel}</em>
        </>
      )}
    </li>
  );
};

export default AttendanceLogisticsRequests;
