import React from 'react';
import { useAssignmentTypes } from '../queries';
import ReactSelect from 'react-select';
import { useFilters, useGradebookActions } from '../../../../stores/useGradebookStore';

const AssignmentTypesDropdown = () => {
  const { data: assignmentTypes, isLoading } = useAssignmentTypes();
  const { setAssignmentType } = useGradebookActions();
  const { assignmentType: currentType } = useFilters();

  const options = [];
  let selectedType = null;
  if (!isLoading) {
    assignmentTypes.forEach((type) => {
      options.push({ value: type.id, label: type.description });
    });
    selectedType = options.find((option) => currentType === option.value);
  }

  return (
    <div
      className="w-44 m-2.5"
      data-testid="assignment-types-dropdown"
    >
      <ReactSelect
        value={selectedType}
        options={options}
        onChange={(newSelection) => setAssignmentType(newSelection.value)}
        isSearchable={false}
        maxMenuHeight={200}
        isDisabled={false}
        isLoading={isLoading}
        menuPlacement="auto"
        styles={{
          control: (base) => ({
            ...base,
            height: '32px',
            minHeight: '32px',
          }),
          indicatorsContainer: (base) => ({
            ...base,
            height: '32px',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          singleValue: (base) => ({
            ...base,
            color: '#555555',
          }),
        }}
      />
    </div>
  );
};

export default AssignmentTypesDropdown;
