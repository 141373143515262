import React, { useRef } from 'react';
import { components } from 'react-select';
import useConferencesStore from '../../../stores/useConferencesStore';
import { DROPDOWN_OPTION_HEIGHT } from './constants';

// Explanation of this file:
// We are importing and modifying the ReactSelect components
// for the sole purpose of showing the Pillar tooltip within
// the ReactSelect dropdown. The 'tooltipInfo' state is null
// if no tooltip is showing, and otherwise contains an object
// with the tooltip description, and icon id that is being hovered
// over.

export const ClearIndicator = (props) => {
  return (
    <div
      className="vx-tooltipped vx-tooltipped--se"
      aria-label="Unregister From This Conference Time"
    >
      <components.ClearIndicator {...props} />
    </div>
  );
};

const calculateOffsets = (parentId, iconId) => {
  const parentElement = document.getElementById(parentId);
  const childElement = document.getElementById(iconId);
  const parentPos = parentElement ? parentElement.getBoundingClientRect() : 0;
  const childPos = childElement ? childElement.getBoundingClientRect() : 0;
  const offsetTop = childPos.top - parentPos.top;
  const offsetLeft = childPos.left - parentPos.left;
  return { offsetTop, offsetLeft };
};

const getStyleVars = (tooltipInfo, height) => {
  if (!tooltipInfo) {
    return null;
  }
  const { offsetTop, offsetLeft } = calculateOffsets(tooltipInfo.parentId, tooltipInfo.iconId);
  return {
    '--tooltip-offsetBottom': (height - offsetTop).toString() + 'px',
    '--tooltip-offsetTop': offsetTop.toString() + 'px',
    '--tooltip-offsetLeft': offsetLeft.toString() + 'px',
    '--tooltip-offsetLeft-before': offsetLeft.toString() + 'px',
  };
};

export const MenuList = (props) => {
  const tooltipInfo = useConferencesStore((state) => state.tooltipInfo);
  const isControl = tooltipInfo?.iconId.endsWith('-control');
  const ref = useRef();
  const menuHeight = ref?.current ? ref.current.clientHeight : 0;
  const showTooltip = tooltipInfo && !isControl && menuHeight !== 0;

  const ariaLabel = tooltipInfo?.description;
  return (
    <div
      className={showTooltip ? 'vx-tooltipped vx-tooltipped--se-adjusted' : ''}
      ref={ref}
      style={showTooltip ? getStyleVars(tooltipInfo, menuHeight) : null}
      id={props.selectProps.parentElementId}
      aria-label={ariaLabel}
    >
      <components.MenuList {...props} />
    </div>
  );
};

export const Control = (props) => {
  const tooltipInfo = useConferencesStore((state) => state.tooltipInfo);
  const showTooltip = tooltipInfo?.iconId.endsWith('-control');
  const ariaLabel = tooltipInfo?.description;

  return (
    <div
      className={showTooltip ? 'vx-tooltipped vx-tooltipped--se-adjusted' : ''}
      style={showTooltip ? getStyleVars(tooltipInfo, DROPDOWN_OPTION_HEIGHT) : null}
      data-testid="selectComponent-control"
      id={props.selectProps.parentElementId + '-control'}
      aria-label={ariaLabel}
    >
      <components.Control {...props} />
    </div>
  );
};
