import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../../shared/LoadingSpinner';
import { useAssignmentFeedback, useStudentGrade } from '../../queries';
import { Student } from '../../types';
import FeedbackInput from './FeedbackInput';
import Message from './Message';

const Feedback = ({ student, sidebarIsOpen }: { student: Student; sidebarIsOpen: boolean }) => {
  const studentId = student.person_pk;
  const [fileHandlerExpanded, setFileHandlerExpanded] = useState(false);
  const gradeRecord = useStudentGrade(studentId);
  const gradeId = gradeRecord?.class_assignment_person_pk;
  const { data: feedback, refetch } = useAssignmentFeedback(gradeId);

  const scrollToBottomOfMessages = () => {
    const element = document.getElementById('messages');
    if (typeof element.scroll === 'function') {
      element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (feedback) {
      scrollToBottomOfMessages();
    }
  }, [feedback, fileHandlerExpanded]);

  useEffect(() => {
    setFileHandlerExpanded(false);
  }, [sidebarIsOpen]);

  if (!feedback) {
    return <LoadingSpinner />;
  }

  const handleOnSave = () => {
    refetch();
    scrollToBottomOfMessages();
    setFileHandlerExpanded(false);
  };

  const handleDeleteMessage = () => {
    refetch();
  };

  return (
    <>
      <div
        className="mt-6 overflow-auto h-[55vh]"
        id="messages"
      >
        {feedback?.messages.map((message) => {
          const files = feedback.files.filter((file) => file.feedback_id === message.feedback_id);
          return (
            <Message
              key={message.feedback_id}
              studentPhotoUrl={student.photo_url}
              message={message}
              files={files}
              onDelete={handleDeleteMessage}
            />
          );
        })}
      </div>
      <FeedbackInput
        gradeId={gradeId}
        onSave={handleOnSave}
        fileHandlerExpanded={fileHandlerExpanded}
        toggleFileHandler={() => setFileHandlerExpanded(!fileHandlerExpanded)}
        sidebarIsOpen={sidebarIsOpen}
      />
    </>
  );
};

export default Feedback;
