import { useContext, useState } from 'react';
import AuthTokenContext from '../components/shared/AuthTokenContext';

const HTTP_METHOD = {
  get: 'GET',
  put: 'PUT',
  post: 'POST',
  delete: 'DELETE',
};

const generateRequestHeaders = ({ authToken, overrideHeaders = null }) => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Cache-Control': 'no-cache, no-store',
  'X-CSRF-Token': authToken,
  ...overrideHeaders,
});

const generateRequestOptions = ({ authToken, overrideHeaders = null, method, body }) => {
  const requestOptions = {
    method,
    headers: generateRequestHeaders({ authToken, overrideHeaders }),
  };

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }

  return requestOptions;
};

const useFetch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const authToken = useContext(AuthTokenContext);

  // TODO: standardize errors for async calls in the backend,
  // standardize error in the front by creating a custom Error that adjusts to what's being returned by backend
  const sendFetchRequest = async ({ url, method, overrideHeaders = null, body }) => {
    try {
      setLoading(true);
      const response = await fetch(
        url,
        generateRequestOptions({ authToken, overrideHeaders, method, body })
      );

      if (response.status >= 400 && response.status < 600) {
        const { error } = await response.json();
        throw new Error(error);
      }

      const data = await response.json();
      setData(data);
      setError(null);
    } catch (error) {
      // TODO: here we could handle errors globally with a toast component,
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { loading, data, error, sendFetchRequest };
};

export { useFetch, HTTP_METHOD };
