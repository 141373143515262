import React from 'react';
import { useSubjectCourses } from '../../../../../stores/useCourseRequestsStore';
import { Course, ZgOption } from '../../types';

type CoursesBySubjectListProps = {
  handleSelectCourse: (course: Course) => void;
  selectedSubject: ZgOption;
};
const CoursesBySubjectList = ({
  handleSelectCourse,
  selectedSubject,
}: CoursesBySubjectListProps) => {
  const courses = useSubjectCourses(selectedSubject.id);

  return (
    <div className="max-h-[280px] overflow-auto">
      {courses.map((course) => {
        return (
          <div
            className="mb-2"
            key={`${course.id} ${course.subject_id}`}
          >
            <div
              className="h-6 cursor-pointer underline"
              onClick={() => handleSelectCourse(course)}
            >
              {course.description}
            </div>
            <div className="text-xs text-neutral-3">{course.notes}</div>
          </div>
        );
      })}
    </div>
  );
};

export default CoursesBySubjectList;
