/* eslint-disable camelcase */
import React from 'react';
import { Form, Formik } from 'formik';
import createValidationSchema from './utils/createValidationSchema';
import AuthTokenContext from '../../shared/AuthTokenContext';
import WebFormSuccess from './WebFormSuccess';
import {
  convertForm,
  getInitialValuesForFormik,
  getSectionDisplayMap,
  serializeFormikValues,
} from './utils/form-helpers';
import { getSubmissionInfo, getSubmissionValues } from './utils/data-helpers';
import { FormType, SubmissionInfo } from './types';

type WebFormContextType = {
  form: FormType;
  initialValues: {};
  sectionDisplayMap: {};
  submission: SubmissionInfo;
  errors: string[];
};

export type WebFormProviderType = {
  form: FormType;
  theKey?: string;
  submissionUrl?: string;
  data?: SubmissionData[];
  children?: JSX.Element;
};

export type SubmissionData = {
  data_type: string;
  field_name: string;
  value: string;
};

export const WebFormContext = React.createContext<Partial<WebFormContextType>>({});

export const useWebForm = () => React.useContext(WebFormContext);

export const WebFormProvider = ({
  form,
  data,
  theKey: key,
  submissionUrl,
  children,
}: WebFormProviderType) => {
  const [errors, setErrors] = React.useState([]);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const authToken = React.useContext<null>(AuthTokenContext);

  const submission = React.useMemo(() => getSubmissionInfo(data), [data]);

  const serializedData = React.useMemo(() => getSubmissionValues(data), [data]);

  const initialValues = React.useMemo(
    () => ({
      ...getInitialValuesForFormik(form),
      ...serializedData,
    }),
    [form]
  );

  const convertedForm = React.useMemo(() => convertForm(form), [form]);

  const sectionDisplayMap = React.useMemo(() => getSectionDisplayMap(form), [form]);

  const context = {
    form: convertedForm,
    initialValues,
    sectionDisplayMap,
    submission,
    errors,
  };

  const validationSchema = createValidationSchema({
    form: convertedForm,
    submission,
    sectionDisplayMap,
    operationType: submission.operation_type,
  });

  const onSubmit = async (values): Promise<any> => {
    const serializedValues = serializeFormikValues(values);
    const body = { ...serializedValues, key, authenticity_token: authToken };

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Cache-Control': 'no-cache, no-store',
      'X-CSRF-Token': authToken,
    };
    const response = await fetch(submissionUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
      cache: 'no-store',
    });
    if (response.status >= 400) {
      const errorJson = await response.json();
      setErrors(errorJson.errors);
      return;
    }
    setIsSubmitted(true);
  };

  return (
    <WebFormContext.Provider value={context}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>{isSubmitted ? <WebFormSuccess /> : children}</Form>
      </Formik>
    </WebFormContext.Provider>
  );
};
