import React from 'react';
import { copyToClipboard } from 'components/shared/copy-to-clipboard';

export default function Copyable(props) {
  const { value } = props;

  const [isCopied, setIsCopied] = React.useState(false);
  const copiedDuration = 3000;

  const handleCopy = () => {
    copyToClipboard(value);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), copiedDuration);
  };

  const CopyButton = isCopied ? (
    <div
      className="copy-button copy-button--copied vx-tooltipped vx-tooltipped--w"
      aria-label="Copied!"
    >
      <i className="nc-icon-glyph design_todo" />
    </div>
  ) : (
    <div
      className="copy-button vx-tooltipped vx-tooltipped--w"
      aria-label="Copy to clipboard"
      onClick={handleCopy}
    >
      <i className="nc-icon-glyph education_notepad" />
    </div>
  );

  return (
    <div className="copyable-container">
      <div className="copyable-content">{props.children}</div>
      {CopyButton}
    </div>
  );
}
