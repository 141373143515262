import React, { FC, useState } from 'react';
import InputField from './InputField';
import DatePicker from './DatePicker';
import Toggle from '../common/Toggle';
import Dropdown from './Dropdown';
import { useAssignmentTypes } from '../queries';
import FormElement from './NewAssignmentFormElement';
import RichTextArea from '../common/RichTextArea';
import { Formik, Form } from 'formik';
import { AssignmentSchema, getErrorMessage, getScaleDescription } from '../helpers';
import {
  APPLIES_TO_ALL_SECTIONS,
  ASSIGNMENT_DETAILS,
  ASSIGNMENT_TYPE,
  DATE_ASSIGNED,
  DESCRIPTION,
  DISPLAY_ON_PORTALS,
  DISPLAY_ON_REPORT_CARD,
  DUE_DATE,
  ERROR_TIMEOUT,
  EXTRA_CREDIT,
  GRADE_CONVERSION_SCALE,
  GRADING_METHOD,
  GradingMethodDescription,
  INCLUDE_IN_CALC_GRADE,
  MAX_SCORE,
  TEACHER_NOTES,
  WEIGHT,
} from '../constants';
import SaveButton from './SaveButton';
import { GradingMethod, ConversionScale, AccessStatuses } from '../types';
import { useScreenSizes } from '../../../../stores/useAppStore';
import { useConfigurations } from '../../../../stores/useGradebookStore';

type FormProps = {
  saveForm?: (a: Record<string, any>) => void;
  initialValues: Record<string, any>;
  gradingMethods: GradingMethod[];
  conversionScales: ConversionScale[];
};
const NewAssignmentForm: FC<FormProps> = ({
  initialValues,
  saveForm,
  gradingMethods,
  conversionScales,
}) => {
  const { data: assignmentTypesData, isLoading: isLoadingAssignmentTypes } = useAssignmentTypes();
  const { show_display_on_report_card_flag: showDisplayOnReportCardToggle } = useConfigurations();
  const isLoadingGradingMethods = !Boolean(gradingMethods);
  const isLoadingScales = !Boolean(conversionScales);

  const { isMd: isMobile } = useScreenSizes();
  const {
    assignment_max_score_teacher_access_status: maxScoreAccess,
    assignment_weight_teacher_access_status: weightAccess,
  } = useConfigurations();
  const displayWeight = weightAccess !== AccessStatuses.DISABLED;
  const displayMaxScore = maxScoreAccess !== AccessStatuses.DISABLED;
  const readOnlyWeight = weightAccess === AccessStatuses.READ_ONLY;
  const readOnlyMaxScore = maxScoreAccess === AccessStatuses.READ_ONLY;

  const [isSavingForm, setIsSavingForm] = useState(false);
  const [errorSaving, setErrorSaving] = useState('');
  const isSaveDisabled = isLoadingAssignmentTypes || isSavingForm;

  const handleSaveForm = async (values) => {
    if (isSaveDisabled) {
      return;
    }

    try {
      setIsSavingForm(true);
      await saveForm(values);
    } catch (error) {
      setErrorSaving(getErrorMessage(error));
      setTimeout(() => {
        setErrorSaving('');
      }, ERROR_TIMEOUT);
    } finally {
      setIsSavingForm(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSaveForm}
      validationSchema={AssignmentSchema}
    >
      {({ values }) => {
        const isLetterGrading = values.grading_method === GradingMethodDescription.LETTER;
        return (
          <Form>
            <div className="px-4 pb-4 md:max-w-screen-xl">
              <div className="md:grid md:grid-cols-2 md:gap-x-4">
                <FormElement
                  label={DESCRIPTION}
                  attribute="description"
                  component={InputField}
                  extraProps={{
                    initialValue: initialValues.description,
                  }}
                />

                <FormElement
                  label={ASSIGNMENT_TYPE}
                  attribute="assignment_type"
                  component={Dropdown}
                  extraProps={{
                    isLoading: isLoadingAssignmentTypes || false,
                    types: assignmentTypesData || [],
                  }}
                />
              </div>

              <div className="md:w-6/12 md:float-right md:pl-2">
                <div className="grid grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-2 gap-x-4">
                  <FormElement
                    label={DATE_ASSIGNED}
                    attribute="assignment_date"
                    component={DatePicker}
                  />

                  <FormElement
                    label={DUE_DATE}
                    attribute="due_date"
                    component={DatePicker}
                  />
                </div>

                <div className="grid grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-2 gap-x-4">
                  <FormElement
                    label={GRADING_METHOD}
                    attribute="grading_method"
                    component={Dropdown}
                    extraProps={{
                      isLoading: isLoadingGradingMethods || false,
                      types: gradingMethods,
                      readOnly: false,
                    }}
                  />

                  {isLetterGrading && (
                    <FormElement
                      label={GRADE_CONVERSION_SCALE}
                      attribute="grade_conversion_scale"
                      component={Dropdown}
                      tooltipInfo={
                        isMobile
                          ? null
                          : getScaleDescription(conversionScales, values.grade_conversion_scale)
                      }
                      extraProps={{
                        isLoading: isLoadingScales || false,
                        types: conversionScales || [],
                        readOnly: true,
                      }}
                    />
                  )}

                  {!isLetterGrading && displayMaxScore && (
                    <FormElement
                      label={MAX_SCORE}
                      attribute="maximum_score"
                      component={InputField}
                      extraProps={{
                        initialValue: initialValues.maximum_score,
                        readOnly: readOnlyMaxScore,
                      }}
                    />
                  )}

                  {!isLetterGrading && displayWeight && (
                    <FormElement
                      label={WEIGHT}
                      attribute="weight"
                      component={InputField}
                      extraProps={{
                        initialValue: initialValues.weight,
                        readOnly: readOnlyWeight,
                      }}
                    />
                  )}
                </div>

                <div className="grid grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-2 gap-x-4">
                  <FormElement
                    label={INCLUDE_IN_CALC_GRADE}
                    attribute="include_in_calc_grade"
                    component={Toggle}
                  />

                  <FormElement
                    label={EXTRA_CREDIT}
                    attribute="extra_credit"
                    component={Toggle}
                  />

                  <FormElement
                    label={APPLIES_TO_ALL_SECTIONS}
                    attribute="applies_to_all_sections"
                    component={Toggle}
                  />

                  <FormElement
                    label={DISPLAY_ON_PORTALS}
                    attribute="display_status"
                    component={Toggle}
                  />

                  {showDisplayOnReportCardToggle && (
                    <FormElement
                      label={DISPLAY_ON_REPORT_CARD}
                      attribute="display_on_report_card"
                      component={Toggle}
                    />
                  )}
                </div>
              </div>

              <div className="md:w-6/12 md:float-left md:pr-2">
                <FormElement
                  label={ASSIGNMENT_DETAILS}
                  attribute="notes"
                  component={RichTextArea}
                />

                <FormElement
                  label={TEACHER_NOTES}
                  attribute="teacher_notes"
                  component={RichTextArea}
                />
              </div>

              <div className="md:w-6/12 md:float-right md:pl-2 mt-4">
                <SaveButton
                  isDisabled={isSaveDisabled}
                  error={errorSaving}
                />
              </div>
              <div className="clear-both"></div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewAssignmentForm;
