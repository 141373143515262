import React from 'react';
import classNames from 'classnames';

import TabsContext from './TabsContext';

// prettier-ignore
function TabButton(props) {
  const { name, value } = props;

  const { isActiveTab, setActiveTab } = React.useContext(TabsContext);

  const handleClick = React.useCallback((event) => {
    setActiveTab(name);
  }, [name, setActiveTab]);

  const tabButtonClassNames = classNames({
    'tab-button': true,
    'tab-button--active': isActiveTab(name),
  });

  const TabButtonContent = props.children || value || name;

  return (
    <div className={tabButtonClassNames} onClick={handleClick}>
      {TabButtonContent}
    </div>
  );
}

export default React.memo(TabButton);
