/***************************************************************************************************
 ** Constants
 ***************************************************************************************************/

// prettier-ignore
export const REQUEST_STATUSES = [
  {
    value: 0,
    name: 'pending',
    label: 'Pending',
    color: '#D6AA1C',
    icon: 'ui-1_edit-72',
  },
  {
    value: 1,
    name: 'submitted_for_approval',
    label: 'Submitted for Approval',
    color: '#0EA4B2',
    icon: 'ui-1_send',
  },
  {
    value: 2,
    name: 'changes_needed',
    label: 'Changes Needed',
    color: '#FF9933',
    icon: 'ui-2_alert-circle-',
  },
  {
    value: 3,
    name: 'approved',
    label: 'Approved',
    color: '#3BA559',
    icon: 'ui-1_check-circle-08',
  },
  {
    value: 4,
    name: 'denied',
    label: 'Denied',
    color: '#BF360C',
    icon: 'ui-2_ban-bold',
  },
  {
    value: 5,
    name: 'cancelled',
    label: 'Cancelled',
    color: '#781C00',
    icon: 'ui-1_circle-bold-remove',
  },
  {
    value: 6,
    name: 'closed',
    label: 'Closed',
    color: '#7B7B7B',
    icon: 'ui-2_archive',
  },
].reduce((a, config) => ({ ...a, [config.value]: config, [config.name]: config }), {});
