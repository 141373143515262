import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { FIELD_HEIGHT } from '../constants';
import { AssignmentFormFieldProps, ZgStatus } from '../types';

type DropdownProps = {
  types: ZgStatus[];
  isLoading: boolean;
  readOnly?: boolean;
};
const Dropdown = ({
  value,
  onChange,
  types,
  isLoading,
  isSuccessNotifying,
  readOnly,
}: AssignmentFormFieldProps & DropdownProps) => {
  const [isSaving, setIsSaving] = useState(false);

  const options = [];
  let selectedType = null;
  if (!isLoading) {
    types.forEach((type) => {
      if (type.id === 0) {
        return;
      }
      options.push({ value: type.id, label: type.description });
    });
    selectedType = options.find((option) => value === option.value);
  }

  if (!selectedType && options.length > 0) {
    // If the selected value is not in the list of options, default to the first option
    // this is used for new assignments where the default value is not in the list of options
    selectedType = options[0];
  }

  const handleSave = async (selection) => {
    // Only change value if it's different
    if (value !== selection.value) {
      setIsSaving(true);
      await onChange(selection.value);
      setIsSaving(false);
    }
  };

  const borderRing = isSuccessNotifying ? 'ring-offset ring-2 ring-green-4 rounded' : '';
  readOnly = readOnly || options.length === 1;

  return (
    <div
      className={borderRing}
      data-testid="assignment-dropdown-container"
    >
      <ReactSelect
        value={selectedType}
        options={options}
        onChange={handleSave}
        isSearchable={false}
        placeholder="Loading..."
        maxMenuHeight={200}
        isLoading={!readOnly && (isLoading || isSaving)}
        isDisabled={readOnly}
        menuPlacement="auto"
        styles={{
          control: (base) => ({
            ...base,
            height: FIELD_HEIGHT,
            minHeight: FIELD_HEIGHT,
            borderColor: readOnly ? '#f5f5f5' : '#a6a6a6',
            boxShadow: 0,
            '&:hover': {
              border: `1px solid #a6a6a6`,
            },
          }),
          indicatorsContainer: (base) => ({
            ...base,
            height: FIELD_HEIGHT,
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          dropdownIndicator: (base) => ({
            ...base,
            display: readOnly ? 'none' : 'flex',
          }),
          singleValue: (base) => ({
            ...base,
            color: '#555555',
          }),
        }}
      />
    </div>
  );
};
export default Dropdown;
