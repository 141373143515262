import create from 'zustand';
import {
  Actions,
  CourseRequestData,
  CourseRequestState,
  StudentMetaData,
} from '../components/system/course-requests/types';
import produce from 'immer';
import { DEFAULT_COURSE_REQUEST_DATA } from '../components/system/course-requests/constants';

export const useCourseRequestsStore = create<CourseRequestState>((set) => ({
  studentState: {
    isFacultyPortal: false,
    student: {
      person_pk: 0,
      name: '',
      first_name: '',
      last_name: '',
    },
    studentData: {
      full_name: '',
      grade_applying_for: '',
      school_year: 0,
      sy_description_long: 0,
      campus: '',
      student_group: '',
      course_requests_finalized: false,
      schedule_url: '',
    },
    studentIsSelected: false,
    version: 1,
    portalConfig: [],
    readOnly: false,
    activeGradingPeriod: 0,
  },

  courseRequestState: {
    current_course_requests: [],
    previous_courses: [],
    credits: [],
    current_schedule: '',
    grading_periods: [],
    enrollment_levels: [],
    gradingPeriods: [],
    subjects: [],
    courses_by_subject: {},
    classifications: [],
    default_form_values: DEFAULT_COURSE_REQUEST_DATA,
    current_grades: [],
  },

  actions: {
    setStudent: (newStudent) =>
      set(
        produce((state) => {
          state.studentState.student = newStudent;
          state.studentState.studentIsSelected = true;
        })
      ),
    setStudentData: (newData) =>
      set(
        produce((state) => {
          state.studentState.studentData = newData;
        })
      ),
    setCourseRequestData: (newData) =>
      set(
        produce((state) => {
          state.courseRequestState = newData;
          state.courseRequestState.default_form_values = {
            ...DEFAULT_COURSE_REQUEST_DATA,
            ...newData.default_form_values,
          };
        })
      ),
    setRequestField: ({ field: field, value: newValue, id: courseRequestId }) =>
      set(
        produce((state) => {
          const request = state.courseRequestState.current_course_requests.find(
            (courseRequest) => courseRequest.id === courseRequestId
          );
          if (request) {
            request[field] = newValue;
          }
        })
      ),
    initialize: (data) =>
      set(
        produce((state) => {
          state.studentState = {
            isFacultyPortal: data.isFacultyPortal,
            student: data.student,
            studentData: data.studentData,
            studentIsSelected: data.studentData.full_name !== null,
            version: data.version,
            portalConfig: data.portalConfig,
            readOnly: data.readOnly,
            activeGradingPeriod: data.activeGradingPeriod,
          };
        })
      ),
  },
}));

export const useStudentState = (): StudentMetaData =>
  useCourseRequestsStore((state) => state.studentState);
export const useCourseRequestState = (): CourseRequestData =>
  useCourseRequestsStore((state) => state.courseRequestState);
export const useCourseRequestActions = (): Actions =>
  useCourseRequestsStore((state) => state.actions);

export const useSubjectCourses = (subjectId: number) =>
  useCourseRequestsStore((state) => state.courseRequestState.courses_by_subject[subjectId]);

export const useCurrentGradesByGradingPeriod = (gradingPeriodId: number) =>
  useCourseRequestsStore((state) =>
    state.courseRequestState.current_grades.filter(
      (grade) => grade.grading_period === gradingPeriodId
    )
  );
