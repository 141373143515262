import { SectionType } from '../navigation/types';

export const NOTIFY_DURATION_MS = 3000;
export const ROW_HOVER_COLOR = 'bg-gray-50';

export const KEYPATH_SECTION_TYPE: Record<string, SectionType> = {
  students__students_page_label: 'students',
  attendance__attendance_page_label: 'attendance',
  gradebook__gradebook_label: 'gradebook',
  report_cards__grades_comments_page_label: 'report_cards',
  notifications__notifications_label: 'notifications',
  calendar__calendar_lessons_page_label: 'calendar',
  website__website_label: 'website',
  photos__photos_page_label: 'photos',
  settings__settings_page_label: 'settings',
};
