import React from 'react';
import { useGridData } from '../queries';
import HeaderCell from './HeaderCell';
import TotalGradeHeaders from './TotalGradeHeaders';
import TotalGradeStudentRow from './TotalGradeStudentRow';
import ClassAverages from './ClassAverages';

const TotalGradeColumns = () => {
  const { data } = useGridData();

  return (
    <>
      {data.students.map((student) => {
        return (
          <TotalGradeStudentRow
            key={student.person_pk}
            student={student}
          />
        );
      })}
      <ClassAverages students={data.students} />
    </>
  );
};

const TotalGradeHeaderColumns = () => {
  return (
    <tr>
      <HeaderCell classOverrides="w-36 bg-white"></HeaderCell>
      <TotalGradeHeaders />
    </tr>
  );
};

const TotalGradeTable = () => {
  return (
    <table
      className="h-fit sticky left-0 top-0 pl-4 bg-white z-20 pb-5"
      id="class-grade-table"
    >
      <thead>
        <TotalGradeHeaderColumns />
      </thead>
      <tbody>
        <TotalGradeColumns />
      </tbody>
    </table>
  );
};

export default TotalGradeTable;
