import React, { memo } from 'react';

const PlainTextField = ({ children }: { children: string }) => (
  <div
    className="plaintext-data"
    data-testid="plain-text-field"
  >
    {children}
  </div>
);

export default memo(PlainTextField);
