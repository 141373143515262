import React from 'react';
import { AssignmentType, TotalsByAssignmentType, WeightConfiguration } from '../types';
import WeightingTableRow from './WeightingTableRow';
import { DEFAULT_GRADING_CONFIGURATIONS } from '../constants';
import { contrastingColor } from '../helpers';
import Cell from '../grid/Cell';

type WeightingTableBodyProps = {
  periodConfig: WeightConfiguration[];
  assignmentTypes: AssignmentType[];
  totals: TotalsByAssignmentType;
};
const WeightingTableBody = ({ periodConfig, assignmentTypes, totals }: WeightingTableBodyProps) => {
  return (
    <tbody>
      {assignmentTypes
        .filter((type) => {
          return type.id !== 0 && periodConfig.some((config) => type.id === config.assignment_type);
        })
        .map((assignmentType, idx) => {
          const config = periodConfig.find(
            (config) => config.assignment_type === assignmentType.id
          );
          const bgColor = assignmentType.color || '#d87474';
          const textColor = contrastingColor(bgColor);

          return (
            <tr key={idx}>
              <Cell
                classOverrides="min-w-max uppercase text-left px-3"
                inlineStyles={{ backgroundColor: bgColor, color: textColor }}
              >
                {assignmentType.description}
              </Cell>
              <WeightingTableRow
                config={config || DEFAULT_GRADING_CONFIGURATIONS}
                totals={totals ? totals[assignmentType.id] : { count: 0, weight: 0 }}
                key={idx}
              />
            </tr>
          );
        })}
    </tbody>
  );
};

export default WeightingTableBody;
