import React from 'react';
import { CategoryMap, Settings } from './types';
import BooleanSetting from './setting-types/BooleanSetting';
import TextSetting from './setting-types/TextSetting';
import DropdownSetting from './setting-types/DropdownSetting';

type CategoryTypes = {
  systemCategory: string;
  categorySettings: Settings;
  categoryMap: CategoryMap;
};

const Category = ({ systemCategory, categorySettings, categoryMap }: CategoryTypes) => {
  return (
    <div className="category-wrapper border-2 border-solid rounded-xl m-4 p-4 sm:px-6">
      {/* Category Header */}
      <div className="category-header divide-y-2 divide-y-reverse select-none">
        <h4 className="text-lg font-medium leading-6 text-gray-900">
          {categoryMap[systemCategory]}
        </h4>
      </div>

      {/* Setting fields */}
      {Object.keys(categorySettings).map((keypath) => {
        if (categorySettings[keypath].input_type === 'checkbox') {
          return (
            <BooleanSetting
              key={[systemCategory, keypath].join('__')}
              category={systemCategory}
              keypath={[systemCategory, keypath].join('__')}
              settingProperties={categorySettings[keypath]}
            />
          );
        } else if (categorySettings[keypath].input_type === 'text') {
          return (
            <TextSetting
              key={[systemCategory, keypath].join('__')}
              category={systemCategory}
              keypath={[systemCategory, keypath].join('__')}
              settingProperties={categorySettings[keypath]}
            />
          );
        } else if (categorySettings[keypath].input_type === 'dropdown') {
          return (
            <DropdownSetting
              key={[systemCategory, keypath].join('__')}
              keypath={[systemCategory, keypath].join('__')}
              settingProperties={categorySettings[keypath]}
            />
          );
        } else {
          // Unknown input type
        }
      })}
    </div>
  );
};

export default Category;
