import React, { useState } from 'react';

type BannerProps = {
  message: string;
  bgColor?: string;
  textColor?: string;
};
const PageBanner = ({
  message,
  bgColor = 'bg-red-3',
  textColor = 'text-neutral-6',
}: BannerProps) => {
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) {
    return null;
  }
  return (
    <div className={`${bgColor} flex items-center gap-x-6  px-6 py-2.5 sm:px-3.5 sm:before:flex-1`}>
      <p className={`text-sm leading-6 ${textColor}`}>{message}</p>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <i
            className={`nc-icon-glyph ui-1_bold-remove ${textColor}`}
            onClick={() => setIsOpen(false)}
          ></i>
        </button>
      </div>
    </div>
  );
};

export default PageBanner;
