import React, { useEffect, useState } from 'react';
import Modal from '../../shared/Modal';
import { usePhotosByGallery } from '../../../stores/usePhotoGalleryStore';

function SlideshowModal({ slideShowImage, setSlideShowImage, galleryId }) {
  const images = usePhotosByGallery(galleryId);
  const firstPhoto = images.find((image) => image.file_pk === slideShowImage.file_pk);
  const currentSlideIndex = images.indexOf(firstPhoto);
  const [currentSlide, setCurrentSlide] = useState(currentSlideIndex);

  useEffect(() => {
    setCurrentSlide(currentSlideIndex);
  }, [currentSlideIndex]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
  };

  if (currentSlide === -1 || !images[currentSlide]) {
    return null;
  }

  return (
    <Modal
      isOpen={Boolean(slideShowImage.id === galleryId && slideShowImage.show)}
      onSave={() => {}}
      setIsOpen={() => setSlideShowImage({ id: null, file_pk: null, show: false })}
      showButtons={false}
    >
      <div
        className="relative w-full h-full"
        data-carousel="static"
      >
        <div className="relative overflow-hidden rounded-lg">
          <img
            src={images[currentSlide].url}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '800px',
              maxWidth: '800px',
              objectFit: 'cover',
            }}
            alt=""
          />
          {images[currentSlide].description !== null && images[currentSlide].description !== '' && (
            <p className="text-md text-center text-neutral-500 overflow-hidden overflow-ellipsis max-w-[400px] mx-auto">
              {images[currentSlide].description}
            </p>
          )}
        </div>
        <button
          type="button"
          className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          onClick={(e) => {
            e.stopPropagation();
            prevSlide();
          }}
        >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white bg-opacity-30 dark:bg-gray-800 dark:bg-opacity-30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <i className="nc-icon-glyph arrows-1_bold-left text-lg leading-6 text-white" />
            <span className="sr-only">Previous</span>
          </span>
        </button>

        <button
          type="button"
          className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          onClick={(e) => {
            e.stopPropagation();
            nextSlide();
          }}
        >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white bg-opacity-30 dark:bg-gray-800 dark:bg-opacity-30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60  dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <i className="nc-icon-glyph arrows-1_bold-right text-lg leading-6 text-white" />
            <span className="sr-only">Next</span>
          </span>
        </button>
      </div>
    </Modal>
  );
}

export default SlideshowModal;
