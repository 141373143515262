import { HTTP_METHOD } from '../../../hooks/useFetch';

export const PORTALS_REQUEST_URL_PARAMS = {
  client: Portals.config.client,
  portal_name: Portals.config.portal_name,
};

export const handleCreateRequest = async (gallery_info, createGallery) => {
  const route = Routes.class_admin_photos_create_path;
  const body = {
    internal_class_id: gallery_info.course,
    gallery_name: gallery_info.galleryName,
  };
  const url = route({
    ...PORTALS_REQUEST_URL_PARAMS,
    internal_class_id: gallery_info.course,
  });
  return await createGallery({ url, method: HTTP_METHOD.post, body });
};

export const handleDeleteRequest = async (gallery_info, deleteGallery) => {
  const route = Routes.class_admin_photos_destroy_path;
  const url = route({
    ...PORTALS_REQUEST_URL_PARAMS,
    internal_class_id: gallery_info.internal_class_id,
    gallery_id: gallery_info.gallery_id,
  });
  return await deleteGallery({ url, method: HTTP_METHOD.delete });
};

export const handleGetRequest = async (id, getGalleries) => {
  const route = Routes.class_admin_photos_index_path;
  const url = route({
    ...PORTALS_REQUEST_URL_PARAMS,
    internal_class_id: id,
  });
  return await getGalleries({ url, method: HTTP_METHOD.get });
};

export const handleUpdateRequest = async (gallery_info, fieldName, value, updateGallery) => {
  const route = Routes.class_admin_photos_update_path;
  const url = route({
    ...PORTALS_REQUEST_URL_PARAMS,
    internal_class_id: gallery_info.internal_class_id,
    gallery_id: gallery_info.galleryId,
    gallery_name: value,
  });

  const data = {
    [fieldName]: value,
  };

  return await updateGallery({ url, method: HTTP_METHOD.put, ...data });
};

export const handleImageCreateRequest = async (photo_info, file_pks, createPhoto) => {
  const route = Routes.class_admin_photos_image_create_path;
  const url = route({
    ...PORTALS_REQUEST_URL_PARAMS,
    internal_class_id: photo_info.internalClassId,
    gallery_id: photo_info.galleryId,
  });
  const body = {
    record_fk: photo_info.galleryId,
    file_pks: file_pks,
  };
  return await createPhoto({ url, method: HTTP_METHOD.post, body });
};

export const handleImageDeleteRequest = async (photo_info, deletePhoto) => {
  const route = Routes.class_admin_photos_image_destroy_path;
  const url = route({
    ...PORTALS_REQUEST_URL_PARAMS,
    internal_class_id: photo_info.internal_class_id,
    gallery_id: photo_info.gallery_id,
    file_pk: photo_info.file_pk,
  });
  return await deletePhoto({ url, method: HTTP_METHOD.delete });
};

export const handleImageUpdateRequest = async (photo_info, fieldName, value, updatePhoto) => {
  const route = Routes.class_admin_photos_image_update_path;
  const url = route({
    ...PORTALS_REQUEST_URL_PARAMS,
    internal_class_id: photo_info.internal_class_id,
    gallery_id: photo_info.gallery_id,
    file_pk: photo_info.file_pk,
    description: value,
  });

  const data = {
    [fieldName]: value,
  };

  return await updatePhoto({ url, method: HTTP_METHOD.put, ...data });
};
