import React from 'react';
import { DATE_FORMAT, isAttendanceTaken } from './utils';
import classNames from 'classnames';

const DatePickerDay = ({
  date,
  hasScheduledDays,
  scheduledDaysHash,
  selectedDateIsTaken,
  today,
  day,
}) => {
  // inner container of calendar day square
  if (!hasScheduledDays) {
    return (
      <div
        className="attendance-day"
        data-testid="datePickerDay-square"
      >
        {day.format('D')}
      </div>
    );
  }

  const getStatusDescription = (day, dayString, isSelected) => {
    const scheduledDay = scheduledDaysHash[dayString];
    const isTaken = isSelected
      ? selectedDateIsTaken
      : scheduledDay && isAttendanceTaken(scheduledDay.attendance_status);

    let status;
    let tooltip;
    if (!scheduledDay) {
      status = 'disabled';
      tooltip = 'Not Scheduled';
    } else if (day.isAfter(moment(today), 'day')) {
      status = 'future';
    } else if (isTaken) {
      status = 'taken';
      tooltip = 'Taken';
    } else {
      status = 'not-taken';
      tooltip = 'Not Taken';
    }
    return { status, tooltip };
  };

  const dayString = day.format(DATE_FORMAT);
  const dayNumber = day.day();
  const isSelected = dayString === date.format(DATE_FORMAT);
  const description = getStatusDescription(day, dayString, isSelected);
  const defaultClasses = `attendance-day attendance-day--${description.status}`;
  const isTooltipLeftAligned = dayNumber > 3; // Wednesday is cutoff
  const classes = classNames(defaultClasses, {
    'attendance-day--selected': isSelected,
    'vx-tooltipped': description.tooltip,
    'vx-tooltipped--w': isTooltipLeftAligned,
    'vx-tooltipped--e': !isTooltipLeftAligned,
  });

  return (
    <div
      className={classes}
      aria-label={description.tooltip}
      data-testid="datePickerDay-square"
    >
      {day.format('D')}
    </div>
  );
};
export default DatePickerDay;
