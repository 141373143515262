import React from 'react';

import { DataField } from 'components/shared/form_elements';

export default function PersonEmploymentSection(props) {
  const {
    personName,
    employer,
    employerConfig,
    jobTitle,
    jobTitleConfig,
    industry,
    industryConfig,
    phoneBusiness,
    phoneBusinessConfig,
  } = props;

  return (
    <div className="person-employment-section">
      <div className="ae-grid">
        <div className="ae-grid__item item-lg-2">
          <div className="person-employment-section__person-name">{personName}</div>
        </div>
        <div className="ae-grid__item item-lg-3">
          <DataField
            value={employer}
            config={employerConfig}
          />
        </div>
        <div className="ae-grid__item item-lg-3">
          <DataField
            value={jobTitle}
            config={jobTitleConfig}
          />
        </div>
        <div className="ae-grid__item item-lg-2">
          <DataField
            value={industry}
            config={industryConfig}
          />
        </div>
        <div className="ae-grid__item item-lg-2">
          <DataField
            value={phoneBusiness}
            config={phoneBusinessConfig}
          />
        </div>
      </div>
    </div>
  );
}
