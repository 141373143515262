import React from 'react';
import Field from '../Field';

export default function TextAreaField(props) {
  const { name, type, value, error, onChange, config } = props;

  const textareaRef = React.useRef();

  const handleLabelClick = () => {
    textareaRef.current.focus();
  };

  return (
    <Field
      className="vx-text-area"
      onLabelClick={handleLabelClick}
      {...props}
    >
      <textarea
        className="vx-text-area__input"
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={config.placeholder}
        required={config.required}
        disabled={config.disabled}
        maxLength={config.maxLength}
        ref={textareaRef}
      />
    </Field>
  );
}
