import React from 'react';

const AnnotationLink = ({ isAnnotated, url }) => {
  if (isAnnotated) {
    return (
      <a
        className="vx-button vx-button--small annotation-link"
        href={`${url}`}
      >
        View Feedback
      </a>
    );
  } else {
    return null;
  }
};

const File = ({ file }) => {
  return (
    <div className="ae-grid-item item-xs-12">
      <div className="file-download">
        <i className="nc-icon-glyph files_single-folded-content"></i>
        <a href={`${file.file_url}`}>{file.filename}</a>
      </div>
      <div className="file-links">
        <AnnotationLink
          isAnnotated={file.annotated}
          url={file.file_annotation_url}
        />
      </div>
    </div>
  );
};

export default File;
