import classNames from 'classnames';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useUpdateGradeRecord } from '../mutations';
import { useConfigurations } from '../../../../stores/useGradebookStore';
import { Grade } from '../types';
import { getErrorMessage } from '../helpers';
import {
  ERROR_TIMEOUT,
  FIELD_HEIGHT,
  MAX_DESCRIPTION,
  NOTIFY_DURATION,
  STATUS_THAT_REMOVES_GRADES,
} from '../constants';
import { getFloatDisplay } from '../helpers';
import * as Yup from 'yup';
import { useGridData } from '../queries';
import { useUserState } from '../../../../stores/useAppStore';
import { useAssignment } from '../queries';

const schema = Yup.object().shape({
  grade: Yup.number(),
});

type AssignmentGradeProps = {
  studentId: number;
  record: Partial<Grade>;
};
const AssignmentGrade = ({ studentId, record }: AssignmentGradeProps) => {
  const { data } = useGridData();
  const updateGrade = useUpdateGradeRecord(studentId, 'raw_score');
  const isStatusWithoutGrade = STATUS_THAT_REMOVES_GRADES.has(record.completion_status);
  const [isNotifying, setIsNotifying] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const elementId = `grade-input-${studentId}`;
  const assignment = useAssignment();
  const { enable_keyboard_shortcuts: enableKeyboardShortcuts } = useConfigurations();
  const { focusedStudentRow, setFocusedStudentRow, isFocusingInput } = useUserState();
  const rowIndex = data.students.findIndex((student) => student.person_pk === studentId);
  const isFocusedRow = focusedStudentRow === rowIndex;
  const inputRef = useRef<HTMLInputElement>(null);

  const getAssignmentGrade = (grade: string) => {
    return isStatusWithoutGrade ? '' : getFloatDisplay(grade);
  };
  const assignmentGrade = getAssignmentGrade(record.score);
  const [editableGrade, setEditableGrade] = useState(assignmentGrade);

  const highlightText = () => {
    inputRef.current?.setSelectionRange(0, MAX_DESCRIPTION);
    requestAnimationFrame(() => {
      // wait for the input focus to be set before selecting the text
      inputRef.current?.setSelectionRange(0, MAX_DESCRIPTION);
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      if (isFocusedRow && isFocusingInput) {
        inputRef.current.focus();
        highlightText();
      } else {
        inputRef.current.blur();
      }
    }
  }, [focusedStudentRow]);

  useEffect(() => {
    setEditableGrade(assignmentGrade);
  }, [record]);

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!enableKeyboardShortcuts) return;

    // prevent the default behavior of the key if it's a keyboard shortcut
    if (
      data.assignment_statuses.some(
        (status) => status.keyboard_shortcut?.toLowerCase() === e.key.toLowerCase()
      )
    ) {
      e.preventDefault();
    }
  };

  const validate = async (grade) => {
    const valid = await schema.isValid({ grade });
    if (grade !== '' && !valid) {
      setErrorMessage('Must be a number');
      setTimeout(() => {
        setErrorMessage(null);
      }, ERROR_TIMEOUT);
      return false;
    }
    setErrorMessage(null);
    return true;
  };

  const saveGrade = async () => {
    let grade = editableGrade.trim();

    // if the grade is empty or zero and the assignment is pending, don't save because nothing changed
    if (isStatusWithoutGrade && (parseInt(grade) === 0 || grade === '')) {
      setEditableGrade('');
      return;
    }

    // if the input is empty, save it as zero
    grade = grade === '' ? '0' : grade;

    if (assignmentGrade === grade) {
      return;
    }

    const isValid = await validate(grade);
    if (!isValid) {
      return;
    }

    try {
      const response = await updateGrade.mutateAsync(parseFloat(grade));
      const gradeInResponse = response.data.updated_object.raw_score;
      if (
        gradeInResponse &&
        parseFloat(gradeInResponse).toFixed(2) !== parseFloat(grade).toFixed(2)
      ) {
        const displayGrade = getAssignmentGrade(gradeInResponse);
        setEditableGrade(displayGrade);
        throw new Error();
      }

      setErrorMessage(null);
      setIsNotifying(true);
      setTimeout(() => {
        setIsNotifying(false);
      }, NOTIFY_DURATION);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
      setTimeout(() => {
        setErrorMessage(null);
      }, ERROR_TIMEOUT);
    }
  };

  const handleInputChange = (e) => {
    const newGrade = e.target.value;
    validate(newGrade);
    setEditableGrade(newGrade);
  };

  const inputClasses = classNames(`w-12 h-[${FIELD_HEIGHT}] rounded-sm outline-none`, {
    'ring-offset ring-2 ring-green-4': isNotifying,
    'shadow-none': !isNotifying && !errorMessage,
    'ring-offset ring-2 ring-red-4': errorMessage,
  });

  return (
    <>
      <div className="flex items-center text-neutral-3">
        <input
          value={editableGrade.toString()}
          onClick={() => setFocusedStudentRow(rowIndex)}
          onChange={handleInputChange}
          onBlur={saveGrade}
          autoFocus={isFocusedRow}
          id={elementId}
          className={inputClasses}
          data-testid="assignment-grade"
          autoComplete="off"
          onKeyDown={handleKeyPress}
          ref={inputRef}
        ></input>
        <div className="ml-1">/{assignment?.maximum_score || ''}</div>
      </div>
      {errorMessage && <div className="text-red-3">{errorMessage}</div>}
    </>
  );
};

export default AssignmentGrade;
