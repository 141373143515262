import React, { useState } from 'react';
import { Properties } from '../types';
import classNames from 'classnames';
import { useUpdateConfiguration } from '../mutations';
import { NOTIFY_DURATION_MS, ROW_HOVER_COLOR } from '../constants';
import { Field, useField } from 'formik';

type DropdownSettingTypes = {
  keypath: string;
  settingProperties: Properties;
};
const DropdownSetting = ({ keypath, settingProperties }: DropdownSettingTypes) => {
  // State Logic
  const [field, meta, helpers] = useField(keypath);
  const [loading, setLoading] = useState(false);
  const [isSavedNotification, setIsSavedNotification] = useState(false);
  const isEditable = settingProperties.teacher_access_strategy === 'editable';

  const saveConfiguration = useUpdateConfiguration(
    () => {},
    () => {}
  );

  const saveConfigurationRequest = async (new_value) => {
    await saveConfiguration.mutateAsync({
      db_source: settingProperties.db_source,
      configuration_target: settingProperties.target,
      new_value: new_value,
    });
  };

  const handleChange = async (event) => {
    event.persist();
    if (loading) return;
    if (field.value === event.target.value) return;

    try {
      setLoading(true);
      helpers.setValue(event.target.value);
      await saveConfigurationRequest(event.target.value);
    } finally {
      setIsSavedNotification(true);
      setTimeout(() => {
        setIsSavedNotification(false);
      }, NOTIFY_DURATION_MS);
      setLoading(false);
    }
  };

  // Style Logic
  const rowClasses = classNames(
    `px-5 py-1 text-sm font-medium hover:${ROW_HOVER_COLOR} sm:flex items-center`,
    {
      'text-gray-900': isEditable,
      'text-gray-500 cursor-not-allowed': !isEditable,
    }
  );

  const selectLabelClasses = classNames('inline-block w-full leading-6 select-none sm:flex-1', {
    'cursor-not-allowed': !isEditable,
  });

  const selectClasses = classNames(
    'my-2 w-11/12 rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:flex-1 sm:mr-8 transition ease-out duration-700',
    {
      'cursor-not-allowed': !isEditable,
      'cursor-wait select-none': loading,
      'ring-4 ring-green-300 focus:ring-4 focus:ring-green-300': isSavedNotification,
    }
  );

  return (
    <div className={rowClasses}>
      <label
        htmlFor={keypath}
        className={selectLabelClasses}
      >
        {settingProperties.description}
      </label>
      <Field
        as="select"
        id={keypath}
        name={keypath}
        className={selectClasses}
        onChange={handleChange}
      >
        {Object.entries(settingProperties.dropdown_options).map(([k, v]) => {
          return (
            <option
              key={k}
              value={k}
            >
              {v}
            </option>
          );
        })}
      </Field>
    </div>
  );
};

export default DropdownSetting;
