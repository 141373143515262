import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { useUpdateGradeRecord } from '../mutations';
import { Grade, GridData } from '../types';
import StatusDescription from './StatusDescription';
import DropdownIndicator from '../common/DropdownIndicator';
import {
  COMPLETION_STATUS,
  ERROR_TIMEOUT,
  NOTIFY_DURATION,
  REMOVE_GRADES_CONFIRMATION,
  STATUS_THAT_REMOVES_GRADES,
} from '../constants';
import { getErrorMessage } from '../helpers';
import Modal from '../../../shared/Modal';
import { useScreenSizes, useUserState } from '../../../../stores/useAppStore';
import { useGridData } from '../queries';
import { useConfigurations } from '../../../../stores/useGradebookStore';
import DropdownOptionWithShortcut from '../../../shared/dropdown/DropdownOptionWithShortcut';

type AssignmentStatusDropdownProps = {
  studentId: number;
  field: string;
  statuses: GridData['assignment_statuses'];
  record: Partial<Grade>;
};
const AssignmentStatusDropdown = ({
  studentId,
  field,
  statuses,
  record,
}: AssignmentStatusDropdownProps) => {
  const [isNotifying, setIsNotifying] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusToConfirm, setStatusToConfirm] = useState<number>(null);
  const {
    shortcutTrigger,
    focusedStudentRow,
    setShortcutTrigger,
    setFocusedStudentRow,
    moveFocusedStudentRow,
  } = useUserState();
  const { data } = useGridData();
  const rowIndex = data.students.findIndex((student) => student.person_pk === studentId);
  const { enable_keyboard_shortcuts: enableKeyboardShortcuts } = useConfigurations();
  const { isMd: isMobile } = useScreenSizes();

  const fieldToSave = field === 'dropbox_status_int' ? 'dropbox_status' : field;
  const updateAssignmentStatus = useUpdateGradeRecord(studentId, fieldToSave);
  const [isLoading, setIsLoading] = useState(false);

  let options = [];
  const selectedStatus = record ? record[field] : '';
  let selectedOption = null;
  options = statuses.map((status) => {
    const option: { value: number; label: string | JSX.Element } = {
      value: status.id,
      label: status.description,
    };
    if (status.id === selectedStatus) {
      selectedOption = {};
      selectedOption.value = status.id;
      selectedOption.label = <StatusDescription description={status.description} />;
    } else if (enableKeyboardShortcuts && status.keyboard_shortcut && !isMobile) {
      option.label = (
        <DropdownOptionWithShortcut
          description={status.description}
          shortcut={status.keyboard_shortcut}
        />
      );
    }
    return option;
  });

  useEffect(() => {
    if (focusedStudentRow === rowIndex && shortcutTrigger?.type === COMPLETION_STATUS) {
      handleOnChange(shortcutTrigger.id);
      moveFocusedStudentRow(data.students.length, 1);
      setShortcutTrigger(null);
    }
  }, [shortcutTrigger]);

  const handleOnChange = async (newStatus: number, wasConfirmed = false) => {
    if (newStatus === selectedStatus) {
      return;
    }

    if (
      !wasConfirmed &&
      field === 'completion_status' &&
      STATUS_THAT_REMOVES_GRADES.has(newStatus) &&
      parseInt(record.score) !== 0
    ) {
      setIsModalOpen(true);
      setStatusToConfirm(newStatus);
      return;
    }

    setIsLoading(true);
    try {
      const response = await updateAssignmentStatus.mutateAsync(newStatus);
      if (response.data.updated_object[field] !== newStatus) {
        throw new Error();
      }
      setIsNotifying(true);
      setTimeout(() => {
        setIsNotifying(false);
      }, NOTIFY_DURATION);
    } catch (error) {
      setError(getErrorMessage(error));

      setTimeout(() => {
        setError(null);
      }, ERROR_TIMEOUT);
    } finally {
      setIsLoading(false);
    }
  };

  const borderRing = isNotifying ? 'ring-offset ring-2 ring-green-4 rounded' : 'shadow-none';

  return (
    <>
      <div
        className={`w-[87%] ${borderRing}`}
        data-testid="assignment-status-dropdown-wrapper"
      >
        <ReactSelect
          value={selectedOption}
          options={options}
          styles={{
            valueContainer: (styles) => ({ ...styles, paddingLeft: '4px' }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            control: (styles) => ({
              ...styles,
              borderColor: '#a6a6a6',
              boxShadow: 0,
              '&:hover': {
                border: `1px solid #a6a6a6`,
              },
            }),
          }}
          onChange={(selection) => handleOnChange(selection.value)}
          maxMenuHeight={242}
          minMenuHeight={242}
          menuPlacement="auto"
          onMenuOpen={() => {
            setFocusedStudentRow(rowIndex, false);
          }}
          isSearchable={false}
          isLoading={isLoading}
          components={{ DropdownIndicator }}
        />
      </div>
      {error && <div className="text-red-3">{error}</div>}
      <Modal
        isOpen={isModalOpen}
        onSave={() => {
          setIsModalOpen(false);
          handleOnChange(statusToConfirm, true);
        }}
        label="Confirm"
        overrideClasses="bg-red-3 hover:bg-red-4"
        setIsOpen={setIsModalOpen}
      >
        <div className="text-red-3 text-lg">{REMOVE_GRADES_CONFIRMATION}</div>
      </Modal>
    </>
  );
};

export default AssignmentStatusDropdown;
