import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NewAssignmentPage from '../assignment-detail/NewAssignmentPage';
import AssignmentPage from './AssignmentPage';
import SecondaryHeader from './SecondaryHeader';
import WeightingPage from '../weighting/WeightingPage';
import { NEW_ASSIGNMENT_PATH, WEIGHTING_PATH } from '../constants';

const SecondaryPage = () => {
  return (
    <div className="h-fit md:h-full max-md:bg-white md:overflow-x-hidden">
      <SecondaryHeader />
      <Routes>
        <Route
          path={WEIGHTING_PATH}
          element={<WeightingPage />}
        />
        <Route
          path={NEW_ASSIGNMENT_PATH}
          element={<NewAssignmentPage />}
        />
        <Route
          path={`:assignmentId/*`}
          element={<AssignmentPage />}
        />
      </Routes>
    </div>
  );
};

export default SecondaryPage;
