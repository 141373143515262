import React, { FC } from 'react';
import classNames from 'classnames';

type HeaderCellProps = {
  classOverrides?: string;
};
const HeaderCell: FC<HeaderCellProps> = ({ classOverrides, children }) => {
  const classes = classNames(
    'flex flex-col rounded-md text-black mr-0.5 mb-0.5 h-28 text-left p-2 pb-3',
    {
      [classOverrides]: classOverrides,
    }
  );

  return (
    <th
      className="sticky top-0 z-30 bg-white pt-5"
      data-testid="header-cell"
    >
      <div className={classes}>{children}</div>
    </th>
  );
};

export default HeaderCell;
