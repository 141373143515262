import React from 'react';
import classNames from 'classnames';

import Field from './Field';

const defaultConfig = {
  options: [],
  disabled: false,
  optionLabel: 'description',
  optionValue: 'id',
  optionsGroupBy: undefined,
};

export default function SelectField(props) {
  const { name, type, value, error, onChange } = props;

  const config = { ...defaultConfig, ...props.config };

  const isBlank = !value;
  const isDisabled = props.disabled || config.disabled;
  const hasGroupedOptions = !!config.optionsGroupBy;

  const selectRef = React.useRef();
  const handleLabelClick = () => selectRef.current.focus();

  const fieldClassNames = classNames({
    'vx-form-field': true,
    'vx-form-field--blank': isBlank,
  });

  const PlaceholderOption = config.placeholder && <option value="">{config.placeholder}</option>;

  const renderOptions = (options) => {
    return (
      options &&
      options.map((option) => (
        <option
          key={option[config.optionValue]}
          value={option[config.optionValue]}
          disabled={option.disabled}
        >
          {option[config.optionLabel]}
        </option>
      ))
    );
  };

  // TODO: Clean-up
  const renderGroupedOptions = (options, optionsGroupBy) => {
    if (!options) return null;

    const optionsWithoutGroup = options.filter((option) => !option[optionsGroupBy]);
    const GrouplessOptions = renderOptions(optionsWithoutGroup);

    const optionsWithGroup = options.filter((option) => option[optionsGroupBy]);
    const groupedOptions = _.groupBy(optionsWithGroup, optionsGroupBy);
    const GroupedOptions = Object.entries(groupedOptions).map(([group, groupOptions]) => (
      <optgroup
        key={group}
        label={group}
      >
        {renderOptions(groupOptions)}
      </optgroup>
    ));

    return [GrouplessOptions, GroupedOptions];
  };

  const FieldValueOptions = hasGroupedOptions
    ? renderGroupedOptions(config.options, config.optionsGroupBy)
    : renderOptions(config.options);

  return (
    <Field
      className="vx-select"
      onLabelClick={handleLabelClick}
      {...props}
    >
      <select
        className="vx-select__input"
        name={name}
        value={value || ''}
        onChange={onChange}
        disabled={isDisabled}
        required={config.required}
        placeholder={config.placeholder}
        ref={selectRef}
      >
        {PlaceholderOption}
        {FieldValueOptions}
      </select>
    </Field>
  );
}
