import React, { useState } from 'react';
import usePhotoGalleryStore from '../../../stores/usePhotoGalleryStore';
import Modal from '../../shared/Modal';
import { useFetch } from '../../../hooks/useFetch';
import { handleImageDeleteRequest } from './Endpoints';

function ImageDeleteModal({ internalClassId, galleryId, photo }) {
  const [deleteConfirmation, setDeleteConfirmation] = useState({ id: null, show: false });
  const [errorMessage, setErrorMessage] = useState(null);
  const { data, sendFetchRequest } = useFetch();
  const { actions } = usePhotoGalleryStore();

  const handleDelete = async (currentPhotoId) => {
    if (deleteConfirmation.id === currentPhotoId && deleteConfirmation.show) {
      try {
        const requestFormData = {
          internal_class_id: internalClassId,
          gallery_id: galleryId,
          file_pk: deleteConfirmation.id,
        };
        await handleImageDeleteRequest(requestFormData, sendFetchRequest);
      } catch (error) {
        setErrorMessage('Something went wrong while deleting the image. Please try again.');
      }
      actions.removePhoto(deleteConfirmation.id);
      setDeleteConfirmation({ id: null, show: false });
    }
  };
  return (
    <div>
      <button
        onClick={() => setDeleteConfirmation({ id: photo.file_pk, show: true })}
        className="py-1 px-2"
      >
        <i className="nc-icon-glyph ui-1_trash text-med leading-7 pt-1 text-sky-700" />
      </button>

      <Modal
        isOpen={Boolean(deleteConfirmation.id === photo.file_pk && deleteConfirmation.show)}
        onSave={() => handleDelete(photo.file_pk)}
        overrideClasses="bg-blue-3 hover:bg-blue-4"
        label="Yes, Delete"
        setIsOpen={() => setDeleteConfirmation({ id: null, show: false })}
      >
        <h2 className="text-lg font-bold text-sky-700">Delete Photo</h2>
        <label className="block text-sm font-bold text-gray-700">PHOTO</label>
        <div style={{ width: '100px', height: '100px' }}>
          <img
            src={photo.url}
            alt={photo.name}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>
        <p className="mb-2">
          Are you sure you want to delete this photo?{' '}
          <strong>This operation cannot be undone!</strong>
        </p>
      </Modal>
      <Modal
        isOpen={Boolean(errorMessage)}
        onSave={() => {}}
        setIsOpen={() => setErrorMessage(null)}
        showButtons={false}
      >
        <h2 className="text-lg font-bold text-sky-700">Error</h2>
        <p className="mb-2">{errorMessage}</p>
      </Modal>
    </div>
  );
}

export default ImageDeleteModal;
