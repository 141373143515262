import React from 'react';
import classNames from 'classnames';

import DropdownMenuItem from 'components/shared/dropdown/DropdownMenuItem';

const defaultProps = {
  triggerButtonClassNames: 'vx-button',
  triggerButtonLabel: undefined,
  triggerButtonIcon: undefined,
  blurTimeDelay: 300,
};

// prettier-ignore
function Dropdown(props) {
  const {
    id,
    triggerButtonClassNames,
    triggerButtonLabel,
    triggerButtonIcon,
    items,
    blurTimeDelay,
  } = { ...defaultProps, ...props };

  const [isActive, setIsActive] = React.useState(false);
  const toggleIsActive = () => setIsActive(prevIsActive => !prevIsActive);

  const hasItems = items?.length > 0;

  /**************************************************************************************************/

  const handleClick = React.useCallback((event) => {
    event.preventDefault();
    event.target.focus();

    toggleIsActive();
  }, []);

  const handleBlur = React.useCallback((event) => {
    event.preventDefault();

    if (isActive) {
      setTimeout(() => setIsActive(false), blurTimeDelay);
    }
  }, [isActive, blurTimeDelay]);

  /**************************************************************************************************/

  const triggerButtonIconClassNames = classNames(['nc-icon-glyph', triggerButtonIcon]);

  const rootClassNames = classNames(['dropdown'], {
    'is-active': isActive,
  });

  return (
    <div
      className={rootClassNames}
      onClick={handleClick}
      onBlur={handleBlur}
    >
      <div className="dropdown-trigger">
        <button
          className={triggerButtonClassNames}
          aria-haspopup="true"
          aria-controls={id}
        >
          {triggerButtonIcon && (
            <i className={triggerButtonIconClassNames} />
          )}
          {triggerButtonLabel}
        </button>
      </div>
      {hasItems && (
        <div
          className="dropdown-menu"
          role="menu"
          id={id}
        >
          <div className="dropdown-menu-content">
            {items.map((item, index) => {
              switch (item.type) {
                case 'item':
                  return (
                    <DropdownMenuItem
                      key={index}
                      {...item}
                    />
                  );

                case 'divider':
                  return (
                    <hr
                      key={index}
                      className="dropdown-menu-divider"
                    />
                  );

                default:
                  return null;
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(Dropdown);
