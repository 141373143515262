import React, { useEffect, useState } from 'react';
import Modal from '../../../shared/Modal';
import { useFetch } from '../../../../hooks/useFetch';
import { handleDeleteRequest } from '../resources';
import { DEFAULT_ERROR_MESSAGE } from '../../gradebook/constants';
import { CLOSE_MODAL_ANIMATION_DURATION } from '../constants';
import classNames from 'classnames';

type DeleteCourseRequestModalProps = {
  modalRemovingCard: number | null;
  setModalRemovingCard: (value: number | null) => void;
  refetchList: () => void;
};

const DeleteCourseRequestModal = ({
  modalRemovingCard,
  setModalRemovingCard,
  refetchList,
}: DeleteCourseRequestModalProps) => {
  const [deleteModalError, setDeleteModalError] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    data: removalResponse,
    sendFetchRequest: deleteCourseRequest,
    error: removalErrorResponse,
  } = useFetch();

  const handleRemoveCard = async (requestId: number) => {
    setDeleteModalError(null);
    setIsLoading(true);
    try {
      await handleDeleteRequest(requestId, deleteCourseRequest);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (removalResponse?.data?.deleted_id) {
      setModalRemovingCard(null);
      refetchList();
    }
  }, [removalResponse]);

  useEffect(() => {
    if (removalErrorResponse) {
      setDeleteModalError(removalErrorResponse?.message || DEFAULT_ERROR_MESSAGE);
    }
  }, [removalErrorResponse]);

  useEffect(() => {
    if (!modalRemovingCard) {
      setTimeout(() => {
        setDeleteModalError(null);
      }, CLOSE_MODAL_ANIMATION_DURATION);
    }
  }, [modalRemovingCard]);

  const modalClasses = classNames(``, {
    'hover:bg-red-4': !isLoading && !deleteModalError,
    'bg-red-3': !isLoading,
    'bg-red-4': isLoading,
  });

  return (
    <Modal
      isOpen={Boolean(modalRemovingCard)}
      onSave={() => handleRemoveCard(modalRemovingCard)}
      setIsOpen={(value) => setModalRemovingCard(value ? 1 : 0)}
      overrideClasses={modalClasses}
      label="Delete"
      isSubmitDisabled={isLoading || Boolean(deleteModalError)}
    >
      <>
        <div
          className="text-lg"
          id="delete-modal"
        >
          Are you sure you want to delete this course request?
        </div>
        {deleteModalError && <div className="text-red-3 mt-4">{deleteModalError}</div>}
      </>
    </Modal>
  );
};

export default DeleteCourseRequestModal;
