import React, { FC, ReactNode, useEffect, useState } from 'react';
import FieldLabel from '../assignment-detail/FieldLabel';
import { Field, useField } from 'formik';
import { useUpdateAssignment } from '../mutations';
import { useParams } from 'react-router-dom';
import { DEFAULT_ERROR_MESSAGE, NOTIFY_DURATION } from '../constants';
import { getErrorMessage } from '../helpers';
import { useAssignment } from '../queries';

type FormElementProps = {
  label: string;
  attribute: string;
  component: ReactNode;
  tooltipInfo?: string;
  extraProps?: Record<any, any>;
};
const FormElement: FC<FormElementProps> = ({
  label,
  attribute,
  component,
  children,
  tooltipInfo,
  extraProps = {},
}) => {
  const [_field, meta, helpers] = useField(attribute);
  const { value } = meta;
  const { setValue, setTouched } = helpers;
  const { assignmentId } = useParams();
  const [updateError, setUpdateError] = useState(null);
  const updateAssignment = useUpdateAssignment(parseInt(assignmentId));
  const [timeoutInt, setTimeoutInt] = useState<ReturnType<typeof setTimeout>>(null);
  const [unsavedChanges, setUnsavedChanges] = useState(null);
  const [isSuccessNotifying, setIsSuccessNotifying] = useState(false);
  const assignment = useAssignment();

  useEffect(() => {
    window.onbeforeunload = () => {
      if (unsavedChanges) {
        saveField(unsavedChanges);
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [unsavedChanges]);

  const showError = (errorMessage) => {
    setUpdateError(getErrorMessage(errorMessage));

    clearTimeout(timeoutInt);
    const timeout = setTimeout(() => {
      setUpdateError(null);
    }, NOTIFY_DURATION);
    setTimeoutInt(timeout);
  };

  const saveField = async (newValue) => {
    try {
      const response = await updateAssignment.mutateAsync({ field: attribute, value: newValue });
      if (response.data.updated_object) {
        // Update the formik field with the new value from the database.
        // Even if the response is successful, it doesn't mean the value was updated in the database.
        setValue(response.data.updated_object[attribute]);

        // for datetime values the database updates are different from the values we send
        // but for the rest of the values, we can just check if the value is the same as the new value
        const attributesThatChangeInDatabase = ['assignment_date', 'due_date'];
        if (
          attributesThatChangeInDatabase.includes(attribute) ||
          response.data.updated_object[attribute] === newValue
        ) {
          setIsSuccessNotifying(true);
          setTimeout(() => {
            setIsSuccessNotifying(false);
          }, NOTIFY_DURATION);
        } else {
          showError(DEFAULT_ERROR_MESSAGE);
        }
      }
      setUnsavedChanges(null);
    } catch (error) {
      showError(error);
      // If the update fails, we need to revert the formik field back to the original value.
      setValue(assignment[attribute]);
    }
  };

  const handleChange = async (
    newValue,
    shouldSaveToDatabase = true,
    isChangeToRedactorField = false
  ) => {
    setUnsavedChanges(newValue); // Unsaved changes that we should save before exiting page.

    if (isChangeToRedactorField) {
      // This is a special case to handle redactor textarea changes specifically.
      // Our current version of Redactor doesn't work correctly with Formik.
      // Saving to Formik when onChange triggers, causes problems. So we only save onBlur.

      // The only thing we needed to do was record the unsaved change, so we exit here
      return;
    }

    setTouched(true);
    const errors = await setValue(newValue); // save to formik

    if (errors?.[attribute]) {
      setUnsavedChanges(null);
      return;
    }
    if (!shouldSaveToDatabase) {
      // If we entered this block, then we are just saving the Formik field,
      // to display the new field value in the UI, but we are not saving to the database.
      // This case happens for the following reasons:

      // 1. shouldSaveToDatabase is passed in as false: This parameter is to handle when the input fields
      //    are being typed into but the onBlur hasn't triggered yet.
      // 2: If isUpdate form is false: This means we are in the 'New Assignment Page' and don't want to update
      //    fields, only saves when the form is submitted.
      return;
    }

    await saveField(newValue);
  };

  const isFormikError = meta.touched && meta.error;

  return (
    <div className="relative">
      <FieldLabel
        label={label}
        tooltipInfo={tooltipInfo}
      />
      <Field
        value={value}
        as={component}
        onChange={handleChange}
        isSuccessNotifying={isSuccessNotifying}
        {...extraProps}
      />
      {children}
      {isFormikError ? <div className="text-red-3 mt-0.5">{meta.error}</div> : null}
      {updateError && !isFormikError ? (
        <div className="text-red-3 mt-0.5">{updateError}</div>
      ) : null}
    </div>
  );
};

export default FormElement;
