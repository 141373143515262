import * as Yup from 'yup';

const yupLabel = Yup.string().max(20, 'Labels should be no more than 20 characters.');

const yupPercent = Yup.number()
  .typeError('Must be an integer greater than or equal to 0.')
  .integer('Must be an integer greater than or equal to 0.')
  .min(0, 'Must be an integer greater than or equal to 0.')
  .max(99999, 'Max value is 99999.');

const gradebookColumnHeader = Yup.string().max(60, 'Max length is 60');

export const SettingsValidation = Yup.object().shape({
  // LMS Configuration Validation
  students__students_page_label: yupLabel,
  attendance__attendance_page_label: yupLabel,
  gradebook__gradebook_label: yupLabel,
  gradebook__calculated_grade_label: gradebookColumnHeader,
  gradebook__predicted_grade_label: gradebookColumnHeader,
  gradebook__school_defined_grade_label: gradebookColumnHeader,

  report_cards__grades_comments_page_label: yupLabel,
  notifications__notifications_label: yupLabel,
  calendar__calendar_lessons_page_label: yupLabel,
  website__website_label: yupLabel,
  photos__photos_page_label: yupLabel,
  settings__settings_page_label: yupLabel,

  // Class field Validation
  grading__exam_weight: yupPercent,
  grading__numeric_weight: yupPercent,
  external_links__virtual_meeting_url: Yup.string().max(2000, 'Max length is 2000'),
  external_links__url_1_description: Yup.string().max(50, 'Max length is 50'),
  external_links__url_1: Yup.string().max(300, 'Max length is 300'),
  external_links__url_2_description: Yup.string().max(50, 'Max length is 50'),
  external_links__url_2: Yup.string().max(300, 'Max length is 300'),
});
