import React, { useEffect } from 'react';

import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { useClassState } from '../../../../stores/useGradebookStore';
import { AssignmentFormFieldProps } from '../types';
import DatePickerDay from './DatePickerDay';
import { useGradingPeriods } from '../queries';
import Modal from '../../../shared/Modal';
import { isGradingPeriodChange } from '../helpers';

const DATE_FORMAT_DISPLAY = 'MMMM DD, YYYY';
const DATE_FORMAT_DATA = 'YYYY-MM-DD';

type DatePickerProps = {
  currentGradingPeriod: number;
  showGPChangeConfirmModal?: boolean;
};
const DatePicker = ({
  value,
  onChange,
  isSuccessNotifying,
  showGPChangeConfirmModal,
  currentGradingPeriod,
}: AssignmentFormFieldProps & DatePickerProps) => {
  const [focused, setFocused] = React.useState(false);
  const [modalConfirmDate, setModalConfirmDate] = React.useState<moment.Moment>(null);
  const [isSavingConfirm, setIsSavingConfirm] = React.useState(false);
  const { startDate, endDate } = useClassState();
  const { data: gradingPeriods } = useGradingPeriods();
  // 'value' is stored as a datetime object in the database, but we only want to display the date
  // so we need to tell moment to parse the date as UTC so that it doesn't convert it to the local date
  const dateSelection =
    value && moment.utc(value.toString()).isValid() ? moment.utc(value.toString()) : '';

  const handleDateChange = async (date: moment.Moment, hasConfirmed = false) => {
    setModalConfirmDate(null);
    if (isSavingConfirm) return;

    if (date?.isValid()) {
      if (
        !hasConfirmed &&
        showGPChangeConfirmModal &&
        isGradingPeriodChange(date, currentGradingPeriod, gradingPeriods)
      ) {
        setModalConfirmDate(date);
        return;
      }
      setIsSavingConfirm(true);
      await onChange(date.format(DATE_FORMAT_DATA));
      setIsSavingConfirm(false);
    }
  };

  const handleConfirm = () => {
    handleDateChange(modalConfirmDate, true);
  };

  const isOutsideRange = (day) => {
    if (!startDate || !endDate) {
      return false;
    }
    const st = startDate.slice(0, 10);
    const en = endDate.slice(0, 10);
    const dayStart = day.startOf('day');
    const begin = moment(st).endOf('day').format(DATE_FORMAT_DATA);
    const end = moment(en).endOf('day').format(DATE_FORMAT_DATA);
    return dayStart.isBefore(begin) || dayStart.isAfter(end);
  };

  const onFocusChange = ({ focused }) => {
    // This check is a workaround for the issue where the datepicker opens automatically after the modal closes
    if (!modalConfirmDate && !isSavingConfirm) {
      setFocused(focused);
    }
  };

  const borderRing = isSuccessNotifying
    ? '[&_.DateInput_input]:ring-offset [&_.DateInput_input]:ring-2 [&_.DateInput_input]:ring-green-4'
    : '[&_.DateInput_input]:shadow-none';
  return (
    <>
      <div
        className={`${borderRing} relative`}
        onClick={() => setFocused(true)}
        data-testid="assignment-datepicker"
      >
        <SingleDatePicker
          date={dateSelection}
          placeholder={'Select a date'}
          isOutsideRange={isOutsideRange}
          focused={focused}
          onFocusChange={onFocusChange}
          onDateChange={handleDateChange}
          numberOfMonths={1}
          noBorder
          displayFormat={DATE_FORMAT_DISPLAY}
          readOnly
          renderDayContents={(day) => {
            return (
              <DatePickerDay
                day={day}
                date={dateSelection as moment.Moment}
                gradingPeriods={gradingPeriods}
              />
            );
          }}
        />
        <i className="nc-icon-outline ui-1_calendar-grid-61 absolute leading-9 right-2.5 text-4"></i>
      </div>
      <Modal
        isOpen={Boolean(modalConfirmDate)}
        setIsOpen={(open) => setModalConfirmDate(open ? modalConfirmDate : null)}
        onSave={handleConfirm}
        isSubmitDisabled={isSavingConfirm}
        overrideClasses="bg-red-3 hover:bg-red-4"
        label="Confirm"
      >
        <>
          <div className="text-lg">
            Are you sure you want to change the grading period for this assignment?
          </div>
        </>
      </Modal>
    </>
  );
};
export default DatePicker;
