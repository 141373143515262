import React from 'react';
import AssignmentGrade from './AssignmentGrade';
import AssignmentStatusDropdown from './AssignmentStatusDropdown';
import { useGridData, useStudentGrade } from '../queries';
import { Student, StudentSidebarTab } from '../types';
import FieldLabel from '../assignment-detail/FieldLabel';
import { GradingMethodDescription } from '../constants';
import LetterGrade from './LetterGrade';
import PublishGradesToggle from './PublishGradesToggle';
import { useAssignment } from '../queries';

type StudentGradesProps = {
  student: Student;
  openSidebar?: (a: Student, b: StudentSidebarTab) => void;
  showFieldLabels?: boolean;
};
const StudentGrades = ({ student, showFieldLabels = false }: StudentGradesProps) => {
  const studentId = student.person_pk;
  const { data } = useGridData();
  const gradeRecord = useStudentGrade(studentId);
  const assignment = useAssignment();
  const isLetterGrade = assignment?.grading_method === GradingMethodDescription.LETTER;
  const hasGradeRecord = gradeRecord && Object.keys(gradeRecord).length > 0;
  const elementId = `grade-input-${studentId}`;

  if (!hasGradeRecord) {
    return (
      <div
        className="text-red-3"
        id={elementId}
      >
        {'Missing assignment grade record for this student'}
      </div>
    );
  }

  return (
    <>
      <div className="w-28">
        {showFieldLabels && <FieldLabel label="Grade" />}
        {isLetterGrade ? (
          <LetterGrade
            studentId={studentId}
            record={gradeRecord}
          />
        ) : (
          <AssignmentGrade
            studentId={studentId}
            record={gradeRecord}
          />
        )}
      </div>
      <div className="w-52">
        {showFieldLabels && <FieldLabel label="Assignment Status" />}
        <AssignmentStatusDropdown
          studentId={studentId}
          field="completion_status"
          statuses={data.assignment_statuses}
          record={gradeRecord}
        />
      </div>
      <div className="w-52">
        {showFieldLabels && <FieldLabel label="Dropbox Status" />}
        <AssignmentStatusDropdown
          studentId={studentId}
          field="dropbox_status_int"
          statuses={data.dropbox_statuses}
          record={gradeRecord}
        />
      </div>
      <div className="w-52 md:hidden">
        {showFieldLabels && <FieldLabel label="Published?" />}
        <PublishGradesToggle studentId={studentId} />
      </div>
    </>
  );
};

export default StudentGrades;
