import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import usePhotoGalleryStore from '../../../stores/usePhotoGalleryStore';
import UploadModal from './UploadModal';
import no_image from '../../../../assets/images/no_image_large.jpg';
import RenameGalleryModal from './RenameGalleryModal';
import DeleteGalleryModal from './DeleteGalleryModal';

function GalleryList({ internalClassId, uploaderOptions }) {
  const { photoGalleries, allPhotos } = usePhotoGalleryStore();
  const [imageUpload, setImageUpload] = useState({ id: null, show: false });

  const findGalleryCoverPhoto = (galleryId) => {
    const photosInGallery = allPhotos.filter((photo) => photo.gallery_fk === galleryId);
    if (photosInGallery.length === 0) {
      return no_image;
    }
    const earliestPhoto = photosInGallery.reduce((earliest, current) => {
      if (!earliest) return current;
      return new Date(current.input_date) < new Date(earliest.input_date) ? current : earliest;
    });
    return earliestPhoto.url;
  };

  const numPhotos = (galleryId) => {
    return allPhotos.filter((photo) => photo.gallery_fk === galleryId).length;
  };

  const getGalleryLink = (galleryId) => {
    const url = Routes.class_admin_photos_gallery_path({
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
      internal_class_id: internalClassId,
      gallery_id: galleryId,
    });
    return url;
  };

  photoGalleries.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="flex flex-wrap ">
      {photoGalleries.map((gallery) => (
        <div
          key={gallery.gallery_pk}
          className="flex items-start p-2 mb-4 w-[400px]"
        >
          <div className="flex-shrink-0 mr-4">
            <Link to={getGalleryLink(gallery.gallery_pk)}>
              <img
                src={findGalleryCoverPhoto(gallery.gallery_pk)}
                alt={gallery.name}
                style={{ width: '200px', height: '200px', objectFit: 'cover' }}
              />
            </Link>
          </div>
          <div className="flex-grow w-200px">
            <div className="mb-1">
              <Link
                to={getGalleryLink(gallery.gallery_pk)}
                className="block font-light text-lg text-sky-700"
              >
                {gallery.name}
              </Link>
              <p className="text-xs">{numPhotos(gallery.gallery_pk)} Photos</p>
            </div>
            <div className="flex mb-2">
              <button
                onClick={() => setImageUpload({ id: gallery.gallery_pk, show: true })}
                className="btn mr-2"
              >
                <i className="nc-icon-glyph media-1_image-add text-lg leading-7 pt-1 text-sky-700"></i>
              </button>
              <UploadModal
                imageUpload={imageUpload}
                setImageUpload={setImageUpload}
                galleryId={gallery.gallery_pk}
                internalClassId={internalClassId}
                uploaderOptions={uploaderOptions}
              />
              <RenameGalleryModal
                internalClassId={internalClassId}
                gallery={gallery}
              />
              <DeleteGalleryModal
                internalClassId={internalClassId}
                gallery={gallery}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default GalleryList;
