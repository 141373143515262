import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { useConfigurations } from '../../../../stores/useGradebookStore';
import EnrollmentStatusIcon from './EnrollmentStatusIcon';

type PersonDisplayProps = {
  avatarUrl: string;
  isLarge?: boolean;
  title: ReactNode;
  subtitle: ReactNode;
  dateWithdrawn?: string;
  lateDateEnrolled?: string;
};
const PersonDisplay = ({
  avatarUrl,
  isLarge,
  title,
  subtitle,
  dateWithdrawn,
  lateDateEnrolled,
}: PersonDisplayProps) => {
  const { hide_gradebook_photos: hidePhotos } = useConfigurations();

  const photoClasses = classNames('vx-avatar float-left object-cover mr-1 left-0 mr-2', {
    'h-9 min-w-[36px] w-9': !isLarge,
    'h-12 min-w-[48px] w-12': isLarge,
  });
  const textClasses = classNames('text-left whitespace-nowrap', {
    'leading-4': !isLarge,
    'leading-5 text-lg ml-1 max-w-[110px] text-wrap': isLarge,
  });

  const containerClasses = classNames('flex items-center h-full', {
    'pl-5': !isLarge,
    'overflow-hidden': isLarge,
  });

  return (
    <div className="flex items-center justify-between">
      <div className={containerClasses}>
        {!hidePhotos && (
          <img
            data-testid="person-photo-display"
            className={photoClasses}
            src={avatarUrl}
          />
        )}
        <div
          className={textClasses}
          data-testid="person-name-display"
        >
          <div className="font-semibold overflow-hidden text-ellipsis">{title}</div>
          <div className="font-light overflow-hidden text-ellipsis">{subtitle}</div>
        </div>
      </div>
      <EnrollmentStatusIcon
        dateWithdrawn={dateWithdrawn}
        lateDateEnrolled={lateDateEnrolled}
      />
    </div>
  );
};

export default PersonDisplay;
