export const UNAVAILABLE = 'unavailable';
export const FAILED = 'failed';
export const OUTDATED = 'outdated';
export const SUCCESS = 'success';
export const LOCKED = 'locked';

export const SUCCESS_MESSAGE_DELAY = 2000;
export const FAILURE_MESSAGE_DELAY = 6000;

export const UPDATE_TEACHER_SLOTS = 'UPDATE_TEACHER_SLOTS';
export const REMOVE_CONFERENCE_SELECTION = 'REMOVE_CONFERENCE_SELECTION';
export const CHANGE_CONFERENCE_LOCATION = 'CHANGE_CONFERENCE_LOCATION';

export const DEFAULT_DATE_FORMAT = 'dddd, MMMM DD';
export const DB_TIME_FORMAT = 'HH:mm:ss';
export const TWELVE_HOUR_FORMAT = 'hh:mm a';
export const TWENTY_FOUR_HOUR_FORMAT = 'HH:mm';

export const DROPDOWN_OPTION_HEIGHT = 45;

export const ERROR_MESSAGE_OUTDATED =
  'Someone in your household chose this time recently, select again to overwrite their selection.';
export const ERROR_MESSAGE_UNAVAILABLE = 'Conference time is unavailable.';
export const ERROR_MESSAGE_FAILED = 'Unknown Error';
export const ERROR_MESSAGE_LOCKED = 'Conference time is locked.';
export const ERROR_UNAUTHORIZED = 'User not authorized';

export const NO_TIMES_AVAILABLE = 'No Times Available';
export const NONE_SELECTED = 'None Selected';

export const IN_PERSON = 0;
export const REMOTE = 1;
export const IN_PERSON_OR_REMOTE = 2;
