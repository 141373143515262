import React from 'react';
import { useClassState } from '../../../../stores/useGradebookStore';
import Cell from './Cell';
import { CompletionStatus } from '../constants';
import classNames from 'classnames';
import { Assignment, Student } from '../types';
import { getCellDateColor, getGradeDisplay, isFailingGrade } from '../helpers';
import { Link } from 'react-router-dom';
import TodayCell from './TodayCell';
import { useUserState } from '../../../../stores/useAppStore';
import {
  useAllAssignmentFeedback,
  useGradeConversionScales,
  useGridData,
  useStudentGrade,
} from '../queries';
import GradePreview from './GradePreview';

type StudentGradeProps = {
  assignment: Assignment;
  studentId: number;
};
const StudentGrade = ({ studentId, assignment }: StudentGradeProps) => {
  const gradeRecord = useStudentGrade(studentId, assignment.class_assignment_pk);
  const { data: conversionScalesData } = useGradeConversionScales();
  const { setFocusedStudentRow } = useUserState();
  const status = gradeRecord?.completion_status;
  const { today } = useClassState();
  const cellDateColor = getCellDateColor(today, assignment.due_date);
  const { grade, isLabel } = getGradeDisplay(
    gradeRecord,
    assignment.grading_method,
    assignment.zg_grade_conversion_scale_levels,
    assignment.include_in_calc_grade
  );
  // fetch feedback ahead of time for the grade preview
  const { data: feedback } = useAllAssignmentFeedback();

  const isFail =
    !isLabel &&
    isFailingGrade(
      gradeRecord?.score,
      assignment.maximum_score,
      conversionScalesData?.class_scale?.minimum_passing_grade
    );
  const { data } = useGridData();
  const rowIndex = data.students.findIndex((student) => student.person_pk === studentId);

  const cellClasses = classNames('w-36 hover:bg-gray-400', {
    [cellDateColor]: true,
    'bg-red-5': status === CompletionStatus.NOT_TURNED_IN,
    'bg-yellow-5': status === CompletionStatus.LATE || status === CompletionStatus.INCOMPLETE,
  });
  const textClasses = classNames('flex relative justify-center group w-full', {
    'text-red-500': isFail,
    'text-black': !isFail,
    'font-light italic': status === CompletionStatus.NOT_REQUIRED_TO_COMPLETE,
  });

  return (
    <TodayCell assignment={assignment}>
      <Cell classOverrides={cellClasses + ' hover:relative group'}>
        <Link
          to={`${assignment.class_assignment_pk}/students`}
          onClick={() => setFocusedStudentRow(rowIndex)}
        >
          <div className={textClasses}>
            {grade}
            <i className="text-black invisible group-hover:visible nc-icon-glyph ui-1_edit-72 absolute right-4 text-lg leading-10" />
          </div>
          {gradeRecord && (
            <GradePreview
              rowIndex={rowIndex}
              assignment={assignment}
              gradeRecord={gradeRecord}
              feedback={feedback}
            />
          )}
        </Link>
      </Cell>
    </TodayCell>
  );
};

type StudentRowProps = {
  assignments: Assignment[];
  student: Student;
};
const StudentRow = ({ assignments, student }: StudentRowProps) => {
  const studentId = student.person_pk;
  return (
    <tr>
      {assignments.map((assignment) => {
        const assignmentId = assignment.class_assignment_pk;
        return (
          <StudentGrade
            key={assignmentId + studentId}
            studentId={studentId}
            assignment={assignment}
          />
        );
      })}
    </tr>
  );
};

export default StudentRow;
