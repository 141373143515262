import React, { FC, useState } from 'react';
import InputField from './InputField';
import DatePicker from './DatePicker';
import Toggle from '../common/Toggle';
import Dropdown from './Dropdown';
import { useAssignmentFiles, useAssignmentTypes } from '../queries';
import FormElement from '../common/FormElement';
import RichTextArea from '../common/RichTextArea';
import Modal from '../../../shared/Modal';
import FileUploader from '../../../shared/FileUploader';
import { useClassState, useConfigurations } from '../../../../stores/useGradebookStore';
import { useAddAssignmentFiles, useDeleteAssignmentFile } from '../mutations';
import FileList from '../common/FileList';
import { Formik, Form } from 'formik';
import { AssignmentSchema, getScaleDescription } from '../helpers';
import { ConversionScale, GradingMethod, ZgStatus, AccessStatuses } from '../types';
import {
  APPLIES_TO_ALL_SECTIONS,
  ASSIGNMENT_DETAILS,
  ASSIGNMENT_TYPE,
  DATE_ASSIGNED,
  DESCRIPTION,
  DISPLAY_ON_PORTALS,
  DISPLAY_ON_REPORT_CARD,
  DUE_DATE,
  EXTRA_CREDIT,
  GRADE_CONVERSION_SCALE,
  GRADING_METHOD,
  GRADING_PERIOD,
  GradingMethodDescription,
  INCLUDE_IN_CALC_GRADE,
  MAX_SCORE,
  TEACHER_NOTES,
  WEIGHT,
} from '../constants';
import { useScreenSizes } from '../../../../stores/useAppStore';
import DeleteButton from './DeleteButton';

type FormProps = {
  initialValues: Record<string, any>;
  gradingMethods: GradingMethod[];
  conversionScales: ConversionScale[];
  gradingPeriods: ZgStatus[];
};
const AssignmentForm: FC<FormProps> = ({
  initialValues,
  gradingMethods,
  conversionScales,
  gradingPeriods,
}) => {
  const { data: assignmentTypesData, isLoading: isLoadingAssignmentTypes } = useAssignmentTypes();
  const { show_display_on_report_card_flag: showDisplayOnReportCardToggle } = useConfigurations();
  const isLoadingGradingMethods = !Boolean(gradingMethods);
  const isLoadingScales = !Boolean(conversionScales);

  const { uploaderOptions } = useClassState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const assignmentFiles = useAssignmentFiles();
  const addAssignmentFiles = useAddAssignmentFiles();
  const deleteAssignmentFile = useDeleteAssignmentFile();
  const { isMd: isMobile } = useScreenSizes();
  const {
    assignment_max_score_teacher_access_status: maxScoreAccess,
    assignment_weight_teacher_access_status: weightAccess,
  } = useConfigurations();
  const displayWeight = weightAccess !== AccessStatuses.DISABLED;
  const displayMaxScore = maxScoreAccess !== AccessStatuses.DISABLED;
  const readOnlyWeight = weightAccess === AccessStatuses.READ_ONLY;
  const readOnlyMaxScore = maxScoreAccess === AccessStatuses.READ_ONLY;

  const handleSaveFiles = () => {
    addAssignmentFiles.mutate({ files: uploadedFiles });
    setUploadedFiles([]);
    setIsModalOpen(false);
  };

  const handleDeleteFile = (filePk: string) => {
    deleteAssignmentFile.mutate({ file_pk: filePk });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={() => {}}
      validationSchema={AssignmentSchema}
    >
      {({ values }) => {
        const isLetterGrading = values.grading_method === GradingMethodDescription.LETTER;
        return (
          <Form>
            <div className="px-4 pb-4 md:max-w-screen-xl">
              <div className="md:grid md:grid-cols-2 md:gap-x-4">
                <FormElement
                  label={DESCRIPTION}
                  attribute="description"
                  component={InputField}
                  extraProps={{
                    initialValue: initialValues.description,
                  }}
                />

                <div className="grid grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-2 gap-x-4">
                  <FormElement
                    label={ASSIGNMENT_TYPE}
                    attribute="assignment_type"
                    component={Dropdown}
                    extraProps={{
                      isLoading: isLoadingAssignmentTypes || false,
                      types: assignmentTypesData || [],
                    }}
                  />
                  <FormElement
                    label={GRADING_PERIOD}
                    attribute="grading_period"
                    component={Dropdown}
                    extraProps={{
                      readOnly: true,
                      types: gradingPeriods,
                    }}
                  />
                </div>
              </div>

              <div className="md:w-6/12 md:float-right md:pl-2">
                <div className="grid grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-2 gap-x-4">
                  <FormElement
                    label={DATE_ASSIGNED}
                    attribute="assignment_date"
                    component={DatePicker}
                    extraProps={{
                      currentGradingPeriod: values.grading_period,
                    }}
                  />

                  <FormElement
                    label={DUE_DATE}
                    attribute="due_date"
                    component={DatePicker}
                    extraProps={{
                      currentGradingPeriod: values.grading_period,
                      showGPChangeConfirmModal: true,
                    }}
                  />
                </div>

                <div className="grid grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-2 gap-x-4">
                  <FormElement
                    label={GRADING_METHOD}
                    attribute="grading_method"
                    component={Dropdown}
                    extraProps={{
                      isLoading: isLoadingGradingMethods || false,
                      types: gradingMethods,
                      readOnly: true,
                    }}
                  />

                  {isLetterGrading && (
                    <FormElement
                      label={GRADE_CONVERSION_SCALE}
                      attribute="grade_conversion_scale"
                      component={Dropdown}
                      tooltipInfo={
                        isMobile
                          ? null
                          : getScaleDescription(conversionScales, values.grade_conversion_scale)
                      }
                      extraProps={{
                        isLoading: isLoadingScales || false,
                        types: conversionScales || [],
                        readOnly: true,
                      }}
                    />
                  )}

                  {!isLetterGrading && displayMaxScore && (
                    <FormElement
                      label={MAX_SCORE}
                      attribute="maximum_score"
                      component={InputField}
                      extraProps={{
                        initialValue: initialValues.maximum_score,
                        readOnly: readOnlyMaxScore,
                      }}
                    />
                  )}

                  {!isLetterGrading && displayWeight && (
                    <FormElement
                      label={WEIGHT}
                      attribute="weight"
                      component={InputField}
                      extraProps={{
                        initialValue: initialValues.weight,
                        readOnly: readOnlyWeight,
                      }}
                    />
                  )}
                </div>

                <div className="grid grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-2 gap-x-4">
                  <FormElement
                    label={INCLUDE_IN_CALC_GRADE}
                    attribute="include_in_calc_grade"
                    component={Toggle}
                  />

                  <FormElement
                    label={EXTRA_CREDIT}
                    attribute="extra_credit"
                    component={Toggle}
                  />

                  <FormElement
                    label={APPLIES_TO_ALL_SECTIONS}
                    attribute="applies_to_all_sections"
                    component={Toggle}
                  />

                  <FormElement
                    label={DISPLAY_ON_PORTALS}
                    attribute="display_status"
                    component={Toggle}
                  />

                  {showDisplayOnReportCardToggle && (
                    <FormElement
                      label={DISPLAY_ON_REPORT_CARD}
                      attribute="display_on_report_card"
                      component={Toggle}
                    />
                  )}
                </div>
              </div>

              <div className="md:w-6/12 md:float-left md:pr-2">
                <FormElement
                  label={ASSIGNMENT_DETAILS}
                  attribute="notes"
                  component={RichTextArea}
                >
                  {Boolean(assignmentFiles?.length) && (
                    <FileList
                      onDelete={handleDeleteFile}
                      files={assignmentFiles}
                      classOverrides="p-2 border-x border-b border-[#cccccc]"
                    />
                  )}

                  <button
                    type="button"
                    className="flex pl-2 pr-1 items-center justify-center group h-7 text-neutral-3 hover:text-neutral-1 border rounded-lg border-neutral-4 hover:border-neutral-2 mt-2"
                    onClick={() => setIsModalOpen(true)}
                    aria-label="Attach Files"
                  >
                    <div className="text-md">Attach Files</div>
                    <i className="nc-icon-glyph ui-1_attach-87 ml-1 text-lg" />
                  </button>
                </FormElement>

                <FormElement
                  label={TEACHER_NOTES}
                  attribute="teacher_notes"
                  component={RichTextArea}
                />
              </div>
              <div className="md:w-6/12 md:float-right md:pl-2 mt-4">
                <DeleteButton />
              </div>

              <div className="clear-both"></div>
            </div>

            <Modal
              isOpen={isModalOpen}
              onSave={handleSaveFiles}
              isSubmitDisabled={uploadedFiles.length === 0}
              setIsOpen={setIsModalOpen}
            >
              <FileUploader
                setUploadedFiles={setUploadedFiles}
                uploaderOptions={{
                  ...uploaderOptions,
                  height: '378px',
                }}
              />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssignmentForm;
