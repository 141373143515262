import React from 'react';
import { useCurrentGradesByGradingPeriod } from '../../../../stores/useCourseRequestsStore';
import CurrentScheduleRow from './CurrentScheduleRow';
import { GRADES_PERMISSION_HELP_TEXT } from '../constants';

type CurrentScheduleTableProps = {
  selectedGradingPeriod: number;
};
const CurrentScheduleTable = ({ selectedGradingPeriod }: CurrentScheduleTableProps) => {
  const currentGrades = useCurrentGradesByGradingPeriod(selectedGradingPeriod);

  if (!currentGrades.length) {
    return (
      <div className="text-center w-full h-[60vh]">
        <div className="text-lg text-neutral-3">
          No classes found for the selected grading period.
        </div>
      </div>
    );
  }

  return (
    <table className="td:px-4 td:py-2 td:border">
      <thead className="bg-neutral-5 border-b border-b-neutral-2 text-left">
        <tr className="">
          <th className="w-60 px-1 py-0.5">Class</th>
          <th
            className="w-40 px-1 py-0.5 text-center vx-tooltipped vx-tooltipped--n cursor-help"
            aria-label={GRADES_PERMISSION_HELP_TEXT}
          >
            Average
          </th>
          <th
            className="w-40 px-1 py-0.5 text-center vx-tooltipped vx-tooltipped--n cursor-help"
            aria-label={GRADES_PERMISSION_HELP_TEXT}
          >
            Posted
          </th>
          <th
            className="w-24 px-1 py-0.5 text-center vx-tooltipped vx-tooltipped--n cursor-help"
            aria-label={GRADES_PERMISSION_HELP_TEXT}
          >
            Grade Detail
          </th>
        </tr>
      </thead>
      <tbody>
        {currentGrades.map((gradeData, idx) => {
          return (
            <CurrentScheduleRow
              key={gradeData.class_name + idx}
              gradeData={gradeData}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default CurrentScheduleTable;
