require('../controls/loader/loader');
require('../controls/error/error');
require('../lib/bluebird_defer');
moment = require('moment');

window.PortalComponent = Ractive.extend({
  onconstruct: function (options) {
    trace.log();
    options.magic = true;
    options.components = {
      Loader: Control.Loader,
    };
  },

  onconfig: function (options) {
    _.each(
      this.get('endpoints'),
      _.bind(function (endpoint) {
        this[endpoint] = _.partial(this.serverEndpoint, endpoint);
      }, this)
    );

    this.set({
      detailURL: this.detailURL,
      systemPageURL: this.systemPageURL,
      parseUserLink: this.parseUserLink,
      formatDate: this.formatDate,
      classURL: this.classURL,
      courseConfigValue: this.courseConfigValue,
      courseConfigBool: this.courseConfigBool,
    });

    this.loading_promise = new BluebirdDeferred();
  },

  data: {
    loads_test_data: false,
    t: (key) => i18next.t(key),
  },

  computed: {
    box_styles: function () {
      // render padding
      var padding = [
        this.get('configuration.styles.paddingTop') + 'px',
        this.get('configuration.styles.paddingRight') + 'px',
        this.get('configuration.styles.paddingBottom') + 'px',
        this.get('configuration.styles.paddingLeft') + 'px',
      ].join(' ');

      var styles = {
        padding: padding,
        'background-color': this.get('configuration.styles.backgroundColor'),
        color: this.get('configuration.styles.color'),
        'border-radius': this.get('configuration.styles.borderRadius') + 'px',
        'border-width': this.get('configuration.styles.borderWidth') + 'px',
        'border-color': this.get('configuration.styles.borderColor'),
        'border-style': this.get('configuration.styles.borderStyle'),
      };

      return (
        _.map(styles, function (value, key) {
          return key + ':' + value;
        }).join('; ') + ';'
      );
    },
  },

  oninit: function () {},

  reload: function () {
    // to be overriden by subclasses
  },

  getLoadingPromise: function () {
    return this.loading_promise.promise;
  },

  componentDoneLoading: function () {
    this.loading_promise.resolve();
  },

  serverEndpoint: function (endpoint, params) {
    params = typeof params !== 'undefined' ? params : {}; // default value

    var route_options = {
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
      component_type: this.get('system_access_name'),
      portal_screen_component_pk: this.get('portal_screen_component_pk'),
      endpoint:
        this.get('loads_test_data') && this.get('admin') && endpoint == 'load_data'
          ? 'load_admin_data'
          : endpoint,
    };
    var url = Routes.component_path(route_options);

    if (this.get('admin')) {
      params.configuration = flatten(this.get('configuration'));
      params.admin = true;

      url = Routes.component_admin_path(route_options);
    }

    this.set('loading', true);
    return $.ajax({
      type: 'GET',
      url: url,
      data: params,
      context: this,
    })
      .then(function (data) {
        this.set('loading', false);
        return data;
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        this.set('loading', false);
        new Control.Error({
          el: $(this.el),
          data: { message: errorThrown },
        });
        this.componentDoneLoading();
      });
  },

  detailURL: function (record_type, record_pk) {
    return Routes[record_type + '_path']({
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
      record_pk: record_pk,
    });
  },

  systemPageURL: function (route_name, params) {
    return Routes[route_name + '_path'](
      _.merge(
        {
          client: Portals.config.client,
          portal_name: Portals.config.portal_name,
        },
        params
      )
    );
  },

  classUrl: function (classPk, location) {
    const v3Routes = {
      home: 'class_admin_home_path',
      attendance: 'attendance_path',
      students: 'class_admin_students_path',
      gradebook: 'class_admin_gradebook_path',
      'website-admin': 'class_admin_website_path',
      photos: 'class_admin_photos_path',
      calendar: 'class_admin_calendar_path',
      'report-cards': 'class_admin_report_cards_path',
    };

    const v3Path = v3Routes[location];

    if (v3Path) {
      return Routes[v3Path]({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        internal_class_id: classPk,
      });
    }

    // Redirect to Portals 2 version of the attendance screen
    return [_.baseURL('classes'), 'course', classPk, location].join('/');
  },

  parseUserLink: function (link_text) {
    var link_regex = /{(system|screen|axiom|detail):(.+?)(:.+)?}/i;
    if (!link_text || !link_regex.test(link_text)) {
      return link_text;
    }

    var parts = link_text.replace(/{|}/g, '').split(':');
    var link = {
      type: parts[0],
      page_name: parts[1],
      param: parts[2],
    };

    if (link.type == 'system') {
      if (link.page_name == 'calendar') {
        return this.systemPageURL(link.page_name, {
          calendar_name: link.param || 'school',
        });
      } else if (link.page_name == 'directory') {
        return this.systemPageURL(link.page_name, {
          directory_pk: link.param || '1',
        });
      } else if (link.page_name == 'directory_type') {
        return this.systemPageURL(link.page_name, {
          directory_type_access_name: link.param || 'households',
        });
      } else if (link.page_name == 'data_consent') {
        return this.systemPageURL(link.page_name, {
          data_consent_policy_pk: link.param || '1',
        });
      } else if (_.includes(_.functions(Routes), link.page_name + '_path')) {
        return this.systemPageURL(link.page_name);
      } else {
        // dynamic links that use database UDFs (see redirect_controller)
        return Routes.redirect_path(
          Portals.config.client,
          Portals.config.portal_name,
          link.page_name
        );
      }
    } else if (link.type == 'screen') {
      return this.systemPageURL('screen', { screen_name: link.page_name });
    } else if (link.type == 'axiom') {
      return [_.baseURL('axiom'), '#', link.page_name, link.param].join('/');
    } else if (link.type == 'detail') {
      return this.detailURL(link.page_name, link.param);
    }

    return link_text;
  },

  formatDate: function (date_value, format) {
    if (format === 'time_ago') {
      return moment(date_value, ['MM/DD/YYYY', 'YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ss.SSSZ']).fromNow();
    } else {
      return moment(date_value, ['MM/DD/YYYY', 'YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ss.SSSZ']).format(
        format
      );
    }
  },

  courseConfigValue: function (course, category, keypath) {
    return _.find(JSON.parse(course.class_configuration), {
      system_category: category,
      keypath: keypath,
    }).value;
  },

  courseConfigBool: function (course, category, keypath) {
    var truthy_values = ['true', 'TRUE', 't', 'T', 'yes', 'YES', 'on', 'ON', '1', 1, true];
    return _.includes(truthy_values, this.courseConfigValue(course, category, keypath));
  },
});
