import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { getStudentData, updateRequestsComplete } from '../resources';
import { Student, StudentList, ClassList } from '../types';
import { useFetch } from '../../../../hooks/useFetch';
import {
  useCourseRequestActions,
  useStudentState,
} from '../../../../stores/useCourseRequestsStore';
import CheckBox from './Checkbox';
import EmptyPage from '../../../shared/EmptyPage';
import classNames from 'classnames';

type StudentAdminHeaderProps = {
  students: Student[];
  advisees: Student[];
  adviseesOnly: boolean;
  setLoading: (loading: boolean) => void;
  classes: ClassList[];
};
const StudentAdminHeader = ({
  students,
  advisees,
  adviseesOnly,
  setLoading,
  classes,
}: StudentAdminHeaderProps) => {
  const { student, studentData, studentIsSelected } = useStudentState();
  const { setStudent, setStudentData } = useCourseRequestActions();
  const [klass, setKlass] = useState(null);
  const [requestsComplete, setRequestsComplete] = useState(studentData.course_requests_finalized);
  const { loading, data, sendFetchRequest: sendStudentDataRequest } = useFetch();
  const { sendFetchRequest: courseRequestsCompleteRequest } = useFetch();

  const studentList: StudentList[] = (klass?.class_pk === -2 ? advisees : students).map(
    (student) => ({
      ...student,
      classPks: student.class_pks?.split(', '),
      label: student.name,
    })
  );

  const handleChangeStudent = (value) => {
    setStudent(value);
  };

  let filteredStudents;
  if (klass === null || [0, -2].includes(klass.class_pk)) {
    filteredStudents = studentList;
  } else {
    filteredStudents = studentList.filter((student) => student.class_pks.includes(klass?.class_pk));
  }

  const mappedClasses = classes.map(
    (klass): ClassList => ({
      ...klass,
      label: klass.description,
    })
  );
  const classList = [
    {
      label: 'General',
      options: [
        { class_pk: 0, label: 'All Students' },
        { class_pk: -2, label: 'Advisees Only' },
      ],
    },
    { label: 'By Class', options: mappedClasses },
  ];

  const handleFilterByClass = (value) => {
    setKlass(value);
  };

  const handleRequestsComplete = async () => {
    const tempRequestsComplete = requestsComplete;

    try {
      await updateRequestsComplete(
        requestsComplete,
        setRequestsComplete,
        student.person_pk,
        courseRequestsCompleteRequest
      );
    } catch (error) {
      setRequestsComplete(tempRequestsComplete);
    }
  };

  useEffect(() => {
    if (student !== null) {
      getStudentData(student, sendStudentDataRequest);
    }
  }, [student]);

  useEffect(() => {
    if (data) {
      setStudentData(data.student_info);
      setRequestsComplete(data.student_info.course_requests_finalized);
    }
  }, [data]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  // Styles
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      height: '28px',
      minHeight: '28px',
    }),
    indicatorsContainer: (styles) => ({ ...styles, height: '28px', minHeight: '28px' }),
    valueContainer: (styles) => ({ ...styles, height: '28px', minHeight: '28px' }),
    indicatorSeparator: (styles) => ({
      ...styles,
      height: '18px',
      minHeight: '18px',
      marginTop: '4px',
    }),
    input: (styles) => ({
      ...styles,
      paddingTop: '0',
      marginTop: '0',
      'input:focus': {
        boxShadow: 'none',
        paddingTop: '0',
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      marginLeft: '4px',
    }),
    singleValue: (styles) => ({
      ...styles,
      marginLeft: '4px',
    }),
    menu: (styles) => ({ ...styles, marginTop: '2px' }),
    groupHeading: (styles) => ({
      ...styles,
      padding: '2px 12px',
      background: '#d6d6d6',
      color: 'black',
      fontSize: '1rem',
      textTransform: 'capitalize',
    }),
  };

  return (
    <>
      <div
        className={classNames('flex w-full', {
          'justify-center': adviseesOnly,
          'justify-between': !adviseesOnly,
        })}
      >
        {!adviseesOnly && (
          <div className="w-full max-md:ml-0">
            <ReactSelect
              options={classList}
              placeholder="All Students"
              onChange={handleFilterByClass}
              defaultValue={klass}
              value={klass}
              getOptionValue={(option) => option.class_pk}
              styles={selectStyles}
            />
          </div>
        )}

        <div
          className={classNames('w-full', {
            'md:w-1/2': adviseesOnly,
            'ml-3': !adviseesOnly,
          })}
        >
          <ReactSelect
            options={filteredStudents}
            placeholder="Select a Student"
            onChange={handleChangeStudent}
            defaultValue={student}
            value={student}
            getOptionValue={(option) => option.person_pk}
            isLoading={loading}
            styles={selectStyles}
          />
        </div>
      </div>

      {!studentIsSelected && (
        <div className="w-full">
          <EmptyPage
            message="Make a selection above to get started."
            icon="arrows-1_tail-up"
          />
        </div>
      )}

      {studentIsSelected && (
        <form className="w-full order-last">
          <CheckBox
            field={'requestsComplete'}
            checkedValue={requestsComplete}
            onChange={handleRequestsComplete}
            disabled={false}
          />
          <label onClick={handleRequestsComplete}>
            This student&apos;s course requests are complete
          </label>
        </form>
      )}
    </>
  );
};

export default StudentAdminHeader;
