import React from 'react';
import classNames from 'classnames';

const defaultConfig = {
  disabled: false,
  displayed: true,
  required: false,
  placeholder: undefined,
  displayFieldStatusIcon: false,
};

export default function Field(props) {
  const { className, onLabelClick, ...fieldProps } = props;

  const { name, type, value, error, onChange, hasError, hasChange, hasSuccess } = fieldProps;

  const config = { ...defaultConfig, ...props.config };

  const FIELD_STATUS_ICON_CONFIG = {
    default: {},
    error: {
      className: 'ui-2_alert-circle-',
      tooltipText: 'This field encountered an error and was unable to be updated',
    },
    change: {
      className: 'media-1_button-record',
      tooltipText: 'This field has a pending, unsaved change',
    },
    success: {
      className: 'ui-1_check-simple',
      tooltipText: 'This field was updated successfully',
    },
  };

  const isDisplayed = config.displayed;
  const isRequired = config.required;
  const isBlank = !value;
  const hasAnyError = hasError || !!error;
  const fieldStatus =
    (hasAnyError && 'error') || (hasChange && 'change') || (hasSuccess && 'success');
  const showFieldStatusIcon = config.displayFieldStatusIcon && !!fieldStatus;
  const fieldStatusConfig =
    FIELD_STATUS_ICON_CONFIG[fieldStatus] || FIELD_STATUS_ICON_CONFIG.default;
  const fieldStatusIconClassName = fieldStatusConfig.className;
  const fieldStatusIconTooltipText = fieldStatusConfig.tooltipText;

  if (!isDisplayed) return null;

  const fieldClassNames = classNames({
    'vx-form-field': true,
    'vx-form-field--blank': isBlank,
    error: hasAnyError,
    changed: hasChange,
    success: hasSuccess,
  });

  const fieldLabelClassNames = classNames({
    'vx-form-label': true,
    'vx-form-label--required': isRequired,
  });

  const fieldControlClassNames = classNames({
    'vx-form-control': true,
    [className]: true,
  });

  const fieldStatusIconClassNames = classNames({
    'nc-icon-glyph': true,
    [fieldStatusIconClassName]: true,
  });

  const FieldLabel = config.label && (
    <label
      className={fieldLabelClassNames}
      htmlFor={name}
      onClick={onLabelClick}
    >
      {config.label}
    </label>
  );

  const FieldStatusIcon = showFieldStatusIcon && (
    <div
      className="vx-form-field-status-icon vx-tooltipped"
      aria-label={fieldStatusIconTooltipText}
    >
      <i className={fieldStatusIconClassNames} />
    </div>
  );

  const FieldError = error && <span className="vx-validation-message">{error}</span>;

  return (
    <div className={fieldClassNames}>
      {FieldLabel}
      {FieldStatusIcon}
      <div className={fieldControlClassNames}>{props.children}</div>
      {FieldError}
    </div>
  );
}
