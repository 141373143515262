import React from 'react';
import { TotalsByAssignmentType, WeightConfiguration } from '../types';
import Cell from '../grid/Cell';
import { getFloatDisplay } from '../helpers';
import classNames from 'classnames';

type WeightingTotalsProps = {
  periodConfig: WeightConfiguration[];
  totals: TotalsByAssignmentType;
};
const WeightingTotals = ({ periodConfig, totals }: WeightingTotalsProps) => {
  const cellClasses = 'bg-yellow-6 border border-yellow-5';
  const totalWeight = periodConfig.reduce(
    (acc, config) => acc + parseFloat(config.weight) * 100,
    0
  );
  const totalWeightTruncated = getFloatDisplay(totalWeight.toString());

  const totalCount = Object.values(totals).reduce((acc, total) => acc + total.count, 0);
  const totalPoints = Object.values(totals).reduce((acc, total) => acc + total.weight, 0);

  const totalWeightCellClasses = classNames('bg-yellow-6 border border-yellow-5', {
    'bg-red-6 border-red-4 text-red-3': totalWeightTruncated !== '100',
    'text-green-3': totalWeightTruncated === '100',
  });

  return (
    <tbody className="">
      <tr>
        <Cell classOverrides={cellClasses + ' pr-3  text-right'}>Totals</Cell>
        <Cell classOverrides={totalWeightCellClasses}>{totalWeightTruncated + '%'}</Cell>
        <Cell classOverrides={cellClasses}></Cell>
        <Cell classOverrides={cellClasses}>{totalCount}</Cell>
        <Cell classOverrides={cellClasses}>{totalPoints}</Cell>
      </tr>
    </tbody>
  );
};

export default WeightingTotals;
