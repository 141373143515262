import React, { useEffect, useState } from 'react';
import { Course } from '../types';
import { handleCreateRequest } from '../resources';
import { useCourseRequestState, useStudentState } from '../../../../stores/useCourseRequestsStore';
import { useFetch } from '../../../../hooks/useFetch';
import { courseDescription } from '../helpers';

type CourseItemProps = {
  course: Course;
  subjectColor: string;
  setScrollToRequest: (scrollToRequest: number) => void;
};

const CourseItem = ({ course, subjectColor, setScrollToRequest }: CourseItemProps) => {
  const { student } = useStudentState();
  const { default_form_values: defaultFormValues } = useCourseRequestState();
  const [createError, setCreateError] = useState<string>(null);
  const { data: createResponse, sendFetchRequest: createCourseRequest } = useFetch();

  const handleAddCourseRequest = async () => {
    const requestFormData = {
      course: course.id,
      priority: defaultFormValues.priority,
      grading_period: defaultFormValues.grading_period,
      enrollment_level: defaultFormValues.enrollment_level,
      approval_status: defaultFormValues.approval_status,
      recommended: defaultFormValues.recommended,
      notes: '',
    };
    try {
      await handleCreateRequest(student.person_pk, requestFormData, 0, 1, createCourseRequest);
    } catch (error) {
      setCreateError(error.message);
    }
  };

  useEffect(() => {
    if (createResponse) {
      setScrollToRequest(createResponse.data.course_request_pk);
    }
  }, [createResponse]);

  return (
    <li>
      <div className="flex">
        <div
          className="flex-none w-3 border-r border-gray-300"
          style={{ backgroundColor: subjectColor }}
        ></div>

        <div className="flex-initial w-full border-b border-gray-300 hover:bg-gray-100">
          <div className="flex p-1">
            <button onClick={handleAddCourseRequest}>
              <i className="nc-icon-glyph ui-1_circle-bold-add mr-1.5 text-green-4"></i>
            </button>
            <div className="text-sm">{courseDescription(course)}</div>
            <div className="flex-grow w-auto">{/* empty */}</div>
            <div className="text-right">{course.classifications}</div>
          </div>
          <div className="font-light px-1">{course.notes}</div>
          {createError && <div className="text-red-3 text-sm">{createError}</div>}
        </div>
      </div>
    </li>
  );
};

export default CourseItem;
