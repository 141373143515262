export const PORTALS_REQUEST_URL_PARAMS = {
  client: Portals.config.client,
  portal_name: Portals.config.portal_name,
};

export async function makeRequest(
  url: string,
  method: string,
  authToken: string,
  body = null
): Promise<any> {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store',
    'X-CSRF-Token': authToken,
  };

  const params = {
    method,
    headers,
    body: undefined,
  };
  if (body) {
    params.body = JSON.stringify(body);
  }
  const response = await fetch(url, params);
  const responseBody = await response.json();
  if (response.ok) {
    return responseBody;
  }
  throw new Error(responseBody.status);
}
