import React from 'react';
import {
  isInPersonOnly,
  isRemoteOnly,
  isInPersonSelected,
  isRemoteSelected,
  isInPersonOrRemote,
} from './helpers';
import classNames from 'classnames';
import useConferencesStore from '../../../stores/useConferencesStore';

const AttendanceIcons = ({ conference, hasAttendanceOption, isCurrentlyDisplaying, iconIds }) => {
  if (!hasAttendanceOption) {
    return null;
  }

  const setTooltipInfo = useConferencesStore((state) => state.setTooltipInfo);

  const buildIcon = (classes, iconId, tooltipMessage) => {
    return (
      <div
        className="location-icon"
        id={iconId}
      >
        <i
          className={classes}
          onMouseOver={() =>
            setTooltipInfo({
              description: tooltipMessage,
              iconId,
              parentId: iconIds.parent,
            })
          }
          onMouseLeave={() => setTooltipInfo(null)}
        ></i>
      </div>
    );
  };

  if (isCurrentlyDisplaying) {
    return (
      <div
        className="conferences-attendance-option"
        data-testid="conferences-attendance-options"
      >
        {isInPersonSelected(conference) &&
          buildIcon(
            'nc-icon-glyph education_school available',
            iconIds.inPerson,
            'Attending In-Person'
          )}
        {isRemoteSelected(conference) &&
          buildIcon(
            'nc-icon-glyph tech_desktop-screen available',
            iconIds.remote,
            'Attending Remotely'
          )}
      </div>
    );
  }

  const inPersonIconClasses = classNames('nc-icon-glyph education_school', {
    available: isInPersonOnly(conference) || isInPersonOrRemote(conference),
    'not-available': isRemoteOnly(conference),
  });
  const remoteIconClasses = classNames('nc-icon-glyph tech_desktop-screen', {
    available: isRemoteOnly(conference) || isInPersonOrRemote(conference),
    'not-available': isInPersonOnly(conference),
  });
  const inPersonTooltip =
    'In-Person Option ' + (isRemoteOnly(conference) ? 'Unavailable' : 'Available');
  const remoteTooltip =
    'Remote Option ' + (isInPersonOnly(conference) ? 'Unavailable' : 'Available');

  return (
    <div
      className="conferences-attendance-options"
      data-testid="conferences-attendance-options"
    >
      {buildIcon(inPersonIconClasses, iconIds.inPerson, inPersonTooltip)}
      {buildIcon(remoteIconClasses, iconIds.remote, remoteTooltip)}
    </div>
  );
};

export default AttendanceIcons;
