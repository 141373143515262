import React, { useEffect, useState } from 'react';
// This is a new version of ./Navbar.js for use in new ExpandableSidebar
// Should remove old file once Gradebook is no longer in beta
import { HTTP_METHOD, useFetch } from '../../../hooks/useFetch';
import { useFocus } from '../../../hooks/useFocus';
import classNames from 'classnames';
import useNavigationStore from './useNavigationStore';
import { useTranslation } from 'react-i18next';

const STATUS_CLASS_ACTIVE = 1;

const AvailableClasses = ({ classes, onSelect, loading, currentClassName, navText }) => {
  const { t } = useTranslation('words');

  if (!classes || loading) {
    return (
      <div className="main-content text-center w-full h-72">
        <div className="vx-loader-spinner mt-8"></div>
      </div>
    );
  }

  if (!classes.length) {
    return (
      <div className="main-content text-center w-full h-72">
        <p className="text-neutral-4 text-sm mt-8">No {t('Classes').toLowerCase()} found.</p>
      </div>
    );
  }

  const content = classes
    .sort((a, b) => b.school_year - a.school_year)
    .filter((classRecord) => {
      if (navText === '') return true;
      return (
        classRecord.description.toLowerCase().includes(navText) ||
        classRecord.class_id.toLowerCase().includes(navText)
      );
    })
    .map((classRecord) => {
      const classLabel = `${classRecord.class_id}: ${classRecord.description}`;
      const isCurrent = classLabel === currentClassName;
      const isActive = classRecord.status === STATUS_CLASS_ACTIVE;

      const styles = classNames('cursor-default select-none rounded-md px-4 py-2 hover:bg-blue-5', {
        'bg-neutral-6': isCurrent,
        'font-semibold text-neutral-2': isActive,
        'italic text-neutral-3': !isActive,
      });

      return (
        <li
          className={styles}
          id="option-1"
          role="option"
          tabIndex={-1}
          key={classRecord.class_id}
          onClick={() => (isCurrent ? () => {} : onSelect(classRecord.class_pk, classLabel))}
        >
          {classLabel}
        </li>
      );
    });

  return (
    <ul
      className="-mb-2 h-72 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
      id="options"
      role="listbox"
    >
      {content}
    </ul>
  );
};

const ClassSearchBox = ({ navText, setNavText, onFocus }) => {
  const [selectRef, setSelectFocus] = useFocus();
  React.useEffect(() => {
    if (onFocus) setSelectFocus();
  }, [onFocus]);

  return (
    <div
      className="relative"
      onClick={(e) => e.stopPropagation()}
    >
      <svg
        className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
          clipRule="evenodd"
        />
      </svg>
      <input
        value={navText}
        onChange={(e) => setNavText(e.target.value)}
        type="text"
        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
        placeholder="Search..."
        role="combobox"
        aria-expanded="false"
        aria-controls="options"
        ref={selectRef}
      />
    </div>
  );
};

const ClassNavigatorModal = () => {
  const [navText, setNavText] = useState('');
  const { setIsModalOpen, isModalOpen, className, currentSection } = useNavigationStore();
  const {
    loading,
    data,
    sendFetchRequest: getFacultyProfileClassNavigationListRequest,
  } = useFetch();

  useEffect(() => {
    fetchClasses();
  }, []);

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        setIsModalOpen(false);
      }
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  const fetchClasses = async () => {
    const url = Routes.class_navigation_list_path({
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
    });

    await getFacultyProfileClassNavigationListRequest({
      url,
      method: HTTP_METHOD.get,
    });
  };

  const handleOnSelect = (classPk, optionLabel) => {
    setIsModalOpen(false);
    location.href = redirectPath(classPk, currentSection);
  };

  const redirectPath = (classPk, section) => {
    const config = {
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
      internal_class_id: classPk,
    };

    switch (section) {
      case 'attendance':
        return Routes.attendance_path(config);
      case 'gradebook':
        return Routes.class_admin_gradebook_path(config);
      case 'report_cards':
        return Routes.class_admin_report_cards_path(config);
      case 'notifications':
        return Routes.class_admin_notifications_path(config);
      case 'calendar':
        return Routes.class_admin_calendar_path(config);
      case 'website':
        return Routes.class_admin_website_path(config);
      case 'photos':
        return Routes.class_admin_photos_path(config);
      case 'settings':
        return Routes.class_admin_settings_path(config);
      case 'students':
      default:
        return Routes.class_admin_students_path(config);
    }
  };

  if (!isModalOpen) {
    return null;
  }

  return (
    <div
      className="relative z-[10000]"
      role="dialog"
      aria-modal="true"
      onClick={() => setIsModalOpen(false)}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div>

      <div className="fixed inset-0 z-[10000] overflow-y-auto p-4 sm:p-6 md:p-20">
        <div
          onClick={(e) => e.stopPropagation()}
          className="mx-auto mt-20 max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
        >
          <ClassSearchBox
            onFocus={isModalOpen}
            navText={navText}
            setNavText={setNavText}
          />

          <AvailableClasses
            classes={data}
            onSelect={handleOnSelect}
            loading={loading}
            currentClassName={className}
            navText={navText}
          />
        </div>
      </div>
    </div>
  );
};

export default ClassNavigatorModal;
