import React from 'react';
import useConferencesStore from '../../../stores/useConferencesStore';
import AttendanceIcons from './AttendanceIcons';
import { getTimeString, getDateFormat } from './helpers';
import SuccessNotification from './SuccessNotification';

const ConferenceDropdownOption = ({
  conference,
  isCurrentlyDisplaying,
  isNotifying,
  loading,
  isDisabled,
  dateFormat,
  timeFormat,
  hasConflict,
  isDefault = false,
  noSelectionMessage,
  hasAttendanceOption,
  iconIds,
  conflicts,
  teacherId,
  studentId,
}) => {
  if (isDefault) {
    return (
      <div className="conferences-teacher-dropdown-option">
        <div className="conferences-none-selected">{noSelectionMessage}</div>
        <SuccessNotification isShowing={isNotifying} />
      </div>
    );
  }

  const setTooltipInfo = useConferencesStore((state) => state.setTooltipInfo);

  const {
    start_date: startDate,
    start_time: startTime,
    end_time: endTime,
    selected: isSelected,
    event_fk: confKey,
  } = conference;

  const showSuccessIcon = isNotifying && isSelected;
  let showConflictIcon;
  if (isSelected) {
    showConflictIcon = hasConflict && !loading;
  } else {
    showConflictIcon = hasConflict && !isDisabled;
  }

  const getConflictDescription = (conflicts) => {
    return (
      conflicts
        // dont show a conflict with itself
        .filter(
          (conflict) => !(teacherId === conflict.teacherId && studentId === conflict.studentId)
        )
        .map((conflict) => `\u2022 ${conflict.teacherName} (${conflict.studentName})\n`)
        .join('')
    );
  };

  const handleMouseOver = () => {
    let description = getConflictDescription(conflicts);
    description = 'Time Conflicts: \n' + description;
    setTooltipInfo({
      description,
      iconId: iconIds.conflict,
      parentId: iconIds.parent,
    });
  };

  return (
    <div
      className="conferences-teacher-dropdown-option"
      key={confKey}
      data-testid="conferences-dropdown-option"
    >
      <AttendanceIcons
        conference={conference}
        isCurrentlyDisplaying={isCurrentlyDisplaying}
        hasAttendanceOption={hasAttendanceOption}
        isNotifying={isNotifying}
        iconIds={iconIds}
      />
      <div className="conferences-slot-container">
        <div className="conferences-slot-date">
          {moment(startDate).format(getDateFormat(dateFormat))}
        </div>
        <div className="conferences-slot-times">
          {getTimeString(timeFormat, startTime, endTime)}
        </div>
      </div>
      {showConflictIcon && !showSuccessIcon && (
        <div
          id={iconIds.conflict}
          data-testid="conferencesDropdown-conflict"
          className="conferences-teacher-conflict"
          onMouseOver={handleMouseOver}
          onMouseLeave={() => setTooltipInfo(null)}
        >
          <i className="nc-icon-mini ui-e_round-e-alert"></i>
        </div>
      )}
      <SuccessNotification isShowing={showSuccessIcon} />
    </div>
  );
};

export default ConferenceDropdownOption;
