import { ACCESS_STATUS_CONFIG_KEY, FIELD_CONFIGS, PRIORITY, READ_ONLY_CONFIG } from './constants';
import {
  CurrentRequestData,
  PortalConfigValues,
  StudentMetaData,
  PortalConfigKeypath,
  Course,
  ZgOption,
} from './types';

const ALL_TABS: { label: string; config: PortalConfigKeypath; type: string }[] = [
  { label: 'Course Requests', config: 'default', type: 'currentCourseRequests' },
  { label: 'Previous Courses', config: 'show_previous_courses', type: 'previousCourses' },
  { label: 'Credit Report', config: 'show_credit_report', type: 'creditReport' },
  { label: 'Current Schedule', config: 'show_current_schedule', type: 'currentSchedule' },
];

export const enabledTabs = (portalConfig: StudentMetaData['portalConfig']) => {
  return ALL_TABS.filter(
    (tab) =>
      portalConfig
        .filter(
          (config) =>
            ['show_previous_courses', 'show_credit_report', 'show_current_schedule'].includes(
              config.keypath
            ) && config.value === 'true'
        )
        .map((config) => config.keypath)
        .includes(tab.config) || tab.config === 'default'
  );
};

export const initStudent = (personPk: number) =>
  personPk
    ? {
        person_pk: personPk,
        name: '',
        first_name: '',
        last_name: '',
      }
    : null;

export const isReadOnlyConfig = (config: PortalConfigValues) =>
  config.find((c) => c.keypath === ACCESS_STATUS_CONFIG_KEY)?.value === READ_ONLY_CONFIG;

export const hasFieldsConfigured = (config: PortalConfigValues) =>
  config.some((c) => FIELD_CONFIGS.includes(c.keypath) && c.value === 'true');

export const isRequestLockedByApproval = (request: CurrentRequestData, isFacultyPortal: boolean) =>
  request?.approval_status === 1 && !isFacultyPortal;
export const isRequestLockedByRecommended = (
  request: CurrentRequestData,
  isFacultyPortal: boolean
) =>
  !isRequestLockedByApproval(request, isFacultyPortal) && request?.recommended && !isFacultyPortal;

export const showField = (keypath: PortalConfigKeypath, portalConfig: PortalConfigValues) => {
  return (portalConfig.find((config) => config.keypath === keypath) || {}).value === 'true';
};

export const courseDescription = (course: Course) => {
  return course.course_id ? `${course.course_id + ': ' + course.description}` : course.description;
};

export const filterCoursesBySubject = (
  subjectId,
  subjectCourses,
  searchInput,
  classificationFilters
): Course[] => {
  let courses = subjectCourses[subjectId] || [];

  courses = courses.filter((course) => {
    return searchInput && searchInput.length > 0
      ? courseDescription(course).toLowerCase().includes(searchInput.toLowerCase())
      : true;
  });
  courses = courses.filter((course) => {
    return classificationFilters && classificationFilters.length > 0
      ? classificationFilters.some((classification) =>
          course.classifications.split(', ').includes(classification)
        )
      : true;
  });

  return courses;
};

export const getDefaultGradingPeriod = (
  gradingPeriods: ZgOption[],
  activeGradingPeriod: number
): number => {
  return gradingPeriods.some((period) => period.id === activeGradingPeriod)
    ? activeGradingPeriod
    : gradingPeriods[0].id;
};

export const getDefaultPriority = (requestGroup: number, currentRequests: CurrentRequestData[]) => {
  const numberOfRequestsInGroup = currentRequests.filter(
    (r) => r.request_group === requestGroup
  ).length;
  if (numberOfRequestsInGroup === 1) {
    return PRIORITY.Second;
  }
  return PRIORITY.Third;
};
