import React from 'react';
import { DESCRIPTION_COLOR_MAP } from '../constants';
const StatusDescription = ({ description }: { description: string }) => {
  return (
    <div className="flex items-center">
      <div className={`h-2.5 w-2.5 rounded-full ${DESCRIPTION_COLOR_MAP[description]}`}></div>
      <div className="ml-1 font-bold">{description}</div>
    </div>
  );
};

export default StatusDescription;
