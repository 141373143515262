import React, { useState } from 'react';
import { ConversionLevelOption, Grade } from '../types';
import { DEFAULT_ERROR_MESSAGE, NOTIFY_DURATION, CompletionStatus } from '../constants';
import { useUpdateGradeRecord } from '../mutations';
import LetterGradeInput from './LetterGradeInput';

type LetterGradeProps = {
  studentId: number;
  record: Partial<Grade>;
};
const LetterGrade = ({ studentId, record }: LetterGradeProps) => {
  const [isNotifying, setIsNotifying] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const updateGrade = useUpdateGradeRecord(studentId, 'raw_score');
  const isPending = record.completion_status === CompletionStatus.PENDING;

  const saveGrade = async (option: ConversionLevelOption) => {
    const convertedGrade = option.stored_value;
    try {
      await updateGrade.mutateAsync(convertedGrade);
      setErrorMessage(null);
      setIsNotifying(true);
      setTimeout(() => {
        setIsNotifying(false);
      }, NOTIFY_DURATION);
    } catch {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  };

  return (
    <LetterGradeInput
      onChange={saveGrade}
      isPending={isPending}
      value={record.score}
      errorMessage={errorMessage}
      isNotifying={isNotifying}
      studentId={studentId}
    />
  );
};

export default LetterGrade;
