import React, { Fragment } from 'react';
import classNames from 'classnames';
import useClassSettingsActions from '../stores/useClassSettingStore';
import BooleanToggle from '../common/BooleanToggle';
import { FieldValueGroup, PersonPermissions, PersonPermissionsByRole, Properties } from '../types';
import LockIcon from '../common/LockIcon';
import { noop } from 'lodash';

const PermissionsTable = ({
  permissions,
  fvgs,
}: {
  permissions: PersonPermissionsByRole;
  fvgs: FieldValueGroup;
}) => {
  const { setPermissionModal } = useClassSettingsActions((state) => state.actions);
  const personName = (person: PersonPermissions) => {
    return `${person.nick_name || person.first_name} ${person.last_name}`;
  };
  const personPermissions = (person: PersonPermissions, field_name: string): Properties => {
    return {
      db_source: 'class_permission',
      target: field_name,
      class_id: 0,
      description: field_name,
      value: person[field_name],
      placeholder_value: '',
      teacher_access_strategy: 'read_only',
      sort_key: 0,
      input_type: 'checkbox',
      dropdown_options: {},
    };
  };

  return (
    <div className="category-wrapper border-2 border-solid rounded-xl m-4 p-4 px-6">
      {/* Card Header */}
      <div className="category-header divide-y-2 divide-y-reverse">
        <h4 className="text-lg font-medium leading-6 text-gray-900">Permissions</h4>
      </div>

      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle px-4">
            <table className="text-center min-w-full">
              <thead className="bg-white select-none">
                <tr>
                  <th>{/* Name */}</th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-sm font-semibold text-gray-900 hidden md:table-cell"
                  >
                    View Grades
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-sm font-semibold text-gray-900 hidden md:table-cell"
                  >
                    Update Grades
                  </th>
                  <th>{/* All Permissions */}</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {Object.entries(permissions).map(([role, people]) => (
                  <Fragment key={fvgs[122][role]}>
                    {/* Group teachers by role */}
                    <tr className="border-t border-gray-200">
                      <th
                        colSpan={5}
                        scope="colgroup"
                        className="bg-gray-50 py-2 pl-5 pr-3 text-left text-sm font-semibold text-gray-900 select-none"
                      >
                        {fvgs[122][role]}
                      </th>
                    </tr>
                    {/* Show grade permissions */}
                    {people.map((person, personIdx) => (
                      <tr
                        key={person.class_permission_pk}
                        className={classNames('border-t', {
                          'border-gray-300': personIdx === 0,
                          'border-gray-200': personIdx !== 0,
                        })}
                      >
                        <td className="py-4 pl-5 pr-3 text-left text-sm font-medium text-gray-900 select-none">
                          {personName(person)}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 select-none hidden md:table-cell">
                          <div className="flex items-center justify-center">
                            <BooleanToggle
                              settingProperties={personPermissions(person, 'view_grades')}
                              setServerError={noop}
                              serverError={null}
                              isEditable={false}
                            />
                            <LockIcon isDisplayed={true} />
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 select-none hidden md:table-cell">
                          <div className="flex items-center justify-center">
                            <BooleanToggle
                              settingProperties={personPermissions(person, 'update_grades')}
                              setServerError={noop}
                              serverError={null}
                              isEditable={false}
                            />
                            <LockIcon isDisplayed={true} />
                          </div>
                        </td>
                        <td className="px-3 py-4 text-xs sm:text-sm flex justify-end">
                          {/* Opens a modal to display all permissions for the person */}
                          <button
                            type="button"
                            className="w-50 rounded-md bg-indigo-600 px-3 py-2 text-xs sm:text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => {
                              setPermissionModal(true, personName(person), person);
                            }}
                          >
                            View All Permissions
                          </button>
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionsTable;
