import React from 'react';
import { FIELD_HEIGHT } from '../constants';

type AssignmentIconProps = {
  iconType: string;
  openSidebar: () => void;
};
const AssignmentIcon = ({ iconType, openSidebar }: AssignmentIconProps) => {
  return (
    <div className={`w-16 text-neutral-4 h-[${FIELD_HEIGHT}]`}>
      <i
        data-testid={`assignmentIcon-${iconType}`}
        className={`w-3 h-[${FIELD_HEIGHT}] ${iconType} leading-[${FIELD_HEIGHT}] text-3xl items-center flex  cursor-pointer hover:text-neutral-3`}
        onClick={openSidebar}
      />
    </div>
  );
};

export default AssignmentIcon;
