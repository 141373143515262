import React from 'react';
import {
  useCourseRequestState,
  useStudentState,
} from '../../../../../stores/useCourseRequestsStore';
import CourseRequestFormSelect from '../../shared/CourseRequestFormSelect';
import { DEFAULT_PRIORITY_OPTIONS } from '../../constants';
import { CourseRequestFormData } from '../../types';
import classNames from 'classnames';
import { showField } from '../../helpers';

type CourseRequestFormProps = {
  formValues: CourseRequestFormData;
  setFormValues: (data: CourseRequestFormData) => void;
  onSubmit: () => void;
  errorSubmitting: string;
  isLoading: boolean;
};
const CourseRequestForm = ({
  formValues,
  setFormValues,
  onSubmit,
  errorSubmitting,
  isLoading,
}: CourseRequestFormProps) => {
  const { grading_periods: gradingPeriods, enrollment_levels: enrollmentLevels } =
    useCourseRequestState();
  const { isFacultyPortal, portalConfig } = useStudentState();

  const onChange = (field: string, value: string | number | boolean) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const buttonClasses = `bg-green-4 text-white rounded-md px-4 py-2 mt-4 ${
    isLoading && 'opacity-75'
  }`;

  const checkboxClasses = classNames(`h-4 w-4 rounded-sm ring-transparent`, {
    'bg-neutral-6 opacity-75': !isFacultyPortal,
  });

  return (
    <div>
      <div className="flex justify-between max-sm:flex-col max-sm:mt-8">
        <div className="flex justify-start flex-grow-1 w-full">
          <div className="w-48 mr-4">
            {showField('show_priority', portalConfig) && (
              <div className="flex justify-between p-0.5 items-center h-6">
                <div className="text-xs">Priority</div>
                <CourseRequestFormSelect
                  field="priority"
                  options={DEFAULT_PRIORITY_OPTIONS}
                  selectedValue={formValues.priority}
                  onChange={onChange}
                />
              </div>
            )}
            {showField('show_term', portalConfig) && (
              <div className="flex justify-between p-0.5 items-center h-6">
                <div className="text-xs">Term</div>
                <CourseRequestFormSelect
                  field="grading_period"
                  options={gradingPeriods}
                  selectedValue={formValues.grading_period}
                  onChange={onChange}
                />
              </div>
            )}
            {showField('show_level', portalConfig) && (
              <div className="flex justify-between p-0.5 items-center h-6">
                <div className="text-xs">Level</div>
                <CourseRequestFormSelect
                  field="enrollment_level"
                  options={enrollmentLevels}
                  selectedValue={formValues.enrollment_level}
                  onChange={onChange}
                />
              </div>
            )}
            {showField('show_approved', portalConfig) && (
              <div className="flex justify-between p-0.5 items-center h-6">
                <div className="text-xs">Approved</div>
                <input
                  type="checkbox"
                  onChange={(e) => onChange('approval_status', e.target.checked ? 1 : 0)}
                  checked={formValues.approval_status === 1}
                  className={checkboxClasses}
                  disabled={!isFacultyPortal}
                />
              </div>
            )}
            {showField('show_recommended', portalConfig) && (
              <div className="flex justify-between p-0.5 items-center h-6">
                <div className="text-xs">Recommended</div>
                <input
                  onChange={(e) => onChange('recommended', e.target.checked)}
                  type="checkbox"
                  checked={formValues.recommended}
                  className={checkboxClasses}
                  disabled={!isFacultyPortal}
                />
              </div>
            )}
          </div>
        </div>
        {showField('show_notes', portalConfig) && (
          <div className="flex-grow-1 w-full ml-3 max-sm:ml-0">
            <div className="text-xs pt-1">Notes</div>
            <textarea
              defaultValue={formValues.notes}
              onChange={(e) => onChange('notes', e.target.value)}
              className="h-24 w-[17rem] border-solid border-[#ddd] text-[12px] p-1 max-sm:mt-4 leading-4 ring-0"
            ></textarea>
          </div>
        )}
        <div className="flex-grow-1 w-full">{/* Empty */}</div>
      </div>
      <div>
        <button
          onClick={onSubmit}
          disabled={isLoading}
          className={buttonClasses}
        >
          Add Course Request
        </button>
      </div>
      <div className="text-red-3 text-sm mt-1">{errorSubmitting || ''}</div>
    </div>
  );
};

export default CourseRequestForm;
