import { TabType } from './types';
import classNames from 'classnames';
import React from 'react';
import useNavigationStore from './useNavigationStore';

type LmsSidebarTabProps = {
  tab: TabType;
  isExpanded?: boolean;
};

export const LmsSidebarTab = ({ tab, isExpanded = true }: LmsSidebarTabProps) => {
  if (!tab.isShowing) {
    return null;
  }

  const iconClasses = classNames(
    `nc-icon-outline ${tab.nc_icon_name} text-gray-600 text-xl group-hover:text-black`,
    {
      'text-black': tab.is_active,
    }
  );

  const linkClasses = classNames(
    'float-left flex hover:bg-blue-5 px-3 py-2 m-3 mb-0 bg-gray-100 items-center text-gray-900 font-medium rounded-md',
    {
      'bg-blue-5': tab.is_active,
      'w-full': isExpanded,
      'w-fit': !isExpanded,
      'text-black': tab.is_active,
      'vx-tooltipped vx-tooltipped--e': !isExpanded,
    }
  );

  const textClasses = classNames('ml-3 group-hover:text-black', {
    'text-black': tab.is_active,
    'text-gray-700': !tab.is_active,
  });

  const tabDescription = tab.description || '';
  const icon = (
    <div
      className={linkClasses}
      aria-label={tabDescription}
    >
      <i className={iconClasses}></i>
      {isExpanded && <div className={textClasses}>{tabDescription}</div>}
    </div>
  );

  return (
    <div className="w-full items-center flex">
      {tab.is_active ? (
        icon
      ) : (
        <a
          href={tab.url}
          className="w-full flex"
        >
          {icon}
        </a>
      )}
    </div>
  );
};

type LmsTabsProps = { isExpanded?: boolean };

const LmsSidebarTabs = ({ isExpanded = true }: LmsTabsProps) => {
  const tabs = useNavigationStore((state) => state.tabs);
  return (
    <>
      {tabs.map((tab, index) => (
        <LmsSidebarTab
          tab={tab}
          key={index}
          isExpanded={isExpanded}
        />
      ))}
    </>
  );
};

export default React.memo(LmsSidebarTabs);
