import classNames from 'classnames';
import React from 'react';

const LockIcon = ({ isDisplayed }: { isDisplayed: boolean }) => {
  if (!isDisplayed) {
    return null;
  }

  return (
    <div // tooltip area
      className="items-center w-5 h-5 vx-tooltipped vx-tooltipped--nw justify-center"
      aria-label="Locked"
    >
      <i className="px-1 nc-icon-glyph ui-1_lock"></i>
    </div>
  );
};

export default LockIcon;
