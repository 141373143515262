import { CourseRequestFormData, ZgOption } from './types';

export const DEFAULT_PRIORITY_OPTIONS: ZgOption[] = [
  { id: 0, description: 'Not Specified' },
  { id: 1, description: 'First' },
  { id: 2, description: 'Second' },
  { id: 3, description: 'Third' },
];

export enum PRIORITY {
  First = 1,
  Second = 2,
  Third = 3,
}

export const DEFAULT_COURSE_REQUEST_DATA: CourseRequestFormData = {
  course: 0,
  priority: 0,
  grading_period: 50,
  enrollment_level: 0,
  approval_status: 0,
  recommended: false,
  notes: '',
};

export const READ_ONLY_CONFIG = 'read-only';

export const ACCESS_STATUS_CONFIG_KEY = 'enabled';

export const CLOSE_MODAL_ANIMATION_DURATION = 300;
export const FAKE_LOADING_DURATION = 500;

export const CREDITS_HELP_TEXT =
  'Credits shown here are a calculation of the typical credits awarded based on prior year class data.';

export const FIELD_CONFIGS = [
  'show_term',
  'show_level',
  'show_approved',
  'show_recommended',
  'show_notes',
];

export const GRADES_PERMISSION_HELP_TEXT =
  'Grades may not be viewable for some classes based on view grades permissions';
