import React from 'react';

import { SelectField, TextField, Button } from 'components/shared/form_elements';

export default function SearchFilters(props) {
  const { config, values, handleChange, handleSearch, handleSearchClear, pageState } = props;

  const isPageLoading = pageState === 'LOADING';
  const isPageSearching = pageState === 'SEARCHING';
  const isPageSearched = pageState === 'SEARCHED';

  const isDisabled = isPageLoading || isPageSearching;
  const searchButtonText = isPageSearching ? 'Searching...' : 'Search';
  const showClearSearchButton = isPageSearched;

  return (
    <form
      className="vx-form search-filters"
      onSubmit={handleSearch}
      noValidate
    >
      <div className="ae-grid">
        <div className="ae-grid__item item-sm-10">
          <TextField
            name="integration_name"
            value={values.integration_name}
            config={config.integration_name}
            onChange={handleChange}
          />
        </div>
        <div className="ae-grid__item item-sm--auto">
          <input
            className="vx-button vx-button--primary"
            type="submit"
            value={searchButtonText}
            disabled={isDisabled}
          />
        </div>
        {showClearSearchButton && (
          <div className="ae-grid__item item-sm--auto">
            <Button
              className="vx-button vx-button--danger"
              disabled={isDisabled}
              onClick={handleSearchClear}
              value="Clear"
            />
          </div>
        )}
      </div>
    </form>
  );
}
