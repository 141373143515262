import React from 'react';

export default function FormLoader(props) {
  const { formTitle, message } = props;

  return (
    <div className="vx-form">
      <div className="vx-form-header">
        <h2>{formTitle}</h2>
      </div>
      <div className="vx-form-loader">
        <div className="vx-form-loader__spinner vx-loader-spinner"></div>
        <div className="vx-form-loader__message">{message}</div>
      </div>
    </div>
  );
}
