import React from 'react';
import classNames from 'classnames';
import { useClassState, useConfigurations } from '../../../../stores/useGradebookStore';
import { getDisplayDate } from '../helpers';
import moment from 'moment';
import { PARSE_DATE_FORMAT } from '../constants';

type EnrollmentStatusIconProps = {
  dateWithdrawn?: string;
  lateDateEnrolled?: string;
};
const EnrollmentStatusIcon = ({ dateWithdrawn, lateDateEnrolled }: EnrollmentStatusIconProps) => {
  const { today } = useClassState();
  const {
    display_date_withdrawn: displayDateWithdrawn,
    display_late_date_enrolled: displayLateEnrollment,
    gradebook_label: gradebookLabel,
  } = useConfigurations();
  const isWithdrawingFromClass = Boolean(dateWithdrawn) && displayDateWithdrawn;
  const isLateEnrolling = Boolean(lateDateEnrolled) && displayLateEnrollment;

  const getLateEnrollTooltipMessage = () => {
    const displayDate = getDisplayDate(lateDateEnrolled);
    return `Late enrollment date: ${displayDate}.`;
  };

  const getWithdrawTooltipMessage = () => {
    const todayMoment = moment(today, PARSE_DATE_FORMAT);
    const dueDateMoment = moment(dateWithdrawn, PARSE_DATE_FORMAT);
    const displayDate = getDisplayDate(dateWithdrawn);
    const mainMessage = `Withdraw date: ${displayDate}.`;

    if (todayMoment.isAfter(dueDateMoment, 'days') || todayMoment.isSame(dueDateMoment, 'days')) {
      const numOfDays = 10 - todayMoment.diff(dueDateMoment, 'days');
      if (numOfDays === 0) {
        return `${mainMessage} They will remain \nin the ${gradebookLabel} until the end of the day.`;
      }
      return `${mainMessage} They will remain \nin the ${gradebookLabel} for ${numOfDays} more calendar day${
        numOfDays > 1 ? 's' : ''
      }.`;
    }

    return mainMessage;
  };

  const getTooltipMessage = () => {
    let message = '';
    if (isLateEnrolling) {
      message += getLateEnrollTooltipMessage();
    }
    if (isLateEnrolling && isWithdrawingFromClass) {
      message += '\n\n';
    }
    if (isWithdrawingFromClass) {
      message += getWithdrawTooltipMessage();
    }
    return message;
  };

  const iconClasses = classNames('nc-icon-mini ui-e_round-e-info text-lg text-neutral-4 mr-1', {
    'text-yellow-500': isWithdrawingFromClass,
  });

  if (!isWithdrawingFromClass && !isLateEnrolling) {
    return null;
  }

  return (
    <>
      <div
        className="flex items-center ml-2 p-px cursor-help vx-tooltipped vx-tooltipped--e"
        aria-label={getTooltipMessage()}
      >
        <i className={iconClasses} />
      </div>
    </>
  );
};

export default EnrollmentStatusIcon;
