import React, { useState } from 'react';
import { Assignment, GridData, Student, StudentSidebarTab } from '../types';
import SidebarContainer from '../../../shared/SidebarContainer';
import StudentRow from './StudentRow';
import Sidebar from './sidebar/Sidebar';
import AssignmentHeader from '../common/AssignmentHeader';
import { getStudentColumnWidth } from '../helpers';
import { useConfigurations } from '../../../../stores/useGradebookStore';
import LmsMobileBanner from '../../navigation/LmsMobileBanner';
import { goBackToGrid } from '../Router';
import { useScreenSizes, useUserState } from '../../../../stores/useAppStore';
import BulkEdit from './BulkEdit';
import { useHotkeys } from 'react-hotkeys-hook';
import { COMPLETION_STATUS } from '../constants';
import { formatShortcut } from '../../../shared/utils';

const ColumnHeaders = () => {
  const { hide_gradebook_photos: hidePhotos } = useConfigurations();

  return (
    <div className="max-md:hidden px-3 h-8 border-b border-neutral-5 flex text-neutral-3">
      <div className={getStudentColumnWidth(hidePhotos)}></div>
      <div className="w-28">Grade</div>
      <div className="w-52">Assignment Status</div>
      <div className="w-52">Dropbox Status</div>
      <div className="w-16">{/* intentionally empty to fit assignment icons */}</div>
      <div className="w-16">{/* intentionally empty to fit assignment icons */}</div>
      <div className="w-16">{/* intentionally empty to fit assignment icons */}</div>
      <div className="w-16">Published?</div>
    </div>
  );
};

const AssignmentStudents = ({ data, assignment }: { data: GridData; assignment: Assignment }) => {
  const { isSm, isMd: isMobile } = useScreenSizes();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<Student>(null);
  const [selectedTab, setSelectedTab] = useState<StudentSidebarTab>('feedback');
  const { enable_keyboard_shortcuts: enableKeyboardShortcuts } = useConfigurations();
  const { moveFocusedStudentRow, setShortcutTrigger } = useUserState();
  const modalElement = document.getElementById('shared-modal');
  const shortcutsEnabled = enableKeyboardShortcuts && !sidebarIsOpen && !isMobile;

  const changeFocusedRow = (direction) => {
    moveFocusedStudentRow(data.students.length, direction);
  };

  useHotkeys(
    'return, shift+return',
    (event) =>
      changeFocusedRow(
        event.shiftKey ? -1 : 1 // return goes down the list, shift+return goes up
      ),
    {
      enabled: !modalElement,
      enableOnFormTags: ['input'],
    }
  );

  data.assignment_statuses.forEach((status) => {
    useHotkeys(
      formatShortcut(status.keyboard_shortcut),
      () => setShortcutTrigger({ type: COMPLETION_STATUS, id: status.id }),
      {
        enabled: Boolean(shortcutsEnabled) && Boolean(status.keyboard_shortcut),
        enableOnFormTags: ['input'],
        ignoreModifiers: true,
        preventDefault: shortcutsEnabled,
        splitKey: status.keyboard_shortcut === ',' ? '+' : ',',
      }
    );
  });

  const openSidebar = (student: Student, tab: StudentSidebarTab) => {
    if (sidebarIsOpen) {
      return;
    }
    setSelectedStudent(student);
    setSelectedTab(tab);
    setSidebarIsOpen(true);
  };

  const closeSidebar = () => {
    setSidebarIsOpen(false);
  };

  return (
    <>
      <LmsMobileBanner
        title="Students"
        subTitle={assignment.description}
        goBack={goBackToGrid}
      />
      <SidebarContainer
        isActive={sidebarIsOpen}
        closeSidebar={closeSidebar}
        onClose={closeSidebar}
        isScrollable={false}
        useMobileWrapper={false}
        navBarClass={'.nav-top'}
        overrideStyles="md:w-[40%] md:min-w-[400px]"
        useCloseBackground={isSm}
        renderSidebar={({ closeSidebar }) => {
          return (
            <Sidebar
              student={selectedStudent}
              selectedTab={selectedTab}
              changeTab={setSelectedTab}
              closeSidebar={closeSidebar}
              sidebarIsOpen={sidebarIsOpen}
            />
          );
        }}
      >
        <AssignmentHeader urlPrefix="students" />
        <div className="md:hidden flex justify-start pt-3 pr-3">
          <BulkEdit />
        </div>
        <ColumnHeaders />
        <div className="p-3 md:pb-5 md:h-[70vh] overflow-x-hidden">
          {data.students.map((student, rowIndex) => {
            return (
              <StudentRow
                student={student}
                key={student.person_fk}
                openSidebar={openSidebar}
                rowIndex={rowIndex}
              />
            );
          })}
        </div>
      </SidebarContainer>
    </>
  );
};

export default AssignmentStudents;
