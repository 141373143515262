import React from 'react';
import classNames from 'classnames';

const Tag = (props) => {
  if (!props.label) return null;

  const tagClassNames = classNames({
    'vx-tag': true,
    'vx-tag--no-text-transform': props.disableTextTransform,
  });

  return <div className={tagClassNames}>{props.label}</div>;
};

const defaultConfig = {
  valueSeparator: ',',
  disableTextTransform: false,
};

export default function TagsList(props) {
  const { value } = props;

  const config = { ...defaultConfig, ...props.config };

  const tags = value?.split(config.valueSeparator);
  if (!tags) return null;

  return tags.map((label, index) => (
    <Tag
      key={index}
      label={label}
      disableTextTransform={config.disableTextTransform}
    />
  ));
}
