import React from 'react';
import ReactSelect from 'react-select';
import { GridData, InputChildProps } from '../types';
import StatusDescription from './StatusDescription';
import DropdownIndicator from '../common/DropdownIndicator';
import { FIELD_HEIGHT } from '../constants';

type StatusDropdownProps = InputChildProps & {
  value: string | number;
  statuses: GridData['assignment_statuses'];
  onChange: (newValue: string | number) => void;
};
const StatusDropdown = ({ value, statuses, isDisabled, onChange }: StatusDropdownProps) => {
  let options = [];
  let selectedOption = null;
  options = statuses.map((status) => {
    const option = { value: status.id, label: status.description };
    if (status.id === value) {
      selectedOption = {};
      selectedOption.value = status.id;
      selectedOption.label = <StatusDescription description={status.description} />;
    }
    return option;
  });

  return (
    <div
      className={`w-36 h-[${FIELD_HEIGHT}]`}
      data-testid="assignment-status-dropdown-wrapper"
    >
      <ReactSelect
        value={selectedOption}
        options={options}
        styles={{
          valueContainer: (styles) => ({ ...styles, paddingLeft: '4px' }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          control: (styles) => ({
            ...styles,
            borderColor: '#a6a6a6',
            boxShadow: 0,
            '&:hover': {
              border: `1px solid #a6a6a6`,
            },
          }),
        }}
        onChange={(option) => onChange(option.value)}
        maxMenuHeight={200}
        minMenuHeight={200}
        menuPlacement="auto"
        isSearchable={false}
        isDisabled={isDisabled}
        components={{ DropdownIndicator }}
      />
    </div>
  );
};

export default StatusDropdown;
