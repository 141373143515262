import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
const FADE_IN_TIME = 100;
const FADE_OUT_TIME = 400;
const TEXT_COLOR_DURATION = 3000;

const ScheduleItem = ({ conference, isConflict, forceUpdate, showRemoval }) => {
  // This component logic handles the slide in/out transitions.
  // Values 'showSuccess' and 'showRemoval' are set in the update function
  // to signal which transition is needed.

  if (!conference.selected) {
    return null;
  }
  const ref = useRef();
  const [showType, setShowType] = useState('normal');

  useEffect(() => {
    resetAnimationVariables();
  }, [showRemoval]);

  useEffect(() => {
    if (showType === 'conflict' || showType === 'success') {
      setTimeout(() => {
        ref.current.classList.add('schedule-item');
      }, FADE_IN_TIME);
    }
  }, [showType]);

  const resetAnimationVariables = () => {
    if (conference.showSuccess) {
      conference.showSuccess = false;
      setShowType(isConflict ? 'conflict' : 'success');
      setTimeout(() => {
        setShowType('normal');
      }, TEXT_COLOR_DURATION);
    }

    if (conference.showRemoval) {
      conference.selected = false;
      conference.showRemoval = false;
      ref.current.classList.remove('schedule-item');

      // Update the parent component to re-render the lists to remove item
      setTimeout(() => {
        forceUpdate();
      }, FADE_OUT_TIME);
    }
  };

  const classes = classNames('vx-subtitle', {
    'schedule-item-hidden-success': showType === 'success',
    'schedule-item-hidden-conflict': showType === 'conflict',
    'schedule-item-hidden': conference.showRemoval || showType !== 'normal',
    'schedule-item': showType === 'normal',
  });
  return (
    <div
      ref={ref}
      className={classes}
      data-testid="mySchedule-slots"
    >
      {`${conference.teacherName} (${conference.studentName})`}
    </div>
  );
};

export default ScheduleItem;
