import React, { useEffect, useState, FC } from 'react';
import {
  useCourseRequestActions,
  useStudentState,
} from '../../../../stores/useCourseRequestsStore';
import Tabs from './Tabs';
import { default as CourseRequestsV1 } from '../V1/CourseRequestsList';
import { default as CourseRequestsV2 } from '../V2/CourseRequestsList';
import PreviousCourses from './PreviousCourses';
import CreditReport from './CreditReport';
import CurrentSchedule from './CurrentSchedule';
import { useFetch } from '../../../../hooks/useFetch';
import { getCourseRequestData } from '../resources';

const CourseRequests = () => {
  const { studentIsSelected, version, student, studentData } = useStudentState();
  const { setCourseRequestData } = useCourseRequestActions();
  const [activeTab, setActiveTab] = useState<string>('currentCourseRequests');
  const { data, sendFetchRequest: sendCourseRequestDataRequest, loading } = useFetch();

  const fetchList = () => {
    if (studentIsSelected) {
      getCourseRequestData(student.person_pk, sendCourseRequestDataRequest);
    }
  };

  useEffect(() => {
    fetchList();
  }, [student]);

  useEffect(() => {
    if (data) {
      setCourseRequestData({ ...data, current_schedule: studentData.schedule_url });
    }
  }, [data]);

  const TAB_TO_COMPONENT_MAP = {
    currentCourseRequests: version === 2 ? CourseRequestsV2 : CourseRequestsV1,
    previousCourses: PreviousCourses,
    creditReport: CreditReport,
    currentSchedule: CurrentSchedule,
  };
  const SelectedComponent = TAB_TO_COMPONENT_MAP[activeTab];

  return (
    <>
      {studentIsSelected && (
        <div>
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <SelectedComponent
            refetchList={fetchList}
            isLoadingList={loading}
          />
        </div>
      )}
    </>
  );
};

export default CourseRequests;
