import React from 'react';

import { compactify } from 'components/shared/utils';

// prettier-ignore
const DEFAULT_HEADER_VALUES = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Cache-Control': 'no-cache, no-store',
};

const defaultProps = {
  pathName: undefined,
  params: {},
  method: 'PATCH',
  onSuccess: (response) => {},
  onFailure: (response) => {
    throw new Error(response.error || response.status);
  },
};

// prettier-ignore
export default function useAsyncRequest(props) {
  const {
    pathName,
    params,
    method,
    authorizationToken,
    onSuccess,
    onFailure,
  } = { ...defaultProps, ...props };

  /**************************************************************************************************/

  const jsRouteFn = pathName && Routes[pathName];
  const jsRouteParams = {
    client: Portals.config.client,
    portal_name: Portals.config.portal_name,
    ...params,
    _options: true,
  };

  const requestUrl = jsRouteFn && jsRouteFn(jsRouteParams);

  /**************************************************************************************************/

  const requestHeaders = React.useMemo(() => new Headers({
    ...DEFAULT_HEADER_VALUES,
    'X-CSRF-Token': authorizationToken,
  }), [authorizationToken]);

  const __sendAsyncRequest = React.useCallback(async (url, options = {}) => {
    if (!url) throw new Error();

    const requestOptions = {
      method: options.method,
      headers: options.headers,
    };

    if (options.data) {
      requestOptions.body = JSON.stringify(options.data);
    }

    const response = await fetch(url, requestOptions);
    const responseBody = await response.json();

    if (response.ok) {
      onSuccess(responseBody);

      return responseBody;
    } else {
      onFailure(responseBody);
    }
  }, [onSuccess, onFailure]);

  /***************************************************************************************************
  ** sendAsyncRequest
  ***************************************************************************************************/

  const sendAsyncRequest = React.useCallback(async (url, options = {}) => {
    const requestOptions = {
      method: method,
      headers: requestHeaders,
      ...options,
    };

    return await __sendAsyncRequest(url, requestOptions);
  }, [
    __sendAsyncRequest,
    requestHeaders,
    method,
  ]);

  /***************************************************************************************************
  ** sendAsyncGetRequest
  ***************************************************************************************************/

  const sendAsyncGetRequest = React.useCallback(async (additionalParams = {}) => {
    const requestOptions = {
      method: 'GET',
      headers: requestHeaders,
    };

    const jsRouteParams = {
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
      ...params,
      ...compactify(additionalParams),
      _options: true,
    };

    const __requestUrl = jsRouteFn && jsRouteFn(jsRouteParams);

    return await __sendAsyncRequest(__requestUrl, requestOptions);
  }, [
    __sendAsyncRequest,
    requestHeaders,
    jsRouteFn,
    params,
  ]);

  /***************************************************************************************************
  ** sendAsyncPatchRequest
  ***************************************************************************************************/

  const sendAsyncPatchRequest = React.useCallback(async (data) => {
    const requestOptions = {
      method: 'PATCH',
      headers: requestHeaders,
      data: data,
    };

    return await __sendAsyncRequest(requestUrl, requestOptions);
  }, [
    __sendAsyncRequest,
    requestHeaders,
    requestUrl,
  ]);

  /***************************************************************************************************
  ** sendAsyncPostRequest
  ***************************************************************************************************/

  const sendAsyncPostRequest = React.useCallback(async (data) => {
    const requestOptions = {
      method: 'POST',
      headers: requestHeaders,
      data: data,
    };

    return await __sendAsyncRequest(requestUrl, requestOptions);
  }, [
    __sendAsyncRequest,
    requestHeaders,
    requestUrl,
  ]);

  /***************************************************************************************************
  ** sendAsyncGetRequest
  ***************************************************************************************************/

  const sendAsyncDeleteRequest = React.useCallback(async (url = requestUrl) => {
    const requestOptions = {
      method: 'DELETE',
      headers: requestHeaders,
    };

    return await __sendAsyncRequest(url, requestOptions);
  }, [
    __sendAsyncRequest,
    requestUrl,
    requestHeaders,
  ]);

  /**************************************************************************************************/

  switch (method.toUpperCase()) {
    case 'GET':
      return sendAsyncGetRequest;
    case 'POST':
      return sendAsyncPostRequest;
    case 'PATCH':
      return sendAsyncPatchRequest;
    case 'DELETE':
      return sendAsyncDeleteRequest;
    default:
      return sendAsyncRequest;
  }
}
