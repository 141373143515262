import React, { FC, useEffect, useRef } from 'react';
import Cell from './Cell';
import HeaderCell from './HeaderCell';
import { useFilters } from '../../../../stores/useGradebookStore';
import { Assignment } from '../types';
import classNames from 'classnames';

type TodayCellProps = {
  isHeader?: boolean;
  assignment: Assignment;
};
const TodayCell: FC<TodayCellProps> = ({ isHeader, assignment, children }) => {
  const ref = useRef(null);
  const { sortAssignmentsByNewest: isDescending } = useFilters();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [ref.current, isDescending]);

  const tooltipClasses = classNames('vx-tooltipped', {
    'vx-tooltipped--e': assignment.todayLine.showLine,
    'vx-tooltipped--w': assignment.todayLine.showLineAfter,
  });

  let content = null;
  if (isHeader) {
    content = (
      <div
        aria-label="Today"
        className={tooltipClasses}
      >
        <HeaderCell classOverrides="w-1 h-full m-0 p-0 pb-[-4px] bg-blue-5">
          <div ref={ref}></div>
        </HeaderCell>
      </div>
    );
  } else {
    content = (
      <div
        aria-label="Today"
        className={tooltipClasses}
      >
        <Cell classOverrides="w-1 h-12 bg-blue-5"></Cell>
      </div>
    );
  }

  return (
    <>
      {assignment.todayLine.showLine && content}
      {children}
      {assignment.todayLine.showLineAfter && content}
    </>
  );
};

export default TodayCell;
