import React, { FC } from 'react';
import classNames from 'classnames';

type CellProps = {
  classOverrides?: string;
  inlineStyles?: Record<string, string>;
  tooltip?: string;
};
const Cell: FC<CellProps> = ({ classOverrides, children, inlineStyles = {}, tooltip }) => {
  const classes = classNames(`leading-10 rounded-md mr-0.5 mb-0.5 h-10`, {
    [classOverrides]: classOverrides,
    'vx-tooltipped vx-tooltipped--s': tooltip,
  });

  return (
    <td className="text-center leading-8 font-semibold h-10">
      <div
        className={classes}
        data-testid="grade-cell"
        style={inlineStyles}
        aria-label={tooltip}
      >
        {children}
      </div>
    </td>
  );
};

export default Cell;
