import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import BulkEdit from '../students/BulkEdit';
import { UrlPrefix } from '../types';
import { useAssignment } from '../queries';
import { useUserState } from '../../../../stores/useAppStore';

type ArrowSwitcherProps = {
  prevAssignmentId: number;
  nextAssignmentId: number;
  urlPrefix: UrlPrefix;
};
const ArrowSwitcher = ({ prevAssignmentId, nextAssignmentId, urlPrefix }: ArrowSwitcherProps) => {
  const { setFocusedStudentRow } = useUserState();
  const getDivClasses = (id) =>
    classNames('text-center text-black leading-10 w-[50%]', {
      'hover:bg-neutral-300': id,
      'pointer-events-none bg-neutral-50 text-neutral-300': !id,
    });

  const handleClick = () => {
    // reset to top of list
    setFocusedStudentRow(-1);
  };

  const leftArrow = (
    <Link
      to={`/${prevAssignmentId}/${urlPrefix}`}
      className={`border-r border-neutral-200 ${getDivClasses(prevAssignmentId)}`}
      onClick={handleClick}
    >
      <i className="nc-icon-glyph arrows-1_minimal-left"></i>
    </Link>
  );

  const rightArrow = (
    <Link
      to={`/${nextAssignmentId}/${urlPrefix}`}
      className={getDivClasses(nextAssignmentId)}
      onClick={handleClick}
    >
      <i className="nc-icon-glyph arrows-1_minimal-right"></i>
    </Link>
  );

  return (
    <div className="border border-neutral-200 h-10 w-24 rounded-md flex">
      {leftArrow}
      {rightArrow}
    </div>
  );
};

const AssignmentHeader = ({ urlPrefix }: { urlPrefix: UrlPrefix }) => {
  const assignment = useAssignment();
  const isStudentsPage = urlPrefix === 'students';

  if (!assignment) return null;

  return (
    <div className="flex justify-between p-5 max-md:hidden items-center">
      <div className="flex">
        <ArrowSwitcher
          prevAssignmentId={assignment.prevAssignmentId}
          nextAssignmentId={assignment.nextAssignmentId}
          urlPrefix={urlPrefix}
        />
        <div className="block ml-4">
          <div className="w-fit bg-red-400 text-white text-xs rounded-sm px-1 py-0.5">
            {assignment.type_description}
          </div>
          <div className="text-lg">{assignment.description}</div>
        </div>
      </div>
      {isStudentsPage && <BulkEdit />}
    </div>
  );
};

export default AssignmentHeader;
