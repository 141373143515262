/* eslint-disable camelcase */
import React from 'react';
import ReactSelect from 'react-select';

import { HTTP_METHOD, useFetch } from '../../../hooks/useFetch';
import { useFocus } from '../../../hooks/useFocus';

const STATUS_CLASS_ACTIVE = 1;

const SearchBox = ({ onFocus, onBlur = () => {}, onSelect, className }) => {
  const [selectRef, setSelectFocus] = useFocus();
  const {
    loading,
    data,
    sendFetchRequest: getFacultyProfileClassNavigationListRequest,
  } = useFetch();

  React.useEffect(() => {
    fetchClasses();
  }, []);

  React.useEffect(() => {
    if (onFocus) setSelectFocus();
  }, [onFocus]);

  const fetchClasses = async () => {
    const url = Routes.class_navigation_list_path({
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
    });

    await getFacultyProfileClassNavigationListRequest({
      url,
      method: HTTP_METHOD.get,
    });
  };

  const mapClasses = (classes) => {
    if (!classes) return [];

    return (
      classes
        // Map specific values to display on dropdown
        .map(({ class_pk, class_id, description, school_year, status }) => ({
          classPk: class_pk,
          value: class_pk,
          label: `${class_id}: ${description}`,
          schoolYear: school_year,
          isActive: status == STATUS_CLASS_ACTIVE,
        }))
        // Sort by schoolYear
        .sort((a, b) => b.schoolYear - a.schoolYear)
    );
  };

  const formatOptionLabel = ({ label, isActive }) => (
    <div className={`vx-searchbox__option ${!isActive ? 'vx-searchbox__option--non-active' : ''}`}>
      <div>{label}</div>
    </div>
  );

  const handleOptionChange = ({ classPk, label }) => {
    onSelect(classPk, label);
  };

  return (
    !loading && (
      <ReactSelect
        className={className}
        closeMenuOnSelect={true}
        formatOptionLabel={formatOptionLabel}
        options={mapClasses(data)}
        onBlur={onBlur}
        onChange={(option) => handleOptionChange(option)}
        openMenuOnFocus
        ref={selectRef}
      />
    )
  );
};

export default SearchBox;
