import { createBrowserHistory } from 'history';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const FormikNavigationBlocker = () => {
  const { isValid, isSubmitting } = useFormikContext();
  let history = createBrowserHistory();

  useEffect(() => {
    let unblock = history.block((_tx) => {});
    if (isValid && !isSubmitting) unblock();
  }, [isValid, isSubmitting]);

  return null;
};

export default FormikNavigationBlocker;
