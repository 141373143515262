import React from 'react';
import classNames from 'classnames';
import { getDateFormat, getTimeConflict, getTimeString } from './helpers';
import ScheduleItem from './ScheduleItem';

const EmptyState = () => (
  <div className="empty-state">
    <i className="icon nc-icon-mini x3 ui-1_calendar-60"></i>
    <h4>No conferences selected</h4>
    <p>Schedule a conference on the left side.</p>
  </div>
);

const ScheduleList = ({ schedule, timeConflicts, dateFormat, timeFormat, forceUpdate }) => {
  const showConflict = (conference) => {
    if (!conference.showSuccess) {
      return false;
    }
    return getTimeConflict(
      timeConflicts,
      conference.start_date,
      conference.start_time,
      conference.end_time
    );
  };

  const buildConferenceSlots = (reservationTime) => {
    return reservationTime.conferences.map((conference) => {
      const isConflict = showConflict(conference);
      return (
        <ScheduleItem
          conference={conference}
          isConflict={isConflict}
          forceUpdate={forceUpdate}
          showRemoval={conference.showRemoval}
          key={conference.event_fk}
        />
      );
    });
  };

  const doesTimeHaveSelections = (reservationTime) => {
    return (
      reservationTime.conferences &&
      reservationTime.conferences.some((conference) => conference.selected)
    );
  };

  const buildTimes = (reservationDate) => {
    return reservationDate.times.map((reservationTime, idx) => {
      if (!doesTimeHaveSelections(reservationTime)) {
        return null;
      }
      const { startTime, endTime } = reservationTime;
      const conflict = getTimeConflict(
        timeConflicts,
        reservationDate.startDate,
        startTime,
        endTime
      );
      const hasTimeOverlap = conflict && conflict.hasOverlaps;

      const borderClasses = classNames({
        'vx-list__item-border': true,
        'vx-list__item-border--orange': conflict,
        'vx-list__item-border--green': !conflict,
        'schedule-item-border--noSpace': idx !== 0 && hasTimeOverlap,
      });

      const itemClasses = classNames({
        'vx-list__item': true,
        'schedule-item--noSpace': idx !== 0 && hasTimeOverlap,
      });

      return (
        <li
          className={itemClasses}
          key={reservationTime.epochStart + reservationTime.epochEnd}
          data-testid="mySchedule-time"
        >
          <div
            className={borderClasses}
            data-testid="mySchedule-border"
          ></div>
          <div className="vx-schedule-item">
            {getTimeString(timeFormat, startTime, endTime)}
            {conflict && (
              <i className="nc-icon-mini ui-e_round-e-alert conferences-schedule-alert"></i>
            )}
          </div>
          <div>{buildConferenceSlots(reservationTime)}</div>
        </li>
      );
    });
  };

  return schedule.map((reservationDate) => {
    const currentDate = moment(reservationDate.startDate).format(getDateFormat(dateFormat));
    return (
      <div key={reservationDate.dateEpoch}>
        <h6
          className="date-header"
          data-testid="mySchedule-date"
        >
          {currentDate}
        </h6>
        <ul className="vx-list">{buildTimes(reservationDate)}</ul>
      </div>
    );
  });
};

const MySchedule = ({
  conferenceUrl,
  schedule,
  timeConflicts,
  dateFormat,
  timeFormat,
  forceUpdate,
}) => {
  return (
    <div className="vx-card conferences-mySchedule ml-10">
      <div className="vx-card__title">
        <div className="vx-card__actions--right">
          <a
            className="vx-button vx-button--primary vx-button--small vx-tooltipped vx-tooltipped--sw"
            aria-label="Print Schedule Document"
            href={conferenceUrl}
            target="_blank"
            rel="noreferrer"
          >
            <span className="nc-icon-glyph tech_print-fold printer-icon"></span>
          </a>
        </div>
        <h4>My Schedule</h4>
      </div>
      <div className="vx-card__content">
        {schedule && schedule.length ? (
          <ScheduleList
            schedule={schedule}
            timeConflicts={timeConflicts}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            forceUpdate={forceUpdate}
          />
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

export default MySchedule;
