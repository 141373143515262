import React from 'react';

import Field from './Field';

const defaultConfig = {
  defaultValue: '',
  disabled: false,
  required: false,
  placeholder: undefined,
  step: 1,
  min: 0,
  max: 1000,
};

export default function NumberField(props) {
  const { name, type, value, error, onChange } = props;

  const config = { ...defaultConfig, ...props.config };

  const isDisabled = props.disabled || config.disabled;

  const inputRef = React.useRef();
  const handleLabelClick = () => inputRef.current.focus();

  return (
    <Field
      className="vx-number-field"
      onLabelClick={handleLabelClick}
      {...props}
    >
      <input
        className="vx-number-field__input"
        type="number"
        name={name}
        value={value || config.defaultValue}
        onChange={onChange}
        disabled={isDisabled}
        required={config.required}
        placeholder={config.placeholder}
        step={config.step}
        min={config.min}
        max={config.max}
        ref={inputRef}
      />
    </Field>
  );
}
