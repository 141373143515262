/* eslint-disable camelcase */

import { ElementProps, FieldMetadata, MetadataType, SubmissionInfo } from '../types';
import {
  FORM_MODE_ADMIN_EDIT,
  FORM_MODE_EDIT,
  FORM_MODE_EDIT_PRESET,
  FORM_MODE_NORMAL,
  FORM_MODE_VIEW_SUBMISSION,
} from './form-helpers';

export const OPERATION_TYPE_NONE = 0;
export const OPERATION_TYPE_ADD = 1;
export const OPERATION_TYPE_UPDATE = 2;
export const OPERATION_TYPE_DELETE = 3;

export const HIDDEN = 0;
export const OPTIONAL = 1;
export const REQUIRED = 2;
export const READ_ONLY = 3;
export const HIDDEN_PRESET = 4;

export const UPDATE_STATUS_READ_ONLY = 3;

export const getFieldKeypath = (element: ElementProps, sectionIndex = '0') =>
  `${element.section_data_keypath}#${sectionIndex}__${element.data_keypath}`;

export const getFieldConfiguration = (element: ElementProps, keypath: string) => {
  const config = (element.element_configuration || []).find(
    (element) => element.category === 'Field' && element.keypath === keypath
  );
  return config ? config.value : null;
};

export const formHasData = (submission: SubmissionInfo) => {
  if (submission === undefined) {
    return false;
  }
  return parseInt(submission.submission_pk) > 0 || parseInt(submission.record_fk) > 0;
};

export const getStatusConfigurationKey = (
  formMode: string,
  operationType: string,
  submission: SubmissionInfo
) => {
  const operationTypeEnum = parseInt(operationType);
  switch (formMode) {
    case FORM_MODE_VIEW_SUBMISSION:
    case FORM_MODE_EDIT:
      return 'update_status';

    case FORM_MODE_ADMIN_EDIT:
      if (operationTypeEnum === OPERATION_TYPE_ADD) {
        return 'insert_status';
      } else if (operationTypeEnum === OPERATION_TYPE_UPDATE) {
        return 'update_status';
      }
      return 'update_status';

    case FORM_MODE_NORMAL:
    default:
      if (formHasData(submission)) {
        return 'update_status';
      }
      return 'insert_status';
  }
};

export const submitButtonType = (formMode: string, submission: SubmissionInfo) => {
  switch (formMode) {
    case FORM_MODE_NORMAL:
      if (formHasData(submission)) {
        return {
          keypath: 'update_button.label',
          defaultLabel: 'Update',
        };
      }
      return {
        keypath: 'add_button.label',
        defaultLabel: 'Submit',
      };

    case FORM_MODE_EDIT_PRESET:
      return {
        keypath: '',
        defaultLabel: 'Save Changes',
      };
    default:
      return {
        keypath: '',
        defaultLabel: 'Submit',
      };
  }
};
export const getFieldStatus = (
  element: ElementProps,
  formMode: string,
  operationType: string,
  submission: SubmissionInfo
) => {
  const key = getStatusConfigurationKey(formMode, operationType, submission);
  return element[key];
};

export const isFieldVisible = (
  element: ElementProps,
  formMode: string,
  operationType: string,
  submission: SubmissionInfo
) => {
  const status = getFieldStatus(element, formMode, operationType, submission);
  switch (formMode) {
    case FORM_MODE_NORMAL:
      return [OPTIONAL, REQUIRED, READ_ONLY].includes(status);
    case FORM_MODE_EDIT:
    case FORM_MODE_EDIT_PRESET:
    case FORM_MODE_VIEW_SUBMISSION:
    case FORM_MODE_ADMIN_EDIT:
      return [OPTIONAL, REQUIRED, READ_ONLY, HIDDEN_PRESET].includes(status);
    default:
      return false;
  }
};

export const isFieldHiddenDefault = (
  element: ElementProps,
  formMode: string,
  operationType: string,
  submission: SubmissionInfo
) => {
  return getFieldStatus(element, formMode, operationType, submission) === HIDDEN_PRESET;
};

export const isFieldReadOnly = (
  element: ElementProps,
  formMode: string,
  operationType: string,
  submission: SubmissionInfo
) => {
  return getFieldStatus(element, formMode, operationType, submission) === READ_ONLY;
};

export const isFieldRequired = (
  element: ElementProps,
  formMode: string,
  operationType: string,
  submission: SubmissionInfo
) => {
  return getFieldStatus(element, formMode, operationType, submission) === REQUIRED;
};

export const isFieldPlainText = (
  element: ElementProps,
  formMode: string,
  operationType: string,
  submission: SubmissionInfo
) => {
  switch (formMode) {
    case FORM_MODE_NORMAL:
    case FORM_MODE_ADMIN_EDIT:
      return isFieldReadOnly(element, formMode, operationType, submission);
    case FORM_MODE_EDIT:
    case FORM_MODE_EDIT_PRESET:
      return false;
    case FORM_MODE_VIEW_SUBMISSION:
    default:
      return true;
  }
};

export const isParentElement = (element: ElementProps) => element.parent_element_fk === 0;

export const getFieldMetadata = ({
  element,
  formMode,
  operationType = '1',
  submission = {},
}: FieldMetadata): MetadataType => ({
  dataKeypath: getFieldKeypath(element),
  isFieldHiddenDefault: isFieldHiddenDefault(element, formMode, operationType, submission),
  isFieldVisible: isFieldVisible(element, formMode, operationType, submission),
  isRequired: isFieldRequired(element, formMode, operationType, submission),
  isPlainText:
    isFieldPlainText(element, formMode, operationType, submission) ||
    isFieldReadOnly(element, formMode, operationType, submission),
});

const RESPONSE_GRID_SIZE_MAP = {
  10: 2,
  9: 3,
  8: 4,
};

const DEFAULT_GRID_SIZE = 5;

export const getResponseGridSize = (element: ElementProps) =>
  RESPONSE_GRID_SIZE_MAP[(element.element_value_list_items || []).length] || DEFAULT_GRID_SIZE;
