import React from 'react';
import { useCourseRequestState } from '../../../../stores/useCourseRequestsStore';
import LoadingSpinner from '../../../shared/LoadingSpinner';

type PreviousCoursesProps = {
  isLoadingList: boolean;
};
const PreviousCourses = ({ isLoadingList }: PreviousCoursesProps) => {
  const { previous_courses: previousCourses } = useCourseRequestState();

  if (isLoadingList) {
    return <LoadingSpinner />;
  }

  const subjectsArray = [];
  let currentSubject;
  previousCourses.forEach((course) => {
    if (currentSubject !== course.subject) {
      currentSubject = course.subject;
      subjectsArray.push({ subject: currentSubject, courses: [course] });
    } else {
      subjectsArray[subjectsArray.length - 1].courses.push(course);
    }
  });

  return (
    <div className="px-2 overflow-auto">
      <table className="td:px-4 td:py-2 td:border">
        <thead className="bg-neutral-5 border-b border-b-neutral-2 font-normal text-left">
          <tr className="">
            <th className="w-40 px-1 py-0.5">Year</th>
            <th className="w-24 py-0.5">Grade Level</th>
            <th className="w-[220px] px-1 py-0.5">Course</th>
            <th className="w-[220px] px-1 py-0.5">Class</th>
            <th className="w-[220px] px-1 py-0.5">Teacher</th>
          </tr>
        </thead>
        {subjectsArray.length === 0 ? (
          <tr className="text-neutral-3">No Records Found</tr>
        ) : (
          subjectsArray.map((coursesArray, idx) => {
            const shade = idx % 2 === 0 ? 'bg-white' : 'bg-neutral-6';
            return (
              <tbody key={coursesArray.subject + idx.toString()}>
                <tr className="text-left font-light border-b">
                  <th className="w-40 italic font-semibold pt-2 border-b border-neutral-2">
                    {coursesArray.subject}
                  </th>
                  <th
                    colSpan={4}
                    className="border-b border-neutral-2"
                  ></th>
                </tr>
                {coursesArray.courses.map((course, idx) => {
                  return (
                    <tr
                      key={course.id + idx.toString()}
                      className={`border-b font-light ${shade}`}
                    >
                      <td className="px-1 py-0.5">{course.year}</td>
                      <td className="px-1 py-0.5">{course.grade_level}</td>
                      <td className="px-1 py-0.5">{course.course}</td>
                      <td className="px-1 py-0.5">{course.class_name}</td>
                      <td className="px-1 py-0.5">{course.teacher_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            );
          })
        )}
      </table>
    </div>
  );
};

export default PreviousCourses;
