import React from 'react';
import { contrastingColor, getCellDateColor, getDisplayDate } from '../helpers';
import { Assignment } from '../types';
import HeaderCell from './HeaderCell';
import { Link } from 'react-router-dom';
import { useClassState } from '../../../../stores/useGradebookStore';
import TodayCell from './TodayCell';
import { useUserState } from '../../../../stores/useAppStore';
import AssignmentPreview from './AssignmentPreview';

const AssignmentCell = ({ assignment }: { assignment: Assignment }) => {
  const bgColor = assignment.color || '#d87474';
  const textColor = contrastingColor(bgColor);
  const { today } = useClassState();
  const cellDateColor = getCellDateColor(today, assignment.due_date);
  const { setFocusedStudentRow } = useUserState();

  return (
    <TodayCell
      isHeader={true}
      assignment={assignment}
    >
      <HeaderCell classOverrides={`w-36 group hover:bg-gray-400 ${cellDateColor}`}>
        <Link
          to={`${assignment.class_assignment_pk}/assignment`}
          className="text-black flex flex-col justify-end h-full"
          onClick={() => setFocusedStudentRow(-1)}
          data-testid="assignment-link"
        >
          <div>
            <div
              className="uppercase w-fit px-1 text-xs rounded-0.5 line-clamp-2 text-ellipsis"
              style={{ backgroundColor: bgColor, color: textColor }}
              id={`assignment-${assignment.due_date}`}
              title={assignment.type_description}
            >
              {assignment.type_description}
            </div>
            <div
              className="mt-1 overflow-hidden text-ellipsis leading-4 break-words line-clamp-2"
              title={assignment.description}
            >
              {assignment.description}
            </div>
          </div>
          <div className="mt-auto text-sm font-light flex relative justify-between">
            {getDisplayDate(assignment.due_date || assignment.assignment_date)}
            <div className="invisible group-hover:visible font-bold text-sm ml-1">EDIT</div>
            <i className="invisible group-hover:visible nc-icon-glyph ui-1_edit-72 text-lg  absolute right-8  leading-5" />
          </div>
          <AssignmentPreview assignment={assignment} />
        </Link>
      </HeaderCell>
    </TodayCell>
  );
};

export default AssignmentCell;
