import React from 'react';
import Field from '../Field';

export default function NumberField(props) {
  const { name, type, value, error, onChange, config } = props;

  const inputRef = React.useRef();

  const handleLabelClick = () => {
    inputRef.current.focus();
  };

  return (
    <Field
      className="vx-number-field"
      onLabelClick={handleLabelClick}
      {...props}
    >
      <input
        className="vx-number-field__input"
        type="number"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={config.placeholder}
        required={config.required}
        disabled={config.disabled}
        step={config.step}
        min={config.min}
        max={config.max}
        ref={inputRef}
      />
    </Field>
  );
}
