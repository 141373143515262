import React, { useState } from 'react';
import { handleUpdateRequest } from './Endpoints';
import { useFetch } from '../../../hooks/useFetch';
import usePhotoGalleryStore from '../../../stores/usePhotoGalleryStore';
import Modal from '../../shared/Modal';

function RenameGalleryModal({ internalClassId, gallery }) {
  const [renameGallery, setRenameGallery] = useState({ id: null, name: '' });
  const [errorMessage, setErrorMessage] = useState(null);
  const { actions } = usePhotoGalleryStore();
  const { data, sendFetchRequest } = useFetch();

  const handleNameChange = async () => {
    try {
      const requestFormData = {
        internal_class_id: internalClassId,
        galleryId: renameGallery.id,
        galleryName: renameGallery.name,
      };
      await handleUpdateRequest(requestFormData, 'name', renameGallery.name, sendFetchRequest);
    } catch (error) {
      setErrorMessage('Something went wrong while renaming the gallery. Please try again.');
    }
    actions.updateGallery(renameGallery);
    setRenameGallery({ id: null, name: '' });
  };

  return (
    <div>
      <button
        onClick={() => setRenameGallery({ id: gallery.gallery_pk, name: gallery.name })}
        className="btn mr-2"
      >
        <i className="nc-icon-glyph ui-1_edit-72 text-lg leading-7 pt-1 text-sky-700"></i>
      </button>
      <Modal
        isOpen={Boolean(renameGallery.id === gallery.gallery_pk)}
        onSave={handleNameChange}
        overrideClasses="bg-blue-3 hover:bg-blue-4"
        label="Save"
        setIsOpen={() => setRenameGallery({ id: null, name: '' })}
        isSubmitDisabled={renameGallery.name.trim() === ''}
      >
        <h2 className="text-lg font-bold text-sky-700">Rename Gallery</h2>
        <input
          type="text"
          id="galleryName"
          name="galleryName"
          value={renameGallery.name}
          placeholder={renameGallery.name}
          onChange={(e) => setRenameGallery({ ...renameGallery, name: e.target.value })}
          className="my-1 block w-full px-1 py-1 border border-gray-300 shadow-sm focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm rounded"
        />
      </Modal>
      <Modal
        isOpen={Boolean(errorMessage)}
        onSave={() => {}}
        setIsOpen={() => setErrorMessage(null)}
        showButtons={false}
      >
        <h2 className="text-lg font-bold text-sky-700">Error</h2>
        <p className="mb-2">{errorMessage}</p>
      </Modal>
    </div>
  );
}

export default RenameGalleryModal;
