import React from 'react';

export default function useForm(props) {
  const { fields, CSRF_TOKEN } = props;

  const initialValues = React.useMemo(() => {
    return Object.entries(fields)
      .filter(([name, config]) => {
        return config.defaultValue != undefined;
      })
      .reduce((obj, [name, config]) => {
        obj[name] = config.defaultValue;
        return obj;
      }, {});
  }, []);

  const [formState, setFormState] = React.useState('loaded');
  const [message, setMessage] = React.useState({});

  const [values, setValues] = React.useState(initialValues);
  const setValue = (name, value) => setValues((values) => ({ ...values, [name]: value }));

  const [errors, setErrors] = React.useState({});
  const setError = (name, error) => setErrors((errors) => ({ ...errors, [name]: error }));
  const resetError = (name) => delete errors[name];

  const [isFormValid, setIsFormValid] = React.useState(true);

  React.useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [errors]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
    resetError(name);
  };

  /***************************************************************************************************
    Validation
  ***************************************************************************************************/

  const requiredFields = React.useMemo(() => {
    return Object.entries(fields)
      .filter(([name, config]) => {
        if (name == 'travelCountries') {
          return values.travelStatus == 2 && config.displayed && config.required;
        } else if (name == 'testResult' || name == 'testDate') {
          return values.testStatus == 2 && config.displayed && config.required;
        } else {
          return config.displayed && config.required;
        }
      })
      .map(([name, props]) => name);
  }, [fields, values]);

  const validateField = (name) => {
    return values[name] ? true : false;
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    requiredFields.forEach((name) => {
      validateField(name);
      let fieldValid = validateField(name);

      if (!fieldValid) {
        isValid = false;
        errors[name] = 'This field is required';
      }
    });

    setErrors(errors);

    return isValid;
  };

  /***************************************************************************************************
    Submission
  ***************************************************************************************************/

  const SUBMIT_URL = Routes.create_covid_screening_path({
    client: Portals.config.client,
    portal_name: Portals.config.portal_name,
  });

  const SUBMIT_HEADERS = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store',
    'X-CSRF-Token': CSRF_TOKEN,
  };

  const SUBMIT_DELAY = 500;

  const submit = async (data = {}) => {
    const response = await fetch(SUBMIT_URL, {
      method: 'POST',
      headers: SUBMIT_HEADERS,
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error(response.status);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    window.scrollTo(0, 0);

    let isValid = validateForm();

    if (isValid) {
      setFormState('submitting');

      if (values.travelStatus != 2) {
        setValue('travelCountries', fields.travelCountries.defaultValue);
      }

      if (values.testStatus != 2) {
        setValue('testResult', fields.testResult.defaultValue);
        setValue('testDate', fields.testDate.defaultValue);
      }

      const submitData = {
        csrf_token: CSRF_TOKEN,
        screening_person_id: values.screeningPerson,
        screening_date: values.screeningDate && new Date(values.screeningDate).toISOString(),
        symptoms_status: values.symptomsStatus,
        screening_temperature:
          values.screeningTemperature &&
          Number(values.screeningTemperature).toString().slice(0, 10),
        exposure_status: values.exposureStatus,
        travel_status: values.travelStatus,
        travel_countries:
          values.travelStatus == 2 ? values.travelCountries : fields.travelCountries.defaultValue,
        test_status: values.testStatus,
        test_result: values.testStatus == 2 ? values.testResult : fields.testResult.defaultValue,
        test_date:
          values.testStatus == 2 && values.testDate
            ? new Date(values.testDate).toISOString()
            : fields.testDate.defaultValue,
        screening_comments: values.screeningComments,
      };

      submit(submitData)
        .then((data) => {
          setTimeout(() => {
            setFormState('submitted');
            setMessage({ type: 'success', text: props.formSubmissionMessage });
          }, SUBMIT_DELAY);
        })
        .catch((error) => {
          setTimeout(() => {
            setFormState('loaded');
            setMessage({
              type: 'error',
              text: 'Unable to submit screening. Please refresh the page and try again',
            });
          }, SUBMIT_DELAY);
        });
    } else {
      setMessage({
        type: 'error',
        text: 'There is an issue with one or more of the fields.',
      });
    }
  };

  /***************************************************************************************************
    RETURN
  ***************************************************************************************************/

  return {
    formState,
    message,
    values,
    errors,
    handleChange,
    handleSubmit,
  };
}
