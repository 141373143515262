import React from 'react';

import MessageBanner from './MessageBanner';
import DataField from './field-types/DataField';

export default function FormSubmission(props) {
  const { formTitle, formSubmissionMessage, formRecommendationMessage, fields, values } = props;

  const handleNewSubmission = (event) => {
    event.preventDefault();

    window.location.reload(true);
  };

  return (
    <div className="vx-form">
      <div className="vx-form-header">
        <h2>{formTitle}</h2>
      </div>

      <div className="vx-form-content">
        <div className="vx-field-group">
          <div className="ae-grid">
            <MessageBanner
              type="success"
              text={formSubmissionMessage}
            />

            <div className="ae-grid__item item-sm-12">
              <div className="vx-form-step-header">
                <div className="vx-form-step-header-text">Recommendation</div>
              </div>
            </div>

            <div className="ae-grid__item item-sm-12">
              <div
                className="vx-message-banner"
                dangerouslySetInnerHTML={{ __html: formRecommendationMessage }}
              />
            </div>

            <div className="ae-grid__item item-sm-6">
              <button
                className="vx-button vx-button--primary"
                onClick={handleNewSubmission}
              >
                Submit another screening
              </button>
            </div>

            <div className="ae-grid__item item-sm-12">
              <div className="vx-form-step-header">
                <div className="vx-form-step-header-text">Submitted Screening</div>
              </div>
            </div>

            <div className="ae-grid__item item-sm-6">
              <DataField
                name="screeningPerson"
                value={values.screeningPerson}
                config={fields.screeningPerson}
              />
            </div>

            <div className="ae-grid__item item-sm-6">
              <DataField
                name="screeningDate"
                value={values.screeningDate}
                config={fields.screeningDate}
              />
            </div>

            <div className="ae-grid__item item-sm-6">
              <DataField
                name="symptomsStatus"
                value={values.symptomsStatus}
                config={fields.symptomsStatus}
              />
            </div>

            <div className="ae-grid__item item-sm-6">
              <DataField
                name="screeningTemperature"
                value={values.screeningTemperature}
                config={fields.screeningTemperature}
              />
            </div>

            {fields.exposureStatus.displayed && (
              <div className="ae-grid__item item-sm-6">
                <DataField
                  name="exposureStatus"
                  value={values.exposureStatus}
                  config={fields.exposureStatus}
                />
              </div>
            )}

            {fields.exposureStatus.displayed && <div className="ae-grid__item item-sm-6"></div>}

            <div className="ae-grid__item item-sm-6">
              <DataField
                name="travelStatus"
                value={values.travelStatus}
                config={fields.travelStatus}
              />
            </div>

            <div className="ae-grid__item item-sm-6">
              {values.travelStatus == 2 && (
                <DataField
                  name="travelCountries"
                  value={values.travelCountries}
                  config={fields.travelCountries}
                />
              )}
            </div>

            <div className="ae-grid__item item-sm-6">
              <DataField
                name="testStatus"
                value={values.testStatus}
                config={fields.testStatus}
              />
            </div>

            <div className="ae-grid__item item-sm-3">
              {values.testStatus == 2 && (
                <DataField
                  name="testResult"
                  value={values.testResult}
                  config={fields.testResult}
                />
              )}
            </div>

            <div className="ae-grid__item item-sm-3">
              {values.testStatus == 2 && (
                <DataField
                  name="testDate"
                  value={values.testDate}
                  config={fields.testDate}
                />
              )}
            </div>

            <div className="ae-grid__item item-sm-12">
              <DataField
                name="screeningComments"
                value={values.screeningComments}
                config={fields.screeningComments}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
