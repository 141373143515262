import React from 'react';
import { useConfigurations, useFilters } from '../../../../stores/useGradebookStore';
import Cell from './Cell';
import { ArrayStats } from '../../../shared/utils';
import { useClassGrades } from '../queries';
import { ArrayStatsType, Student } from '../types';
import { ALL_YEAR_GRADING_PERIOD } from '../constants';
import classNames from 'classnames';
import BorderRow from './BorderRow';

type ClassAveragesRowProps = {
  calculated: number;
  predicted: number;
  header: string;
};
const ClassAveragesRow = ({ calculated, predicted, header }: ClassAveragesRowProps) => {
  const {
    display_calculated_grade_column: displayCalculated,
    display_predicted_grade_column: displayPredicted,
  } = useConfigurations();
  const { gradingPeriod } = useFilters();

  let calculatedDisplay = calculated ? calculated.toFixed(2) : '-';
  let predictedDisplay = predicted ? predicted.toFixed(2) : '-';
  if (gradingPeriod === ALL_YEAR_GRADING_PERIOD) {
    calculatedDisplay = 'N/A';
  }

  const classes = 'bg-blue-6 border border-blue-5 w-24 font-normal';

  return (
    <tr className="text-center">
      <Cell classOverrides="bg-white float-right pr-2 font-normal">{header}</Cell>
      {displayCalculated && (
        <Cell classOverrides={classes}>
          <div>{calculatedDisplay}</div>
        </Cell>
      )}
      {displayPredicted && (
        <Cell classOverrides={classes}>
          <div>{predictedDisplay}</div>
        </Cell>
      )}
    </tr>
  );
};

type ClassAveragesProps = {
  students: Student[];
};
const ClassAverages = ({ students }: ClassAveragesProps) => {
  const {
    display_class_mean_row: displayAssignmentsMeanRow,
    display_class_median_row: displayAssignmentsMedianRow,
    display_class_standard_deviation_row: displayAssignmentsStandardDeviationRow,
    display_calculated_grade_column: displayCalculated,
    display_predicted_grade_column: displayPredicted,
  } = useConfigurations();

  const { gradingPeriod } = useFilters();
  const { data: classGrades } = useClassGrades();

  const gradesByPeriod = students.map((student) => {
    const gradeRecord = classGrades[`${student.person_fk}-${gradingPeriod}`];
    return {
      calculated:
        gradingPeriod !== ALL_YEAR_GRADING_PERIOD
          ? gradeRecord?.calculated_grade
            ? parseFloat(gradeRecord.calculated_grade)
            : 0
          : null,
      predicted: gradeRecord?.predicted_grade ? parseFloat(gradeRecord.predicted_grade) : 0,
    };
  });

  const calculatedStats = new ArrayStats(
    gradesByPeriod.map((grades) => grades.calculated).filter((g) => g && g > 0)
  ) as ArrayStatsType;
  const predictedStats = new ArrayStats(
    gradesByPeriod.map((grades) => grades.predicted).filter((g) => g && g > 0)
  ) as ArrayStatsType;

  const hasAtLeastOneAverageRow =
    displayAssignmentsMeanRow ||
    displayAssignmentsMedianRow ||
    displayAssignmentsStandardDeviationRow;

  const numberOfBorderCells = displayCalculated && displayPredicted ? 2 : 1; // need at least one for correct alignment

  return (
    <>
      {hasAtLeastOneAverageRow && (
        <BorderRow
          skipFirstCell={true}
          trimFirst={true}
          numberOfBorderCells={numberOfBorderCells}
        />
      )}
      {displayAssignmentsMeanRow && (
        <ClassAveragesRow
          header="Class Average"
          calculated={calculatedStats.mean}
          predicted={predictedStats.mean}
        />
      )}

      {displayAssignmentsMedianRow && (
        <ClassAveragesRow
          header="Class Median"
          calculated={calculatedStats.median}
          predicted={predictedStats.median}
        />
      )}

      {displayAssignmentsStandardDeviationRow && (
        <ClassAveragesRow
          header="Standard Dev"
          calculated={calculatedStats.stdev}
          predicted={predictedStats.stdev}
        />
      )}
    </>
  );
};

export default ClassAverages;
