import React from 'react';
import Field from '../Field';

export default function RadioGroupField(props) {
  const { name, type, value, error, onChange, config } = props;

  const fieldValueOptions = config.options.map((option) => (
    <div
      className="ae-grid__item item-sm-3"
      key={option.value}
    >
      <label className="vx-radio-group__item">
        <input
          className="vx-radio-input"
          type="radio"
          name={name}
          value={option.value}
          onChange={onChange}
          required={config.required}
          disabled={config.disabled}
          checked={option.value == value}
        />
        <span className="vx-radio-group__label">{option.description}</span>
      </label>
    </div>
  ));

  return (
    <Field
      className="vx-radio-group"
      {...props}
    >
      <div
        className="ae-grid"
        aria-labelledby={name}
      >
        {fieldValueOptions}
      </div>
    </Field>
  );
}
