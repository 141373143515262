import React from 'react';
import ForkliftUploader from 'forklift-ui';

type FileUploaderProps = {
  uploaderOptions: Record<any, any>;
  setUploadedFiles: React.Dispatch<React.SetStateAction<string[]>>;
};
const FileUploader = ({ uploaderOptions, setUploadedFiles }: FileUploaderProps) => {
  const onComplete = ({ successful: filesAdded }) => {
    const filePks = filesAdded.map((file) => file.fileInfo.file_pk);
    setUploadedFiles((prev) => [...prev, ...filePks]);
  };

  const onFileRemoved = ({ fileInfo }, reason) => {
    if (reason === 'removed-by-user' && fileInfo) {
      setUploadedFiles((prev) => prev.filter((filePk) => fileInfo.file_pk !== filePk));
    }
  };

  return (
    <ForkliftUploader
      {...{
        ...uploaderOptions,
        onComplete,
        onFileRemoved,
      }}
    />
  );
};

export default FileUploader;
