import React from 'react';
import PersonDisplay from '../common/PersonDisplay';
import { useConfigurations } from '../../../../stores/useGradebookStore';
import { Student, StudentSidebarTab } from '../types';
import AssignmentIcon from './AssignmentIcon';
import { getStudentColumnWidth } from '../helpers';
import MobileStudentRow from './MobileStudentRow';
import StudentGrades from './StudentGrades';
import PublishGradesToggle from './PublishGradesToggle';
import { useUserState } from '../../../../stores/useAppStore';
import { useStudentGrade } from '../queries';

type StudentRowProps = {
  student: Student;
  isInSidebar?: boolean;
  openSidebar?: (a: Student, b: StudentSidebarTab) => void;
  rowIndex?: number;
};
const StudentRow = ({
  student,
  isInSidebar = false,
  openSidebar,
  rowIndex = -1,
}: StudentRowProps) => {
  const { hide_gradebook_photos: hidePhotos } = useConfigurations();
  const studentId = student.person_pk;
  const gradeRecord = useStudentGrade(studentId);
  const hasGradeRecord = gradeRecord && Object.keys(gradeRecord).length > 0;
  const { setFocusedStudentRow } = useUserState();

  return (
    <>
      <div className="max-md:hidden flex h-fit min-h-[80px] border-b border-neutral-5 items-center">
        <div className={`${getStudentColumnWidth(hidePhotos)}`}>
          <PersonDisplay
            avatarUrl={student.photo_url}
            title={student.first_name}
            subtitle={student.last_name}
            isLarge={true}
            dateWithdrawn={student.date_withdrawn}
            lateDateEnrolled={student.late_date_enrolled}
          />
        </div>
        <StudentGrades student={student} />
        {hasGradeRecord && (
          <>
            <AssignmentIcon
              iconType="nc-icon-glyph ui-2_chat-round-content"
              openSidebar={() => {
                setFocusedStudentRow(rowIndex, false);
                openSidebar(student, 'feedback');
              }}
            />
            <AssignmentIcon
              iconType="nc-icon-glyph files_locked"
              openSidebar={() => {
                setFocusedStudentRow(rowIndex, false);
                openSidebar(student, 'note');
              }}
            />
            <AssignmentIcon
              iconType="nc-icon-glyph ui-1_email-83"
              openSidebar={() => {
                setFocusedStudentRow(rowIndex, false);
                openSidebar(student, 'notifications');
              }}
            />
            <PublishGradesToggle
              studentId={student.person_fk}
              rowIndex={rowIndex}
            />
          </>
        )}
      </div>
      {hasGradeRecord && (
        <MobileStudentRow
          student={student}
          key={student.person_fk}
          isInSidebar={isInSidebar}
          openSidebar={() => openSidebar(student, 'grades')}
        />
      )}
    </>
  );
};

export default StudentRow;
