import React from 'react';
import AssignmentHeader from '../common/AssignmentHeader';
import LmsMobileBanner from '../../navigation/LmsMobileBanner';
import { Assignment } from '../types';
import AssignmentForm from './AssignmentForm';
import { goBackToGrid } from '../Router';
import { useGradeConversionScales, useGradingMethods, useGradingPeriods } from '../queries';

const AssignmentDetail = ({ assignment }: { assignment: Assignment }) => {
  const { data: gradingMethodsFiltered } = useGradingMethods();
  const { data: conversionScalesData } = useGradeConversionScales();
  const { data: gradingPeriods } = useGradingPeriods();

  return (
    <>
      <LmsMobileBanner
        title="Assignment Information"
        subTitle={assignment.description}
        goBack={goBackToGrid}
      />
      <AssignmentHeader urlPrefix="assignment" />
      <AssignmentForm
        initialValues={assignment}
        gradingMethods={gradingMethodsFiltered || []}
        gradingPeriods={gradingPeriods || []}
        conversionScales={conversionScalesData?.grade_conversion_scales || []}
      />
    </>
  );
};

export default AssignmentDetail;
