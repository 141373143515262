import React from 'react';
import ReactSelect from 'react-select';

type RequestCardSelectProps = {
  field: string;
  options: any[];
  selectedValue: number;
  onChange: (field: string, selection: number) => void;
  styles?: { [key: string]: string };
  readOnly?: boolean;
};
const RequestCardSelect = ({
  field,
  options,
  selectedValue,
  onChange,
  styles,
  readOnly,
}: RequestCardSelectProps) => {
  const selectOptions = options.map((option) => {
    return {
      value: option.id,
      label: option.description,
    };
  });

  const selectedOption = selectOptions.find((option) => option.value === selectedValue);
  const height = '24px';

  return (
    <ReactSelect
      value={selectedOption}
      options={selectOptions}
      isDisabled={readOnly}
      styles={{
        valueContainer: (styles) => ({
          ...styles,
          paddingLeft: '4px',
          height,
          minHeight: height,
          width: styles?.width ? '100px' : 'auto',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        indicatorsContainer: (styles) => ({
          ...styles,
          height,
          minHeight: height,
        }),
        control: (styles) => ({
          ...styles,
          width: '140px',
          height,
          minHeight: height,
          borderColor: '#a6a6a6',
          boxShadow: 0,
          '&:hover': {
            border: `1px solid #a6a6a6`,
          },
        }),
      }}
      onChange={(selection) => onChange(field, parseInt(selection.value))}
      maxMenuHeight={242}
      minMenuHeight={242}
      menuPlacement="auto"
      isSearchable={false}
    />
  );
};

export default RequestCardSelect;
