import React from 'react';
import PropTypes from 'prop-types';
import Hideable from './Hideable';
import AttendanceStatusSelect from './AttendanceStatusSelect';
import Alerts from './Alerts';
import AttendanceNotes from './AttendanceNotes';
import { useScreenSizes } from '../../../stores/useAppStore';
import { CLASS_NOTES_FIELD, isAttendanceRemote, MASTER_NOTES_FIELD } from './utils';
import { AccordionItem } from './Accordion';
import AttendanceContext from './AttendanceContext';
import AttendanceTime from './AttendanceTime';
import classNames from 'classnames';

const StudentAvatar = ({ student, isRemote }) => {
  const className = `vx-avatar avatar-fit ${isRemote ? 'vx-avatar--cutout' : ''}`;
  return (
    <div
      className="vx-avatar-container"
      data-testid="student-avatar"
    >
      {student.photo_url ? (
        <img
          className={className}
          src={student.photo_url}
        />
      ) : (
        <span className={className}>
          {student.first_name}
          {student.last_name[0]}
        </span>
      )}
      {isRemote && (
        <div
          className="vx-icon-badge vx-icon-badge--blue remote-badge"
          title="Remote"
        >
          <i className="nc-icon-glyph ui-1_home-simple" />
        </div>
      )}
    </div>
  );
};

const Student = ({
  attendanceStatuses,
  isAttendanceLocked,
  isMaster,
  onClick,
  selected,
  student,
  dailyNotesClasses,
  classNotesClasses,
  settings,
  rowIndex,
}) => {
  const { isSm: isMobile } = useScreenSizes();
  const { attendance: attendanceObj } = React.useContext(AttendanceContext);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const attendance = attendanceObj[student.id] || {};
  const isRemote = isAttendanceRemote(attendance);
  const zIndex = isMenuOpen ? 1000 : 1;
  const {
    hideSensitiveData,
    areClassNotesSensitive,
    hidePhotos: isCondensedView,
    enableKeyboardShortcuts,
  } = settings;

  const nameContainerClasses = classNames({
    'ae-grid__item item-sm-6': true,
    'student-name-condensed': isCondensedView,
  });

  return (
    <div
      className="ae-container student-row"
      onClick={() => onClick(student.id, rowIndex)}
      data-testid="student-row-click"
    >
      <div
        className={
          'ae-grid vx-list-card' +
          (selected ? ' vx-list-card--selected' : '') +
          (hideSensitiveData ? '' : ' vx-list-card--clickable')
        }
      >
        <div
          className={`ae-grid__item item-sm-6 fixed-student ${isExpanded ? 'active' : ''}`}
          style={{ zIndex }}
        >
          <div className="ae-grid student-info">
            {!isCondensedView && (
              <div className="ae-grid__item item-xs-3 item-md-2">
                <StudentAvatar
                  student={student}
                  isRemote={isRemote}
                />
              </div>
            )}
            <div
              className={
                'ae-grid__item item-xs-9 item-md-10' +
                (isCondensedView ? 'item-xs-12 item-md-12 ' : '')
              }
            >
              <div className="ae-grid">
                <div className={nameContainerClasses}>
                  {isMobile || isCondensedView ? (
                    <p className={`vx-heading-${isMobile ? '6' : '5'} line-height-normal`}>
                      {student.first_name} {student.last_name}
                    </p>
                  ) : (
                    <>
                      <p className="vx-heading-5 line-height-normal">{student.first_name}</p>
                      <p className="vx-heading-6 line-height-normal">{student.last_name}</p>
                    </>
                  )}
                  <Hideable hidden={hideSensitiveData && !isMobile}>
                    <Hideable hidden={isMobile}>
                      <Alerts
                        alerts={student.alerts}
                        tagOnly={true}
                      />
                    </Hideable>
                  </Hideable>
                </div>
                <div className="ae-grid__item item-sm-6">
                  <AttendanceStatusSelect
                    isMaster={isMaster}
                    isAttendanceLocked={isAttendanceLocked}
                    student={student}
                    options={attendanceStatuses}
                    value={attendanceStatuses[attendance.status]}
                    setIsMenuOpen={setIsMenuOpen}
                    isMenuOpen={isMenuOpen}
                    rowIndex={rowIndex}
                    enableKeyboardShortcuts={enableKeyboardShortcuts}
                  />
                </div>
              </div>
            </div>
          </div>
          <Hideable hidden={!isMobile}>
            <button
              className="vx-button vx-button--expand"
              onClick={() => setIsExpanded((prev) => !prev)}
            >
              <i
                className={`nc-icon-glyph ${
                  isExpanded ? 'arrows-1_minimal-up' : 'arrows-1_minimal-down'
                }`}
              ></i>
            </button>
          </Hideable>
        </div>

        <Hideable hidden={!isMobile}>
          <Hideable hidden={!isExpanded}>
            <div className="mobile-details">
              {!isMaster && (
                <AccordionItem
                  title="Class Attendance Notes"
                  isExpanded={true}
                >
                  <AttendanceNotes
                    student={student}
                    readOnly={isAttendanceLocked}
                    isAttendanceLocked={isAttendanceLocked}
                    defaultNotes={attendance.class_attendance_notes}
                    emptyText={'None'}
                    notesType={CLASS_NOTES_FIELD}
                    rowIndex={rowIndex}
                  />
                </AccordionItem>
              )}
              <AccordionItem
                title="Daily Notes"
                isExpanded={isMaster}
              >
                <AttendanceNotes
                  student={student}
                  isAttendanceLocked={isAttendanceLocked}
                  defaultNotes={attendance.master_attendance_notes}
                  notesType={MASTER_NOTES_FIELD}
                  readOnly={isAttendanceLocked || !isMaster}
                  emptyText={'None'}
                  showLogisticsRequests={true}
                  rowIndex={rowIndex}
                />
              </AccordionItem>
              {isMaster && (
                <AccordionItem title="Times">
                  <AttendanceTime
                    isDisabled={isAttendanceLocked}
                    attendance={attendance}
                    field={'late_arrival_time'}
                  />
                  <AttendanceTime
                    isDisabled={isAttendanceLocked}
                    attendance={attendance}
                    field={'early_dismissal_time'}
                  />
                  <AttendanceTime
                    isDisabled={isAttendanceLocked}
                    attendance={attendance}
                    field={'return_time'}
                    className="mb-2"
                  />
                </AccordionItem>
              )}
              {student.alerts && (
                <AccordionItem title="Alerts">
                  <Alerts alerts={student.alerts} />
                </AccordionItem>
              )}
            </div>
          </Hideable>
        </Hideable>

        <Hideable hidden={isMobile}>
          <Hideable hidden={isMaster}>
            <div className={classNotesClasses}>
              <Hideable hidden={hideSensitiveData && areClassNotesSensitive}>
                <AttendanceNotes
                  student={student}
                  readOnly={isAttendanceLocked}
                  isAttendanceLocked={isAttendanceLocked}
                  defaultNotes={attendance.class_attendance_notes}
                  notesType={CLASS_NOTES_FIELD}
                  key={student.id}
                  rowIndex={rowIndex}
                />
              </Hideable>
            </div>
          </Hideable>
          <div className={dailyNotesClasses}>
            <Hideable hidden={hideSensitiveData}>
              <AttendanceNotes
                student={student}
                isAttendanceLocked={isAttendanceLocked}
                defaultNotes={attendance.master_attendance_notes}
                notesType={MASTER_NOTES_FIELD}
                readOnly={isAttendanceLocked || !isMaster}
                key={student.id}
                showLogisticsRequests={true}
                rowIndex={rowIndex}
              />
            </Hideable>
          </div>
        </Hideable>
      </div>
    </div>
  );
};

Student.propTypes = {
  attendanceStatuses: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      category: PropTypes.number.isRequired,
    })
  ).isRequired,
  attendance: PropTypes.shape({
    cohort_status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    status: PropTypes.number.isRequired,
    master_attendance_notes: PropTypes.string,
  }).isRequired,
  isMaster: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    hideSensitiveData: PropTypes.bool.isRequired,
    areClassNotesSensitive: PropTypes.bool.isRequired,
    hidePhotos: PropTypes.bool.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  student: PropTypes.object.isRequired,
};

export default Student;
