import React, { useEffect, useState } from 'react';
import { WeightConfiguration } from '../types';
import Cell from '../grid/Cell';
import CheckBox from '../common/Checkbox';
import NumericGradeInput from '../students/NumericGradeInput';
import { useUpdateClassGradingConfiguration } from '../mutations';
import * as Yup from 'yup';
import { ERROR_TIMEOUT, NOTIFY_DURATION } from '../constants';
import { getErrorMessage, getFloatDisplay } from '../helpers';
import classNames from 'classnames';

const schema = Yup.object().shape({
  newValue: Yup.number(),
});

type WeightingTableRowProps = {
  config: WeightConfiguration;
  totals: { count: number; weight: number };
};
const WeightingTableRow = ({ config, totals }: WeightingTableRowProps) => {
  const weightFloat = parseFloat(config.weight) * 100;
  let configWeight = getFloatDisplay(weightFloat.toString());
  configWeight = configWeight === '0' ? '' : configWeight;
  const [weightString, setWeightString] = useState(configWeight);
  const [dropLowest, setDropLowest] = useState(config.drop_lowest);
  const [errorMessage, setErrorMessage] = useState('');
  const [isNotifying, setisNotifying] = useState(false);

  const updateWeight = useUpdateClassGradingConfiguration(
    config.class_grading_configuration_pk,
    'weight'
  );
  const updateDropLowest = useUpdateClassGradingConfiguration(
    config.class_grading_configuration_pk,
    'drop_lowest'
  );

  const cellClasses = 'bg-neutral-6';
  const cellClassesWeight = classNames('bg-neutral-6', {
    'ring-1 ring-red-4': errorMessage,
  });

  useEffect(() => {
    setWeightString(configWeight);
  }, [config.weight]);

  const validate = async (newValue) => {
    const valid = await schema.isValid({ newValue });
    if (newValue !== '' && !valid) {
      setErrorMessage('Must be a number');
      return false;
    } else if (parseFloat(newValue) < 0 || parseFloat(newValue) > 100) {
      setErrorMessage('Must be between 0 and 100');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const handleSaveWeight = async () => {
    if (weightString === configWeight) return;
    if (weightString === '0' && configWeight === '') return;

    const valid = await validate(weightString);
    if (!valid) return;
    setErrorMessage('');

    const newValue =
      weightString === '' || parseFloat(weightString) === 0 ? 0 : parseFloat(weightString) / 100;

    try {
      await updateWeight.mutateAsync(newValue);
      setisNotifying(true);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
      setTimeout(() => {
        setErrorMessage('');
      }, ERROR_TIMEOUT);
    } finally {
      setTimeout(() => {
        setisNotifying(false);
      }, NOTIFY_DURATION);
    }
  };

  const handleSaveCheckbox = () => {
    const newValue = !config.drop_lowest;
    setDropLowest(newValue);
    updateDropLowest.mutate(newValue);
    // if something goes wrong the checkbox will revert to its previous state
    // after the query refetches
  };

  const handleChange = (newValue) => {
    setWeightString(newValue);
    validate(newValue);
  };

  return (
    <>
      <Cell
        classOverrides={cellClassesWeight}
        tooltip={errorMessage}
      >
        <NumericGradeInput
          value={weightString}
          onChange={handleChange}
          onBlur={handleSaveWeight}
          alignRight={true}
          isNotifying={isNotifying}
          classOverrides="h-[28px]"
        >
          {' '}
          %{' '}
        </NumericGradeInput>
      </Cell>
      <Cell classOverrides={cellClasses}>
        <CheckBox
          checked={dropLowest}
          onChange={handleSaveCheckbox}
        />
      </Cell>
      <Cell classOverrides={cellClasses}>{totals?.count || ''}</Cell>
      <Cell classOverrides={cellClasses}>{totals?.weight || ''}</Cell>
    </>
  );
};

export default WeightingTableRow;
