import React from 'react';
import { useCourseRequestState } from '../../../../stores/useCourseRequestsStore';
import CourseItem from './CourseItem';
import { Course } from '../types';
import { filterCoursesBySubject } from '../helpers';

type CoursesListProps = {
  searchInput: string;
  subjectFilters: number[];
  classificationFilters: string[];
  setScrollToRequest: (value: number) => void;
};

const CoursesList = ({
  searchInput,
  subjectFilters,
  classificationFilters,
  setScrollToRequest,
}: CoursesListProps) => {
  const { subjects, courses_by_subject: subjectCourses } = useCourseRequestState();

  const coursesBySubject = (subjectId): Course[] => {
    return filterCoursesBySubject(subjectId, subjectCourses, searchInput, classificationFilters);
  };
  const backgroundColor = (subjectId): string => {
    return coursesBySubject(subjectId)[0]?.link_style;
  };

  return (
    <div className="max-h-[600px] overflow-auto">
      {subjects.map((subject) => {
        if (subjectFilters?.length > 0 && !subjectFilters.includes(subject.id)) return null;
        const courses = coursesBySubject(subject.id);
        if (!courses || courses.length === 0) return null;

        return (
          <div key={subject.id}>
            <h2
              className="text-lg px-1 border-b border-gray-300"
              style={{ backgroundColor: backgroundColor(subject.id) }}
            >
              {subject.description}
            </h2>
            <ul>
              {courses.map((course) => {
                return (
                  <CourseItem
                    key={course.id}
                    course={course}
                    subjectColor={backgroundColor(subject.id)}
                    setScrollToRequest={setScrollToRequest}
                  />
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default CoursesList;
