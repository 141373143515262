import React from 'react';

const LoadingSpinner = () => {
  return (
    <div
      className="text-center w-full h-[60vh]"
      data-testid="loading-spinner"
    >
      <div className="vx-loader-spinner mt-40"></div>
    </div>
  );
};

export default LoadingSpinner;
