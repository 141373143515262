import React from 'react';
import classNames from 'classnames';

type SaveButtonProps = {
  isDisabled: boolean;
  error: string;
};
const SaveButton = ({ isDisabled, error }: SaveButtonProps) => {
  const classes = classNames(
    'bg-green-4 text-white rounded-md w-full md:w-fit text-center py-3 px-8',
    {
      'opacity-50 cursor-default': isDisabled,
    }
  );

  return (
    <>
      <button
        type="submit"
        className={classes}
        data-testid="save-button"
        disabled={isDisabled}
      >
        Save Assignment
      </button>
      {Boolean(error) && <div className="text-red-3 mt-0.5">{error}</div>}
    </>
  );
};

export default SaveButton;
