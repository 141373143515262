import React from 'react';
import PropTypes from 'prop-types';

const MarkAttendanceButton = ({ onClick, loading }) => (
  <button
    type="button"
    className={`vx-button mark-attendance-button vx-button--primary max-md:mb-4 ${
      loading ? 'vx-button--loading' : ''
    }`}
    data-testid="markAttendance-button"
    disabled={loading}
    onClick={onClick}
  >
    {loading ? null : <i className="nc-icon-glyph ui-1_check-curve"></i>}
    Mark Complete
  </button>
);

MarkAttendanceButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MarkAttendanceButton;
