import React from 'react';

import useForm from 'components/shared/useForm';
import { Button, DataField, MessageBanner } from 'components/shared/form_elements';

export default function IntegratedPartnerEnrollment(props) {
  const { authToken, data } = props;

  const enrollPartnerUrl = Routes.enroll_partner_path({
    client: Portals.config.client,
    portal_name: Portals.config.portal_name,
    partner_id: data.partner.partner_pk,
  });

  const { formState, formMessage, formValues, handleFormSubmit } = useForm({
    url: enrollPartnerUrl,
    initialFormValues: data.partner,
    authToken: authToken,
    config: {
      requestMethod: 'PUT',
      submitSuccessMessageText: 'Company Profile was successfully saved!',
      submitFailureMessageText:
        'Error: Unable to save updates. Please refresh the page and try again.',
    },
  });

  const isPartnerEnrolled = formValues.approval_status === 1;

  const isFormSaving = formState === 'SAVING';
  const isFormDisabled = isFormSaving;

  const enrollmentButtonText = isFormSaving ? 'Enrolling...' : 'Become an Integrated Partner';
  const EnrollmentStatusText = isPartnerEnrolled
    ? '✓ Enrolled as Integrated Partner'
    : 'Not Enrolled as Integrated Partner';

  const handleEnrollPartner = (event) => {
    event.preventDefault();

    Swal.fire({
      title: 'Are you sure you want to become an integrated partner?',
      text: 'This action cannot be undone.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#86d067',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value === true) {
        formValues.approval_status = 1;
        handleFormSubmit(event);
      }
    });
  };

  const FormInstructions = (
    <p className="vx-form-instruction">
      By enrolling as an Integrated Partner, your company profile will visible to Veracross schools
      so they can select you to integrate with. If you have any questions, please email{' '}
      <a
        href="mailto:partners@veracross.com"
        title="partners@veracross.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        partners@veracross.com
      </a>
      .
    </p>
  );

  const EnrollmentButton = !isPartnerEnrolled && (
    <div className="ae-grid">
      <div className="ae-grid__item item-sm--offset-4 item-sm-4">
        <Button
          className="vx-button vx-button--success"
          disabled={isFormDisabled}
          onClick={handleEnrollPartner}
          value={enrollmentButtonText}
        />
      </div>
    </div>
  );

  return (
    <div className="vx-card integrated-partner-enrollment">
      <div className="vx-card__title">
        <h4>Integrated Partner Enrollment</h4>
      </div>
      <div className="vx-card__content">
        {formMessage && (
          <MessageBanner
            type={formMessage.type}
            text={formMessage.message}
          />
        )}
        {FormInstructions}
        {isPartnerEnrolled ? (
          <DataField
            name="integration_status"
            value={EnrollmentStatusText}
            config={{ label: 'Enrollment Status' }}
          />
        ) : (
          EnrollmentButton
        )}
      </div>
    </div>
  );
}
