import React from 'react';
import classNames from 'classnames';

import TabsContext from './TabsContext';

export default function TabButton(props) {
  const { name, value } = props;

  const { activeTab, isActiveTab, setActiveTab } = React.useContext(TabsContext);
  const handleClick = () => setActiveTab(name);

  const tabButtonClassNames = classNames({
    'tab-button': true,
    'tab-button--active': isActiveTab(name),
  });

  const TabButtonContent = props.children || value || name;

  return (
    <div
      className={tabButtonClassNames}
      onClick={handleClick}
    >
      {TabButtonContent}
    </div>
  );
}
