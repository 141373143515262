import React, { useState } from 'react';
import classNames from 'classnames';
import { useDeleteAssignment } from '../mutations';
import Modal from '../../../shared/Modal';
import { getErrorMessage } from '../helpers';
import { ERROR_TIMEOUT } from '../constants';

const DeleteButton = () => {
  const [error, setError] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const deleteAssignment = useDeleteAssignment();

  const handleDelete = async () => {
    setIsModalOpen(false);
    setIsDeleting(true);
    try {
      await deleteAssignment.mutateAsync();
      setError(null);
    } catch (error) {
      setError(getErrorMessage(error));
      setTimeout(() => {
        setError('');
      }, ERROR_TIMEOUT);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleClick = (e) => {
    e.preventDefault(); // stop formik submit
    setIsModalOpen(true);
  };

  const classes = classNames(
    'bg-neutral-6 text-red-3 border border-red-3 rounded-md w-full md:w-fit text-center py-3 px-8',
    {
      'opacity-50 cursor-default': isDeleting,
    }
  );

  return (
    <>
      <button
        className={classes}
        data-testid="delete-button"
        disabled={isDeleting}
        onClick={handleClick}
      >
        {isDeleting ? 'Deleting...' : 'Delete Assignment'}
      </button>
      {Boolean(error) && <div className="text-red-3 mt-0.5">{error}</div>}

      <Modal
        isOpen={isModalOpen}
        onSave={handleDelete}
        setIsOpen={setIsModalOpen}
        overrideClasses="bg-red-3 hover:bg-red-4"
        label="Delete"
      >
        <div
          className="text-red-3 text-lg"
          id="delete-modal"
        >
          Are you sure you want to delete this assignment?
        </div>
      </Modal>
    </>
  );
};

export default DeleteButton;
