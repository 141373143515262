import React, { FC } from 'react';
import { useCourseRequestState } from '../../../../../stores/useCourseRequestsStore';

type SectionHeaderProps = {
  subHeaderText?: string;
  selectedRequestGroup?: number;
};
const SectionHeader: FC<SectionHeaderProps> = ({
  children,
  subHeaderText,
  selectedRequestGroup,
}) => {
  const { current_course_requests: courses } = useCourseRequestState();
  const requestGroupCourses = courses.filter(
    (request) => request.request_group === selectedRequestGroup
  );

  return (
    <div className="mb-4 mt-2.5">
      {Boolean(requestGroupCourses.length) && (
        <div className="">
          Create alternates to:
          <span> {requestGroupCourses.map((request) => request.course).join(', ')}</span>
        </div>
      )}
      <div className="font-extralight text-lg mb-1.5 leading-5">{children}</div>
      {subHeaderText && <div className="text-xs text-neutral-4">{subHeaderText}</div>}
    </div>
  );
};

export default SectionHeader;
