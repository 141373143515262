import React from 'react';
import classNames from 'classnames';
import { Assignment, AssignmentFeedbackCondensed, Grade } from '../types';
import { useGridData } from '../queries';
import { capitalizeFirstLetter } from '../../../shared/utils';
import { HAS_FEEDBACK, HAS_PRIVATE_NOTE, NOTIFICATIONS_SENT } from '../constants';

type GradePreviewProps = {
  gradeRecord: Partial<Grade>;
  assignment: Assignment;
  feedback: AssignmentFeedbackCondensed;
  rowIndex: number;
};
const GradePreview = ({ gradeRecord, assignment, feedback, rowIndex }: GradePreviewProps) => {
  const { data } = useGridData();
  const hasFeedback = feedback?.includes(gradeRecord.class_assignment_person_pk);
  const hasPrivateNote = gradeRecord.notes?.length > 0;
  const completionStatus = data.assignment_statuses.find(
    (status) => status.id === gradeRecord.completion_status
  ).description;
  const dropboxStatus = capitalizeFirstLetter(gradeRecord.dropbox_status);
  const hasNotifications = gradeRecord.notification_count > 0;
  const numOfStudents = data.students.length;
  const isBottomOfList = rowIndex >= numOfStudents - 4;
  const showOnTop = isBottomOfList && numOfStudents > 8;

  const popupContainerClasses = classNames(
    'invisible hover:hidden group-hover:visible group-hover:delay-700 h-[170px] flex absolute',
    {
      'bottom-10 left-[-4px] items-end': showOnTop,
      'top-10 left-[-4px] items-start': !showOnTop,
      'z-40': numOfStudents > 2, // hide the popup behind the assignment header cells in this case
      'z-50': numOfStudents <= 2, // want to be able to see the popup over the assignment header cells in this case
    }
  );

  const popupClasses = classNames(
    ' flex flex-col text-left shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)] w-36 h-fit z-50 border border-neutral-5 text-black rounded-[5px] bg-white m-1 p-2'
  );

  return (
    <div className={popupContainerClasses}>
      <div className={popupClasses}>
        {hasFeedback && (
          <div className="h-6 flex flex-col justify-center">
            <div className="flex text-xs items-center">
              <i className="nc-icon-glyph text-neutral-4 ui-2_chat-round-content mr-1"></i>
              {HAS_FEEDBACK}
            </div>
          </div>
        )}
        {hasPrivateNote && (
          <div className="h-6 flex flex-col justify-center">
            <div className="flex text-xs items-center">
              <i className="nc-icon-glyph text-neutral-4 files_single-folded-content mr-1"></i>
              {HAS_PRIVATE_NOTE}
            </div>
          </div>
        )}
        {hasNotifications && (
          <div className="h-6 flex flex-col justify-center">
            <div className="flex text-xs items-center">
              <i className="nc-icon-glyph text-neutral-4 ui-1_notification-69 mr-1"></i>
              {NOTIFICATIONS_SENT}
            </div>
          </div>
        )}
        <div className="flex flex-col justify-center">
          <div className="text-xs text-neutral-4 uppercase">Assignment Status</div>
          <div className="text-xs">{completionStatus}</div>
        </div>
        <div className="flex flex-col justify-center mt-0.5">
          <div className="text-xs text-neutral-4 uppercase">Dropbox Status</div>
          <div className="text-xs">{dropboxStatus}</div>
        </div>
      </div>
    </div>
  );
};

export default GradePreview;
