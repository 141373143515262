/* eslint-disable camelcase */

import _ from 'lodash';
import { FormType } from '../types';
import { getFieldKeypath } from './element-helpers';

export const FORM_MODE_ADMIN_EDIT = 'admin_edit';
export const FORM_MODE_EDIT = 'edit';
export const FORM_MODE_EDIT_PRESET = 'edit_preset';
export const FORM_MODE_NORMAL = 'normal';
export const FORM_MODE_VIEW_SUBMISSION = 'view_submission';

const COMPONENTS_TO_IGNORE_IN_INITIAL_VALUES = new Set(['header', 'paragraph', 'response_grid']);

const COMPONENT_TO_DEFAULT_VALUE_MAP = {
  checkboxes_columns: [],
  date_picker: '',
  header: '',
  number: '',
  multi_field: '',
  paragraph: '',
  radio: '',
  response_grid: '',
  section_display: '',
  select: '',
  textarea: '',
  textbox: '',
  toggle: false,
};

export const sortBy =
  (fieldName: string) =>
  (a, b): number =>
    a[fieldName] - b[fieldName];

export const getAllElements = (form: FormType) => {
  return _.flatten((form.sections || []).map((section) => section.elements || []));
};

export const trimForm = (values: unknown) =>
  Object.keys(values).reduce((newValues, key) => {
    newValues[key] = typeof values[key] === 'string' ? values[key].trim() : values[key];
    return newValues;
  }, {});

export const convertForm = (form: FormType) => {
  return {
    ...form,
    sections: form.sections.sort(sortBy('sort_key')).map((section) => {
      const childrenElementsMap = {};

      for (const element of section.elements) {
        const { parent_element_fk } = element;

        if (parent_element_fk === 0 || parent_element_fk === undefined) {
          continue;
        }

        if (!childrenElementsMap.hasOwnProperty(parent_element_fk)) {
          childrenElementsMap[parent_element_fk] = [];
        }

        childrenElementsMap[parent_element_fk].push({
          ...element,
          ...(element.element_value_list_items
            ? {
                element_value_list_items: [...element.element_value_list_items].sort(
                  sortBy('sort_key')
                ),
              }
            : { element_value_list_items: [] }),
        });
      }

      const elements = section.elements.map((element) => ({
        ...element,
        ...(element.element_value_list_items
          ? {
              element_value_list_items: [...element.element_value_list_items].sort(
                sortBy('sort_key')
              ),
            }
          : { element_value_list_items: [] }),
        ...(childrenElementsMap.hasOwnProperty(element.element_pk) && {
          children: childrenElementsMap[element.element_pk],
        }),
      }));

      return {
        ...section,
        elements,
      };
    }),
  };
};

export const getSectionDisplayMap = (form: FormType) => {
  const results = {};

  (form.sections || []).forEach((section, sectionIndex) => {
    (section.elements || [])
      .filter((element) => element.component === 'section_display')
      .forEach((element) => {
        (element.element_value_list_items || []).forEach((item) => {
          const key = item.value;
          const sectionKeypath = element.section_data_keypath;
          const elementKeypath = element.data_keypath;
          results[key] = `${sectionKeypath}#${sectionIndex}__${elementKeypath}`;
        });
      });
  });

  return results;
};

export const getInitialValuesForFormik = (form: FormType) => {
  const results = {};

  (form.sections || []).forEach((section, sectionIndex) => {
    (section.elements || []).forEach((element) => {
      // if the form has relationship elements, add each field individually instead.
      if (element.component === 'relationship') {
        element.element_value_list_items
          .filter((item) => item.table_name === 'related_people')
          .forEach((person) => {
            results[getFieldKeypath(element, person.value)] = '';
          });

        return;
      }

      if (COMPONENTS_TO_IGNORE_IN_INITIAL_VALUES.has(element.component)) {
        return;
      }

      const key = `${section.data_keypath}#0__${element.data_keypath}`;
      results[key] = COMPONENT_TO_DEFAULT_VALUE_MAP.hasOwnProperty(element.component)
        ? COMPONENT_TO_DEFAULT_VALUE_MAP[element.component]
        : '';
    });
  });

  return results;
};

/**
 * Takes a Formik values object and converts the keys from the format from:
 *
 *   <section_keypath>#<section_index>__<element_keypath>
 *
 *      to
 *
 *   <section_keypath>.<section_index>.<element_keypath>
 *
 * For example:
 *
 *    {
 *      "section-123#0__foo": 1,
 *      "section-123#0__bar": 2,
 *      "section-456#0__foo": 3,
 *      "section-456#1__bar": 4 ,
 *    }
 *
 *    will be converted to
 *
 *    {
 *      "section-123.0.foo": 1,
 *      "section-123.0.bar": 2,
 *      "section-456.0.foo": 3,
 *      "section-456.1.bar": 4,
 *    }
 *
 * This is because of limitations with Yup where referring to nested values with
 * dot-notation not working and thus the current workaround is to remove dots
 * from property names.
 *
 * @param {object} values - The values object from Formik.
 * @return {object}
 */
export const serializeFormikValues = (values) =>
  Object.entries(values).reduce((memo, [key, value]) => {
    const newVar = Array.isArray(value) && value.length === 0 ? null : value;
    return {
      ...memo,
      [key.replace(/#(\d+)__/, '.$1.')]: newVar,
    };
  }, {});

// Used for web form unit tests:
export const DEFAULT_SECTION_PROPS = {
  data_keypath: '',
  description: '',
  detail_override_index: 1,
  display: 1,
  elements: [],
  posted: false,
  repeatable: 1,
  section_system_name: '',
  sort_key: 1,
};

export const DEFAULT_FORM_PROPS = {
  access_name: '',
  account_lookup_required: false,
  allow_updates: 1,
  description: '',
  detail_override_index: 1,
  form_configuration: [],
  form_context_type: 1,
  form_context_value: 1,
  form_type_system_name: '',
  mode: FORM_MODE_NORMAL,
  published: false,
  sections: [],
  sort_key: 1,
  status: 1,
  submission_strategy: 1,
};

export const generateForm = (props) => ({
  ...DEFAULT_FORM_PROPS,
  mode: props.mode || FORM_MODE_NORMAL,
  sections: mapSections(props.sections || []),
});

const mapSections = (sections) => {
  return sections.map((section) => {
    return { ...DEFAULT_SECTION_PROPS, ...section };
  });
};

export const setRelationshipDefaults = (form, values) => {
  const result = {};

  const relationshipElement = getAllElements(form).find(
    (elm) => elm.data_keypath === 'relationships'
  );

  const people = relationshipElement.element_value_list_items.filter(
    (item) => item.table_name === 'related_people'
  );

  const keypaths = people.map((person) => getFieldKeypath(relationshipElement, person.value));
  keypaths.forEach((kp) => {
    if (values[kp] === '') result[kp] = 0;
  });

  return result;
};
