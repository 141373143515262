import { useEffect, useState, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ElementProps } from '../types';
import { FieldInputProps } from 'formik';

export const useDebounceOnChange = ({
  field,
  time = 200,
}: {
  field: FieldInputProps<any>;
  time?: number;
}) => {
  const [fieldValue, setFieldValue] = useState(field.value || '');

  const debouncedOnChange = useDebouncedCallback(field.onChange, time);
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.persist();

      setFieldValue(event.currentTarget.value);
      debouncedOnChange(event);
    },
    []
  );

  return { fieldValue, handleOnChange };
};
