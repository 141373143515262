import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

type RequestCardProps = {
  notesValueProps: string;
  onBlur: (field: string, value: string) => Promise<boolean>;
  readOnly: boolean;
};
const RequestCardNotes = ({ notesValueProps, onBlur, readOnly }: RequestCardProps) => {
  const [isSuccessNotifying, setIsSuccessNotifying] = useState(false);
  const [notesValue, setNotesValue] = useState(notesValueProps);

  useEffect(() => {
    setNotesValue(notesValueProps);
  }, [notesValueProps]);

  const handleBlur = async (newNotesValue: string) => {
    newNotesValue = newNotesValue.trim();
    if (newNotesValue === notesValueProps) return;

    if (newNotesValue === '') newNotesValue = null;

    const success = await onBlur('notes', newNotesValue);
    if (success) {
      setIsSuccessNotifying(true);
      setTimeout(() => {
        setIsSuccessNotifying(false);
      }, 2000);
    }
  };

  const classes = classNames(
    'h-28 w-[17rem] border-solid border-[#ddd] text-[12px] ring-transparent p-1 max-sm:mt-4 leading-4 max-sm:w-full',
    {
      'ring-2 ring-green-4': isSuccessNotifying,
      'cursor-not-allowed opacity-50': readOnly,
    }
  );

  return (
    <div className="flex-grow-1 w-full text-center">
      <textarea
        value={notesValue}
        readOnly={readOnly}
        onChange={(e) => setNotesValue(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
        className={classes}
      ></textarea>
      <div className="text-green-4 text-left pl-2.5 h-4">{isSuccessNotifying && 'Saved!'}</div>
    </div>
  );
};

export default RequestCardNotes;
