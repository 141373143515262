import React from 'react';

type EmptyPageProps = {
  message: string;
  subMessage?: string;
  icon?: string;
};
const EmptyPage = ({ message, subMessage, icon = 'files_archive' }: EmptyPageProps) => {
  return (
    <div className="mt-20 h-[60vh]">
      <div className="vx-empty-state-read-only">
        <div className="vx-empty-state__icon">
          <i className={`nc-icon-outline x2 ${icon}`}></i>
        </div>
        <h5>{message}</h5>
        <p>{subMessage}</p>
      </div>
    </div>
  );
};

export default EmptyPage;
