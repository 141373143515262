import React from 'react';
import Field from '../Field';

export default function DateField(props) {
  const { name, type, value, error, onChange, config } = props;

  const PARSABLE_DATE_FORMATS = ['x', 'YYYY-MM-DD', 'ddd MMM DD, YYYY'];
  const STORAGE_DATE_FORMAT = 'YYYY-MM-DD';
  const DISPLAY_DATE_FORMAT = 'ddd MMM DD, YYYY';

  const inputRef = React.useRef();

  React.useEffect(() => {
    $(inputRef.current).pickadate({
      max: true,
      onRender: handleRender,
      onSet: handleSet,
    });
  });

  const formatDate = (date, date_format) => {
    return date ? moment(date, PARSABLE_DATE_FORMATS).format(date_format) : '';
  };

  const handleLabelClick = () => {
    inputRef.current.focus();
  };

  const handleRender = () => {
    inputRef.current.value = formatDate(value, DISPLAY_DATE_FORMAT);
  };

  const handleSet = (context) => {
    let newDate = typeof context.select === 'object' ? context.select.pick : context.select;
    setValue(formatDate(newDate, STORAGE_DATE_FORMAT));
    inputRef.current.value = formatDate(newDate, DISPLAY_DATE_FORMAT);
  };

  const setValue = (newValue) => {
    let event = { target: { name: name, value: newValue } };
    onChange(event);
  };

  return (
    <Field
      className="vx-date-field"
      onLabelClick={handleLabelClick}
      {...props}
    >
      <input
        className="vx-date-field__input"
        type="text"
        name={name}
        data-value={value}
        onChange={onChange}
        placeholder={config.placeholder}
        required={config.required}
        disabled={config.disabled}
        ref={inputRef}
      />
      <i className="nc-icon-outline ui-1_calendar-grid-61 vx-date-field__icon" />
    </Field>
  );
}
