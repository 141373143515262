import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isInPersonOrRemote } from './helpers';
import { IN_PERSON, REMOTE } from './constants';

const ConferenceMoreOptions = ({ conference, toggleLocation, isReadOnly }) => {
  const [currentLocation, setLocation] = useState(conference.attendance_option_choice);
  const userCanChooseLocation = isInPersonOrRemote(conference);

  useEffect(() => {
    setLocation(conference.attendance_option_choice);
  }, [conference]);

  const handleToggleLocation = async (newLocation) => {
    if (newLocation === currentLocation) {
      return;
    }
    newLocation = parseInt(newLocation, 10);
    const prevLocation = currentLocation;
    // make the change assuming it will succeed
    setLocation(newLocation);
    const response = await toggleLocation(conference, newLocation);
    if (response !== 'success') {
      // undo change if failed
      setLocation(prevLocation);
    }
  };

  const isDisabled = !userCanChooseLocation || isReadOnly;

  const radioClasses = classNames('vx-radio-group__label', {
    active: !isDisabled,
    disabled: isDisabled,
  });

  return (
    <div className="conferences-more-options">
      <div className="vx-radio-group-buttons">
        <label className="vx-radio-group__item">
          <input
            className="vx-radio-input"
            checked={currentLocation === IN_PERSON}
            disabled={isDisabled}
            type="radio"
            value={0}
            onChange={(e) => handleToggleLocation(parseInt(e.target.value, 10))}
            data-testid="conference-inPerson"
          />
          <span className={radioClasses}>
            <i className="nc-icon-glyph education_school available"></i>
            In&#x2011;Person
          </span>
        </label>
        <label className="vx-radio-group__item">
          <input
            className="vx-radio-input"
            checked={currentLocation === REMOTE}
            disabled={isDisabled}
            type="radio"
            value={1}
            onChange={(e) => handleToggleLocation(parseInt(e.target.value, 10))}
            data-testid="conference-remote"
          />
          <span className={radioClasses}>
            <i className="nc-icon-glyph tech_desktop-screen available"></i>
            Remote
          </span>
        </label>
      </div>
    </div>
  );
};

export default ConferenceMoreOptions;
