import React, { useEffect, useRef, useState } from 'react';
import '../../../../vendor/redactor.scss';
import { AssignmentFormFieldProps } from '../types';
import $ from 'jquery';

const RichTextArea = ({
  value,
  onChange,
  saveOnChange = true,
  isSuccessNotifying,
}: AssignmentFormFieldProps & { saveOnChange?: boolean }) => {
  value = value || '';
  const textRef = useRef<HTMLTextAreaElement>();
  const valueRef = useRef(value || '');
  const [redactorClass, setRedactor] = useState(null);

  const handleSave = () => {
    const textValue = textRef.current.value;
    if (valueRef.current === textValue) {
      return;
    }
    onChange(textValue);
  };

  // Redactor configuration options found here: https://imperavi.com/redactor/docs/settings/
  const redactorOptions = {
    buttons: [
      'bold',
      'italic',
      'underline',
      'unorderedlist',
      'orderedlist',
      'outdent',
      'indent',
      'alignment',
    ],
    linebreaks: true,
    iframe: true,
    removeEmptyTags: false,
    convertLinks: false,
    allowedTags: [
      'p',
      'b',
      'strong',
      'em',
      'i',
      'u',
      'sup',
      'sub',
      'del',
      's',
      'strike',
      'img',
      'br',
      'span',
      'div',
      'ul',
      'ol',
      'li',
      'table',
      'thead',
      'tbody',
      'tr',
      'th',
      'td',
      'tfoot',
    ],
    css: '/stylesheets/tinymce.css',
    changeCallback: saveOnChange ? () => onChange(textRef.current.value, false, true) : () => {},
    blurCallback: handleSave,
  };

  useEffect(() => {
    // initialize redactor object, and this creates the text area
    if (textRef.current && !redactorClass) {
      setRedactor($(textRef.current).redactor(redactorOptions));
    }
  }, [textRef.current]);

  useEffect(() => {
    // whenever value prop changes we need to update the redator value
    valueRef.current = value;
    if (redactorClass) {
      redactorClass.redactor('set', value);
    }
  }, [value]);

  return (
    <>
      <div>
        {isSuccessNotifying && (
          <div className="max-[926px]:mb-1 min-[926px]:absolute flex items-center h-10 right-32 z-10 text-green-3">
            Saved!
          </div>
        )}
      </div>
      <textarea
        className=""
        id="content"
        ref={textRef}
        rows={8}
        defaultValue={value}
      ></textarea>
    </>
  );
};

export default React.memo(RichTextArea);
