import React from 'react';
import Alerts from './Alerts';
import AttendanceTime from './AttendanceTime';

const AttendanceDetail = ({
  student,
  attendance,
  isMaster,
  closeSidebar,
  isAttendanceLocked,
  classesUrl,
  settings,
}) => {
  const popupTitle = `${student.first_name} ${student.last_name} Profile`;
  const studentPopupUrl = `${classesUrl}/profile/${student.id}`;
  const hasPronouns = student.pronouns && settings.showPronouns;

  return (
    <>
      <div
        className="vx-sidebar__title"
        title="Open Student Profile"
      >
        <div>
          <a
            href={`javascript:void(window.open('${studentPopupUrl}','${popupTitle}','width=550,height=750'))`}
          >
            <span className="vx-heading-4">
              {student.first_name}
              <br />
            </span>
            <span className="vx-heading-5">{student.last_name}</span>
          </a>
          {hasPronouns && <p className="vx-heading-6">{student.pronouns}</p>}
        </div>
        <div
          className="vx-sidebar__close-button"
          onClick={closeSidebar}
        >
          <i className="nc-icon-glyph x2 arrows-1_minimal-right" />
        </div>
      </div>
      <img
        className="avatar-fit"
        src={student.photo_url}
      />
      <div className="vx-sidebar__content">
        <div className="vx-sidebar__section">
          <p className="vx-heading-4">Alerts</p>
          <Alerts alerts={student.alerts} />
        </div>
        <div className="vx-sidebar__section">
          {isMaster && (
            <>
              <p className="vx-heading-4">Times</p>
              <AttendanceTime
                isDisabled={isAttendanceLocked}
                attendance={attendance}
                field={'late_arrival_time'}
              />
              <AttendanceTime
                isDisabled={isAttendanceLocked}
                attendance={attendance}
                field={'early_dismissal_time'}
              />
              <AttendanceTime
                isDisabled={isAttendanceLocked}
                attendance={attendance}
                field={'return_time'}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AttendanceDetail;
