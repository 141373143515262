import React from 'react';
import { CurrentRequestData } from '../types';
import { useStudentState } from '../../../../stores/useCourseRequestsStore';
import { isRequestLockedByApproval, isRequestLockedByRecommended } from '../helpers';

type RequestCardActionProps = {
  request: CurrentRequestData;
  handleRemoveCard: (number) => void;
};
const RequestCardAction = ({ request, handleRemoveCard }: RequestCardActionProps) => {
  const { readOnly, isFacultyPortal } = useStudentState();
  const isLockedByApproval = isRequestLockedByApproval(request, isFacultyPortal);
  const isLockedByRecommended = isRequestLockedByRecommended(request, isFacultyPortal);

  if (isLockedByApproval) {
    return (
      <div className="font-light text-green-3 leading-7 flex items-center">
        <div>Request Approved</div>
        <i className="nc-icon-glyph ui-1_check ml-1"></i>
      </div>
    );
  }
  if (isLockedByRecommended) {
    return (
      <div className="font-light text-green-3 leading-7 flex items-center">
        <div>Recommended!</div>
      </div>
    );
  }

  if (readOnly) return null;

  return (
    <div
      className="text-red-4 hover:text-red-3 text-xs leading-7 cursor-pointer"
      onClick={() => handleRemoveCard(request.id)}
    >
      <i className="nc-icon-glyph ui-1_trash text-xl leading-7 pt-1"></i>
    </div>
  );
};

export default RequestCardAction;
