import React from 'react';
import ReactSelect from 'react-select';
import { useScreenSizes } from '../../../stores/useAppStore';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
};

const PeriodSelect = ({ switchPeriod, periods, isLoadingPeriod }) => {
  const { isSm: isMobile } = useScreenSizes();

  if (!periods.options || !periods.options.length) {
    return null;
  }

  return (
    <div
      className="period-dropdown"
      data-testid="periods-dropdown"
    >
      <ReactSelect
        defaultValue={periods.selected}
        value={periods.selected}
        options={periods.options}
        styles={colourStyles}
        isSearchable={false}
        onChange={(period) => {
          if (period.value !== periods.selected.value) {
            switchPeriod(period);
          }
        }}
        isOptionDisabled={() => isLoadingPeriod}
        isLoading={isLoadingPeriod}
        isDisabled={!periods.options || periods.options.length === 1}
        maxMenuHeight={242}
        minMenuHeight={242}
        menuPlacement={isMobile ? 'top' : 'auto'}
      />
    </div>
  );
};

export default PeriodSelect;
