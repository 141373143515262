import React from 'react';
import PropTypes from 'prop-types';
import { TOGGLE } from './utils';
import { useScreenSizes, useUserState } from '../../../stores/useAppStore';

const SettingsDropdownItem = ({ label, onChecked, onChange }) => {
  return (
    <div className="settings-dropdown-item">
      <div className="vx-form-label">{label}</div>
      <label className="vx-form-control vx-switch">
        <input
          className="vx-switch__input"
          type="checkbox"
          checked={onChecked}
          onChange={onChange}
          data-testid="toggle-sensitive-data-button"
        />
        <div className="vx-switch__slider">
          <span className="vx-switch__text-on">
            <i className="nc-icon-glyph ui-1_check-curve"></i>
          </span>
        </div>
      </label>
    </div>
  );
};

const SettingsDropdownButton = ({ settings, setSettings }) => {
  const ref = React.useRef(null);
  const { isSm: isMobile } = useScreenSizes();
  const [isDropdownActive, setIsDropdownActive] = React.useState(false);
  const { setFocusedStudentRow } = useUserState();

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsDropdownActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      ref={ref}
      onClick={() => setIsDropdownActive((prev) => !prev)}
      className={`settings-dropdown-container ${isDropdownActive ? 'active' : ''}`}
    >
      <button
        type="button"
        className={`vx-button vx-button--neutral ${isDropdownActive ? 'vx-button--active' : ''}`}
        data-testid="settingsDropdown-button"
      >
        <i className="nc-icon-glyph ui-1_settings-gear-64"></i>
        Settings
      </button>
      <div className="settings-dropdown">
        {!isMobile && (
          <SettingsDropdownItem
            label={'Hide Sensitive Data?'}
            onChecked={settings.hideSensitiveData}
            onChange={() => setSettings({ type: TOGGLE, name: 'hideSensitiveData' })}
          />
        )}
        <SettingsDropdownItem
          label={'Hide Student Photos?'}
          onChecked={settings.hidePhotos}
          onChange={() => setSettings({ type: TOGGLE, name: 'hidePhotos' })}
        />
        <SettingsDropdownItem
          label={'Group Students by Cohort Status?'}
          onChecked={settings.groupByCohort}
          onChange={() => {
            setFocusedStudentRow(-1); // reset to right before the first student
            setSettings({ type: TOGGLE, name: 'groupByCohort' });
          }}
        />
      </div>
    </div>
  );
};

SettingsDropdownButton.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default SettingsDropdownButton;
