import React from 'react';
import { useGridData } from '../queries';

type BorderRowProps = {
  numberOfBorderCells: number;
  skipFirstCell?: boolean;
  trimFirst?: boolean;
  trimLast?: boolean;
};
const BorderRow = ({ skipFirstCell, numberOfBorderCells, trimFirst, trimLast }: BorderRowProps) => {
  const { data: gridData } = useGridData();
  const hasAssignments = gridData?.assignments.length;
  if (!hasAssignments) {
    return null;
  }

  const borderElements = [];

  for (let i = 1; i <= numberOfBorderCells; i++) {
    borderElements.push(
      <td className="h-4 bg-white p-0 translate-y-[-1px]">
        <div
          className={`bg-neutral-5 h-1.5 ${trimLast && i === numberOfBorderCells ? 'mr-1' : ''} ${
            trimFirst && i === 1 ? 'ml-0.5' : ''
          }`}
        ></div>
      </td>
    );
  }

  return (
    <tr className="">
      {skipFirstCell && <td></td>}
      {borderElements}
    </tr>
  );
};

export default BorderRow;
