import React from 'react';

const ErrorMessage = ({ message }) => (
  <small
    className="vx-subtitle status-error-message"
    data-testid="attendance-errorMessage"
  >
    <i className="nc-icon-outline ui-2_alert-circle-i"></i>
    {message}
  </small>
);

ErrorMessage.defaultProps = {
  message: 'There was an issue with saving. Please refresh the page and try again.',
};

export default ErrorMessage;
