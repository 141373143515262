import React from 'react';
import { areConferencesAvailable, getDisplayName, getNumOfSelections } from './helpers';
import classNames from 'classnames';
import ConferenceTeacherRow from './ConferenceTeacherRow';

const Teachers = ({
  teachers,
  timeConflicts,
  handleSelectionChange,
  toggleLocation,
  schedule,
  canSelect,
  studentId,
  dateFormat,
  timeFormat,
  allowConflicts,
  isReadOnly,
  isMobile,
  hasConferences,
  hasAttendanceOption,
}) => {
  if (!teachers || !Object.values(teachers).length) {
    return (
      <div className="vx-subtitle conferences-none-available">
        <em>No Conferences Available</em>
      </div>
    );
  }

  const sortedTeachers = Object.values(teachers).sort((a, b) =>
    a.last_name.localeCompare(b.last_name)
  );
  return sortedTeachers.map((teacher) => {
    return (
      <ConferenceTeacherRow
        key={teacher.person_fk}
        studentId={studentId}
        teacher={teacher}
        schedule={schedule}
        timeConflicts={timeConflicts}
        handleSelectionChange={handleSelectionChange}
        toggleLocation={toggleLocation}
        canSelect={canSelect}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        allowConflicts={allowConflicts}
        isReadOnly={isReadOnly}
        isMobile={isMobile}
        hasConferences={hasConferences}
        hasAttendanceOption={hasAttendanceOption}
      />
    );
  });
};

const ConferencesList = ({
  students,
  timeConflicts,
  handleSelectionChange,
  toggleLocation,
  schedule,
  maxSelections,
  dateFormat,
  timeFormat,
  allowConflicts,
  isReadOnly,
  isMobile,
  hasAttendanceOption,
}) => {
  const sortedStudents = Object.values(students).sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );
  const content = sortedStudents.map((student) => {
    const numOfSelections = getNumOfSelections(student);
    const canSelect = maxSelections === 0 || numOfSelections < maxSelections;
    const numOfTeachers = student.teachers ? student.teachers.length : 0;
    const hasConferences = areConferencesAvailable(student);
    const headerClasses = classNames('vx-card__title student-header-title', {
      'student-header-no-conferences': !hasConferences,
    });

    return (
      <div
        className="vx-card conferences-student-item"
        key={student.person_fk}
      >
        <div className={headerClasses}>
          {student.photo_url && (
            <img
              className="vx-avatar avatar-fit student-avatar"
              src={student.photo_url}
              data-testid="conferencesList-student-img"
            />
          )}
          <h5>{getDisplayName(student)}</h5>
          {hasConferences && (
            <small
              className="vx-subtitle"
              data-testid="conferencesList-numOfTeachers"
            >
              {numOfTeachers} {numOfTeachers === 1 ? 'teacher' : 'teachers'}
            </small>
          )}
        </div>
        {hasConferences && maxSelections > 0 && (
          <div className="conferences-student-selections">
            {numOfSelections}/{maxSelections}
          </div>
        )}
        <div className="vx-card__content teacher-row-container">
          <Teachers
            studentId={student.person_fk}
            teachers={student.teachers}
            schedule={schedule}
            timeConflicts={timeConflicts}
            handleSelectionChange={handleSelectionChange}
            toggleLocation={toggleLocation}
            canSelect={canSelect}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            allowConflicts={allowConflicts}
            isReadOnly={isReadOnly}
            isMobile={isMobile}
            hasConferences={hasConferences}
            hasAttendanceOption={hasAttendanceOption}
          />
        </div>
      </div>
    );
  });

  return content;
};

export default ConferencesList;
