import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import DatePickerMonth from './DatePickerMonth';
import DatePickerCalendarDay from './DatePickerCalenderDay';
import classNames from 'classnames';
import { convertListToObject, DATE_FORMAT } from './utils';
import DatePickerDay from './DatePickerDay';
import { useScreenSizes } from '../../../stores/useAppStore';

const DatePicker = ({
  date,
  onChange,
  selectYears,
  today,
  selectedDateIsTaken,
  scheduledDays,
  defaultFocused = false,
}) => {
  const [focused, setFocused] = React.useState(defaultFocused);
  const [scheduledDaysHash, setScheduledDaysHash] = React.useState(
    convertListToObject(scheduledDays, 'calendar_date')
  );
  const hasScheduledDays = scheduledDays.length;
  scheduledDays = scheduledDays.sort((a, b) => a.sort_key - b.sort_key);
  const { isSm: isMobile } = useScreenSizes();

  useEffect(() => {
    setScheduledDaysHash(convertListToObject(scheduledDays, 'calendar_date'));
  }, [scheduledDays]);

  const getDaySelection = (direction) => {
    let closestDate;
    if (direction === 1) {
      // find first scheduled day that is after current date
      closestDate = scheduledDays.find((scheduledDay) => {
        return date.isBefore(scheduledDay.calendar_date);
      });
    } else {
      // work backwards and find first scheduled day that is before current date
      closestDate = scheduledDays
        .slice()
        .reverse()
        .find((scheduledDay) => {
          return date.isAfter(scheduledDay.calendar_date);
        });
    }

    if (closestDate) {
      onChange(moment(closestDate.calendar_date));
    } else {
      onChange(date.add(direction, 'days'));
    }
  };

  const leftArrowClasses = classNames('attendance-date-arrow-left', {
    'vx-tooltipped vx-tooltipped--se': !isMobile,
  });
  const rightArrowClasses = classNames('attendance-date-arrow-right', {
    'vx-tooltipped vx-tooltipped--sw': !isMobile,
  });

  return (
    <div className="attendance-date-picker-container">
      <div
        className={leftArrowClasses}
        aria-label="Previous Scheduled Day"
        data-testid="prevDate-button"
        onClick={() => getDaySelection(-1)}
      >
        <i className="nc-icon-glyph arrows-1_minimal-left"></i>
      </div>
      <div
        className="date-picker-inside-container"
        data-testid="datePicker-open"
      >
        <SingleDatePicker
          date={date}
          focused={focused}
          isOutsideRange={(day) =>
            hasScheduledDays && !(day.format(DATE_FORMAT) in scheduledDaysHash)
          }
          noBorder={true}
          numberOfMonths={1}
          onDateChange={onChange}
          onFocusChange={({ focused }) => setFocused(focused)}
          placeholder=""
          renderDayContents={(day) => {
            return (
              <DatePickerDay
                day={day}
                date={date}
                hasScheduledDays={hasScheduledDays}
                selectedDateIsTaken={selectedDateIsTaken}
                scheduledDaysHash={scheduledDaysHash}
                today={today}
              />
            );
          }}
          renderMonthElement={(props) => {
            return (
              <DatePickerMonth
                date={props.month}
                onMonthSelect={props.onMonthSelect}
                onYearSelect={props.onYearSelect}
                selectYears={selectYears}
              />
            );
          }}
          renderCalendarDay={(props) => {
            return (
              <DatePickerCalendarDay
                date={date}
                today={today}
                hasScheduledDays={hasScheduledDays}
                datesProps={props}
              />
            );
          }}
          readOnly
          displayFormat="MMMM DD, YYYY"
        />
      </div>
      <div
        className={rightArrowClasses}
        aria-label="Next Scheduled Day"
        data-testid="nextDate-button"
        onClick={() => getDaySelection(1)}
      >
        <i className="nc-icon-glyph arrows-1_minimal-right"></i>
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  date: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectYears: PropTypes.number.isRequired,
  today: PropTypes.string.isRequired,
  selectedDateIsTaken: PropTypes.bool.isRequired,
};

DatePicker.defaultProps = {
  selectYears: 10,
};

export default DatePicker;
