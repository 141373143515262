import React from 'react';

import Field from './Field';
import TagsList from '../TagsList';
import Copyable from './Copyable';

const defaultConfig = {
  dataType: 'text',
  maxLength: 10,
  copyable: false,
  locale: 'en',
  code: 'USD',
};

export default function DataField(props) {
  const { name, value } = props;

  const config = { ...defaultConfig, ...props.config };

  const BLANK_PLACEHOLDER = '--';
  const PARSE_DATE_FORMAT = 'YYYY-MM-DD';
  const DISPLAY_DATE_FORMAT = 'ddd MMM DD, YYYY';
  const isCopyable = config.copyable;

  const formatDateValue = (value) => {
    return value ? moment(value, PARSE_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : BLANK_PLACEHOLDER;
  };

  const formatOptionValue = (value) => {
    const optionValue = config.options.find((option) => option.value === value);
    return optionValue ? optionValue.description : BLANK_PLACEHOLDER;
  };

  const formatNumberValue = (value) => {
    return value ? Number(value).toString().slice(0, config.maxLength) : BLANK_PLACEHOLDER;
  };

  const formatMonetaryValue = (value) => {
    const formatter = new Intl.NumberFormat(config.locale, {
      style: 'currency',
      currency: config.code,
    });
    return value ? formatter.format(value) : BLANK_PLACEHOLDER;
  };

  const formatPhoneValue = (value) => {
    if (!value) return BLANK_PLACEHOLDER;

    return (
      <a
        href={`tel:${value}`}
        title={value}
      >
        {value}
      </a>
    );
  };

  const formatTagsValue = (value) => {
    if (!value) return BLANK_PLACEHOLDER;

    return (
      <TagsList
        value={value}
        config={{ valueSeparator: ' ', disableTextTransform: true }}
      />
    );
  };

  const formatMaskedValue = (value) => {
    if (!value) return BLANK_PLACEHOLDER;

    return value.replace(/.(?=.{4,}$)/g, '*');
  };

  const formatValue = (value) => {
    switch (config.dataType) {
      case 'date':
        return formatDateValue(value);

      case 'radio-group':
      case 'select':
        return formatOptionValue(value);

      case 'number':
        return formatNumberValue(value);

      case 'money':
        return formatMonetaryValue(value);

      case 'phone':
        return formatPhoneValue(value);

      case 'tags':
        return formatTagsValue(value);

      case 'masked':
        return formatMaskedValue(value);

      case 'text':
      case 'textarea':
      default:
        return value || BLANK_PLACEHOLDER;
    }
  };

  const displayValue = isCopyable ? (
    <Copyable value={value}>{formatValue(value)}</Copyable>
  ) : (
    formatValue(value)
  );

  return (
    <Field
      className="vx-data-field"
      {...props}
    >
      <div className="vx-data-field__div">{displayValue}</div>
    </Field>
  );
}
