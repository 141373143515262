import React from 'react';
import PropTypes from 'prop-types';
import Hideable from './Hideable';
import Student from './Student';
import AttendanceContext from './AttendanceContext';
import { isAttendanceLocked } from './utils';
import classNames from 'classnames';
import { useScreenSizes } from '../../../stores/useAppStore';

const Students = ({
  settings,
  isMaster,
  students,
  attendanceStatuses,
  selectedStudent,
  onClickStudent,
}) => {
  const { attendance: attendanceObj } = React.useContext(AttendanceContext);
  const { hideSensitiveData, areClassNotesSensitive, groupByCohort } = settings;
  const { isSm: isMobile } = useScreenSizes();

  const dailyNotesClasses = classNames('notes-left-padding ae-grid__item item-sm-3', {
    'item-sm-6': isMaster,
  });
  const classNotesClasses = classNames('notes-left-padding ae-grid__item item-sm-3', {
    'item-sm-6': hideSensitiveData,
  });

  const buildStudents = (students) => {
    return students.map((student, rowIndex) => {
      const attendanceEntry = attendanceObj[student.id];
      return (
        <Student
          key={student.id}
          student={student}
          attendance={attendanceEntry}
          attendanceStatuses={attendanceStatuses}
          selected={selectedStudent === student.id}
          onClick={onClickStudent}
          settings={settings}
          isMaster={isMaster}
          isAttendanceLocked={isAttendanceLocked(attendanceEntry, isMaster)}
          dailyNotesClasses={dailyNotesClasses}
          classNotesClasses={classNotesClasses}
          rowIndex={student.rowIndex !== undefined ? student.rowIndex : rowIndex}
        />
      );
    });
  };

  const buildStudentGroups = () => {
    let rowIndex = 0;
    const groupedStudents = _(students)
      .map((student) => ({
        ...student,
        cohortStatus: attendanceObj[student.id].cohort_status,
        cohortStatusSortKey: attendanceObj[student.id].cohort_status_sort_key,
      }))
      .groupBy((student) => student.cohortStatus)
      .sortBy((group) => group[0].cohortStatusSortKey)
      // set the rowIndex to enable focusing student rows in order
      .map((group) => {
        return group.map((student) => {
          return { ...student, rowIndex: rowIndex++ };
        });
      })
      .value();

    return groupedStudents.map((group) => {
      return (
        <div
          className="students-group"
          key={group[0].cohortStatus}
        >
          <h4 className="ae-container students-group-header">{group[0].cohortStatus}</h4>
          <div>{buildStudents(group)}</div>
        </div>
      );
    });
  };

  return (
    <div className="students-list-container">
      {!isMobile && (
        <div className="ae-container">
          <div className="ae-grid">
            <div className="ae-grid__item item-sm-6" />
            <Hideable hidden={(hideSensitiveData && areClassNotesSensitive) || isMaster}>
              <div className="ae-grid__item item-sm-3 notes-left-padding">
                <small className="vx-form-label">Class Attendance Notes</small>
              </div>
            </Hideable>
            <Hideable hidden={hideSensitiveData}>
              <div className={dailyNotesClasses}>
                <small className="vx-form-label">Daily Notes</small>
              </div>
            </Hideable>
          </div>
        </div>
      )}

      {groupByCohort ? buildStudentGroups() : buildStudents(students)}
    </div>
  );
};

Students.propTypes = {
  settings: PropTypes.object.isRequired,
  isMaster: PropTypes.bool.isRequired,
  students: PropTypes.array.isRequired,
  attendanceStatuses: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      category: PropTypes.number.isRequired,
    })
  ).isRequired,
  selectedStudent: PropTypes.number,
  onClickStudent: PropTypes.func.isRequired,
};

export default Students;
