import React from 'react';
import classnames from 'classnames';
import { enabledTabs } from '../helpers';
import { useStudentState } from '../../../../stores/useCourseRequestsStore';

type TabsProps = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
};

const Tabs = ({ activeTab, setActiveTab }: TabsProps) => {
  const { portalConfig } = useStudentState();
  const handleClickTab = (tab: React.MouseEvent<HTMLElement>) => {
    setActiveTab(tab.target['id']);
  };

  return (
    <>
      <div className="mt-2.5 mb-4 border-b border-black">
        <ul className="flex gap-1">
          {enabledTabs(portalConfig).map((tab, index) => (
            <li
              key={index}
              id={tab.type}
              className={classnames(
                'w-fit px-2 py-1 rounded-t cursor-pointer text-center text-lg max-md:leading-4 max-md:text-base hover:bg-neutral-7 hover:text-[#012C56]',
                {
                  'bg-gray-100 border-b border-black': activeTab === tab.type,
                }
              )}
              onClick={handleClickTab}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Tabs;
