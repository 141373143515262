import React from 'react';
import { getFloatDisplay } from '../helpers';
import { useClassGrades } from '../queries';
import { useConfigurations, useFilters } from '../../../../stores/useGradebookStore';
import { Student } from '../types';
import Cell from './Cell';
import { ALL_YEAR_GRADING_PERIOD } from '../constants';

const TotalGradeCells = ({ student }: { student: Student }) => {
  const {
    display_calculated_grade_column: displayCalculated,
    display_predicted_grade_column: displayPredicted,
    predicted_grade_grading_period: predictedGradeGradingPeriod,
    predicted_grade_format: predictedGradeFormat,
  } = useConfigurations();
  const { gradingPeriod } = useFilters();
  const { data: classGrades } = useClassGrades();

  const gradeRecord = classGrades[`${student.person_fk}-${gradingPeriod}`];
  const classGrade =
    gradingPeriod !== ALL_YEAR_GRADING_PERIOD
      ? gradeRecord && gradeRecord.calculated_grade
        ? getFloatDisplay(gradeRecord.calculated_grade)
        : '-'
      : 'N/A';
  const detailUrl = student.grade_detail_url;

  // if the configuration predicted_grade_grading_period = 'all', then we want to always show the
  // student grade record for grading_period = 50, instead of the record for the selected grading period
  const predictedGradeRecord =
    predictedGradeGradingPeriod === 'active' ? gradeRecord : classGrades[`${student.person_fk}-50`];
  let predictedClassGrade = '-';
  const predictedGradeFromRecord = predictedGradeRecord?.predicted_grade;

  if (predictedGradeFromRecord && predictedGradeFromRecord !== '0.0') {
    predictedClassGrade =
      predictedGradeFormat === 'letter'
        ? predictedGradeRecord.predicted_grade
        : getFloatDisplay(predictedGradeRecord.predicted_grade);
  }

  const classes = 'bg-blue-6 border border-blue-5 w-24';
  return (
    <>
      {displayCalculated && (
        <Cell classOverrides={classes}>
          <div className="flex relative justify-center group w-full h-[38px]">
            {classGrade}
            {detailUrl && (
              <a
                href={detailUrl}
                target="_blank"
                rel="noreferrer"
              >
                <i className="invisible group-hover:visible text-black nc-icon-glyph files_single-folded-content absolute right-2 text-lg leading-[38px]" />
              </a>
            )}
          </div>
        </Cell>
      )}
      {displayPredicted && (
        <Cell classOverrides={classes}>
          <div className="h-[38px]">{predictedClassGrade}</div>
        </Cell>
      )}
    </>
  );
};

export default TotalGradeCells;
