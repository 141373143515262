import { SubTabType } from './types';
import React from 'react';

const LmsSidebarSubTab = ({ subTab }: { subTab: SubTabType }) => {
  return (
    <a
      href={subTab.url}
      className="flex hover:bg-blue-5 p-3 mb-2 bg-gray-100 items-center text-gray-900 h-10 font-medium rounded-md"
    >
      <i
        className={`nc-icon-outline ${subTab.nc_icon_name} text-gray-600 text-xl group-hover:text-black`}
      ></i>
      <div className={'ml-3 text-gray-600'}>{subTab.description}</div>
    </a>
  );
};

export default React.memo(LmsSidebarSubTab);
