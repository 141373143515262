import React, { useEffect, useState } from 'react';
import { useSendEmailNotifications } from '../../mutations';
import classNames from 'classnames';
import { EmailNotification } from '../../types';
import { DEFAULT_NOTIFICATIONS, ERROR_TIMEOUT, TIME_FORMAT } from '../../constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '../../helpers';
import { useStudentGrade } from '../../queries';

type NotificationsProps = {
  studentId: number;
  sidebarIsOpen: boolean;
};
const Notifications = ({ studentId, sidebarIsOpen }: NotificationsProps) => {
  const { t } = useTranslation('words');
  const [selectedTypes, setSelectedTypes] = useState(DEFAULT_NOTIFICATIONS);
  const [errorMessage, setErrorMessage] = useState(null);
  const hasSelection = Object.values(selectedTypes).some((t) => t === true);
  const gradeRecord = useStudentGrade(studentId);
  const gradeId = gradeRecord?.class_assignment_person_pk;
  const sendEmailNotifications = useSendEmailNotifications(gradeId);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (!sidebarIsOpen) {
      setSelectedTypes(DEFAULT_NOTIFICATIONS);
      setSuccessMessage(null);
    }
  }, [sidebarIsOpen]);

  const toggleSelectedType = (selectedType: EmailNotification) => {
    setSelectedTypes((state) => {
      return {
        ...state,
        [selectedType]: !state[selectedType],
      };
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await sendEmailNotifications.mutateAsync(selectedTypes);
      const selectedTypesString = Object.keys(selectedTypes)
        .filter((key) => selectedTypes[key])
        .map((key) => key[0].toUpperCase() + key.slice(1))
        .join(', ');

      const timeString = moment().format(TIME_FORMAT);
      setSuccessMessage(`Sent notifications at ${timeString} to: ${selectedTypesString}`);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
      setTimeout(() => {
        setErrorMessage(null);
      }, ERROR_TIMEOUT);
    } finally {
      setIsLoading(false);
    }
  };

  const buttonClasses = classNames('mt-5 text-white rounded-md h-8 w-40 flex justify-center', {
    'bg-neutral-3 cursor-pointer': hasSelection,
    'bg-neutral-4 pointer-events-none': !hasSelection || isLoading,
  });

  return (
    <div className="pt-5 px-4">
      <div className="text-lg font-semibold">Notification Email for Others</div>
      <div className="text-sm font-light">
        Send a system email notification about this assignment.
      </div>

      <div className="mt-5 vx-form-control vx-checkbox-group vx-checkbox-group--stacked">
        <label className="vx-checkbox-group__item w-[50%]">
          <input
            className="vx-checkbox-input"
            type="checkbox"
            name="my-field"
            value="Check Me"
            onChange={() => toggleSelectedType('student')}
            checked={selectedTypes.student}
          />
          <span className="vx-checkbox-group__label w-full">Student</span>
        </label>
        <label className="vx-checkbox-group__item mt-2 w-[50%]">
          <input
            className="vx-checkbox-input"
            type="checkbox"
            name="my-field-2"
            value="Check Me"
            onChange={() => toggleSelectedType('parents')}
            checked={selectedTypes.parents}
          />
          <span className="vx-checkbox-group__label w-full">Parents</span>
        </label>
        <label className="vx-checkbox-group__item mt-2 w-[50%]">
          <input
            className="vx-checkbox-input"
            type="checkbox"
            name="my-field-3"
            value="Check Me"
            onChange={() => toggleSelectedType('advisor')}
            checked={selectedTypes.advisor}
          />
          <span className="vx-checkbox-group__label w-full">{t('Advisor')}</span>
        </label>
        <label className="vx-checkbox-group__item mt-2 w-[50%]">
          <input
            className="vx-checkbox-input"
            type="checkbox"
            name="my-field-3"
            value="Check Me"
            onChange={() => toggleSelectedType('homeroom')}
            checked={selectedTypes.homeroom}
          />
          <span className="vx-checkbox-group__label w-full">{t('Homeroom')}</span>
        </label>
      </div>

      <button
        className={buttonClasses}
        onClick={handleSave}
        disabled={!hasSelection}
      >
        <i className="nc-icon-glyph ui-1_email-83 text-lg mr-2 leading-8" />
        <span className="leading-8">Send Email Now</span>
      </button>
      {successMessage && <div className="mt-2 text-green-3">{successMessage}</div>}
      {errorMessage && <div className="mt-2 text-red-3">{errorMessage}</div>}
    </div>
  );
};

export default Notifications;
