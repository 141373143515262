import React from 'react';
import PropTypes from 'prop-types';

const DEFAULT_ALERT_COLOR = 'blue';

const ALERT_COLORS = {
  general: 'yellow',
  family: 'purple',
  medical: 'red',
};

const Alert = ({ alertType, tagOnly, children }) => {
  const color = ALERT_COLORS[alertType] || DEFAULT_ALERT_COLOR;
  const alertTitle = _.capitalize(alertType);

  if (tagOnly === true) {
    return (
      <span
        className={`vx-tag vx-tag--${color} vx-tag--abbrev`}
        title={alertTitle}
      >
        {alertType[0]}
      </span>
    );
  }

  return (
    <>
      <p className="vx-heading-6">
        <span className={`vx-tag vx-tag--${color} vx-tag--abbrev alert-icon`}>{alertType[0]}</span>
        {alertTitle}
      </p>
      <p>{children}</p>
    </>
  );
};

Alert.propTypes = {
  alertType: PropTypes.oneOf(['general', 'family', 'medical', 'academic']).isRequired,
  tagOnly: PropTypes.bool,
};

Alert.defaultProps = {
  tagOnly: false,
};

const Alerts = ({ alerts: alertsObject, tagOnly }) => {
  // we only want to show alert types that actually have alerts
  const alerts = Object.entries(alertsObject).filter(([key, value]) => !_.isEmpty(value));

  if (alerts.length === 0) {
    return !tagOnly && <p>None</p>;
  }

  return (
    <>
      {alerts.map(([type, description]) => (
        <Alert
          key={type}
          alertType={type}
          tagOnly={tagOnly}
        >
          {description}
        </Alert>
      ))}
    </>
  );
};

Alerts.propTypes = {
  alerts: PropTypes.object.isRequired,
  tagOnly: PropTypes.bool,
};

export default Alerts;
