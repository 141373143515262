import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import usePhotoGalleryStore from '../../../stores/usePhotoGalleryStore';
import SlideshowModal from './SlideshowModal';
import GalleryViewHeader from './GalleryViewHeader';
import UploadModal from './UploadModal';
import ImageDeleteModal from './ImageDeleteModal';
import ImageCaptionModal from './ImageCaptionModal';

function GalleryView({ internalClassId, uploaderOptions }) {
  const { galleryId } = useParams();
  const { photoGalleries, allPhotos } = usePhotoGalleryStore();
  const [slideShowImage, setSlideShowImage] = useState({ id: null, file_pk: null, show: false });
  const [imageUpload, setImageUpload] = useState({ id: null, show: false });
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  const gallery = photoGalleries.find(
    (gallery) => Number(gallery.gallery_pk) === Number(galleryId)
  );

  const images = allPhotos.filter((photo) => photo.gallery_fk === gallery.gallery_pk);
  images.sort((a, b) => new Date(b.input_date) - new Date(a.input_date));

  if (!gallery || !images) {
    return <div>Loading gallery...</div>;
  }

  return (
    <div>
      <GalleryViewHeader
        internalClassId={internalClassId}
        gallery={gallery}
        uploaderOptions={uploaderOptions}
      />
      <div>
        {images.length > 0 ? (
          <div>
            <div className="flex flex-wrap ">
              {images.map((photo) => (
                <div
                  key={photo.file_pk}
                  className="justify-start w-fit sm:w-1/2 md:w-1/3 lg:w-1/4 p-2"
                >
                  <div className="relative">
                    <img
                      src={photo.url}
                      alt={photo.name}
                      onClick={() =>
                        setSlideShowImage({
                          id: photo.gallery_fk,
                          file_pk: photo.file_pk,
                          show: true,
                        })
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        aspectRatio: '1 / 1',
                      }}
                    />
                    {photo.description !== null && photo.description !== '' && (
                      <p className="text-[10px] neutral-500 truncate absolute bottom-0 w-full bg-opacity-50 bg-black text-white p-1">
                        {photo.description}
                      </p>
                    )}
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="text-[10px] text-neutral-400">
                      <i>{new Date(photo.input_date).toLocaleDateString('en-US', options)}</i>
                    </p>
                    <div className="flex justify-end">
                      <ImageCaptionModal
                        internalClassId={internalClassId}
                        galleryId={galleryId}
                        photo={photo}
                      />
                      <ImageDeleteModal
                        internalClassId={internalClassId}
                        galleryId={galleryId}
                        photo={photo}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <SlideshowModal
              slideShowImage={slideShowImage}
              setSlideShowImage={setSlideShowImage}
              galleryId={slideShowImage.id}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center flex-col pb-20 ">
            <h5 className="text-center mb-4">There are no photos in this gallery!</h5>
            <button
              onClick={() => setImageUpload({ id: gallery.gallery_pk, show: true })}
              className="text-neutral-400 font-bold py-2 px-4 border-dashed border-2 border-neutral-400 rounded flex items-center"
            >
              <i className="nc-icon-glyph ui-1_circle-bold-add text-sm leading-6 bg-white mr-2" />
              Click here to add photos
            </button>
            <UploadModal
              imageUpload={imageUpload}
              setImageUpload={setImageUpload}
              galleryId={gallery.gallery_pk}
              internalClassId={internalClassId}
              uploaderOptions={uploaderOptions}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default GalleryView;
