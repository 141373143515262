import React from 'react';
import VxLabel from './VxLabel';
import HelpText from './HelpText';
import PlainTextField from './PlainTextField';
import ReactSelect from 'react-select';
import { Field, useField, useFormikContext } from 'formik';
import VxErrorMessage from './VxErrorMessage';
import { ElementMetadata, ElementValueListItem } from '../types';
import { UPDATE_STATUS_READ_ONLY } from '../utils/element-helpers';
import classNames from 'classnames';

const SelectField = ({ element, metadata }: ElementMetadata) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(metadata.dataKeypath);
  const hasError = meta.touched && meta.error;
  const listItems = React.useMemo(
    () =>
      element.element_value_list_items.map((e) => ({
        ...e,
        label: e.description,
      })),
    []
  );
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const selectedValue = listItems.find(({ value }) => value === field.value);

  const gridItem = classNames({
    'ae-grid__item item-sm-8': element.component === 'relationship',
    'read-only': element.update_status === UPDATE_STATUS_READ_ONLY,
  });

  return (
    <>
      {element.component !== 'relationship' && (
        <VxLabel
          element={element}
          htmlFor={metadata.dataKeypath}
          hasError={hasError}
        />
      )}
      <HelpText
        element={element}
        hasError={hasError}
      />
      <div
        className={`vx-form-control vx-select ${gridItem}`}
        data-testid="select-field"
      >
        {metadata.isPlainText ? (
          <PlainTextField>{selectedValue?.description}</PlainTextField>
        ) : (
          <Field name={metadata.dataKeypath}>
            {() => (
              <ReactSelect
                options={listItems}
                defaultValue={selectedValue}
                value={selectedValue}
                onChange={(option: ElementValueListItem) => {
                  const value = (option && option.value) || '';
                  setFieldTouched(metadata.dataKeypath, true);
                  setFieldValue(metadata.dataKeypath, value);
                }}
                placeholder={element.placeholder_text}
                {...(element.start_blank ? { isClearable: true } : { defaultValue: listItems[0] })}
                isDisabled={isSubmitting}
              />
            )}
          </Field>
        )}
      </div>
      <VxErrorMessage
        element={element}
        metadata={metadata}
      />
    </>
  );
};

export default SelectField;
