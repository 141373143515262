import React from 'react';
import { useCourseRequestState } from '../../../../stores/useCourseRequestsStore';
import classNames from 'classnames';
import LoadingSpinner from '../../../shared/LoadingSpinner';

type CreditReportProps = {
  isLoadingList: boolean;
};
const CreditReport = ({ isLoadingList }: CreditReportProps) => {
  const { credits: CreditReports } = useCourseRequestState();

  if (isLoadingList) {
    return <LoadingSpinner />;
  }

  return (
    <div className="pl-2 overflow-auto">
      <table className="td:px-4 td:py-2 td:border">
        <thead className="bg-neutral-5 border-b border-b-neutral-2 text-left">
          <tr className="">
            <th className="w-40 px-1 py-0.5">Subject</th>
            <th className="w-20 px-1 py-0.5">Required</th>
            <th className="w-20 px-1 py-0.5">Earned</th>
            <th className="w-20 px-1 py-0.5">Needed</th>
            <th className="w-20 px-1 py-0.5">Scheduled</th>
            <th className="w-20 px-1 py-0.5">Will Need</th>
          </tr>
        </thead>
        <tbody>
          {CreditReports.length === 0 ? (
            <tr className="text-neutral-3">No records Found</tr>
          ) : (
            CreditReports.map((course, idx) => {
              const classes = classNames('px-1 py-0.5', {
                'text-neutral-4': course.will_need === 0,
                'text-neutral-1': course.will_need > 0,
              });

              return (
                <tr
                  key={course.subject + idx}
                  className="border-b"
                >
                  <td className={classes}>{course.subject}</td>
                  <td className={classes}>{course.credits_required.toFixed(1)}</td>
                  <td className={classes}>{course.credits_earned.toFixed(1)}</td>
                  <td className={classes}>{course.needed.toFixed(1)}</td>
                  <td className={classes}>{course.scheduled.toFixed(1)}</td>
                  <td className={classes}>{course.will_need.toFixed(1)}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CreditReport;
