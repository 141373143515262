import React, { FC } from 'react';
import { EditOptions, InputChildProps, ZgStatus } from '../types';

type BulkEditOptionProps = {
  field: string;
  label: string;
  handleToggle: (value, field) => void;
  onChange: (value, field) => void;
  editOptions: EditOptions;
  Component: FC<InputChildProps>;
  errorMessage?: string;
  statuses?: ZgStatus[];
};
const BulkEditOption: FC<BulkEditOptionProps> = ({
  Component,
  statuses,
  onChange,
  editOptions,
  errorMessage,
  label,
  field,
  handleToggle,
}) => {
  const { value, checked } = editOptions[field];
  const opacityClass = !checked ? 'opacity-70' : '';
  return (
    <div className="mb-2">
      <div className="flex justify-start">
        <div className="w-10"></div>
        <div className={`vx-form-label ${opacityClass}`}>{label}</div>
      </div>
      <div className="flex justify-start items-center">
        <div className="w-10">
          <input
            type="checkbox"
            data-testid="bulk-edit-checkbox"
            checked={checked}
            onChange={(e) => handleToggle(e.target.checked, field)}
            className="h-6 w-6 rounded border-gray-300 text-blue-4 shadow-none"
          />
        </div>
        <div className={opacityClass}>
          <Component
            value={value}
            onChange={(newValue) => onChange(newValue, field)}
            isDisabled={!checked}
            errorMessage={errorMessage}
            statuses={statuses}
          />
        </div>
      </div>
    </div>
  );
};

export default BulkEditOption;
