import React from 'react';
import Assignment from './Assignment.js';

const SelectAssignmentButton = ({ mobileDisplay, toggleAssignmentDisplay }) => {
  if (mobileDisplay) {
    return (
      <button
        className="vx-button select-assignment-button"
        onClick={toggleAssignmentDisplay}
      >
        Select Assignment
      </button>
    );
  }
  return null;
};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    let mobileDisplay = window.innerWidth <= 575 ? true : false;
    this.state = {
      mobileDisplay: mobileDisplay,
      mobileShowUnselectedAssignments: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeAssignmentId != prevProps.activeAssignmentId) {
      this.setState({ mobileShowUnselectedAssignments: false });
    }
  }

  // On mobile we want to hide inactive assignments unless the user is selecting a new one
  showUnselectedAssignments = () => {
    if (this.state.mobileDisplay && !this.state.mobileShowUnselectedAssignments) {
      return false;
    }

    return true;
  };

  toggleAssignmentDisplay = () => {
    this.setState((prevState) => ({
      mobileShowUnselectedAssignments: !prevState.mobileShowUnselectedAssignments,
    }));
  };

  render() {
    const assignmentList = this.props.assignments.map((assignment) => {
      if (
        this.showUnselectedAssignments() ||
        assignment.class_assignment_person_pk === this.props.activeAssignmentId
      ) {
        return (
          <Assignment
            key={assignment.class_assignment_person_pk}
            assignment={assignment}
            activeAssignmentId={this.props.activeAssignmentId}
            updateActiveAssignmentId={this.props.updateActiveAssignmentId}
          />
        );
      }
    });

    return (
      <div
        className={`ae-grid__item--collapse item-sm-4 item-xs-12 sidebar ${
          this.props.windowHeightExpanded ? 'file-attach-displayed' : ''
        }`}
      >
        {assignmentList}
        <SelectAssignmentButton
          mobileDisplay={this.state.mobileDisplay}
          toggleAssignmentDisplay={this.toggleAssignmentDisplay}
        />
      </div>
    );
  }
}

export default Sidebar;
