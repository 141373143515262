import React, { useState, useEffect } from 'react';
import { handleImageCreateRequest } from './Endpoints';
import { useFetch } from '../../../hooks/useFetch';
import Modal from '../../shared/Modal';
import ImageFileUploader from '../../shared/FileUploader';
import usePhotoGalleryStore from '../../../stores/usePhotoGalleryStore';

function UploadModal({ imageUpload, setImageUpload, internalClassId, galleryId, uploaderOptions }) {
  const { data: uploadResponse, sendFetchRequest: uploadRequest } = useFetch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { actions } = usePhotoGalleryStore();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleAddImages = async () => {
    const requestFormData = {
      internalClassId: internalClassId,
      galleryId: galleryId,
    };
    try {
      await handleImageCreateRequest(requestFormData, uploadedFiles, uploadRequest);
    } catch (error) {
      setErrorMessage('Something went wrong while uploading the image(s). Please try again.');
    }
    setImageUpload({ id: null, show: false });
    setUploadedFiles([]);
  };

  useEffect(() => {
    if (uploadResponse) {
      actions.addPhotos(uploadResponse.data.new_photos);
    }
  }, [uploadResponse]);

  return (
    <div>
      <Modal
        isOpen={Boolean(imageUpload.id === galleryId && imageUpload.show)}
        onSave={handleAddImages}
        overrideClasses="bg-blue-3 hover:bg-blue-4"
        label="Done"
        setIsOpen={() => setImageUpload({ id: null, show: false })}
      >
        <h2 className="text-lg font-bold text-sky-700">Upload Photos</h2>
        <ImageFileUploader
          setUploadedFiles={setUploadedFiles}
          uploaderOptions={{
            ...uploaderOptions,
          }}
        />
      </Modal>
      <Modal
        isOpen={Boolean(errorMessage)}
        onSave={() => {}}
        setIsOpen={() => setErrorMessage(null)}
        showButtons={false}
      >
        <h2 className="text-lg font-bold text-sky-700">Error</h2>
        <p className="mb-2">{errorMessage}</p>
      </Modal>
    </div>
  );
}

export default UploadModal;
