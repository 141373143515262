import React from 'react';
import { CurrentGrade } from '../types';
import { useStudentState } from '../../../../stores/useCourseRequestsStore';

type CurrentScheduleProps = {
  gradeData: CurrentGrade;
};
const CurrentScheduleRow = ({ gradeData }: CurrentScheduleProps) => {
  const { isFacultyPortal } = useStudentState();
  const canViewGrades = !isFacultyPortal || gradeData.has_view_grades_permission;
  const canViewClass = isFacultyPortal || gradeData.include_grades_flag;

  const average = gradeData.average > 0 ? gradeData.average : '-';
  const posted = gradeData.numeric_grade;
  const postedLetter = gradeData.letter_grade;
  let postedDisplay = '-';
  if (posted) {
    postedDisplay =
      postedLetter && postedLetter !== posted.toString()
        ? `${posted} (${postedLetter})`
        : posted.toString();
  }

  if (!canViewClass) return null;

  if (!canViewGrades) {
    return (
      <tr
        key={gradeData.class_name}
        className="border-b h-8 bg-neutral-6"
      >
        <td className="px-1 py-0.5">{gradeData.class_name}</td>
        <td colSpan={3} />
      </tr>
    );
  }

  return (
    <tr
      key={gradeData.class_name}
      className="border-b h-8"
    >
      <td className="px-1 py-0.5">{gradeData.class_name}</td>
      <td className="px-1 py-0.5 text-center">{average}</td>
      <td className="px-1 py-0.5 text-center">{postedDisplay}</td>
      <td className="px-1 py-0.5 text-center">
        <a
          href={gradeData.grade_detail_url}
          target="_blank"
          rel="noreferrer"
        >
          <i className="text-blue-4 nc-icon-glyph files_single-folded-content text-lg leading-[38px]" />
        </a>
      </td>
    </tr>
  );
};

export default CurrentScheduleRow;
