import React, { useState } from 'react';
import { handleDeleteRequest } from './Endpoints';
import { useFetch } from '../../../hooks/useFetch';
import usePhotoGalleryStore from '../../../stores/usePhotoGalleryStore';
import Modal from '../../shared/Modal';

function DeleteGalleryModal({ internalClassId, gallery }) {
  const [deleteConfirmation, setDeleteConfirmation] = useState({ id: null, show: false });
  const [errorMessage, setErrorMessage] = useState(null);
  const { actions } = usePhotoGalleryStore();
  const { data, sendFetchRequest } = useFetch();

  const handleDelete = async (currentGalleryId) => {
    if (deleteConfirmation.id === currentGalleryId && deleteConfirmation.show) {
      try {
        const requestFormData = {
          internal_class_id: internalClassId,
          gallery_id: currentGalleryId,
        };
        await handleDeleteRequest(requestFormData, sendFetchRequest);
      } catch (error) {
        setErrorMessage('Something went wrong while deleting the gallery. Please try again.');
      }
      actions.removeGallery(deleteConfirmation.id);
    }
  };

  return (
    <div>
      <button
        onClick={() => setDeleteConfirmation({ id: gallery.gallery_pk, show: true })}
        className="btn"
      >
        <i className="nc-icon-glyph ui-1_trash text-lg leading-7 pt-1 text-sky-700"></i>
      </button>
      <Modal
        isOpen={Boolean(deleteConfirmation.id === gallery.gallery_pk && deleteConfirmation.show)}
        onSave={() => handleDelete(gallery.gallery_pk)}
        overrideClasses="bg-blue-3 hover:bg-blue-4"
        label="Yes, Delete"
        setIsOpen={() => setDeleteConfirmation({ id: null, show: false })}
      >
        <h2 className="text-lg font-bold text-sky-700">Delete Gallery</h2>
        <p className="mb-2">
          Are you sure you want to delete the gallery{' '}
          <strong>
            <em>{gallery.name}</em>
          </strong>
          ?
          <br />
          Are you sure you want to delete this gallery and all photos inside of it?{' '}
          <strong>This operation cannot be undone!</strong>
        </p>
      </Modal>
      <Modal
        isOpen={Boolean(errorMessage)}
        onSave={() => {}}
        setIsOpen={() => setErrorMessage(null)}
        showButtons={false}
      >
        <h2 className="text-lg font-bold text-sky-700">Error</h2>
        <p className="mb-2">{errorMessage}</p>
      </Modal>
    </div>
  );
}

export default DeleteGalleryModal;
