import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import LmsSidebarTabs from './LmsSidebarTabs';
import useNavigationStore from './useNavigationStore';
import LmsSidebarSubTab from './LmsSidebarSubTab';

type LmsMobileSidebarProps = {
  portalName: string;
  portalUrl: string;
};

const LmsMobileSidebar = ({ portalName, portalUrl }: LmsMobileSidebarProps) => {
  const className = useNavigationStore((state) => state.className);
  const subTabs = useNavigationStore((state) => state.subTabs);
  const { setIsModalOpen, isSidebarOpen, setSidebarOpen } = useNavigationStore();

  return (
    <Transition.Root
      show={isSidebarOpen}
      as={Fragment}
    >
      <div className="relative z-[1001] h-full md:hidden">
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0">
            <div className="bg-gray-100 h-full flex flex-col overflow-auto overscroll-contain">
              {/* close button */}
              <div className="fixed top-0 right-0">
                <i
                  className="p-2 nc-icon-glyph ui-1_simple-remove text-2xl cursor-pointer"
                  onClick={() => setSidebarOpen(false)}
                />
              </div>

              {/* class picker */}
              <div
                className="mt-6 w-full items-start text-center leading-10 flex px-6 mb-3 cursor-pointer"
                onClick={() => setIsModalOpen(true)}
              >
                <i className={'text-xl nc-icon-outline education_chalkboard'}></i>
                <div className="ml-3 text-left leading-3">
                  <p className="text-base font-semibold">{className}</p>
                  <p className="text-sm pointer-events-auto">Click here to change class</p>
                </div>
              </div>

              {/* lms sections */}
              <LmsSidebarTabs />

              {/* extra links */}
              <div className={'border-t border-slate-300 mx-3 mt-6 py-3'}>
                {subTabs.map((subTab, index) => (
                  <LmsSidebarSubTab
                    key={index}
                    subTab={subTab}
                  />
                ))}

                <LmsSidebarSubTab
                  subTab={{
                    url: portalUrl,
                    nc_icon_name: 'ui-1_home-simple',
                    description: `Back to ${portalName}`,
                  }}
                />
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

export default React.memo(LmsMobileSidebar);
