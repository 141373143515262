import React from 'react';

import TabsContext from './TabsContext';

function TabsView(props) {
  const { defaultTab } = props;

  const [activeTab, setActiveTab] = React.useState(defaultTab);
  const isActiveTab = (tab) => tab === activeTab;

  return (
    <div className="tabs">
      <TabsContext.Provider value={{ activeTab, isActiveTab, setActiveTab }}>
        {props.children}
      </TabsContext.Provider>
    </div>
  );
}

export default React.memo(TabsView);
