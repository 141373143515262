import React, { useState } from 'react';
import { Properties } from '../types';
import classNames from 'classnames';
import BooleanLabel from '../common/BooleanLabel';
import BooleanToggle from '../common/BooleanToggle';
import LockIcon from '../common/LockIcon';
import { ROW_HOVER_COLOR } from '../constants';

type BooleanSettingTypes = {
  category: string;
  keypath: string;
  settingProperties: Properties;
};

const BooleanSetting = ({ category, keypath, settingProperties }: BooleanSettingTypes) => {
  const isEditable = settingProperties.teacher_access_strategy === 'editable';
  const [serverError, setServerError] = useState(null);

  const rowClasses = classNames(
    `flex flex-wrap items-center px-5 py-2 text-sm font-medium hover:${ROW_HOVER_COLOR}`,
    {
      'text-gray-900': settingProperties.teacher_access_strategy === 'editable',
      'text-gray-500': settingProperties.teacher_access_strategy !== 'editable',
    }
  );

  const errorClasses = classNames('block w-full text-right text-red-500 text-xs mr-8', {
    hidden: !serverError,
  });

  return (
    <div className={rowClasses}>
      <BooleanLabel settingProperties={settingProperties} />
      <BooleanToggle
        settingProperties={settingProperties}
        setServerError={setServerError}
        serverError={serverError}
        isEditable={isEditable}
      />
      <div className="flex-none w-8">
        <LockIcon isDisplayed={!isEditable} />
      </div>
      <div className={errorClasses}>{serverError}</div>
    </div>
  );
};

export default BooleanSetting;
