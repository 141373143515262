import React from 'react';
import { dropboxStatuses } from '../../../constants.js';
import moment from 'moment';

const Assignment = ({ assignment, activeAssignmentId, updateActiveAssignmentId }) => {
  const lateCompletionStatus = 7;

  const assignmentStatusLabel = () => {
    let today = moment();
    let tomorrow = moment().add(1, 'days');
    let assignmentDueDate = moment(assignment.due_date, 'YYYY-MM-DD');

    if (assignment.assignment_submission_status === dropboxStatuses.EXPECTING) {
      if (assignment.completion_status === lateCompletionStatus) {
        return <div className="vx-tag vx-tag--red">LATE</div>;
      }
      if (assignmentDueDate.isSame(today, 'day')) {
        return <div className="vx-tag vx-tag--yellow">DUE TODAY</div>;
      }
      if (assignmentDueDate.isSame(tomorrow, 'day')) {
        return <div className="vx-tag vx-tag--yellow">DUE TOMORROW</div>;
      }
    } else if (assignment.assignment_submission_status === dropboxStatuses.RECEIVED) {
      return <div className="vx-tag vx-tag--green">COMPLETE</div>;
    }

    return null;
  };

  const handleClick = () => {
    updateActiveAssignmentId(assignment.class_assignment_person_pk);
  };

  const isActiveAssignment = () => {
    return assignment.class_assignment_person_pk === activeAssignmentId;
  };

  return (
    <div
      className={`vx-record-detail assignment ${isActiveAssignment() ? 'selected-assignment' : ''}`}
      onClick={handleClick}
    >
      <div className={`vx-record-header ${isActiveAssignment() ? 'selected-assignment' : ''}`}>
        <div className="vx-record-header__title">
          {assignment.class_id}:
          <span className="vx-record-header__course-description">
            {' '}
            {assignment.class_description}
          </span>
        </div>
        <div className="vx-subtitle subtitle">Due Date: {assignment.due_date_description}</div>
        {assignmentStatusLabel()}
        <div className="assigntment-type">{assignment.assignment_type}</div>
        <div className="vx-subtitle">{assignment.assignment_description}</div>
      </div>
    </div>
  );
};

export default Assignment;
