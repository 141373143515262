import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useStudentState } from '../../../../stores/useCourseRequestsStore';

type NotesTextareaProps = {
  readOnly: boolean;
  courseRequestNotes: string;
  onBlur: (field: string, value: string) => Promise<boolean>;
  error: string;
};

const NotesTextarea = ({ readOnly, courseRequestNotes, onBlur, error }: NotesTextareaProps) => {
  const [isSuccessNotifying, setIsSuccessNotifying] = useState(false);
  const [notesFocused, setNotesFocused] = useState<boolean>(false);
  const [notesValue, setNotesValue] = useState<string>(courseRequestNotes);

  useEffect(() => {
    setNotesValue(courseRequestNotes);
  }, [courseRequestNotes, error]);

  const handleBlur = async (newValue) => {
    newValue = newValue.trim();
    if (newValue === courseRequestNotes) {
      setNotesFocused(false);
      return;
    }

    if (newValue === '') newValue = null;

    const success = await onBlur('notes', newValue);
    if (success) {
      setNotesFocused(false);
      setIsSuccessNotifying(true);
      setTimeout(() => {
        setIsSuccessNotifying(false);
      }, 2000);
    }
  };
  const classes = classNames(
    'border-solid border-[#ddd] text-[12px] ring-transparent p-1 max-sm:mt-4 leading-4 resize',
    {
      'ring-2 ring-green-4': isSuccessNotifying,
      'cursor-not-allowed bg-gray-100': readOnly,
    }
  );

  return (
    <div className="flex-grow-1 w-full text-center">
      <textarea
        value={notesValue}
        readOnly={readOnly}
        onChange={(e) => setNotesValue(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
        onClick={() => setNotesFocused(true)}
        rows={3}
        cols={28}
        className={classes}
      ></textarea>
      {notesFocused && !readOnly && (
        <div className="italic text-[9px] text-left pt-px pl-2.5">
          Click outside the text box to save.
        </div>
      )}
      {isSuccessNotifying && <div className="text-green-4 text-left pl-2.5 h-4">Saved!</div>}
    </div>
  );
};

export default NotesTextarea;
