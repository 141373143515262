import React from 'react';
import Submission from './Submission.js';

const SubmissionList = ({ submissions, files }) => {
  if (submissions.length) {
    return submissions.map((submission) => {
      return (
        <Submission
          key={submission.class_assignment_person_submission_pk}
          submission={submission}
          files={files}
        />
      );
    });
  }

  return (
    <div className="vx-empty-state-read-only">
      <div className="vx-empty-state__icon">
        <i className="nc-icon-outline ui-1_attach-87"></i>
      </div>
      <p>Add a submission below. After adding a submission, mark the assignment as complete.</p>
    </div>
  );
};

export default SubmissionList;
