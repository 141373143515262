import React, { useEffect, useState } from 'react';
import {
  useCourseRequestActions,
  useCourseRequestState,
  useStudentState,
} from '../../../../stores/useCourseRequestsStore';
import classNames from 'classnames';
import CourseRequestFormSelect from '../shared/CourseRequestFormSelect';
import CheckBox from '../shared/Checkbox';
import { DEFAULT_PRIORITY_OPTIONS } from '../constants';
import { showField } from '../helpers';
import { useFetch } from '../../../../hooks/useFetch';
import { handleUpdateRequest } from '../resources';
import NotesTextarea from './NotesTextarea';
import { CurrentRequestData } from '../types';

type RequestCardBodyProps = {
  courseRequest: CurrentRequestData;
  index: number;
  totalRequests: number;
  setModalRemovingCard: (value: number | null) => void;
};

const RequestCardBody = ({
  courseRequest,
  index,
  totalRequests,
  setModalRemovingCard,
}: RequestCardBodyProps) => {
  const { setRequestField } = useCourseRequestActions();
  const { grading_periods: gradingPeriods, enrollment_levels: enrollmentLevels } =
    useCourseRequestState();
  const { isFacultyPortal, portalConfig, readOnly: readOnlyPortalStatus } = useStudentState();
  const [isSuccessNotifying, setIsSuccessNotifying] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  const requestApproved: boolean = courseRequest.approval_status === 1;
  const requestDenied: boolean = courseRequest.approval_status === 2 && !isFacultyPortal;
  const readOnly: boolean = readOnlyPortalStatus || (!isFacultyPortal && requestApproved);
  const { sendFetchRequest: courseRequestsUpdate, error: updateErrorResponse } = useFetch();

  const handleChangeRequest = async (field, selection): Promise<any> => {
    setErrorMessage(null);

    if (field === 'recommended') {
      selection = !courseRequest.recommended;
    } else if (field === 'approval_status') {
      // approval_status is not a boolean so we have to set it to whichever integer it was not
      selection = courseRequest.approval_status === 1 ? 0 : 1;
    }
    if (field === 'request_group' && selection.match(/[^0-9]/)) return;

    const tempSelection = selection;

    try {
      await handleUpdateRequest(courseRequest.id, field, selection, courseRequestsUpdate);
      setRequestField({ field, value: selection, id: courseRequest.id });

      if (field === 'request_group') {
        setIsSuccessNotifying(true);
        setTimeout(() => {
          setIsSuccessNotifying(false);
        }, 2000);
      }

      return true; // To allow success notification
    } catch (error) {
      if (error.message && !error.message.startsWith('TinyTds::Error:')) {
        setErrorMessage(error.message);
      } else {
        setRequestField({ field, value: tempSelection, id: courseRequest.id });
        return false;
      }
    }
  };

  const canDeleteRequest =
    !readOnly &&
    !requestApproved &&
    !requestDenied &&
    (isFacultyPortal ? true : !courseRequest.recommended);

  const handleRemoveRequest = () => {
    if (requestApproved) return;

    setModalRemovingCard(courseRequest.id);
  };

  const courseDescription = (): string => {
    const courseId = courseRequest.course_id ? `${courseRequest.course_id}: ` : '';
    return `${courseId}${courseRequest.course}`;
  };
  const menuPlacement =
    (totalRequests > 2 && [totalRequests - 1, totalRequests - 2].includes(index)) ||
    (totalRequests === 2 && totalRequests - 1 === index)
      ? 'top'
      : 'bottom';
  const readOnlyClasses = readOnly ? 'bg-gray-100' : '';
  const tooltipClasses =
    'invisible group-hover:visible absolute -top-2 right-[52px] w-[88px] bg-[#000000CC] rounded-sm after:absolute after:top-[32%] after:-right-3.5 after:border-8 after:border-l-[#000000CC] after:border-y-transparent after:border-r-transparent text-white text-left font-normal p-1';

  return (
    <tr
      id={`request-${courseRequest.id}`}
      className={classNames('w-full text-xs border-b border-gray-300', {
        'bg-gray-100': readOnly || index % 2 !== 0,
        'bg-green-100': readOnlyPortalStatus && courseRequest.enrolled,
      })}
    >
      <td
        className="w-2"
        style={{ backgroundColor: courseRequest.link_style }}
      ></td>

      <td className="w-10 py-1 text-center">
        {readOnlyPortalStatus ? (
          courseRequest.enrolled ? (
            <i className="nc-icon-glyph ui-1_check-curve text-neutral-3"></i>
          ) : (
            '-'
          )
        ) : (
          <input
            type="text"
            value={courseRequest.request_group}
            onChange={(e) => handleChangeRequest('request_group', e.target.value)}
            className={classNames(
              'flex-initial text-[12px] w-7 h-5 mx-1 px-1 border-gray-300',
              readOnlyClasses,
              { 'ring-2 ring-green-4': isSuccessNotifying }
            )}
            disabled={readOnly}
          />
        )}
      </td>
      <td className="text-sm px-[3px] w-52 min-h-[70px]">
        <div className="font-bold">{courseDescription()}</div>
        <div className="italic">{courseRequest.subject}</div>
        {showField('show_level', portalConfig) && (
          <div className="pb-2 w-28">
            <div className="inline pr-1 align-top">Level:</div>
            <CourseRequestFormSelect
              field="enrollment_level"
              options={enrollmentLevels}
              selectedValue={courseRequest.enrollment_level}
              onChange={handleChangeRequest}
              disabled={readOnly}
              containerWidth="64px"
              menuPlacement={menuPlacement}
            />
          </div>
        )}
        {errorMessage && <div className="text-red-3 text-xs">{errorMessage}</div>}
      </td>

      {showField('show_notes', portalConfig) && (
        <td className="pr-[3px] py-1 min-h-[84px] min-w-48">
          <NotesTextarea
            readOnly={readOnly}
            courseRequestNotes={courseRequest.notes || ''}
            onBlur={handleChangeRequest}
            error={updateErrorResponse}
          />
        </td>
      )}

      {showField('show_priority', portalConfig) && (
        <td className="px-[3px] py-1 w-28">
          <CourseRequestFormSelect
            field="priority"
            options={DEFAULT_PRIORITY_OPTIONS}
            selectedValue={courseRequest.priority}
            onChange={handleChangeRequest}
            disabled={readOnly}
            menuPlacement={menuPlacement}
          />
        </td>
      )}
      {showField('show_term', portalConfig) && (
        <td className="px-[3px] py-1 w-28">
          <CourseRequestFormSelect
            field="grading_period"
            options={gradingPeriods}
            selectedValue={courseRequest.grading_period}
            onChange={handleChangeRequest}
            disabled={readOnly}
            menuPlacement={menuPlacement}
          />
        </td>
      )}
      {showField('show_recommended', portalConfig) && (
        <td className="px-[3px] w-[85px] text-center">
          <CheckBox
            field={'recommended'}
            checkedValue={courseRequest.recommended}
            onChange={handleChangeRequest}
            disabled={!isFacultyPortal || readOnly}
            styles={classNames('w-3.5 h-3.5', {
              'bg-gray-100': !isFacultyPortal || readOnlyPortalStatus,
              'bg-gray-400':
                (readOnlyPortalStatus && courseRequest.recommended) ||
                (!isFacultyPortal && courseRequest.recommended),
            })}
          />
        </td>
      )}
      {showField('show_approved', portalConfig) && (
        <td className="px-[3px] w-[85px] text-center">
          <CheckBox
            field={'approval_status'}
            checkedValue={requestApproved}
            onChange={handleChangeRequest}
            disabled={!isFacultyPortal || readOnly}
            styles={classNames('w-3.5 h-3.5', {
              'bg-gray-100': !isFacultyPortal || readOnlyPortalStatus,
              'bg-gray-400':
                (readOnlyPortalStatus && requestApproved) || (!isFacultyPortal && requestApproved),
            })}
          />
        </td>
      )}
      <td className="w-auto">{/* empty */}</td>
      <td className="w-12 p-1 text-center">{(courseRequest.credits || 0).toFixed(2)}</td>
      <td className="w-[84px] p-1 pl-5 text-center">
        {requestApproved && (
          <div className="group relative cursor-help">
            <i className="nc-icon-glyph ui-1_check-curve text-green-4 text-xl"></i>
            <span className={tooltipClasses}>Course Request Approved!</span>
          </div>
        )}
        {requestDenied && (
          <div className="group relative cursor-help">
            <i className="nc-icon-glyph ui-2_ban text-neutral-3 text-xl"></i>
            <span className={tooltipClasses}>Course Request Denied</span>
          </div>
        )}
        {canDeleteRequest && (
          <button
            onClick={handleRemoveRequest}
            disabled={readOnly}
            className="text-red-4"
          >
            <i className="nc-icon-glyph ui-1_trash text-xl leading-7"></i>
          </button>
        )}
      </td>
    </tr>
  );
};

export default RequestCardBody;
