// Cohort Day Presence Status
export const COHORT_DAY_IN_PERSON = 'In Person';
export const COHORT_DAY_REMOTE = 'Remote';

// Attendance Statuses
export const ATTENDANCE_STATUS_NOT_AVAILABLE = 0;
export const ATTENDANCE_STATUS_NOT_TAKEN = 1;
export const ATTENDANCE_STATUS_TAKEN = 2;

export const DATE_FORMAT = 'YYYY-MM-DD';

export const CHANGE_PERIOD = 'CHANGE_PERIOD';
export const UPDATE_PERIODS = 'UPDATE_PERIODS';

export const MASTER_NOTES_FIELD = 'master_attendance_notes';
export const CLASS_NOTES_FIELD = 'class_attendance_notes';

export const ATTENDANCE_CATEGORY_PRESENT = 0;
export const ATTENDANCE_CATEGORY_ABSENT = 1;
export const ATTENDANCE_CATEGORY_TARDY = 2;
export const ATTENDANCE_CATEGORY_EARLY_DISMISSAL = 3;
export const ATTENDANCE_STATUS_IMPERFECT_CLASS_ATTENDANCE_ID = 70;

export const ATTENDANCE_STATUS = 'attendance_status';

export const TOGGLE = 'toggle';

export const isAttendanceRemote = (attendance = {}) => {
  return attendance.cohort_status === COHORT_DAY_REMOTE;
};

export const isAttendanceTaken = (attendanceStatus) => attendanceStatus === ATTENDANCE_STATUS_TAKEN;

export const isAttendanceLocked = (attendance, isMaster) => {
  if (!attendance) {
    return false;
  }

  return isMaster ? attendance.attendance_locked : attendance.class_attendance_locked;
};

export const convertListToObject = (list, keyName) => {
  if (list && Array.isArray(list)) {
    return list.reduce((memo, values) => {
      memo[values[keyName]] = values;
      return memo;
    }, {});
  }
  return {};
};

export const convertAttendanceListToObject = (list) => {
  if (list && Array.isArray(list)) {
    return list.reduce((memo, values) => {
      memo[values.period] = {
        ...memo[values.period],
        [values.student_id]: values,
      };
      return memo;
    }, {});
  }
  return {};
};

export const getPeriodOptions = (periodData, selectedId) => {
  let nonSpecifiedBlockFound = false;

  const options = periodData.reduce((array, period) => {
    const option = {
      ...period,
      value: period.period_id,
      label: period.period_name,
    };
    if (period.period_id === 0) {
      if (nonSpecifiedBlockFound) {
        // only add the first non-specified block found
        return array;
      }
      nonSpecifiedBlockFound = true;
      option.label = 'No Block Specified';
    }
    array.push(option);
    return array;
  }, []);

  // Select earliest period unless changing period
  let selectedPeriod = options[0];
  if (typeof selectedId === 'number') {
    selectedPeriod = options.find((option) => option.value === selectedId);
  }
  return {
    options,
    selected: selectedPeriod,
  };
};

export const periodReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PERIODS:
      return getPeriodOptions(action.periodData, action.selectedPeriodId);
    case CHANGE_PERIOD:
      return {
        ...state,
        selected: action.newPeriod,
      };
    default:
      return state;
  }
};

export const settingsReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE:
      return {
        ...state,
        [action.name]: !state[action.name],
      };
    default:
      return state;
  }
};

export const initSettings = (propsConfig) => {
  const configNames = {
    hideSensitiveData: 'sensitive_data_mode_default',
    hidePhotos: 'hide_photos_default',
    areClassNotesSensitive: 'include_class_notes_in_sensitive_mode',
    groupByCohort: 'group_students_cohort_default',
    enableKeyboardShortcuts: 'enable_keyboard_shortcuts',
    showPronouns: 'display_pronouns',
  };

  const settings = {};
  Object.entries(configNames).forEach(([setting, config]) => {
    settings[setting] = Boolean(propsConfig[config]);
  });
  return settings;
};
