import React from 'react';
import { dropboxStatuses } from '../../../constants.js';

class Submit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assignmentSubmissionStatusUpdated: false,
      displaySuccessBanner: false,
    };
  }

  toggleStatus = (status) => {
    const { EXPECTING, RECEIVED } = dropboxStatuses;
    return status === EXPECTING ? RECEIVED : EXPECTING;
  };

  // if switching between assignments, clear out state and reset completion status
  componentDidUpdate(prevProps, prevState) {
    if (this.props.classAssignmentPersonPk != prevProps.classAssignmentPersonPk) {
      if (prevState.assignmentSubmissionStatusUpdated) {
        let newStatus = this.toggleStatus(prevProps.assignmentSubmissionStatusToggle);
        this.props.updateAssignmentStatusToggle(prevProps.classAssignmentPersonPk, newStatus);
        this.setState({ assignmentSubmissionStatusUpdated: false });
      }
      if (prevState.displaySuccessBanner) {
        this.setState({ displaySuccessBanner: false });
      }
    }
  }

  isChecked = () => {
    if (this.props.assignmentSubmissionStatusToggle === dropboxStatuses.RECEIVED) {
      return true;
    }

    return false;
  };

  isConfirmationCheckboxDisabled = () => {
    if (
      this.errorState() === this.errorStates.ALREADY_SUBMITTED ||
      this.errorState() === this.errorStates.NO_SUBMISSIONS
    ) {
      return true;
    }

    return false;
  };

  onCheckboxChange = () => {
    let newStatus = this.toggleStatus(this.props.assignmentSubmissionStatusToggle);
    this.props.updateAssignmentStatusToggle(this.props.classAssignmentPersonPk, newStatus);

    this.setState((prevState) => ({
      assignmentSubmissionStatusUpdated: !prevState.assignmentSubmissionStatusUpdated,
    }));
  };

  // Disable submission unless the user has toggled the checkbox to "YES", or if the assignment has already been submitted in the past
  confirmButtonVisible = () => {
    if (!this.isChecked()) {
      return false;
    } else if (!this.state.assignmentSubmissionStatusUpdated) {
      return false;
    }

    return true;
  };

  handleConfirmationSubmit = () => {
    $.ajax({
      method: 'PUT',
      url: Routes.assignment_submission_update_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
      }),
      data: {
        class_assignment_person_fk: this.props.classAssignmentPersonPk,
        assignment_submission_status: this.props.assignmentSubmissionStatusToggle,
      },
    })
      .then((data) => {
        this.props.updateAssignmentStatus(
          this.props.classAssignmentPersonPk,
          this.props.assignmentSubmissionStatusToggle
        );
        this.setState({
          assignmentSubmissionStatusUpdated: false,
          displaySuccessBanner: true,
        });
      })
      .fail((data) => {
        if (data.status === 403 && data.responseJSON.error === 'Submissions closed') {
          this.props.updateErrorMessage(this.errorStates.SUBMISSIONS_CLOSED);
        }
      });
  };

  // Once submitted, users can only set assignments as complete, they cannot remove completion
  // Users cannot mark complete until a submission exists
  errorStates = {
    NONE: null,
    ALREADY_SUBMITTED: 'This assignment has already been submitted.',
    NO_SUBMISSIONS: 'Add a submission before marking as complete.',
    SUBMISSIONS_CLOSED: 'Submissions are closed for this assignment.',
  };

  errorState = () => {
    if (!this.props.submissionsExist) {
      return this.errorStates.NO_SUBMISSIONS;
    } else if (this.isChecked() && !this.state.assignmentSubmissionStatusUpdated) {
      return this.errorStates.ALREADY_SUBMITTED;
    }

    return this.errorState.NONE;
  };

  evaluateErrorStatus = () => {
    let errorState = this.errorState();

    if (errorState) {
      this.props.updateErrorMessage(errorState);
    }
  };

  render() {
    return (
      <div className="assignment-submit ae-grid">
        <div className="vx-message-banner submit-confirmation-banner ae-grid__item item-sm-12">
          <h4>Is this assignment complete?</h4>
          <div className="submit-confirmation">
            <span onClick={this.evaluateErrorStatus}>
              <label className="vx-form-control vx-switch">
                <input
                  className="vx-switch__input"
                  type="checkbox"
                  id="section-confirm-switch"
                  checked={this.isChecked()}
                  onChange={this.onCheckboxChange}
                  disabled={this.isConfirmationCheckboxDisabled()}
                />
                <div className="vx-switch__slider">
                  <span className="vx-switch__text-on">YES</span>
                  <span className="vx-switch__text-off">NO</span>
                </div>
              </label>
            </span>
            {this.confirmButtonVisible() && (
              <button
                className="vx-button vx-button--success"
                name="section-confirm-button"
                onClick={this.handleConfirmationSubmit}
              >
                Confirm
              </button>
            )}
          </div>
        </div>
        {this.state.displaySuccessBanner && (
          <div className="submit-success vx-message-banner vx-message-banner--success item-sm-12">
            Assignment Submitted
          </div>
        )}
      </div>
    );
  }
}

export default Submit;
