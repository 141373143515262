import React from 'react';
import { createBrowserHistory } from 'history';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useGradebookActions } from '../../../stores/useGradebookStore';
import { GradebookConfigurations } from './types';
import GradebookPage from './grid/GradebookPage';
import SecondaryPage from './common/SecondaryPage';
import { getBasePath } from './helpers';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { SORT_BY_LOCAL_STORAGE_KEY, STALE_TIME_DURATION } from './constants';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME_DURATION,
    },
  },
});

const history = createBrowserHistory();
export const goBackToGrid = () => history.push(getBasePath());

type RouterProps = {
  authToken: string;
  classId: string;
  gradingPeriod: number;
  configurations: GradebookConfigurations;
  teacherPhotoUrl: string;
  className: string;
  startDate: string;
  endDate: string;
  today: string;
  uploaderOptions: Record<any, any>;
};
const Router = ({
  authToken,
  classId,
  gradingPeriod,
  configurations,
  teacherPhotoUrl,
  className,
  startDate,
  endDate,
  today,
  uploaderOptions,
}: RouterProps) => {
  const { initialize } = useGradebookActions();
  const [value, _] = useLocalStorage(SORT_BY_LOCAL_STORAGE_KEY, 'true');
  const newestFirst = value === 'true' ? true : false;
  initialize({
    authToken,
    classId,
    filters: { gradingPeriod, sortAssignmentsByNewest: newestFirst },
    configurations,
    teacherPhotoUrl,
    className,
    startDate,
    endDate,
    today,
    uploaderOptions,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <HistoryRouter
        history={history}
        basename={getBasePath()}
      >
        <Routes>
          <Route
            path="/"
            element={<GradebookPage />}
          />

          <Route
            path="/*"
            element={<SecondaryPage />}
          />
        </Routes>
      </HistoryRouter>
    </QueryClientProvider>
  );
};

export default Router;
