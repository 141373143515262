import React from 'react';
import VxLabel from '../fields/VxLabel';
import HelpText from '../fields/HelpText';
import PlainTextField from '../fields/PlainTextField';
import { FastField, useField, useFormikContext } from 'formik';
import VxErrorMessage from './VxErrorMessage';
import { ElementMetadata } from '../types';

const RadioField = ({ element, metadata }: ElementMetadata) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(metadata.dataKeypath);
  const hasError = meta.touched && meta.error;

  const renderPlaintext = () => {
    const item = element.element_value_list_items.find(({ value }) => value === field.value);
    const description = (item && item.description) || '';
    return <PlainTextField>{description}</PlainTextField>;
  };

  return (
    <>
      <VxLabel
        element={element}
        htmlFor={metadata.dataKeypath}
        hasError={hasError}
      />
      <HelpText
        element={element}
        hasError={hasError}
      />
      <div className="vx-form-control vx-radio-group">
        {metadata.isPlainText
          ? renderPlaintext()
          : element.element_value_list_items.map(({ description, value }, listItemIndex) => (
              <label
                key={value}
                data-testid={`${metadata.dataKeypath}_${listItemIndex}`}
                htmlFor={`${metadata.dataKeypath}_${listItemIndex}`}
                className="vx-radio-group__item"
              >
                <FastField
                  type="radio"
                  className="vx-radio-input"
                  name={metadata.dataKeypath}
                  id={`${metadata.dataKeypath}_${listItemIndex}`}
                  value={value}
                  disabled={isSubmitting}
                />
                <span
                  className="vx-radio-group__label"
                  data-option-value={description}
                >
                  {description}
                </span>
              </label>
            ))}
      </div>
      <VxErrorMessage
        element={element}
        metadata={metadata}
      />
    </>
  );
};

export default RadioField;
