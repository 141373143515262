import React, { useState, useEffect } from 'react';
import { handleCreateRequest } from './Endpoints';
import { useFetch } from '../../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import usePhotoGalleryStore from '../../../stores/usePhotoGalleryStore';
import Modal from '../../shared/Modal';

function AddGalleryHeader({ internalClassId }) {
  const history = useNavigate();
  const [galleryName, setGalleryName] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { data: createResponse, sendFetchRequest: createGalleryRequest } = useFetch();

  const [errorMessage, setErrorMessage] = useState(null);

  const { actions } = usePhotoGalleryStore();

  const handleAddGallery = async () => {
    const requestFormData = {
      course: internalClassId,
      galleryName: galleryName,
    };
    try {
      await handleCreateRequest(requestFormData, createGalleryRequest);
      setIsPopupOpen(false);
    } catch (error) {
      setErrorMessage('Something went wrong while creating the gallery. Please try again.');
    }
  };

  useEffect(() => {
    if (createResponse) {
      actions.addGallery(createResponse.data.gallery);
      const url = Routes.class_admin_photos_gallery_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        internal_class_id: internalClassId,
        gallery_id: createResponse.data.gallery.gallery_pk,
      });
      history(url);
    }
  }, [createResponse]);

  return (
    <div>
      <div className="pl-5 pt-2 pb-2">
        <button
          className="bg-green-4 text-white rounded-md h-8 py-1 px-2.5 flex inline-flex items-center"
          onClick={() => setIsPopupOpen(true)}
        >
          <i className="nc-icon-glyph ui-1_circle-bold-add text-sm leading-6 bg-green mr-2" />
          <span>Add Gallery</span>
        </button>
        <Modal
          isOpen={Boolean(isPopupOpen)}
          onSave={handleAddGallery}
          overrideClasses="bg-blue-3 hover:bg-blue-4"
          label="Create"
          setIsOpen={setIsPopupOpen}
          isSubmitDisabled={galleryName.trim() === ''}
        >
          <h2 className="text-lg font-bold text-sky-700">New Gallery</h2>
          <input
            type="text"
            value={galleryName}
            onChange={(e) => setGalleryName(e.target.value)}
            placeholder="NAME"
            className="my-1 block w-full px-1 py-1 border border-gray-300 shadow-sm focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm rounded"
          />
        </Modal>
      </div>
      <hr className="border-gray-300 border-t" />
      <div className="py-2 px-2">
        <h3>All Galleries</h3>
      </div>
      <br />
      <Modal
        isOpen={Boolean(errorMessage)}
        onSave={() => {}}
        setIsOpen={() => setErrorMessage(null)}
        showButtons={false}
      >
        <h2 className="text-lg font-bold text-sky-700">Error</h2>
        <p className="mb-2">{errorMessage}</p>
      </Modal>
    </div>
  );
}

export default AddGalleryHeader;
