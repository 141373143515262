import React from 'react';
import File from './File.js';

const Submission = ({ submission, files }) => {
  return (
    <div>
      <div className="ae-grid vx-data-field submission">
        <div className="ae-grid-item item-xs-1">
          <img
            className="vx-avatar vx-avatar--small submission-photo"
            src={submission.photo_url}
          />
        </div>
        <div className="ae-grid-item item-xs-11">
          <h6>{submission.submitter_name}</h6>
          <small className="vx-subtitle">{submission.submission_date}</small>
        </div>
        <div className="ae-grid-item item-xs-12">
          <p className="vx-data-field_content">{submission.notes}</p>
        </div>
        <div className="file-wrapper ae-grid">
          {files.map((file) => {
            if (
              file.class_assignment_person_submission_pk ===
              submission.class_assignment_person_submission_pk
            ) {
              return (
                <File
                  key={file.file_pk}
                  file={file}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Submission;
