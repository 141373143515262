import React from 'react';
import classNames from 'classnames';

const defaultConfig = {
  isCollapsedByDefault: true,
  toggleText: ['Show More', 'Show Less'],
  toggleIcon: ['arrows-1_bold-down', 'arrows-1_bold-up'],
  toggleLocation: 'bottom',
  toggleClassName: '',
  contentClassName: '',
  onReveal: () => {},
};

export default function Collapsible(props) {
  const COLLAPSED_SCROLL_HEIGHT = 0;

  const config = { ...defaultConfig, ...props };
  const initialIsCollapsed = config.isCollapsedByDefault;
  const isCollapsibleToggleAtTop = config.toggleLocation === 'top';
  const isCollapsibleToggleAtBottom = !isCollapsibleToggleAtTop;

  const toggleClassNames = classNames('collapsible__toggle', config.toggleClassName);
  const contentClassNames = classNames('collapsible__content', config.contentClassName);

  const [isCollapsed, setIsCollapsed] = React.useState(initialIsCollapsed);
  const toggleCollapsible = () => setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);

  const [maxHeight, setMaxHeight] = React.useState(COLLAPSED_SCROLL_HEIGHT);

  const collapsibleRef = React.useRef();

  const onReveal = config.onReveal;

  React.useEffect(() => {
    const scrollHeight = isCollapsed
      ? COLLAPSED_SCROLL_HEIGHT
      : collapsibleRef.current.scrollHeight;
    setMaxHeight(scrollHeight);

    if (!isCollapsed) {
      onReveal();
    }
  }, [isCollapsed]);

  const collapsibleToggleText = isCollapsed ? config.toggleText[0] : config.toggleText[1];
  const collapsibleToggleIcon = isCollapsed ? config.toggleIcon[0] : config.toggleIcon[1];

  const CollapsibleToggle = (
    <div
      className={toggleClassNames}
      onClick={toggleCollapsible}
    >
      {collapsibleToggleText}
      <i className={`nc-icon-glyph ${collapsibleToggleIcon}`} />
    </div>
  );

  return (
    <div className="collapsible">
      {isCollapsibleToggleAtTop && CollapsibleToggle}
      <div
        ref={collapsibleRef}
        className={contentClassNames}
        style={{ maxHeight: `${maxHeight}px` }}
      >
        {props.children}
      </div>
      {isCollapsibleToggleAtBottom && CollapsibleToggle}
    </div>
  );
}
