import React from 'react';
import PersonDisplay from '../common/PersonDisplay';
import { useConfigurations } from '../../../../stores/useGradebookStore';
import { Student } from '../types';
import { getStudentColumnWidth } from '../helpers';
import StudentGrades from './StudentGrades';

type MobileStudentRowProps = {
  student: Student;
  openSidebar: () => void;
  isInSidebar?: boolean;
};
const MobileStudentRow = ({ student, isInSidebar = false, openSidebar }: MobileStudentRowProps) => {
  const { hide_gradebook_photos: hidePhotos } = useConfigurations();

  if (isInSidebar) {
    return (
      <div className="ml-5 h-fit flex flex-col justify-evenly">
        <StudentGrades
          student={student}
          showFieldLabels={true}
        />
      </div>
    );
  }

  return (
    <div
      className="md:hidden flex h-20 mb-3 border border-neutral-5 items-center"
      onClick={openSidebar}
    >
      <div className={`${getStudentColumnWidth(hidePhotos)} pl-5`}>
        <PersonDisplay
          avatarUrl={student.photo_url}
          title={student.first_name}
          subtitle={student.last_name}
          isLarge={true}
        />
      </div>
    </div>
  );
};

export default MobileStudentRow;
