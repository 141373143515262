import { CompletionStatus, DropboxStatus, COMPLETION_STATUS } from './constants';

export type GridData = {
  assignments: Assignment[];
  assignmentsObj: Record<string, Assignment>;
  assignment_grades_by_student: Record<string, Grade>;
  assignment_statuses: ZgStatus[];
  dropbox_statuses: ZgStatus[];
  students: Student[];
  grading_period: number;
  assignment_files: Record<string, File[]>;
};

export type InputChildProps = {
  value: string | boolean | number;
  onChange: (a) => void;
  isDisabled?: boolean;
  errorMessage?: string;
  statuses?: ZgStatus[];
};

export type WeightConfiguration = {
  weight: string;
  drop_lowest: boolean;
  assignment_type?: number;
  grading_period?: number;
  class_grading_configuration_pk?: number;
};

export type AssignmentType = ZgStatus & {
  color: string;
};

export type ClassGradingConfiguration = Record<number, WeightConfiguration[]>;

export type ClassGrades = Record<string, ClassGrade>;

export type UrlPrefix = 'students' | 'assignment';

export type ClassGrade = {
  class_person_grade_pk: number;
  calculated_grade: string;
  predicted_grade: string;
  grading_period: number;
  person_fk: number;
};

export type TotalsByGradingPeriod = Record<string, TotalsByAssignmentType>;
export type TotalsByAssignmentType = Record<string, { count: number; weight: number }>;

export type Assignment = {
  applies_to_all_sections: boolean;
  assignment_date: string;
  assignment_type: number;
  grading_method?: number;
  class_assignment_pk: number;
  class_fk: number;
  description: string;
  display_status: boolean;
  display_on_report_card: boolean;
  due_date: string;
  extra_credit: boolean;
  teacher_notes?: string;
  grading_period: number;
  grade_conversion_scale: number;
  zg_grade_conversion_scale_levels: ConversionLevel[];
  maximum_score: number;
  nextAssignmentId?: number;
  include_in_calc_grade: boolean;
  notes: string;
  prevAssignmentId?: number;
  weight: number;
  abbreviation: string;
  type_description: string;
  id: number;
  color: string | null;
  todayLine?: { showLine: boolean; showLineAfter: boolean };
};

export type AssignmentFormValues = {
  applies_to_all_sections: boolean;
  assignment_date: string;
  assignment_type: number;
  description: string;
  display_status: number;
  display_on_report_card: boolean;
  due_date: string;
  extra_credit: boolean;
  grade_conversion_scale: number;
  grading_method: number;
  maximum_score: number;
  include_in_calc_grade: boolean;
  notes: string;
  teacher_notes: string;
  weight: number;
};

export type ConversionScales = Record<number, ConversionLevel[]>;

export type ConversionScale = {
  description: string;
  id: number;
  minimum_passing_grade?: string;
  zg_grade_conversion_scale_levels: ConversionLevel[];
};

export type ConversionLevel = {
  id: number;
  letter: string;
  minimum_value: number;
  stored_value: number;
};

export type ArrayStatsType = {
  mean: number;
  median: number;
  stdev: number;
};

export type Grade = {
  class_assignment_person_pk: number;
  class_assignment_fk: number;
  person_fk: number;
  completion_status: number;
  publish_status: number;
  dropbox_status: string;
  dropbox_status_int: number;
  notes: string;
  score: string;
  conversion_scale?: ConversionLevel[];
  scale_level?: ConversionLevel;
  notification_count?: number;
};

export type Student = {
  class_person_pk: number;
  person_pk: number;
  first_name: string;
  last_name: string;
  person_fk: number;
  photo_url: string;
  grade_detail_url: string;
  date_withdrawn?: string;
  late_date_enrolled?: string;
};

export type ZgStatus = {
  description: string;
  id: number;
  keyboard_shortcut?: string;
  begin_date?: string;
  end_date?: string;
};

export type ShortcutTrigger = {
  type: string;
  id: number;
};

export type FormElementCommonProps = {
  currentValue: string | number | boolean;
  saveValue?: any;
};

export type UpdateObjectParams = {
  student_id: string;
  assignment_id: string;
  updated_field: string;
  updated_object: Grade | Assignment;
};

export type LocationState = {
  bannerMessage?: string;
  notAuthorized?: string;
};

export type StudentSidebarTab = 'grades' | 'feedback' | 'note' | 'notifications';

export type AssignmentFeedbackCondensed = number[];

export type AssignmentFeedback = {
  messages: AssignmentFeedbackMessage[];
  files: File[];
};

export type AssignmentFeedbackMessage = {
  feedback_id: number;
  notes: string;
  record_type: string;
  student_read_status: boolean;
  submission_date: string;
  submission_user: string;
  teacher_read_status: boolean;
};

export type File = {
  file_pk: number;
  description: string;
  feedback_id: number;
  id?: number;
  url: string;
};

export type GradebookConfigurations = {
  calculated_grade_label: string;
  display_calculated_grade_column: boolean;
  display_predicted_grade_column: boolean;
  predicted_grade_format: string;
  predicted_grade_grading_period: string;
  enabled: boolean;
  gradebook_label: string;
  hide_gradebook_photos: boolean;
  predicted_grade_label: string;
  enable_keyboard_shortcuts: boolean;
  show_display_on_report_card_flag: boolean;
  assignment_max_score_teacher_access_status: AccessStatus;
  assignment_weight_teacher_access_status: AccessStatus;
  default_assignment_max_score_value: number;
  default_assignment_weight_value: number;
  display_class_mean_row: boolean;
  display_class_median_row: boolean;
  display_class_standard_deviation_row: boolean;
  display_date_withdrawn: boolean;
  display_late_date_enrolled: boolean;
};

export const AccessStatuses = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  READ_ONLY: 'read-only',
} as const;

export type AccessStatus = (typeof AccessStatuses)[keyof typeof AccessStatuses];

export type GradingMethod = {
  id: number;
  description: string;
  active: boolean;
};

export type ConversionScalesResponseObject = {
  grade_conversion_scales: ConversionScale[];
  class_scale?: ConversionScale;
};

export type FilterState = {
  gradingPeriod: number;
  assignmentType?: number;
  sortAssignmentsByNewest: boolean;
};

export type ClassState = {
  authToken: string;
  classId: string;
  configurations: GradebookConfigurations;
  teacherPhotoUrl: string;
  className: string;
  filters: FilterState;
  startDate: string;
  endDate: string;
  today: string;
  uploaderOptions: Record<any, any>;
};

export type Actions = {
  initialize: (classState: ClassState) => any;
  setGradingPeriod: (newGradingPeriod: number) => any;
  setAssignmentType: (newAssignmentType: number) => any;
  toggleSortAssignmentsByNewest: () => any;
};

export type GradebookState = {
  classState: ClassState;
  actions: Actions;
};

export type EmailNotification = 'student' | 'parents' | 'advisor' | 'homeroom';

export type EmailNotifications = Record<EmailNotification, boolean>;

export type AssignmentFormFieldProps = {
  onChange: (
    value: string | number,
    shouldSaveToTheDatabase?: boolean,
    justNotifyOfUnsavedChanges?: boolean
  ) => void;
  value: string | number;
  isSuccessNotifying?: boolean;
};

export type EditOptions = {
  raw_score: { value: string; checked: boolean };
  dropbox_status: { value: DropboxStatus; checked: boolean };
  completion_status: { value: CompletionStatus; checked: boolean };
  publish_status: { value: boolean; checked: boolean };
};

export type ConversionLevelOption = ConversionLevel & {
  value: number;
  label: string;
};
